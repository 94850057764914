import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { api_urls, routes, tasks_constants } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormDataMachines,
  setFormDataPersonnel,
  setHighlightedRecord,
  setFormErrors,
} from "../../../../store/employee/tasks/taskReducer";
import { resetForm } from "../../../../store/employee/tasks/taskActions";
import DivLoader from "../../../utils/DivLoaderComp";
import {
  InputTextInline,
  SelectInputInline,
  SelectMultiInline,
} from "../../../utils/Forms/InlineInputs";

export default function TaskCreate() {
  const history = useHistory();
  const [isPageLoading, SetIsPageLoading] = useState(false);
  const [modifiedTaskTypeOptions, SetModifiedTaskTypeOptions] = useState([]);
  const [modifiedInquiryOptions, SetModifiedInquiryOptions] = useState([]);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.task.formData);
  const formDataMachines = useSelector((state) => state.task.formDataMachines);
  const formDataPersonnel = useSelector(
    (state) => state.task.formDataPersonnel
  );
  const formErrors = useSelector((state) => state.task.formErrors);
  const machineOptions = useSelector((state) => state.machine.options);
  const personnelOptions = useSelector((state) => state.personnel.options);
  const taskTypeOptions = useSelector((state) => state.taskType.options);
  const inquiryOptions = useSelector(
    (state) => state.task.formDataInquiryValidForTask
  );

  useEffect(() => {
    const newTaskTypeOptions = Object.assign([], taskTypeOptions);
    const newInquiryOptions = Object.assign([], inquiryOptions);
    newTaskTypeOptions.unshift({ value: "", label: "Select" });
    newInquiryOptions.unshift({ value: "", label: "Select" });
    SetModifiedTaskTypeOptions(newTaskTypeOptions);
    SetModifiedInquiryOptions(newInquiryOptions);
  }, [dispatch, taskTypeOptions, inquiryOptions]);

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Tasks",
          subtitle: "Create Task",
          icon: "icon-bookmark",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK,
              name: "Manage Task",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/create`,
              name: "Create Task",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const redirectBackToTaskList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_TASKS_TASK),
    [history]
  );

  const handleCreate = (e, isa) => {
    e.preventDefault();
    SetIsPageLoading(true);
    const machines = formDataMachines.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
    const personnel = formDataPersonnel.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
    axios
      .post(`${api_urls.TASKS_TASK}/`, {
        task_type: formData.taskType?.value,
        description: formData.description,
        machine_ids: machines,
        personnel_ids: personnel,
        status: formData.status.value,
        jo_number: formData.JONumber?.value,
        datetime_from: formData.datetimeFrom,
        datetime_to: formData.datetimeTo,
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      })
      .then((response) => {
        dispatch(resetForm());
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Task Successfully Created!",
            },
          })
        );
        dispatch(setHighlightedRecord(response.data.id));
        if (isa === 0) {
          redirectBackToTaskList();
        }
        SetIsPageLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              taskType: field_errors.task_type?.toString(),
              description: field_errors.description?.toString(),
              machine: field_errors.machine_ids?.toString(),
              personnel: field_errors.personnel_ids?.toString(),
              status: field_errors.status?.toString(),
              JONumber: field_errors.jo_number?.toString(),
              datetimeFrom: field_errors.datetime_from?.toString(),
              datetimeTo: field_errors.datetime_to?.toString(),
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetIsPageLoading(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={isPageLoading} />
        <div className="card-header">
          <h5>Create Task</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_TASK}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="col-md-12">
            <SelectInputInline
              type="text"
              label="Task Type:"
              isDisabled={false}
              options={modifiedTaskTypeOptions}
              errorField={formErrors.taskType}
              value={formData.taskType}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, taskType: value }))
              }
            />

            <InputTextInline
              type="text"
              label="Description:"
              placeholder="Description"
              errorField={formErrors.description}
              value={formData.description}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, description: e.target.value })
                )
              }
            />

            <SelectMultiInline
              label="Equipments:"
              name="machines"
              value={formDataMachines}
              errorField={formErrors.machines}
              options={machineOptions}
              onChange={(values) => dispatch(setFormDataMachines(values))}
              closeMenuOnSelect={false}
              defaultMenuIsOpen={false}
            />

            <SelectMultiInline
              label="Personnel:"
              name="personnel"
              value={formDataPersonnel}
              errorField={formErrors.personnel}
              options={personnelOptions}
              onChange={(values) => dispatch(setFormDataPersonnel(values))}
              closeMenuOnSelect={false}
              defaultMenuIsOpen={false}
            />

            <SelectInputInline
              type="text"
              label="Status:"
              isDisabled={false}
              options={tasks_constants.TASK_STATUS_DROPDOWN}
              errorField={formErrors.status}
              value={formData.status}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, status: value }))
              }
            />

            <SelectInputInline
              type="text"
              label="JO Number:"
              isDisabled={false}
              options={modifiedInquiryOptions}
              errorField={formErrors.JONumber}
              value={formData.JONumber}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, JONumber: value }))
              }
            />

            <InputTextInline
              type="datetime-local"
              label="Start Date:"
              placeholder="Start Date"
              errorField={formErrors.datetimeFrom}
              value={formData.datetimeFrom}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, datetimeFrom: e.target.value })
                )
              }
            />

            <InputTextInline
              type="datetime-local"
              label="End Date:"
              placeholder="End Date"
              errorField={formErrors.datetimeTo}
              value={formData.datetimeTo}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, datetimeTo: e.target.value })
                )
              }
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 1)}
              >
                Save and add another
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={() => dispatch(resetForm())}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
