import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import {
  retrievePersonnel,
  fetchTasksByPersonnel,
} from "../../../../store/employee/tasks/personnelActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";
import { TaskStatusLabel } from "./molecules/TaskStatusLabel";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";

export default function PersonnelDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);
  const [tasksPagination, SetTasksPagination] = useState({
    pagePrev: 0,
    pageCurrent: 1,
    pageNext: 2,
    pageSize: 10,
  });

  const history = useHistory();
  const { personnel_id } = useParams();
  const dispatch = useDispatch();
  const personnelDetails = useSelector((state) => state.personnel.details);
  const personnelDetailsTasks = useSelector(
    (state) => state.personnel.detailsTasks
  );

  useEffect(() => {
    dispatch(retrievePersonnel({ personnel_id: personnel_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Personnels",
          subtitle: "Personnel Details",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL,
              name: "Manage Personnels",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/${personnel_id}`,
              name: "Personnel Details",
            },
          ],
        },
      })
    );
  }, [dispatch, personnel_id]);

  useEffect(() => {
    dispatch(
      fetchTasksByPersonnel({ personnel_id, pagination: tasksPagination })
    );
  }, [dispatch, personnel_id, tasksPagination]);

  const redirectBackToPersonnelList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL),
    [history]
  );

  const handleDeletePersonnelSubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(`${api_urls.TASKS_PERSONNEL}/${personnel_id}/`)
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Personnel Successfully Deleted!",
            },
          })
        );
        redirectBackToPersonnelList();
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    if (willCurrent > 0 && willCurrent <= personnelDetailsTasks.limit) {
      SetTasksPagination({
        pagePrev: willCurrent - 1,
        pageCurrent: willCurrent,
        pageNext: willCurrent + 1,
        pageSize: 10,
      });
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Personnel Details</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/${personnel_id}/edit`}
            className="btn btn-md btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-pencil-square-o"></i> Edit
          </Link>
          <button
            className="btn btn-md btn-danger btn-outline-danger float-right pt-2 pb-2"
            onClick={() => SetIsOpenDeleteModal(true)}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-md-3">
              <p>Fullname:</p>
              <p>Position:</p>
              <p>Email:</p>
              <p>Contact No:</p>
              <p>Created at:</p>
              <p>Updated at:</p>
              <p>Created by:</p>
              <p>Updated by:</p>
            </div>
            <div className="col-md-9">
              <p>
                {personnelDetails.fullname}
                {"\u00A0"}
              </p>
              <p>
                {personnelDetails.position}
                {"\u00A0"}
              </p>
              <p>
                {personnelDetails.email}
                {"\u00A0"}
              </p>
              <p>
                {personnelDetails.contactNo}
                {"\u00A0"}
              </p>
              <p>
                {moment(personnelDetails.createdAt).format(
                  "MM/DD/YYYY hh:mm A"
                )}
                {"\u00A0"}
              </p>
              <p>
                {moment(personnelDetails.updatedAt).format(
                  "MM/DD/YYYY hh:mm A"
                )}
                {"\u00A0"}
              </p>
              <p>
                {personnelDetails.createdBy?.fullname}
                {"\u00A0"}
              </p>
              <p>
                {personnelDetails.updatedBy?.fullname}
                {"\u00A0"}
              </p>
            </div>
            <div className="col-md-12 mt-4">
              <h4>Assigned Tasks</h4>
              <div className="card-block table-border-style">
                <div className="table-responsive">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="align-middle">Task ID</th>
                        <th className="align-middle">Title</th>
                        <th className="align-middle">Status</th>
                        <th className="align-middle">From</th>
                        <th className="align-middle">To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {personnelDetailsTasks.records.map((val, key) => {
                        return (
                          <tr>
                            <td className="align-middle">TASK-{val.id}</td>
                            <td className="align-middle">{val.title}</td>
                            <td className="align-middle">
                              <TaskStatusLabel status={val.status} />
                            </td>
                            <td className="align-middle">
                              {moment(val.datetime_from).format(
                                "MMM DD, YYYY hh:mm A"
                              )}
                            </td>
                            <td className="align-middle">
                              {moment(val.datetime_to).format(
                                "MMM DD, YYYY hh:mm A"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Table Footer */}
                <div className="card-footer">
                  <TableFooterDefault
                    counterPageSize={tasksPagination.pageSize}
                    counterPageCurrent={tasksPagination.pageCurrent}
                    counterPageLimit={personnelDetailsTasks.limit}
                    counterTotalRecords={personnelDetailsTasks.totalCount}
                    paginationPagePrev={tasksPagination.pagePrev}
                    paginationPageNext={tasksPagination.pageNext}
                    paginationPageLimit={personnelDetailsTasks.limit}
                    paginationPrevClickHandler={(e) =>
                      handlePaginationClick(e, tasksPagination.pagePrev)
                    }
                    paginationNextClickHandler={(e) =>
                      handlePaginationClick(e, tasksPagination.pageNext)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeletePersonnelSubmit}
      />
    </div>
  );
}
