import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import { getLabServicesStatistics } from "../../../../store/employee/dashboardActions";
import NewInquiriesTable from "./NewInquiriesTable";
import InquiryCountCard from "./InquiryCountCard";
import TaskCountCard from "./TaskCountCard";
import TaskTable from "./TaskTable";

export default function Home() {
  const dispatch = useDispatch();
  const {
    inquiryTodayCount,
    inquiryMonthCount,
    inquiryIsUpdatedByClientCount,
    inquiryIsUpdatedByClientRecord,
    taskInQueueCount,
    taskInQueueRecord,
    taskOngoingCount,
    taskOngoingRecord,
    taskCompletedTodayCount,
    taskCompletedThisMonthCount,
  } = useSelector((state) => state.dashboard.labServicesStatistics);
  const labServicesStatisticsLoading = useSelector(
    (state) => state.dashboard.labServicesStatisticsLoading
  );

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Home Page",
          subtitle: "Welcome User!",
          icon: "icon-home",
          pages: [],
        },
      })
    );
    dispatch(getLabServicesStatistics());
  }, [dispatch]);

  return (
    <div className="row col-md-12">
      <div class="col-xl-8 col-md-12">
        <NewInquiriesTable
          inquiryIsUpdatedByClientRecord={inquiryIsUpdatedByClientRecord}
          labServicesStatisticsLoading={labServicesStatisticsLoading}
        />
      </div>
      <div class="col-xl-4 col-md-12">
        {/* New/ Unopen */}
        <InquiryCountCard
          color="yellow"
          title="New Lab Services Inquiries"
          body={`${inquiryIsUpdatedByClientCount} ${
            inquiryIsUpdatedByClientCount > 1 ? "Inquiries" : "Inquiry"
          }`}
          description={`You have ${inquiryIsUpdatedByClientCount} unopen lab services 
          ${inquiryIsUpdatedByClientCount > 1 ? "inquiries" : "inquiry"}`}
        />
        {/* TODAY */}
        <InquiryCountCard
          color="blue"
          title="Lab Services Inquiries Today"
          body={`
          ${inquiryTodayCount} ${
            inquiryTodayCount > 1 ? "Inquiries" : "Inquiry"
          }`}
          description={`You have ${inquiryTodayCount} total lab services
          ${inquiryTodayCount > 1 ? "inquiries" : "inquiry"} today`}
        />
        {/* MONTH */}
        <InquiryCountCard
          color="green"
          title="Labservices Inquiries this Month"
          body={`${inquiryMonthCount} ${
            inquiryMonthCount > 1 ? "Inquiries" : "Inquiry"
          }`}
          description={`
          You have ${inquiryMonthCount} total lab services ${
            inquiryMonthCount > 1 ? "inquiries" : "inquiry"
          } this month`}
        />
      </div>
      <div className="col-xl-12">
        <TaskCountCard
          taskInQueueCount={taskInQueueCount}
          taskOngoingCount={taskOngoingCount}
          taskCompletedTodayCount={taskCompletedTodayCount}
          taskCompletedThisMonthCount={taskCompletedThisMonthCount}
        />
      </div>
      <div class="col-xl-6 col-md-12">
        <TaskTable
          title="In Queue Tasks"
          record={taskInQueueRecord}
          isLoading={labServicesStatisticsLoading}
        />
      </div>
      <div class="col-xl-6 col-md-12">
        <TaskTable
          title="Ongoing Tasks"
          record={taskOngoingRecord}
          isLoading={labServicesStatisticsLoading}
        />
      </div>
    </div>
  );
}
