export default function InquiryInstructions() {
  return (
    <ul>
      <li>
        <h4 class="mb-3">PART I: FILLING OUT THE INQUIRY FORM</h4>

        <h6>General Instructions:</h6>
        <ul class="mb-4">
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 1: Fill out the
            Inquiry Form completely. Fill out the form and attach documents as
            required under Specific Instructions.
          </li>
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 2: Wait for an
            email containing your log in credentials to be sent to the email
            address provided.
          </li>
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 3: Activate your
            account. Go to Herbanext Portal Clientlink provided in the email and
            log in using the username and password provided.
          </li>
        </ul>

        <h6>Specific Instructions:</h6>
        <dl class="dl-horizontal row">
          <dt class="col-sm-3">For business-affiliated customers:</dt>
          <dd class="col-sm-9">
            Indicate profession or degree (if applicable) and your position in
            company.
          </dd>
          <dd class="col-sm-3">Documents to upload:</dd>
          <dd class="col-sm-9">
            None. May require other documents depending on nature of request.
          </dd>
          <dt class="col-sm-3">Academic staff:</dt>
          <dd class="col-sm-9">
            Indicate profession or degree, and position in academe.
          </dd>
          <dd class="col-sm-3">Documents to upload:</dd>
          <dd class="col-sm-9">Research Methodology/Research Paper</dd>
          <dt class="col-sm-3 ">Student researchers:</dt>
          <dd class="col-sm-9">Indicate course/program</dd>
          <dd class="col-sm-3">Documents to upload: </dd>
          <dd class="col-sm-9">
            Valid Student ID, Cover letter signed by adviser (indicate purpose
            of experiment or purchase), Research Methodology/Research Paper
          </dd>
        </dl>
      </li>

      <li>
        <h4 class="mb-3">PART II: FINALIZING REQUESTS</h4>

        <ul class="mb-4">
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 1: Log in to your
            account to check for updates on the status of inquiry/request.
            Notifications will also be sent through the email linked to your
            account. Please check your SPAM folder.j
          </li>
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 2: Once price
            quotation is received, return a signed copy by uploading to your
            account. For questions on the quotation, place your questions in the
            comment section. To request for another quotation, please generate a
            new inquiry and upload the pertinent documents. Documents such as
            Research Methodology serve as basis for price quotation.
          </li>
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 3: To finalize
            your request, accomplish the Laboratory Service Request (or LSR)
            Form to be sent by Herbanext. Upload a signed copy to your account.
          </li>
          <li>
            <i class="icofont icofont-bubble-right"></i> Step 4: Check for
            updates by accessing your account anytime.
          </li>
        </ul>
      </li>

      <li>
        <h4 class="mb-3">PART III: PAYMENT</h4>

        <ul>
          <li class="mb-3">
            <i class="icofont icofont-bubble-right"></i> Step 1: Upload a proof
            of your payment (i.e., bank deposit slip). You may settle your fees
            before the completion of the service or once service has been tagged
            as finished.
          </li>
          <span class="f-w-700">BDO (Banco De Oro)</span>
          <ul class="mb-2">
            <li>Account name: Herbanext Laboratories Inc</li>
            <li>Account number: 003030116977</li>
          </ul>
          <span class="f-w-700">GCash:</span>
          <ul>
            <li>Account name: Don Theon Cruz</li>
            <li>Account number: 09177754177</li>
          </ul>

          <li class="mt-3">
            <i class="icofont icofont-bubble-right"></i> Step 2: Wait for the
            Official Receipt, Test Reports, and other pertinent documents to be
            released through your account.
          </li>
        </ul>
      </li>
    </ul>
  );
}
