import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { api_urls, routes, tasks_constants } from "../../../../constants";
import {
  retrieveMachine,
  fetchTasksByMachine,
} from "../../../../store/employee/tasks/machineActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TaskStatusLabel } from "./molecules/TaskStatusLabel";

export default function MachineDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);
  const [tasksPagination, SetTasksPagination] = useState({
    pagePrev: 0,
    pageCurrent: 1,
    pageNext: 2,
    pageSize: 10,
  });

  const history = useHistory();
  const { machine_id } = useParams();
  const dispatch = useDispatch();
  const machineDetails = useSelector((state) => state.machine.details);
  const machineDetailsTasks = useSelector(
    (state) => state.machine.detailsTasks
  );

  useEffect(() => {
    dispatch(retrieveMachine({ machine_id: machine_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Equipments",
          subtitle: "Equipment Details",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE,
              name: "Manage Equipments",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}/${machine_id}`,
              name: "Equipment Details",
            },
          ],
        },
      })
    );
  }, [dispatch, machine_id]);

  useEffect(() => {
    dispatch(fetchTasksByMachine({ machine_id, pagination: tasksPagination }));
  }, [dispatch, machine_id, tasksPagination]);

  const redirectBackToMachineList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE),
    [history]
  );

  const handleDeleteMachineSubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(`${api_urls.TASKS_MACHINE}/${machine_id}/`)
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Equipment Successfully Deleted!",
            },
          })
        );
        redirectBackToMachineList();
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    if (willCurrent > 0 && willCurrent <= machineDetailsTasks.limit) {
      SetTasksPagination({
        pagePrev: willCurrent - 1,
        pageCurrent: willCurrent,
        pageNext: willCurrent + 1,
        pageSize: 10,
      });
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case tasks_constants.MACHINE_STATUS_DROPDOWN[1].value:
        return (
          <label className="label label-md label-success">
            {tasks_constants.MACHINE_STATUS_DROPDOWN[1].label}
          </label>
        );
      case tasks_constants.MACHINE_STATUS_DROPDOWN[2].value:
        return (
          <label className="label label-md label-warning">
            {tasks_constants.MACHINE_STATUS_DROPDOWN[2].label}
          </label>
        );
      case tasks_constants.MACHINE_STATUS_DROPDOWN[3].value:
        return (
          <label className="label label-md label-danger">
            {tasks_constants.MACHINE_STATUS_DROPDOWN[3].label}
          </label>
        );
      case tasks_constants.MACHINE_STATUS_DROPDOWN[4].value:
        return (
          <label className="label label-md label-danger">
            {tasks_constants.MACHINE_STATUS_DROPDOWN[4].label}
          </label>
        );
      default:
        return "";
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Equipment Details</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}/${machine_id}/edit`}
            className="btn btn-md btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-pencil-square-o"></i> Edit
          </Link>
          <button
            className="btn btn-md btn-danger btn-outline-danger float-right pt-2 pb-2"
            onClick={() => SetIsOpenDeleteModal(true)}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-md-3">
              <p>Name:</p>
              <p>Description:</p>
              <p>Status:</p>
              <p>Created at:</p>
              <p>Updated at:</p>
              <p>Created by:</p>
              <p>Updated by:</p>
            </div>
            <div className="col-md-9">
              <p>
                {machineDetails.name}
                {"\u00A0"}
              </p>
              <p>
                {machineDetails.description}
                {"\u00A0"}
              </p>
              <p>
                {renderStatus(machineDetails.status)}
                {"\u00A0"}
              </p>
              <p>
                {moment(machineDetails.createdAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(machineDetails.updatedAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {machineDetails.createdBy?.fullname}
                {"\u00A0"}
              </p>
              <p>
                {machineDetails.updatedBy?.fullname}
                {"\u00A0"}
              </p>
            </div>
            <div className="col-md-12 mt-4">
              <h4>Related Tasks</h4>
              <div className="card-block table-border-style">
                <div className="table-responsive">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="align-middle">Task ID</th>
                        <th className="align-middle">Title</th>
                        <th className="align-middle">Status</th>
                        <th className="align-middle">From</th>
                        <th className="align-middle">To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {machineDetailsTasks.records.map((val, key) => {
                        return (
                          <tr>
                            <td className="align-middle">TASK-{val.id}</td>
                            <td className="align-middle">{val.title}</td>
                            <td className="align-middle">
                              <TaskStatusLabel status={val.status} />
                            </td>
                            <td className="align-middle">
                              {moment(val.datetime_from).format(
                                "MMM DD, YYYY hh:mm A"
                              )}
                            </td>
                            <td className="align-middle">
                              {moment(val.datetime_to).format(
                                "MMM DD, YYYY hh:mm A"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Table Footer */}
                <div className="card-footer">
                  <TableFooterDefault
                    counterPageSize={tasksPagination.pageSize}
                    counterPageCurrent={tasksPagination.pageCurrent}
                    counterPageLimit={machineDetailsTasks.limit}
                    counterTotalRecords={machineDetailsTasks.totalCount}
                    paginationPagePrev={tasksPagination.pagePrev}
                    paginationPageNext={tasksPagination.pageNext}
                    paginationPageLimit={machineDetailsTasks.limit}
                    paginationPrevClickHandler={(e) =>
                      handlePaginationClick(e, tasksPagination.pagePrev)
                    }
                    paginationNextClickHandler={(e) =>
                      handlePaginationClick(e, tasksPagination.pageNext)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteMachineSubmit}
      />
    </div>
  );
}
