import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageHeader: {
    title: "",
    subtitle: "",
    icon: "",
    pages: [],
  },
  toastNotification: {
    isOpen: false,
    type: "success",
    message: "",
  },
  labServicesStatistics: {
    inquiryTodayCount: 0,
    inquiryMonthCount: 0,
    inquiryIsUpdatedByClientCount: 0,
    inquiryIsUpdatedByClientRecord: [],
    taskInQueueCount: 0,
    taskInQueueRecord: [],
    taskOngoingCount: 0,
    taskOngoingRecord: [],
    taskCompletedTodayCount: 0,
    taskCompletedThisMonthCount: 0,
  },
  labServicesStatisticsLoading: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPageHeader(state, action) {
      state.pageHeader = action.payload.pageHeader;
    },
    setToastNotification(state, action) {
      state.toastNotification = action.payload.toastNotification;
    },
    setLabServicesStatistics(state, action) {
      state.labServicesStatistics = action.payload.labServicesStatistics;
    },
    setLabServicesStatisticsLoading(state, action) {
      state.labServicesStatisticsLoading =
        action.payload.labServicesStatisticsLoading;
    },
  },
});

export const {
  setPageHeader,
  setToastNotification,
  setLabServicesStatistics,
  setLabServicesStatisticsLoading,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
