import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { labservices_constants, routes } from "../../../../constants";

export default function NewInquiriesTable({
  inquiryIsUpdatedByClientRecord,
  labServicesStatisticsLoading,
}) {
  const history = useHistory();

  const getProcessStatusConstantLabel = (value) => {
    return labservices_constants.PROCESS_STATUS_DROPDOWN.find(
      (data) => data.value === value
    ).label;
  };

  const renderProcessStatusLabel = (processStatus, isDeclined) => {
    if (isDeclined) {
      return <label className="label label-md label-warning">Declined</label>;
    }
    if (
      processStatus === labservices_constants.PROCESS_STATUS_DROPDOWN[1].value
    ) {
      return (
        <label className="label label-md label-danger">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else if (
      processStatus === labservices_constants.PROCESS_STATUS_DROPDOWN[2].value
    ) {
      return (
        <label className="label label-md label-warning">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else {
      return (
        <label className="label label-md label-primary">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    }
  };

  const renderServiceType = (value) => {
    if (value === "") {
    }
    const serviceType = labservices_constants.SERVICE_TYPE_DROPDOWN.find(
      (data) => data.value === value
    );
    if (!serviceType || serviceType["label"] === "Select") {
      return "NA";
    }
    return serviceType["label"];
  };

  const redirectToInquiryDetails = useCallback(
    (id) =>
      history.push(routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_DETAILS + id),
    [history]
  );

  const handleOpenInquiryDetails = (e, id) => {
    e.preventDefault();
    redirectToInquiryDetails(id);
  };

  return (
    <div class="card table-card" style={{ height: "495px" }}>
      <div class="card-header">
        <h5>New / Updated Inquiries</h5>
      </div>
      <div class="card-block">
        <div class="table-responsive">
          <table class="table table-hover m-b-0 table-borderless">
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Status</th>
                <th>Service Type</th>
                <th>Date Encoded</th>
              </tr>
            </thead>
            <tbody>
              {inquiryIsUpdatedByClientRecord.length ? (
                inquiryIsUpdatedByClientRecord.map((data) => {
                  return (
                    <tr>
                      <td onClick={(e) => handleOpenInquiryDetails(e, data.id)}>
                        <div class="d-inline-block align-middle">
                          <h6>{data.requestor}</h6>
                        </div>
                      </td>
                      <td>{renderProcessStatusLabel(data.process_status)}</td>
                      <td>{renderServiceType(data.service_type)}</td>
                      <td>
                        {data.created_at
                          ? moment(data.created_at).format("MM/DD/YYYY hh:mm A")
                          : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
              {!inquiryIsUpdatedByClientRecord.length &&
              !labServicesStatisticsLoading ? (
                <tr style={{ textAlign: "center" }}>
                  <td colspan="4">
                    <h4>No Records Found!</h4>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
