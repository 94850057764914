import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setHighlightedRecord,
  setFormErrors,
} from "../../../../store/employee/menuReducer";
import {
  resetForm,
  addFormDataSubroutesRow,
  modifyFormDataSubroutesRow,
  deleteFormDataSubroutesRow,
} from "../../../../store/employee/menuActions";
import DivLoader from "../../../utils/DivLoaderComp";
import {
  InputTextInline,
  RadioButtonInline,
} from "../../../utils/Forms/InlineInputs";

const SubrouteInputField = (props) => {
  return (
    <input
      name={props.name}
      value={props.value}
      className="form-control"
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
};

const SubrouteFieldError = (props) => {
  if (props.errors) {
    let errors = [...props.errors];
    if (errors[props.errorkey]) {
      return (
        <div className="col-form-label">
          <p className="text-danger">
            {errors[props.errorkey][props.fieldName]}
          </p>
        </div>
      );
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export default function MenuCreate() {
  const history = useHistory();
  const [is_page_loading, SetIsPageLoading] = useState(false);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.menu.formData);
  const formDataSubroutes = useSelector(
    (state) => state.menu.formDataSubroutes
  );
  const formErrors = useSelector((state) => state.menu.formErrors);

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Menus",
          subtitle: "Create Menu",
          icon: "icon-menu",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_MENUS,
              name: "Manage Menus",
            },
            {
              url: routes.EMPLOYEE_DASHBOARD_MENUS_CREATE,
              name: "Create Menu",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const redirectBackToMenuList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_MENUS),
    [history]
  );

  const handleAddSubroutes = () => {
    dispatch(addFormDataSubroutesRow(formDataSubroutes));
  };

  const handleModifySubroutes = (key, e) => {
    dispatch(
      modifyFormDataSubroutesRow({
        existingFormDataSubroute: formDataSubroutes,
        key: key,
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleDeleteSubroutes = (key) => {
    dispatch(
      deleteFormDataSubroutesRow({
        existingFormDataSubroute: formDataSubroutes,
        key: key,
      })
    );
  };

  const handleCreate = (e, isa) => {
    e.preventDefault();
    SetIsPageLoading(true);
    axios
      .post(api_urls.ROUTE_STORE, {
        category: formData.category,
        name: formData.name,
        nav_name: formData.navName,
        url: formData.url,
        url_name: formData.urlName,
        icon: formData.icon,
        is_menu: formData.isMenu,
        is_dropdown: formData.isDropdown,
        subroutes: formDataSubroutes,
      })
      .then((response) => {
        dispatch(resetForm());
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Menu Successfully Created!",
            },
          })
        );
        dispatch(setHighlightedRecord({ id: response.data.id }));
        if (isa === 0) {
          redirectBackToMenuList();
        }
        SetIsPageLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              category: field_errors.category?.toString(),
              name: field_errors.name?.toString(),
              navName: field_errors.nav_name?.toString(),
              url: field_errors.url?.toString(),
              urlName: field_errors.url_name?.toString(),
              icon: field_errors.icon?.toString(),
              isMenu: field_errors.is_menu?.toString(),
              isDropdown: field_errors.is_dropdown?.toString(),
              subroutes: field_errors.subroutes,
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetIsPageLoading(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={is_page_loading} />
        <div className="card-header">
          <h5>Create Menu and Permissions</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_MENUS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          {/* MENU DETAILS */}
          <div className="col-md-12">
            <h4 className="sub-title">Menu Details</h4>

            <InputTextInline
              type="text"
              label="Category:"
              placeholder="Category"
              errorField={formErrors.category}
              value={formData.category}
              setter={(e) =>
                dispatch(setFormData({ ...formData, category: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Name:"
              placeholder="Name"
              errorField={formErrors.name}
              value={formData.name}
              setter={(e) =>
                dispatch(setFormData({ ...formData, name: e.target.value }))
              }
            />

            <RadioButtonInline
              label="Is Side Navigation:"
              name="isMenu"
              value={formData.isMenu}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              onChange={(e) =>
                dispatch(setFormData({ ...formData, isMenu: e.target.value }))
              }
              errorField={formErrors.isMenu}
            />

            <RadioButtonInline
              label="Is Side Navigation Dropdown"
              name="is_dropdown"
              value={formData.isDropdown}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              onChange={(e) =>
                dispatch(
                  setFormData({ ...formData, isDropdown: e.target.value })
                )
              }
              errorField={formErrors.isDropdown}
            />

            <InputTextInline
              type="text"
              label="Side Navigation Name:"
              placeholder="Side Navigation Name"
              errorField={formErrors.navName}
              value={formData.navName}
              setter={(e) =>
                dispatch(setFormData({ ...formData, navName: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Side Navigation Icon:"
              placeholder="Side Navigation Icon"
              errorField={formErrors.icon}
              value={formData.icon}
              setter={(e) =>
                dispatch(setFormData({ ...formData, icon: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Url / Main Path:"
              placeholder="Url / Main Path"
              errorField={formErrors.url}
              value={formData.url}
              setter={(e) =>
                dispatch(setFormData({ ...formData, url: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Url Name:"
              placeholder="(Django Route Name)"
              errorField={formErrors.urlName}
              value={formData.urlName}
              setter={(e) =>
                dispatch(setFormData({ ...formData, urlName: e.target.value }))
              }
            />
          </div>

          {/* PERMISSIONS */}
          <div className="col-md-12 mt-5">
            <h5 className="sub-title">Permissions</h5>
            <div className="table-responsive">
              <button
                className="btn btn-md btn-success btn-outline-success float-right mb-2  pt-2 pb-2"
                onClick={handleAddSubroutes}
              >
                <i className="fa fa-plus"></i> Add Permission
              </button>

              <table className="table table-de">
                <thead>
                  <tr>
                    <th>Permission Name</th>
                    <th>Type</th>
                    <th>Subitem Name</th>
                    <th>Url</th>
                    <th>Url Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formDataSubroutes.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <SubrouteInputField
                            name="name"
                            value={val.name}
                            placeholder="Ex: Can View User List"
                            onChange={(e) => handleModifySubroutes(key, e)}
                          />
                          <SubrouteFieldError
                            errors={formErrors.subroutes}
                            errorkey={key}
                            fieldName="name"
                          />
                        </td>
                        <td>
                          <select
                            name="is_nav"
                            value={val.is_nav}
                            className="form-control form-control-primary"
                            onChange={(e) => handleModifySubroutes(key, e)}
                          >
                            <option value="">Select</option>
                            <option value={false}>Page / API</option>
                            <option value={true}>Nav Subitem</option>
                          </select>
                          <SubrouteFieldError
                            errors={formErrors.subroutes}
                            key={key}
                            fieldName="is_nav"
                          />
                        </td>
                        <td>
                          <SubrouteInputField
                            name="nav_name"
                            value={val.nav_name}
                            placeholder="Ex: User Manage"
                            onChange={(e) => handleModifySubroutes(key, e)}
                          />
                          <SubrouteFieldError
                            errors={formErrors.subroutes}
                            errorkey={key}
                            fieldName="nav_name"
                          />
                        </td>
                        <td>
                          <SubrouteInputField
                            name="url"
                            value={val.url}
                            placeholder="Ex: /user/list/"
                            onChange={(e) => handleModifySubroutes(key, e)}
                          />
                          <SubrouteFieldError
                            errors={formErrors.subroutes}
                            errorkey={key}
                            fieldName="url"
                          />
                        </td>
                        <td>
                          <SubrouteInputField
                            name="url_name"
                            value={val.url_name}
                            placeholder="Ex: user_list"
                            onChange={(e) => handleModifySubroutes(key, e)}
                          />
                          <SubrouteFieldError
                            errors={formErrors.subroutes}
                            errorkey={key}
                            fieldName="url_name"
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            type="button"
                            onClick={() => handleDeleteSubroutes(key)}
                          >
                            <i className="fa fa-trash ml-1"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 1)}
              >
                Save and add another
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={() => dispatch(resetForm())}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
