import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "../../../constants.js";
import SideNavMenu from "./SideNavMenu";
import SideNavMenuWithLevel from "./SideNavMenuWithLevel.js";

export default function Sidenav() {
  const [adminRoutes, SetAdminRoutes] = useState([]);

  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const admin = [];
    if (user) {
      user.userRoute_user.forEach((data) => {
        switch (data.route.category) {
          case "ADM":
            admin.push(data.route);
            break;
          case "LAB-SERV":
            admin.push(data.route);
            break;
          case "ARIL":
            admin.push(data.route);
            break;
          default:
            break;
        }
      });
      SetAdminRoutes(admin);
    }
  }, [user]);

  return (
    <nav className="pcoded-navbar">
      <div className="nav-list">
        <div className="pcoded-inner-navbar main-menu">
          {/* ADMIN MENU */}
          {adminRoutes.length > 0 ? (
            <>
              <div
                className="pcoded-navigation-label"
                style={{ color: "#f1f7ff" }}
              >
                Admin
              </div>
              <ul className="pcoded-item pcoded-left-item">
                <li
                  className={
                    location.pathname === routes.EMPLOYEE_DASHBOARD_HOME
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    to={routes.EMPLOYEE_DASHBOARD_HOME}
                    className="waves-effect waves-dark"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-home"></i>
                    </span>
                    <span className="pcoded-mtext">Home</span>
                  </NavLink>
                </li>
                {adminRoutes.map((data) => {
                  if (data.is_menu) {
                    if (!data.is_dropdown) {
                      return (
                        <SideNavMenu
                          key={data.id}
                          url={data.url}
                          icon={data.icon}
                          nav_name={data.nav_name}
                        />
                      );
                    } else {
                      return (
                        <SideNavMenuWithLevel
                          key={data.id}
                          url_name={data.url_name}
                          nav_name={data.nav_name}
                          icon={data.icon}
                          submenus={data.subroute_route}
                          pathname={location.pathname}
                        />
                      );
                    }
                  }else{
                    return <></>;
                  }
                })}
              </ul>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </nav>
  );
}
