import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

import DeleteDialog from "../common/DeleteDialog";
import { labservices_constants, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import {
  retrieveInquiry,
  createTransactionLog,
  updateProcessStatus,
} from "../../../../store/employee/lab_services/inquiryActions";
import ViewFileDialog from "../common/ViewFileDialog";
import {
  fileStorageUrlToMediaUrl,
  getFileTypeByFileStorageUrl,
  getEllipsisText,
} from "../../../utils/StringUtils";

const ACTION = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export default function InquiryDetailsProofOfPayment({
  inquiryDetails,
  inquiryId,
}) {
  const dispatch = useDispatch();

  const [isOpenImportFileFormDialog, SetIsOpenImportFileFormDialog] =
    useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);
  const [selectedRecordToDeleteId, SetSelectedRecordToDeleteId] = useState(0);
  const [selectedId, SetSelectedId] = useState(null);
  const [formDataFile, SetFormDataFile] = useState(null);
  const [formDataDescription, SetFormDataDescription] = useState("");
  const [formAction, SetFormAction] = useState("");
  const [formTitle, SetFormTitle] = useState("");
  const [formSubtitle, SetFormSubtitle] = useState("");
  const [commentDetails, SetCommentDetails] = useState({
    isOpen: false,
    id: "",
    comments: "",
  });
  const [fileViewerDetails, SetFileViewerDetails] = useState({
    isOpen: false,
    fileData: null,
  });

  const handleOpenCreateDialog = (e) => {
    e.preventDefault();
    SetIsOpenImportFileFormDialog(true);
    SetFormAction(ACTION.CREATE);
    SetFormTitle("Add Proof Of Payment");
    SetFormSubtitle("Upload File / Document");
  };

  const handleOpenUpdateDialog = (e, proofOfPayment) => {
    e.preventDefault();
    SetSelectedId(proofOfPayment.id);
    SetFormDataDescription(proofOfPayment.description);
    SetIsOpenImportFileFormDialog(true);
    SetFormAction(ACTION.UPDATE);
    SetFormTitle("Update Proof Of Payment");
    SetFormSubtitle("Replace File / Document");
  };

  const handleCloseForm = () => {
    SetIsOpenImportFileFormDialog(false);
    SetFormAction("");
    SetFormTitle("");
    SetFormSubtitle("");
    SetSelectedId(null);
    SetFormDataFile(null);
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      SetFormDataFile(e.target.files[0]);
    }
  };

  const handleSubmitForm = () => {
    if (formDataFile === null) {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "No File selected!",
          },
        })
      );
      return;
    }
    const form = new FormData();
    form.append("file", formDataFile);
    form.append("description", formDataDescription);
    form.append("inquiry_id", inquiryId);
    if (formAction === ACTION.CREATE) {
      form.append("is_created_by_admin", true);
      axios
        .post(`${api_urls.LABSERVICES_INQUIRY_PROOF_OF_PAYMENT}/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          SetIsOpenImportFileFormDialog(false);
          SetFormDataDescription("");
          dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Proof of Payment successfully added!",
              },
            })
          );
          updateProcessStatus({
            inquiry_id: inquiryId,
            status: labservices_constants.PROCESS_STATUS_DROPDOWN[10].value,
          });
        })
        .catch((err) => {
          SetIsOpenImportFileFormDialog(false);
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
          createTransactionLog({
            inquiry_id: inquiryId,
            action: `Created Inquiry Proof of Payment`,
          });
        });
    } else {
      axios
        .put(
          `${api_urls.LABSERVICES_INQUIRY_PROOF_OF_PAYMENT}/${selectedId}/`,
          form,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          SetIsOpenImportFileFormDialog(false);
          SetFormDataDescription("");
          dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Proof of Payment successfully updated!",
              },
            })
          );
          createTransactionLog({
            inquiry_id: inquiryId,
            action: `Updated Inquiry Proof of Payment Details`,
          });
        })
        .catch((err) => {
          SetIsOpenImportFileFormDialog(false);
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        });
    }
  };

  const handleUpdateProofOfPaymentStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.LABSERVICES_INQUIRY_PROOF_OF_PAYMENT}/${id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        updateProcessStatus({
          inquiry_id: inquiryId,
          status: labservices_constants.PROCESS_STATUS_DROPDOWN[10].value,
        });
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the proof of payment status!",
            },
          })
        );
        createTransactionLog({
          inquiry_id: inquiryId,
          action: `Updated Inquiry Proof of Payment Status`,
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleUpdateProofOfPaymenComments = (e) => {
    e.preventDefault();
    axios
      .patch(
        `${api_urls.LABSERVICES_INQUIRY_PROOF_OF_PAYMENT}/${commentDetails.id}/`,
        {
          comments: commentDetails.comments,
        }
      )
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the proof of payment comments!",
            },
          })
        );
        SetCommentDetails({ isOpen: false, id: "", comments: "" });
        createTransactionLog({
          inquiry_id: inquiryId,
          action: `Updated Inquiry Proof of Payment Comments`,
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
          SetCommentDetails({ isOpen: false, id: "", comments: "" });
        }
      });
  };

  const handleDownloadFile = (e, id, filename) => {
    e.preventDefault();
    axios
      .get(`${api_urls.LABSERVICES_INQUIRY_PROOF_OF_PAYMENT}/${id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleRemoveClick = (id) => {
    SetIsOpenDeleteModal(true);
    SetSelectedRecordToDeleteId(id);
  };

  const handleRemoveSubmit = () => {
    axios
      .delete(
        `${api_urls.LABSERVICES_INQUIRY_PROOF_OF_PAYMENT}/${selectedRecordToDeleteId}/`
      )
      .then((res) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Proof of payment Successfully Deleted!",
            },
          })
        );
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        SetIsOpenDeleteModal(false);
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetIsOpenDeleteModal(false);
      });
  };

  const renderStatus = (data) => {
    let statusLabel = "";
    let statusClass = "";
    switch (data.status) {
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[0].value:
        statusClass = "label-warning";
        statusLabel = labservices_constants.PROOF_OF_PAYMENT_STATUS[0].label;
        break;
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[1].value:
        statusClass = "label-primary";
        statusLabel = labservices_constants.PROOF_OF_PAYMENT_STATUS[1].label;
        break;
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[2].value:
        statusClass = "label-danger";
        statusLabel = labservices_constants.PROOF_OF_PAYMENT_STATUS[2].label;
        break;
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[3].value:
        statusClass = "label-success";
        statusLabel = labservices_constants.PROOF_OF_PAYMENT_STATUS[3].label;
        break;
      default:
        statusClass = "";
    }
    return <label className={`label ${statusClass}`}>{statusLabel}</label>;
  };

  const renderActionButtons = (data) => {
    let approveButtonClass = "";
    let rejectButtonClass = "";
    switch (data.status) {
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[0].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[1].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[2].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-disabled disabled";
        break;
      case labservices_constants.PROOF_OF_PAYMENT_STATUS[3].value:
        approveButtonClass = "btn-disabled disabled";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      default:
        approveButtonClass = "btn-disabled disabled";
        rejectButtonClass = "btn-disabled disabled";
        break;
    }
    return (
      <>
        {!data.is_created_by_admin ? (
          <>
            <button
              onClick={(e) => handleOpenUpdateDialog(e, data)}
              className="btn btn-sm btn-primary pb-2 pt-2 mr-2"
              type="button"
            >
              Edit
            </button>
            <button
              onClick={(e) =>
                handleUpdateProofOfPaymentStatus(
                  e,
                  data.id,
                  labservices_constants.PROOF_OF_PAYMENT_STATUS[3].value
                )
              }
              className={`btn btn-sm ${approveButtonClass} pb-2 pt-2 mr-2`}
              type="button"
            >
              Approve
            </button>
            <button
              onClick={(e) =>
                handleUpdateProofOfPaymentStatus(
                  e,
                  data.id,
                  labservices_constants.PROOF_OF_PAYMENT_STATUS[2].value
                )
              }
              className={`btn btn-sm ${rejectButtonClass} pb-2 pt-2 mr-2`}
              type="button"
            >
              Dissaprove
            </button>
            <button
              onClick={(e) => handleRemoveClick(data.id)}
              className={`btn btn-sm btn-danger pb-2 pt-2`}
              type="button"
            >
              Remove
            </button>
          </>
        ) : (
          <>
            <button
              onClick={(e) => handleRemoveClick(data.id)}
              className={`btn btn-sm btn-danger pb-2 pt-2`}
              type="button"
            >
              Remove
            </button>
          </>
        )}
      </>
    );
  };

  const renderCommentsButton = (data) => {
    if (
      !commentDetails.isOpen ||
      (commentDetails.isOpen && commentDetails.id !== data.id)
    ) {
      return !data.comments ? (
        <button
          className="btn btn-sm btn-primary btn-outline-primary"
          onClick={(e) =>
            SetCommentDetails({ ...commentDetails, isOpen: true, id: data.id })
          }
        >
          Add Comments
        </button>
      ) : (
        <a
          href="/#"
          className="mt-2 ml-3"
          onClick={(e) => {
            e.preventDefault();
            SetCommentDetails({
              ...commentDetails,
              isOpen: true,
              id: data.id,
              comments: data.comments,
            });
          }}
        >
          <i className="fa fa-edit"></i>
        </a>
      );
    }
  };

  const renderComments = (data) => {
    if (commentDetails.isOpen && commentDetails.id === data.id) {
      return (
        <div className="no-padding d-flex flex-row">
          <input
            type="text"
            value={commentDetails.comments}
            className="form-control"
            onChange={(e) =>
              SetCommentDetails({
                ...commentDetails,
                id: data.id,
                comments: e.target.value,
              })
            }
          />
          <button
            className="btn btn-sm btn-primary ml-3"
            onClick={handleUpdateProofOfPaymenComments}
          >
            Save
          </button>
          <a
            href="/#"
            className="mt-2 ml-3"
            onClick={(e) => {
              e.preventDefault();
              SetCommentDetails({
                isOpen: false,
                id: "",
                comments: "",
              });
            }}
          >
            <i className="fa fa-times"></i>
          </a>
        </div>
      );
    }
    return getEllipsisText(data.comments, 30);
  };

  return (
    <div
      className="col-md-12"
      style={
        inquiryDetails.isDeclined ||
        inquiryDetails.processStatus ===
          labservices_constants.PROCESS_STATUS_DROPDOWN[1].label
          ? { pointerEvents: "none", opacity: "0.5" }
          : {}
      }
    >
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Payment</h5>
          <button
            className="btn btn-success btn-outline-success float-right pt-2 pb-2 ml-2"
            onClick={handleOpenCreateDialog}
          >
            <i className="fa fa-plus"></i> Add
          </button>
        </div>
        <div className="card-block">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Filename</th>
                <th>Description</th>
                <th>Comment</th>
                <th>Status</th>
                <th style={{width:'370px'}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {inquiryDetails.proofOfPayments.map((data) => {
                return (
                  <tr>
                    <td>
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          SetFileViewerDetails({
                            ...fileViewerDetails,
                            isOpen: true,
                            fileData: data,
                          });
                        }}
                      >
                        <ins className="text-info">
                          {getEllipsisText(data.orig_filename, 30)}
                        </ins>
                      </a>
                    </td>
                    <td>{data.description}</td>
                    <td>
                      {renderComments(data)} {renderCommentsButton(data)}
                    </td>
                    <td>{renderStatus(data)}</td>
                    <td className="align-middle">
                      {renderActionButtons(data)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Import File Dialog */}
      <ImportFileFormDialog
        isOpen={isOpenImportFileFormDialog}
        title={formTitle}
        subtitle={formSubtitle}
        fileInputHandler={handleFileInput}
        descriptionInputValue={formDataDescription}
        descriptionInputHandler={(e) => SetFormDataDescription(e.target.value)}
        handleSubmit={handleSubmitForm}
        handleClose={handleCloseForm}
      />

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleRemoveSubmit}
      />

      {/* View File Dialog */}
      <ViewFileDialog
        isOpen={fileViewerDetails.isOpen}
        fileType={getFileTypeByFileStorageUrl(fileViewerDetails.fileData?.file)}
        filePath={fileStorageUrlToMediaUrl(fileViewerDetails.fileData?.file)}
        errorComponent={<p>Error Encountered Trying to load file ...</p>}
        handleClose={() =>
          SetFileViewerDetails({
            isOpen: false,
            fileData: null,
          })
        }
        handleDownload={(e) =>
          handleDownloadFile(
            e,
            fileViewerDetails.fileData?.id,
            fileViewerDetails.fileData?.orig_filename
          )
        }
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  dialogContent: {
    minHeight: "55vh",
    maxHeight: "20vh",
    overflow: "hidden",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: 20,
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "80vh",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function ImportFileFormDialog({
  isOpen,
  title,
  subtitle,
  fileInputHandler,
  descriptionInputValue,
  descriptionInputHandler,
  handleSubmit,
  handleClose,
}) {
  const styleClasses = useStyles();
  return (
    <Dialog
      className={styleClasses.dialog}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={styleClasses.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          {subtitle}
        </DialogContentText>
        <form className={styleClasses.form} noValidate>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>File / Document:</label>
            <input
              type="file"
              className="form-control"
              onChange={fileInputHandler}
            />
          </FormControl>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>Description:</label>
            <input
              type="text"
              className="form-control"
              value={descriptionInputValue}
              onChange={descriptionInputHandler}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
