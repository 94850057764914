import { useEffect, useCallback, useState } from "react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { routes } from "../../../../constants";
import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import {
  search,
  filter,
} from "../../../../store/employee/tasks/taskBoardReducer";
import { fetchOnboardLists } from "../../../../store/employee/tasks/taskBoardActions";
import { SelectInput, InputText } from "../../../utils/Forms/DefaultInputs";
import { TaskBoardBlock } from "./TaskBoardBlock";

export default function TaskBoard() {
  const [searchText, SetSearchText] = useState("");
  const [filterPersonnel, SetFilterPersonnel] = useState({
    value: "",
    label: "Select",
  });
  const [filterMachine, SetFilterMachine] = useState({
    value: "",
    label: "Select",
  });
  const [filterTaskType, SetFilterTaskType] = useState({
    value: "",
    label: "Select",
  });

  const [machineTransformedOptions, SetMachineTransformedOptions] = useState(
    []
  );
  const [personnelTransformedOptions, SetPersonnelTransformedOptions] =
    useState([]);
  const [taskTypeTransformedOptions, SetTaskTypeTransformedOptions] = useState(
    []
  );

  const queuedListAll = useSelector((state) => state.taskBoard.queuedListAll);
  const ongoingListAll = useSelector((state) => state.taskBoard.ongoingListAll);

  const onBoardFilters = useSelector((state) => state.taskBoard.onBoardFilters);

  const machineOptions = useSelector((state) => state.machine.options);
  const personnelOptions = useSelector((state) => state.personnel.options);
  const taskTypeOptions = useSelector((state) => state.taskType.options);

  const dispatch = useDispatch();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search(value)), 500),
    []
  );

  useEffect(() => {
    const newMachineOptions = Object.assign([], machineOptions);
    const newPersonnelOptions = Object.assign([], personnelOptions);
    const newTaskTypeOptions = Object.assign([], taskTypeOptions);
    newMachineOptions.unshift({ value: "", label: "Select" });
    newPersonnelOptions.unshift({ value: "", label: "Select" });
    newTaskTypeOptions.unshift({ value: "", label: "Select" });
    SetMachineTransformedOptions(newMachineOptions);
    SetPersonnelTransformedOptions(newPersonnelOptions);
    SetTaskTypeTransformedOptions(newTaskTypeOptions);
  }, [dispatch, machineOptions, personnelOptions, taskTypeOptions]);

  useEffect(() => {
    dispatch(fetchOnboardLists({ onBoardFilters }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Task Board",
          subtitle: "Manage Tasks in Board View",
          icon: "icon-list",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD,
              name: "Tasks Board",
            },
          ],
        },
      })
    );
  }, [dispatch, onBoardFilters]);

  useEffect(() => {
    dispatch(
      filter({
        personnel: filterPersonnel.value,
        machine: filterMachine.value,
        task_type: filterTaskType.value,
      })
    );
  }, [dispatch, filterPersonnel, filterMachine, filterTaskType]);

  const handleSearch = (e) => {
    e.preventDefault();
    SetSearchText(e.target.value);
    delaySearch(e.target.value);
  };

  return (
    <>
      <div class="col-md-12">
        <div class="card ticket-card">
          <div className="card-header">
            <div class="col-md-12">
              <h5 class="card-title">Filters</h5>
              <Link
                to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_COMPLETED}`}
                className="btn btn-sm btn-success btn-outline-success float-right pt-2 pb-2 ml-2"
              >
                View Completed Tasks
              </Link>
              <Link
                to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_CANCELLED}`}
                className="btn btn-sm btn-danger btn-outline-danger float-right pt-2 pb-2"
              >
                View Cancelled Tasks
              </Link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div className="col-md-3">
                <InputText
                  type="text"
                  label="Search:"
                  placeholder="Search Task ID, Description, etc ..."
                  errorField={null}
                  value={searchText}
                  setter={handleSearch}
                />
              </div>
              <div className="col-md-3">
                <SelectInput
                  label="Personnel:"
                  name="personnel"
                  value={filterPersonnel}
                  errorField={null}
                  options={personnelTransformedOptions}
                  onChange={(values) => SetFilterPersonnel(values)}
                />
              </div>
              <div className="col-md-3">
                <SelectInput
                  label="Equipment:"
                  name="machine"
                  value={filterMachine}
                  errorField={null}
                  options={machineTransformedOptions}
                  onChange={(values) => SetFilterMachine(values)}
                />
              </div>
              <div className="col-md-3">
                <SelectInput
                  label="Task Type:"
                  name="task_type"
                  value={filterTaskType}
                  errorField={null}
                  options={taskTypeTransformedOptions}
                  onChange={(values) => SetFilterTaskType(values)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <TaskBoardBlock title="In Queue" tasks={queuedListAll} />
      <TaskBoardBlock title="Ongoing" tasks={ongoingListAll} />
    </>
  );
}
