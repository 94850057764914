import { useEffect, useCallback, useState } from "react";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import {
  search,
  resetList,
} from "../../../../store/employee/tasks/taskReducer";
import {
  fetchTask,
  paginationClick,
  pageSizeClick,
} from "../../../../store/employee/tasks/taskActions";
import { routes, tasks_constants } from "../../../../constants";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TableHeaderDefault } from "../../../utils/Table/TableHeaders";

export default function TaskBoardCancelled() {
  const [searchQuery, SetSearchQuery] = useState("");

  const list = useSelector((state) => state.task.list);
  const pagination = useSelector((state) => state.task.listPagination);
  const filters = useSelector((state) => state.task.listFilters);
  const highlightedRecord = useSelector(
    (state) => state.task.highlightedRecord
  );
  const checkedRecords = useSelector((state) => state.task.checkedRecords);

  const dispatch = useDispatch();
  const history = useHistory();
  const redirectToCompletedTaskDetails = useCallback(
    (id) =>
      history.push(
        `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_CANCELLED}/${id}`
      ),
    [history]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search({ query: value })), 500),
    []
  );

  useEffect(() => {
    dispatch(
      fetchTask({
        pagination,
        filters: {
          query: filters.query,
          sortField: filters.sortField,
          sortorder: filters.sortorder,
          status: tasks_constants.TASK_STATUS_DROPDOWN[4].value,
        },
      })
    );
  }, [dispatch, pagination, filters]);

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Task Board",
          subtitle: "Cancelled Tasks",
          icon: "icon-list",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD,
              name: "Task Board",
            },
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_CANCELLED,
              name: "Cancelled Tasks",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    SetSearchQuery(value);
    delaySearch(value);
  };

  const handleOpenCompletedTaskDetails = (e, id) => {
    e.preventDefault();
    redirectToCompletedTaskDetails(id);
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    dispatch(
      paginationClick({
        willCurrent: willCurrent,
        limit: list.limit,
        size: pagination.pageSize,
      })
    );
  };

  const handlePageSizeClick = (e) => {
    e.preventDefault();
    dispatch(pageSizeClick(e.target.value));
  };

  const handleRefreshClick = () => {
    SetSearchQuery("");
    dispatch(resetList());
  };

  const recordIsChecked = (id) => {
    return checkedRecords.some(
      (data) => data.id === id && data.status === true
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case tasks_constants.TASK_STATUS_DROPDOWN[1].value:
        return (
          <label className="label label-md label-warning">
            {tasks_constants.TASK_STATUS_DROPDOWN[1].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[2].value:
        return (
          <label className="label label-md label-primary">
            {tasks_constants.TASK_STATUS_DROPDOWN[2].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[3].value:
        return (
          <label className="label label-md label-success">
            {tasks_constants.TASK_STATUS_DROPDOWN[3].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[4].value:
        return (
          <label className="label label-md label-danger">
            {tasks_constants.TASK_STATUS_DROPDOWN[4].label}
          </label>
        );
      default:
        return "";
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card table-card z-depth-0">
        {/* Table Header */}
        <div className="card-header">
          <TableHeaderDefault
            searchInputValue={searchQuery}
            searchInputHandler={handleSearch}
            filterButton={false}
            sortButton={false}
            refreshButtonClickHandler={handleRefreshClick}
            deleteButton={false}
            deleteButtonDisable={false}
            createButton={false}
            entriesSelect={true}
            entriesSelectPageSize={pagination.pageSize}
            entriesSelectChangeHandler={handlePageSizeClick}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>

        {/* TABLE BODY */}
        <div className="card-block table-border-style pb-0 pt-0">
          <div className="table-responsive">
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th className="align-middle">Task ID</th>
                  <th className="align-middle">Description</th>
                  <th className="align-middle">Type</th>
                  <th className="align-middle">Status</th>
                </tr>
              </thead>
              <tbody>
                {list.records.map((val, key) => {
                  return (
                    <tr
                      key={key}
                      className={
                        val.id == highlightedRecord || recordIsChecked(val.id)
                          ? "table-info"
                          : ""
                      }
                    >
                      <td className="align-middle">
                        <a
                          href="/#"
                          onClick={(e) =>
                            handleOpenCompletedTaskDetails(e, val.id)
                          }
                        >
                          <ins className="text-info">TASK-{val.id}</ins>
                        </a>
                      </td>
                      <td className="align-middle">{val.description}</td>
                      <td className="align-middle">{val.task_type.name}</td>
                      <td className="align-middle">
                        {renderStatus(val.status)}
                      </td>
                    </tr>
                  );
                })}

                {!list.isFetched && (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="5">
                      <h4>Loading ...</h4>
                    </td>
                  </tr>
                )}

                {list.isFetched && !list.records.length ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="5">
                      <h4>No Records Found!</h4>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Table Footer */}
        <div className="card-footer">
          <TableFooterDefault
            counterPageSize={pagination.pageSize}
            counterPageCurrent={pagination.pageCurrent}
            counterPageLimit={list.limit}
            counterTotalRecords={list.totalCount}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>
      </div>
    </div>
  );
}
