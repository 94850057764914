import { HashRouter, Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "../../constants.js";

import LandingPage from "./landingPage/Index.js";
import InquireLabServicesPage from "./inquireLabServicesPage/Index.js";
import InquireARILPage from "./inquireARILPage/Index.js";
import ClientDashboardLogin from "./ClientDashboardLogin.js";
import ClientDashboardForgotPassword from "./ClientDashboardForgotPassword";
import ClientDashboard from "./dashboard/Index.js";
import Cookies from 'js-cookie';

const ClientMain = () => {

  useEffect(() => {
    Cookies.set('herbanext_pms', btoa('herbanext_pms'));
  }, []);

  return (
    <HashRouter>
      <Switch>
        <Route exact path={routes.CLIENT_LANDING_PAGE}>
          <LandingPage />
        </Route>
        <Route exact path={routes.CLIENT_INQ_LAB_SERVICES}>
          <InquireLabServicesPage />
        </Route>
        <Route exact path={routes.CLIENT_INQ_ARIL}>
          <InquireARILPage />
        </Route>
        <Route exact path={routes.CLIENT_DASHBOARD_FORGOT_PASSWORD}>
          <ClientDashboardForgotPassword />
        </Route>
        <Route exact path={routes.CLIENT_DASHBOARD_LOGIN}>
          <ClientDashboardLogin />
        </Route>
        <Route path={routes.CLIENT_DASHBOARD}>
          <ClientDashboard />
        </Route>
      </Switch>
    </HashRouter>
  );
};

export default ClientMain;
