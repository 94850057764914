import "./Index.css";
import { routes } from "../../../constants.js";

import TopNav from "../common/TopNav.js";
import PageHeader from "../common/PageHeader.js";
import ProcessDetailsDiv from "./ProcessDetailsDiv.js";
import InquiryFormDiv from "./InquiryFormDiv.js";

function InquireLabServicesPage() {
  return (
    <div className="lab-services-container">
      <TopNav />
      <PageHeader
        heading="Herbanext Laboratory Services Inquiry"
        subHeading="Requirements Submission for Laboratory Services"
        pages={[
          {
            url: routes.CLIENT_INQ_LAB_SERVICES,
            name: "Lab Services Requirement Submission",
          },
        ]}
      />

      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <InquiryFormDiv />
                <ProcessDetailsDiv />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InquireLabServicesPage;
