import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { routes, tasks_constants } from "../../../../constants";
import { retrieveTask } from "../../../../store/employee/tasks/taskActions";
import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";

export default function TaskCalendarDetails() {
  const [pageLoader, SetPageLoader] = useState(false);

  const { task_id } = useParams();
  const dispatch = useDispatch();
  const taskDetails = useSelector((state) => state.task.details);

  useEffect(() => {
    dispatch(retrieveTask({ task_id: task_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Task Calendar",
          subtitle: "Task Details",
          icon: "icon-list",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR,
              name: "Tasks Calendar",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR}/${task_id}`,
              name: "Task Details",
            },
          ],
        },
      })
    );
  }, [dispatch, task_id, SetPageLoader]);

  const renderStatus = () => {
    switch (taskDetails.status.value) {
      case tasks_constants.TASK_STATUS_DROPDOWN[1].value:
        return (
          <label className="label label-md label-warning">
            {tasks_constants.TASK_STATUS_DROPDOWN[1].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[2].value:
        return (
          <label className="label label-md label-primary">
            {tasks_constants.TASK_STATUS_DROPDOWN[2].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[3].value:
        return (
          <label className="label label-md label-success">
            {tasks_constants.TASK_STATUS_DROPDOWN[3].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[4].value:
        return (
          <label className="label label-md label-danger">
            {tasks_constants.TASK_STATUS_DROPDOWN[4].label}
          </label>
        );
      default:
        return "";
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader
          type="Circles"
          loading={!taskDetails.taskID ? true : pageLoader}
        />
        <div className="card-header">
          <h5>Task Details</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back to Calendar
          </Link>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-md-3">
              <p>Task ID:</p>
              <p>Task Type:</p>
              <p>Description:</p>
              <p>Equipments:</p>
              <p>Personnel:</p>
              <p>JO Number:</p>
              <p>Status:</p>
              <p>Start Date:</p>
              <p>End Date:</p>
              <p>Created at:</p>
              <p>Updated at:</p>
              <p>Created by:</p>
              <p>Updated by:</p>
            </div>
            <div className="col-md-9">
              <p>
                {taskDetails.taskID}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.taskType?.name}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.description}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.machines.map((data) => (
                  <label
                    key={data.value}
                    className="label label-md label-primary"
                  >
                    {data.machine.name}
                  </label>
                ))}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.personnel.map((data) => (
                  <label
                    key={data.value}
                    className="label label-md label-primary"
                  >
                    {data.personnel.fullname}
                  </label>
                ))}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.JONumber}
                {"\u00A0"}
              </p>
              <p>
                {renderStatus()}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskDetails.datetimeFrom).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskDetails.datetimeTo).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskDetails.createdAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskDetails.updatedAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.createdBy?.fullname}
                {"\u00A0"}
              </p>
              <p>
                {taskDetails.updatedBy?.fullname}
                {"\u00A0"}
              </p>
            </div>
            <div className="col-md-12 mt-4">
              <h4>Sub Tasks</h4>
              <div className="card-block table-border-style">
                <div className="table-responsive">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="align-middle">Description</th>
                        <th className="align-middle">Status</th>
                        <th className="align-middle">Personnels</th>
                        <th className="align-middle">Equipments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taskDetails.subtasks.map((data) => {
                        return (
                          <tr>
                            <td className="align-middle">{data.description}</td>
                            <td className="align-middle">
                              {renderStatus(data.status)}
                            </td>
                            <td className="align-middle">
                              {data.taskSubTaskPersonnel_taskSubTask &&
                                data.taskSubTaskPersonnel_taskSubTask.map(
                                  (dataMachines) => {
                                    return (
                                      <label className="label label-md label-primary">
                                        {dataMachines.personnel.fullname}
                                      </label>
                                    );
                                  }
                                )}
                            </td>
                            <td className="align-middle">
                              {data.taskSubTaskMachines_taskSubTask &&
                                data.taskSubTaskMachines_taskSubTask.map(
                                  (dataMachines) => {
                                    return (
                                      <label className="label label-md label-primary">
                                        {dataMachines.machine.name}
                                      </label>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
