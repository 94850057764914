import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: {
    records: [],
    totalCount: 0,
    limit: 0,
    errorMessage: "",
  },
  listPagination: {
    pagePrev: 0,
    pageCurrent: 1,
    pageNext: 2,
    pageSize: 10,
  },
  listFilters: {
    query: "",
    serviceType: "",
    processStatus: "",
  },
  formData: {
    requestor: "",
    company: "",
    position: "",
    course: "",
    description: "",
    email: "",
    contactNo: "",
    serviceType: { value: "", label: "Select" },
    processStatus: { value: "", label: "Select" },
  },
  formDataFiles: [],
  formErrors: {
    requestor: "",
    company: "",
    position: "",
    course: "",
    description: "",
    email: "",
    contactNo: "",
    serviceType: "",
    processStatus: "",
    files: "",
  },
  details: {
    JONumber: "",
    requestor: "",
    company: "",
    position: "",
    course: "",
    description: "",
    email: "",
    contactNo: "",
    serviceType: "",
    processStatus: "",
    createdAt: "",
    updatedAt: "",
    files: [],
    transactionLogs: [],
    reports: [],
    proofOfPayments: [],
    user: null,
    quote: "",
    quoteSigned: "",
    quoteSignedStatus: "",
    quoteSignedComments: "",
    lsr: "",
    lsrSigned: "",
    lsrSignedStatus: "",
    lsrSignedComments: "",
  },
  checkedRecords: [],
  isCheckedAllRecords: false,
  highlightedRecord: 0,
};

const clientArilInquirySlice = createSlice({
  name: "clientArilInquiry",
  initialState,
  reducers: {
    fetch(state, action) {
      state.list = action.payload.list;
    },
    paginate(state, action) {
      state.listPagination = action.payload.listPagination;
    },
    search(state, action) {
      state.listFilters = { ...state.listFilters, query: action.payload.query };
    },
    resetList(state) {
      state.list = {
        records: [],
        totalCount: 0,
        limit: 0,
        errorMessage: "",
      };
      state.listPagination = {
        pagePrev: 0,
        pageCurrent: 1,
        pageNext: 2,
        pageSize: 10,
      };
      state.listFilters = {
        query: "",
      };
      state.checkedRecords = [];
      state.isCheckedAllRecords = false;
      state.highlightedRecord = 0;
    },

    setFormData(state, action) {
      state.formData = action.payload;
    },
    setFormDataFiles(state, action) {
      state.formDataFiles = action.payload;
    },
    setFormErrors(state, action) {
      state.formErrors = action.payload;
    },
    setDetails(state, action) {
      state.details = action.payload;
    },
    setCheckedRecords(state, action) {
      state.checkedRecords = action.payload.checkedRecords;
    },
    setIsCheckedAllRecords(state, action) {
      state.isCheckedAllRecords = action.payload;
    },
    setHighlightedRecord(state, action) {
      state.highlightedRecord = action.payload;
    },
  },
});

export const {
  fetch,
  search,
  paginate,
  resetList,
  setFormData,
  setFormDataFiles,
  setFormErrors,
  setDetails,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setHighlightedRecord,
} = clientArilInquirySlice.actions;
export default clientArilInquirySlice.reducer;
