import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import { retrieveMenu } from "../../../../store/employee/menuActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

export default function MenuDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const history = useHistory();
  const { menu_id } = useParams();
  const dispatch = useDispatch();
  const menuDetails = useSelector((state) => state.menu.details);

  useEffect(() => {
    dispatch(retrieveMenu({ menu_id: menu_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Menus",
          subtitle: "Menu Details",
          icon: "icon-menu",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_MENUS, name: "Manage Menus" },
            {
              url: routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS + menu_id,
              name: "Menu Details",
            },
          ],
        },
      })
    );
  }, [dispatch, menu_id]);

  const redirectBackToMenuList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_MENUS),
    [history]
  );

  const handleDeleteMenuSubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(api_urls.ROUTE_DELETE + menu_id + "/")
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Menu Successfully Deleted!",
            },
          })
        );
        redirectBackToMenuList();
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Menu Details</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_MENUS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
          <button
            className="btn btn-md btn-danger btn-outline-danger float-right pt-2 pb-2"
            onClick={() => SetIsOpenDeleteModal(true)}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>

        <div className="card-block">
          <div className="row">
            {/* Menu */}
            <div className="col-md-5">
              <h5 className="sub-title">Menu</h5>
              <Link
                to={`${routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS}${menu_id}/edit`}
                className="btn btn-md btn-primary btn-outline-primary pt-2 pb-2"
              >
                <i className="fa fa-pencil-square-o"></i> Edit Menu
              </Link>
              <div className="row mt-4">
                <div className="col-md-6">
                  <p>Category:</p>
                  <p>Name:</p>
                  <p>Is Side Navigation:</p>
                  <p>Is Side Navigation Dropdown:</p>
                  <p>Side Navigation Name:</p>
                  <p>Side Navigation Icon:</p>
                  <p>Url:</p>
                  <p>Url Name:</p>
                </div>
                <div className="col-md-6">
                  <p>{menuDetails.category}</p>
                  <p>{menuDetails.name}</p>
                  <p>{menuDetails.isMenu === true ? "YES" : "NO"}</p>
                  <p>{menuDetails.isDropdown === true ? "YES" : "NO"} </p>
                  <p>{menuDetails.navName}</p>
                  <p>
                    <i className={menuDetails.icon}></i>
                  </p>
                  <p>{menuDetails.url}</p>
                  <p>{menuDetails.urlName}</p>
                </div>
              </div>
            </div>

            {/* Permissions */}
            <div className="col-md-7">
              <h5 className="sub-title">Permissions</h5>
              <Link
                to={`${routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS}${menu_id}/edit_permissions`}
                className="btn btn-md btn-primary btn-outline-primary pt-2 pb-2"
              >
                <i className="fa fa-pencil-square-o"></i> Edit Permissions
              </Link>
              <div className="table-responsive mt-3">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Permission</th>
                      <th>Type</th>
                      <th>Subitem Name</th>
                      <th>Url</th>
                      <th>Url Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuDetails.subroutes.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.name}</td>
                          <td className="align-middle">
                            {val.is_nav === true ? (
                              <label className="label label-success">
                                {" "}
                                Nav Subitem
                              </label>
                            ) : (
                              <label className="label label-warning">
                                Page / API
                              </label>
                            )}
                          </td>
                          <td>{val.nav_name}</td>
                          <td>{val.url}</td>
                          <td>{val.url_name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteMenuSubmit}
      />
    </div>
  );
}
