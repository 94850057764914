import { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { routes, api_urls } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormErrors,
  setHighlightedRecord,
} from "../../../../store/employee/menuReducer";
import { retrieveMenu } from "../../../../store/employee/menuActions";
import DivLoader from "../../../utils/DivLoaderComp";
import {
  InputTextInline,
  RadioButtonInline,
} from "../../../utils/Forms/InlineInputs";

export default function MenuEdit() {
  const [pageLoader, SetPageLoader] = useState(false);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.menu.formData);
  const formErrors = useSelector((state) => state.menu.formErrors);
  const history = useHistory();
  const { menu_id } = useParams();

  useEffect(() => {
    dispatch(retrieveMenu({ menu_id: menu_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Menus",
          subtitle: "Menu Edit",
          icon: "icon-menu",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_MENUS, name: "Manage Menus" },
            {
              url: routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS + menu_id,
              name: "Menu Details",
            },
            {
              url: routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS + menu_id + "/edit",
              name: "Menu Edit",
            },
          ],
        },
      })
    );
  }, [dispatch, menu_id]);

  const redirectBackToMenuList = useCallback(() => {
    history.push(routes.EMPLOYEE_DASHBOARD_MENUS);
  }, [history]);

  const handleSave = (e, btl) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(api_urls.ROUTE_UPDATE + menu_id + "/", {
        category: formData.category,
        name: formData.name,
        nav_name: formData.navName,
        url: formData.url,
        url_name: formData.urlName,
        icon: formData.icon,
        is_menu: formData.isMenu,
        is_dropdown: formData.isDropdown,
      })
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Menu Successfully Updated!",
            },
          })
        );
        dispatch(setHighlightedRecord({ id: response.data.id }));
        if (btl === 1) {
          redirectBackToMenuList();
        }
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              category: field_errors.category?.toString(),
              name: field_errors.name?.toString(),
              navName: field_errors.nav_name?.toString(),
              url: field_errors.url?.toString(),
              urlName: field_errors.url_name?.toString(),
              icon: field_errors.icon?.toString(),
              isMenu: field_errors.is_menu?.toString(),
              isDropdown: field_errors.is_dropdown?.toString(),
              subroutes: field_errors.subroutes,
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Edit Menu </h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS}${menu_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_MENUS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          {/* MENU DETAILS */}
          <div className="col-md-12">
            <h4 className="sub-title">Menu Details</h4>

            <InputTextInline
              type="text"
              label="Category:"
              placeholder="Category"
              errorField={formErrors.category}
              value={formData.category}
              setter={(e) =>
                dispatch(setFormData({ ...formData, category: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Name:"
              placeholder="Name"
              errorField={formErrors.name}
              value={formData.name}
              setter={(e) =>
                dispatch(setFormData({ ...formData, name: e.target.value }))
              }
            />

            <RadioButtonInline
              label="Is Side Navigation:"
              name="isMenu"
              value={formData.isMenu}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              onChange={(e) =>
                dispatch(setFormData({ ...formData, isMenu: e.target.value }))
              }
              errorField={formErrors.isMenu}
            />

            <RadioButtonInline
              label="Is Side Navigation Dropdown"
              name="is_dropdown"
              value={formData.isDropdown}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              onChange={(e) =>
                dispatch(
                  setFormData({ ...formData, isDropdown: e.target.value })
                )
              }
              errorField={formErrors.isDropdown}
            />

            <InputTextInline
              type="text"
              label="Side Navigation Name:"
              placeholder="Side Navigation Name"
              errorField={formErrors.navName}
              value={formData.navName}
              setter={(e) =>
                dispatch(setFormData({ ...formData, navName: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Side Navigation Icon:"
              placeholder="Side Navigation Icon"
              errorField={formErrors.icon}
              value={formData.icon}
              setter={(e) =>
                dispatch(setFormData({ ...formData, icon: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Url / Main Path:"
              placeholder="Url / Main Path"
              errorField={formErrors.url}
              value={formData.url}
              setter={(e) =>
                dispatch(setFormData({ ...formData, url: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Url Name:"
              placeholder="(Django Route Name)"
              errorField={formErrors.urlName}
              value={formData.urlName}
              setter={(e) =>
                dispatch(setFormData({ ...formData, urlName: e.target.value }))
              }
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleSave(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleSave(e, 1)}
              >
                Save and back to list
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
