import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPageHeader } from "../../../../store/client/dashboardReducer";
import {
  countAllLabServicesByUserInquiry,
  countUpdatedLabServicesByUserInquiry,
  // countAllARILInquiry,
  // countUpdatedARILInquiry,
} from "../../../../store/client/home/homeActions";

export default function HomeClientMain() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.clientAuth.user);
  const labServicesTotalRecords = useSelector(
    (state) => state.clientHome.labServicesTotalRecords
  );
  const labServicesUpdatedRecords = useSelector(
    (state) => state.clientHome.labServicesUpdatedRecords
  );
  // const arilTotalRecords = useSelector(
  //   (state) => state.clientHome.arilTotalRecords
  // );
  // const arilUpdatedRecords = useSelector(
  //   (state) => state.clientHome.arilUpdatedRecords
  // );

  useEffect(() => {
    dispatch(countAllLabServicesByUserInquiry());
    dispatch(countUpdatedLabServicesByUserInquiry());
    // dispatch(countAllARILInquiry());
    // dispatch(countUpdatedARILInquiry());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: `Welcome ${user?.first_name}`,
          subtitle: "Home Page",
          icon: "icon-home",
          pages: [],
        },
      })
    );
  }, [dispatch, user]);

  return (
    <div className="row pt-4">
      <div class="col-xl-6 col-md-6">
        <div class="card prod-p-card card-blue">
          <div class="card-body">
            <div class="row align-items-center m-b-30">
              <div class="col">
                <h6 class="m-b-5 text-white">Lab Services</h6>
                <h3 class="m-b-0 f-w-700 text-white">
                  {labServicesTotalRecords}{" "}
                  {labServicesTotalRecords > 1 ? "Inquiries" : "Inquiry"}
                </h3>
              </div>
              <div class="col-auto">
                <i class="fas fa-tag text-c-blue f-18"></i>
              </div>
            </div>
            <p class="m-b-0 text-white">
              You have {labServicesUpdatedRecords}{" "}
              {labServicesUpdatedRecords > 1 ? "updates" : "update"} in your
              inquiries
            </p>
          </div>
        </div>
      </div>
      {/* <div class="col-xl-6 col-md-6">
        <div class="card prod-p-card card-green">
          <div class="card-body">
            <div class="row align-items-center m-b-30">
              <div class="col">
                <h6 class="m-b-5 text-white">Aril</h6>
                <h3 class="m-b-0 f-w-700 text-white">
                  {arilTotalRecords}{" "}
                  {arilTotalRecords > 1 ? "Inquiries" : "Inquiry"}
                </h3>
              </div>
              <div class="col-auto">
                <i class="fas fa-tag text-c-green f-18"></i>
              </div>
            </div>
            <p class="m-b-0 text-white">
              You have {arilUpdatedRecords}{" "}
              {arilUpdatedRecords > 1 ? "updates" : "update"} in your inquiries
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
