import { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { routes, api_urls } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormErrors,
  setHighlightedRecord,
} from "../../../../store/employee/tasks/taskTypeReducer";
import { retrieveTaskType } from "../../../../store/employee/tasks/taskTypeActions";
import DivLoader from "../../../utils/DivLoaderComp";
import { InputTextInline } from "../../../utils/Forms/InlineInputs";

export default function TaskTypeEdit() {
  const [pageLoader, SetPageLoader] = useState(false);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.taskType.formData);
  const formErrors = useSelector((state) => state.taskType.formErrors);
  const history = useHistory();
  const { task_type_id } = useParams();

  useEffect(() => {
    dispatch(retrieveTaskType({ task_type_id: task_type_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Task Types",
          subtitle: "Task Type Edit",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE,
              name: "Manage Task Types",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/${task_type_id}`,
              name: "Task Type Details",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/${task_type_id}/edit`,
              name: "Task Type Edit",
            },
          ],
        },
      })
    );
  }, [dispatch, task_type_id]);

  const redirectBackToTaskTypeList = useCallback(() => {
    history.push(routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE);
  }, [history]);

  const handleUpdate = (e, btl) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(`${api_urls.TASKS_TASK_TYPE}/${task_type_id}/`, {
        name: formData.name,
        description: formData.description,
      })
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "TaskType Successfully Updated!",
            },
          })
        );
        dispatch(setHighlightedRecord(response.data.id));
        if (btl === 1) {
          redirectBackToTaskTypeList();
        }
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              name: field_errors.name?.toString(),
              description: field_errors.description?.toString(),
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Edit Task Type </h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/${task_type_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="col-md-12">
            <InputTextInline
              type="text"
              label="Name:"
              placeholder="Name"
              errorField={formErrors.name}
              value={formData.name}
              setter={(e) =>
                dispatch(setFormData({ ...formData, name: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Description:"
              placeholder="Description"
              errorField={formErrors.description}
              value={formData.description}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, description: e.target.value })
                )
              }
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, 1)}
              >
                Save and back to list
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
