import axios from "axios";
import { api_urls, tasks_constants } from "../../../constants.js";
import {
  fetch,
  filter,
  paginate,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setFormData,
  setFormErrors,
  setDetails,
  setFormDataPersonnel,
  setFormDataMachines,
  setFormDataSubTask,
  setFormDataSubTaskPersonnel,
  setFormDataSubTaskMachines,
  setFormErrorsSubTask,
  setDetailsSubTask,
} from "./taskReducer";

export const fetchTask = ({ pagination, filters }) => {
  return (dispatch) => {
    dispatch(setCheckedRecords({ checkedRecords: [] }));
    axios
      .get(api_urls.TASKS_TASK, {
        params: {
          q: filters.query,
          s: filters.status,
          tt: filters.taskType,
          page_size: pagination.pageSize,
          page: pagination.pageCurrent,
          sort_field: filters.sortField,
          sort_order: filters.sortOrder,
        },
      })
      .then((res) => {
        const result = res.data.results;
        const newCheckedRecords = [];
        result.forEach((data) =>
          newCheckedRecords.push({ id: data.id, status: false })
        );
        dispatch(
          fetch({
            list: {
              records: result,
              totalCount: res.data.count,
              limit: Math.ceil(res.data.count / pagination.pageSize),
              isFetched: true,
            },
          })
        );
        dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
      });
  };
};

export const retrieveTask = ({ task_id }) => {
  return (dispatch) => {
    if (task_id !== "") {
      axios.get(`${api_urls.TASKS_TASK}/${task_id}`).then((res) => {
        const machines = res.data.taskMachines_task.reduce((acc, data) => {
          acc.push({ value: data.machine.id, label: data.machine.name });
          return acc;
        }, []);
        const personnel = res.data.taskPersonnel_task.reduce((acc, data) => {
          acc.push({
            value: data.personnel.id,
            label: `${data.personnel.fullname} - ${data.personnel.position}`,
          });
          return acc;
        }, []);
        const status = tasks_constants.TASK_STATUS_DROPDOWN.find((data) => {
          return data.value === res.data.status;
        });
        const taskType = {
          value: res.data.task_type.id,
          label: res.data.task_type.name,
        };
        const JONumber = {
          value: res.data.jo_number,
          label: res.data.jo_number,
        };
        dispatch(
          setFormData({
            taskType: taskType,
            description: res.data.description,
            status,
            JONumber: JONumber,
            datetimeFrom: res.data.datetime_from.slice(0, -1),
            datetimeTo: res.data.datetime_to.slice(0, -1),
          })
        );
        dispatch(setFormDataPersonnel(personnel));
        dispatch(setFormDataMachines(machines));
        dispatch(
          setDetails({
            taskID: `TASK-${res.data.id}`,
            user: res.data.user,
            taskType: res.data.task_type,
            description: res.data.description,
            machines: res.data.taskMachines_task,
            personnel: res.data.taskPersonnel_task,
            subtasks: res.data.taskSubTask_task,
            status,
            JONumber: res.data.jo_number,
            datetimeFrom: res.data.datetime_from.slice(0, -1),
            datetimeTo: res.data.datetime_to.slice(0, -1),
            createdAt: res.data.created_at,
            updatedAt: res.data.updated_at,
            createdBy: res.data.created_by,
            updatedBy: res.data.updated_by,
          })
        );
      });
    }
  };
};

export const filterRecords = ({ filters, status, taskType }) => {
  return (dispatch) => {
    dispatch(
      filter({
        query: filters.query,
        status: status,
        taskType: taskType,
        sortField: filters.sortField,
        sortOrder: filters.sortOrder,
      })
    );
  };
};

export const sortRecords = ({ sortField, sortOrder, filters }) => {
  return (dispatch) => {
    dispatch(
      filter({
        query: filters.query,
        status: filters.status,
        taskType: filters.taskType,
        sortField: sortField,
        sortOrder: sortOrder,
      })
    );
  };
};

export const checkAllRecords = ({ checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = [];
    checkedRecords.map((data) =>
      newCheckedRecords.push({ id: data.id, status: checked })
    );
    dispatch(setIsCheckedAllRecords(checked));
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const checkSpecificRecord = ({ id, checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = JSON.parse(JSON.stringify(checkedRecords));
    let objIndex = newCheckedRecords.findIndex((data) => data.id === id);
    newCheckedRecords[objIndex].status = checked;
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const paginationClick = ({ willCurrent, limit, size }) => {
  return (dispatch) => {
    if (willCurrent > 0 && willCurrent <= limit) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: willCurrent - 1,
            pageCurrent: willCurrent,
            pageNext: willCurrent + 1,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const pageSizeClick = (size) => {
  return (dispatch) => {
    if (size > 0) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: 0,
            pageCurrent: 1,
            pageNext: 2,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const resetForm = () => {
  return (dispatch) => {
    // Task
    dispatch(
      setFormData({
        taskType: { value: "", label: "Select" },
        description: "",
        JONumber: { value: "", label: "Select" },
        status: { value: "NA", label: "Select" },
        datetimeFrom: "",
        datetimeTo: "",
      })
    );
    dispatch(setFormDataPersonnel([]));
    dispatch(setFormDataMachines([]));
    dispatch(
      setFormErrors({
        taskType: "",
        description: "",
        JONumber: "",
        status: "",
        machines: "",
        personnel: "",
        datetimeFrom: "",
        datetimeTo: "",
      })
    );
    // Sub Task
    dispatch(
      setFormDataSubTask({
        description: "",
        status: { value: "NA", label: "Select" },
        datetimeFrom: "",
        datetimeTo: "",
      })
    );
    dispatch(setFormDataSubTaskPersonnel([]));
    dispatch(setFormDataSubTaskMachines([]));
    dispatch(
      setFormErrorsSubTask({
        description: "",
        status: "",
        machines: "",
        personnel: "",
        datetimeFrom: "",
        datetimeTo: "",
      })
    );
  };
};

// SUBTASK
export const retrieveSubTask = ({ subtask_id }) => {
  return (dispatch) => {
    if (subtask_id !== "") {
      axios.get(`${api_urls.TASKS_TASK_SUBTASK}/${subtask_id}`).then((res) => {
        const machines = res.data.taskSubTaskMachines_taskSubTask.reduce(
          (acc, data) => {
            acc.push({ value: data.machine.id, label: data.machine.name });
            return acc;
          },
          []
        );
        const personnel = res.data.taskSubTaskPersonnel_taskSubTask.reduce(
          (acc, data) => {
            acc.push({
              value: data.personnel.id,
              label: `${data.personnel.fullname} - ${data.personnel.position}`,
            });
            return acc;
          },
          []
        );
        const status = tasks_constants.TASK_STATUS_DROPDOWN.find((data) => {
          return data.value === res.data.status;
        });
        dispatch(
          setFormDataSubTask({
            description: res.data.description,
            status,
            datetimeFrom: res.data.datetime_from.slice(0, -1),
            datetimeTo: res.data.datetime_to.slice(0, -1),
          })
        );
        dispatch(setFormDataSubTaskPersonnel(personnel));
        dispatch(setFormDataSubTaskMachines(machines));
        dispatch(
          setDetailsSubTask({
            subTaskId: res.data.id,
            description: res.data.description,
            machines: res.data.taskSubTaskMachines_taskSubTask,
            personnel: res.data.taskSubTaskPersonnel_taskSubTask,
            status,
            datetimeFrom: res.data.datetime_from.slice(0, -1),
            datetimeTo: res.data.datetime_to.slice(0, -1),
            createdAt: res.data.created_at,
            updatedAt: res.data.updated_at,
            createdBy: res.data.created_by,
            updatedBy: res.data.updated_by,
          })
        );
      });
    }
  };
};
