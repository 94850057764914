import { tasks_constants } from "../../../../../constants";

export const TaskStatusLabel = ({ status }) => {
  switch (status) {
    case tasks_constants.TASK_STATUS_DROPDOWN[1].value:
      return (
        <label className="label label-md label-warning">
          {tasks_constants.TASK_STATUS_DROPDOWN[1].label}
        </label>
      );
    case tasks_constants.TASK_STATUS_DROPDOWN[2].value:
      return (
        <label className="label label-md label-primary">
          {tasks_constants.TASK_STATUS_DROPDOWN[2].label}
        </label>
      );
    case tasks_constants.TASK_STATUS_DROPDOWN[3].value:
      return (
        <label className="label label-md label-success">
          {tasks_constants.TASK_STATUS_DROPDOWN[3].label}
        </label>
      );
    case tasks_constants.TASK_STATUS_DROPDOWN[4].value:
      return (
        <label className="label label-md label-danger">
          {tasks_constants.TASK_STATUS_DROPDOWN[4].label}
        </label>
      );
    default:
      return "";
  }
};
