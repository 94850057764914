import { labservices_constants } from "../../../../constants";

export default function InquiryDetailsGenerateClientAccount({
  inquiryDetails,
  handleGenerateUser,
}) {
  return (
    <div
      className="col-md-12"
      style={
        inquiryDetails.isDeclined ||
        inquiryDetails.processStatus ===
          labservices_constants.PROCESS_STATUS_DROPDOWN[1].label
          ? { pointerEvents: "none", opacity: "0.5" }
          : {}
      }
    >
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Client Account Generation</h5>
          {inquiryDetails.user ? (
            <a
              href="/#"
              className="btn btn-disabled float-right pt-2 pb-2 ml-2 disabled"
            >
              Generate Client Account
            </a>
          ) : (
            <a
              href="/#"
              className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
              onClick={handleGenerateUser}
            >
              Generate Client Account
            </a>
          )}
        </div>
        <div className="card-block">
          <div className="row mt-4">
            <div className="col-md-4">
              <p>Username:</p>
              <p>Password:</p>
            </div>
            <div className="col-md-8">
              <p>
                {inquiryDetails.user
                  ? inquiryDetails.user.username
                  : "Not yet created"}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.user ? "********" : "Not yet created"}
                {"\u00A0"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
