import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import { retrieveTaskType } from "../../../../store/employee/tasks/taskTypeActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

export default function TaskTypeDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const history = useHistory();
  const { task_type_id } = useParams();
  const dispatch = useDispatch();
  const taskTypeDetails = useSelector((state) => state.taskType.details);

  useEffect(() => {
    dispatch(retrieveTaskType({ task_type_id: task_type_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Task Types",
          subtitle: "Task Type Details",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE,
              name: "Manage Task Types",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/${task_type_id}`,
              name: "Task Type Details",
            },
          ],
        },
      })
    );
  }, [dispatch, task_type_id]);

  const redirectBackToTaskTypeList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE),
    [history]
  );

  const handleDeleteTaskTypeSubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(`${api_urls.TASKS_TASK_TYPE}/${task_type_id}/`)
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Task Type Successfully Deleted!",
            },
          })
        );
        redirectBackToTaskTypeList();
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Task Type Details</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/${task_type_id}/edit`}
            className="btn btn-md btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-pencil-square-o"></i> Edit
          </Link>
          <button
            className="btn btn-md btn-danger btn-outline-danger float-right pt-2 pb-2"
            onClick={() => SetIsOpenDeleteModal(true)}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-md-3">
              <p>Name:</p>
              <p>Description:</p>
              <p>Created at:</p>
              <p>Updated at:</p>
              <p>Created by:</p>
              <p>Updated by:</p>
            </div>
            <div className="col-md-9">
              <p>
                {taskTypeDetails.name}
                {"\u00A0"}
              </p>
              <p>
                {taskTypeDetails.description}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskTypeDetails.createdAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskTypeDetails.updatedAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {taskTypeDetails.createdBy?.fullname}
                {"\u00A0"}
              </p>
              <p>
                {taskTypeDetails.updatedBy?.fullname}
                {"\u00A0"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteTaskTypeSubmit}
      />
    </div>
  );
}
