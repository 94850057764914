import { HashRouter, Switch, Route } from "react-router-dom";
import { routes } from "../../../../constants";
import UserList from "./List";
import UserCreate from "./Create";
import UserDetails from "./Details";
import UserEdit from "./Edit";
import UserResetPassword from "./ResetPassword";

export default function UserMain() {

    return (

        <HashRouter>
            <Switch>

                {/* LIST */}
                <Route exact path={ routes.EMPLOYEE_DASHBOARD_USERS }>
                    <UserList/>
                </Route>

                {/* CREATE */}
                <Route exact path={ routes.EMPLOYEE_DASHBOARD_USERS_CREATE }>
                    <UserCreate/>
                </Route>

                {/* details */}
                <Route exact path={ routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + ':user_id' }>
                    <UserDetails/>
                </Route>

                {/* edit */}
                <Route exact path={ routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + ':user_id/edit' }>
                    <UserEdit/>
                </Route>

                {/* reset password */}
                <Route exact path={ routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + ':user_id/reset_password' }>
                    <UserResetPassword/>
                </Route>

            </Switch>
        </HashRouter>
        
    )

}