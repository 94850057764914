import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { api_urls } from "../../../../constants";
import { InputTextInline } from "../../../utils/Forms/InlineInputs";
import { logoutClient } from "../../../../store/client/authActions";

export default function PasswordForm() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [errorFields, setErrorFields] = useState({
    currentPassword: "",
    newPassword: "",
    reNewPassword: "",
    nonFieldErrors: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(api_urls.PROFILE_USER_SET_PASSWORD, {
        current_password: currentPassword,
        new_password: newPassword,
        re_new_password: reNewPassword,
      })
      .then((res) => {
        setCurrentPassword("");
        setNewPassword("");
        setReNewPassword("");
        setErrorFields({
          currentPassword: "",
          newPassword: "",
          reNewPassword: "",
          nonFieldErrors: "",
        });
        dispatch(logoutClient());
      })
      .catch((error) => {
        setErrorFields({
          currentPassword: error.response.data.current_password?.toString(),
          newPassword: error.response.data.new_password?.toString(),
          reNewPassword: error.response.data.re_new_password?.toString(),
          nonFieldErrors: error.response.data.non_field_errors?.toString(),
        });
      });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>Change Password</h5>
      </div>
      <div className="card-block">
        <div className="alert alert-warning background-warning">
          <strong>
            <i className="fa fa-warning"></i>
          </strong>{" "}
          You will be ask to login again after you change your password.
        </div>

        <form onSubmit={handleSubmit}>
          <InputTextInline
            type="password"
            label="Current Password:"
            placeholder="Current Password"
            errorField={errorFields.currentPassword}
            value={currentPassword}
            setter={(e) => setCurrentPassword(e.target.value)}
          />

          <InputTextInline
            type="password"
            label="New Password:"
            placeholder="New Password"
            errorField={errorFields.newPassword}
            value={newPassword}
            setter={(e) => setNewPassword(e.target.value)}
          />

          <InputTextInline
            type="password"
            label="Retype New Password:"
            placeholder="Retype New Password"
            errorField={errorFields.reNewPassword}
            value={reNewPassword}
            setter={(e) => setReNewPassword(e.target.value)}
          />

          <div className="form-group row">
            <label className="col-sm-2"></label>
            <div className="col-sm-10">
              <button type="submit" className="btn btn-primary m-b-0">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
