import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import { setFormErrors } from "../../../../store/employee/menuReducer";
import {
  addFormDataSubroutesRow,
  modifyFormDataSubroutesRow,
  deleteFormDataSubroutesRow,
  retrieveMenu,
  resetForm,
} from "../../../../store/employee/menuActions";
import DeleteDialog from "../common/DeleteDialog";
import DivLoader from "../../../utils/DivLoaderComp";

function ErrorFields(props) {
  return (
    <div className="alert alert-danger">
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <i className="icofont icofont-close-line-circled"></i>
      </button>
      <p>Field Errors!</p>
      {props.errors[0]["name"] && props.errors[0]["name"][0] ? (
        <p>
          Name:<code>{props.errors[0]["name"][0]}</code>
        </p>
      ) : (
        ""
      )}
      {props.errors[0]["is_nav"] && props.errors[0]["is_nav"][0] ? (
        <p>
          Type:<code>{props.errors[0]["is_nav"][0]}</code>
        </p>
      ) : (
        ""
      )}
      {props.errors[0]["nav_name"] && props.errors[0]["nav_name"][0] ? (
        <p>
          Subitem Name:<code>{props.errors[0]["nav_name"][0]}</code>
        </p>
      ) : (
        ""
      )}
      {props.errors[0]["url"] && props.errors[0]["url"][0] ? (
        <p>
          Url:<code>{props.errors[0]["url"][0]}</code>
        </p>
      ) : (
        ""
      )}
      {props.errors[0]["url_name"] && props.errors[0]["url_name"][0] ? (
        <p>
          Url Name:<code>{props.errors[0]["url_name"][0]}</code>
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

function TableInputField(props) {
  return (
    <input
      name={props.name}
      value={props.value}
      className="form-control"
      placeholder={props.placeholder}
      onChange={props.handleModifySubroute}
    />
  );
}

export default function MenuEditPermissions() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [selectedSubrouteId, SetSelectedSubrouteId] = useState("");
  const [subrouteDeleteId, SetSubrouteDeleteId] = useState("");
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const { menu_id } = useParams();
  const dispatch = useDispatch();
  const formDataSubroutes = useSelector(
    (state) => state.menu.formDataSubroutes
  );
  const formErrors = useSelector((state) => state.menu.formErrors);

  useEffect(() => {
    dispatch(retrieveMenu({ menu_id: menu_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Menus",
          subtitle: "Menu Edit Permissions",
          icon: "icon-menu",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_MENUS, name: "Manage Menus" },
            {
              url: routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS + menu_id,
              name: "Menu Details",
            },
            {
              url:
                routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS +
                menu_id +
                "/edit_permissions",
              name: "Menu Edit Permission",
            },
          ],
        },
      })
    );
  }, [dispatch, menu_id]);

  const handleCreateFromFormDataSubroute = () => {
    dispatch(addFormDataSubroutesRow(formDataSubroutes));
  };

  const handleModifyFromFormDataSubroute = (e, key) => {
    dispatch(
      modifyFormDataSubroutesRow({
        existingFormDataSubroute: formDataSubroutes,
        key: key,
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleDeleteFromFormDataSubroute = (e, key) => {
    dispatch(
      deleteFormDataSubroutesRow({
        existingFormDataSubroute: formDataSubroutes,
        key: key,
      })
    );
  };

  const handleCreateSubrouteFromQuery = (e, key) => {
    e.preventDefault();
    SetPageLoader(true);
    const subroute = formDataSubroutes[key];
    axios
      .post(api_urls.SUBROUTE_STORE, {
        route: menu_id,
        name: subroute.name,
        is_nav: subroute.is_nav,
        nav_name: subroute.nav_name,
        url: subroute.url,
        url_name: subroute.url_name,
      })
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Permission Successfully Created!",
            },
          })
        );
        dispatch(resetForm());
        dispatch(retrieveMenu({ menu_id: menu_id }));
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          const field_errors = [];
          field_errors.push(error.response.data);
          dispatch(setFormErrors({ ...formErrors, subroutes: field_errors }));
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handleUpdateSubrouteFromQuery = (e, key, id) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(api_urls.SUBROUTE_UPDATE + id + "/", {
        route: menu_id,
        name: formDataSubroutes[key]["name"],
        is_nav: formDataSubroutes[key]["is_nav"],
        nav_name: formDataSubroutes[key]["nav_name"],
        url: formDataSubroutes[key]["url"],
        url_name: formDataSubroutes[key]["url_name"],
      })
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Permission Successfully Updated!",
            },
          })
        );
        SetSelectedSubrouteId(response.data.id);
        dispatch(resetForm());
        dispatch(retrieveMenu({ menu_id: menu_id }));
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          const field_errors = [];
          field_errors.push(error.response.data);
          dispatch(setFormErrors({ ...formErrors, subroutes: field_errors }));
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handleDeleteSubrouteFromQueryModal = (e, id) => {
    e.preventDefault();
    SetSubrouteDeleteId(id);
    SetIsOpenDeleteModal(true);
  };

  const handleDeleteSubrouteFromQuerySubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(api_urls.SUBROUTE_DELETE + subrouteDeleteId + "/")
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Permission Successfully Deleted!",
            },
          })
        );
        dispatch(resetForm());
        dispatch(retrieveMenu({ menu_id: menu_id }));
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Edit Menu Permissions</h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS}${menu_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_MENUS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          {/* MENU DETAILS */}
          <div className="col-md-12">
            {formErrors.subroutes.length > 0 ? (
              <ErrorFields errors={formErrors.subroutes} />
            ) : (
              <></>
            )}
            <h4 className="sub-title">Menu Permissions</h4>

            <div className="table-responsive">
              <button
                className="btn btn-md btn-success btn-outline-success mb-2 pt-2 pb-2"
                onClick={handleCreateFromFormDataSubroute}
              >
                <i className="fa fa-plus"></i> Add Permission
              </button>

              <table className="table table-de">
                <thead>
                  <tr>
                    <th>Permission Name</th>
                    <th>Type</th>
                    <th>Subitem Name</th>
                    <th>Url</th>
                    <th>Url Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formDataSubroutes.map((val, key) => {
                    return (
                      <tr
                        key={key}
                        className={
                          val.id === selectedSubrouteId ? "table-info" : ""
                        }
                      >
                        <td className="align-middle">
                          <TableInputField
                            name="name"
                            value={val.name}
                            placeholder="Ex: Can View User List"
                            handleModifySubroute={(e) =>
                              handleModifyFromFormDataSubroute(e, key)
                            }
                          />
                        </td>
                        <td className="align-middle">
                          <select
                            name="is_nav"
                            value={val.is_nav}
                            className="form-control form-control-primary"
                            onChange={(e) =>
                              handleModifyFromFormDataSubroute(e, key)
                            }
                          >
                            <option value="">Select</option>
                            <option value={false}>Page / API</option>
                            <option value={true}>Nav Subitem</option>
                          </select>
                        </td>
                        <td className="align-middle">
                          <TableInputField
                            name="nav_name"
                            value={val.nav_name}
                            placeholder="Ex: User Manage"
                            handleModifySubroute={(e) =>
                              handleModifyFromFormDataSubroute(e, key)
                            }
                          />
                        </td>
                        <td className="align-middle">
                          <TableInputField
                            name="url"
                            value={val.url}
                            placeholder="Ex: /user/list/"
                            handleModifySubroute={(e) =>
                              handleModifyFromFormDataSubroute(e, key)
                            }
                          />
                        </td>
                        <td className="align-middle">
                          <TableInputField
                            name="url_name"
                            value={val.url_name}
                            placeholder="x: user_list"
                            handleModifySubroute={(e) =>
                              handleModifyFromFormDataSubroute(e, key)
                            }
                          />
                        </td>
                        {val.is_from_query === true ? (
                          <td className="align-middle">
                            <button
                              className="btn btn-primary pb-2 pt-2"
                              type="button"
                              onClick={(e) =>
                                handleUpdateSubrouteFromQuery(e, key, val.id)
                              }
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-danger pb-2 pt-2 ml-2"
                              type="button"
                              onClick={(e) =>
                                handleDeleteSubrouteFromQueryModal(e, val.id)
                              }
                            >
                              <i className="fa fa-trash ml-1"></i>
                            </button>
                          </td>
                        ) : (
                          <td className="align-middle">
                            <button
                              className="btn btn-success pb-2 pt-2"
                              type="button"
                              onClick={(e) =>
                                handleCreateSubrouteFromQuery(e, key)
                              }
                            >
                              Create
                            </button>
                            <button
                              className="btn btn-danger pb-2 pt-2 ml-2"
                              type="button"
                              onClick={(e) =>
                                handleDeleteFromFormDataSubroute(e, key)
                              }
                            >
                              <i className="fa fa-trash ml-1"></i>
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteSubrouteFromQuerySubmit}
      />
    </div>
  );
}
