
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom"


function Submenu(props){
    return (
        <li className={ props.isActive ? 'active' : '' }>
            <NavLink to={props.url} className="waves-effect waves-dark">
                <span className="pcoded-mtext">{ props.navName }</span>
            </NavLink>
        </li>
    )
}


export default function SideNavMenuWithLevel(props) {

    const location = useLocation();
    const [is_menu_open, SetIsMenuOpen] = useState(false);
        
    useEffect(() => {
        props.submenus.forEach((val, key) => {
            if(props.pathname.includes(val.url)){
                SetIsMenuOpen(true);
            }
        })
    }, [props.submenus, props.pathname]);

    const hasActiveMenuPath = () => is_menu_open === true || location.pathname.includes(props.url_name);
    
    const handleOpen = (e) =>{
        e.preventDefault()
        if(!is_menu_open){
            SetIsMenuOpen(true);
        }else{
            SetIsMenuOpen(false);
        }
    }

    return (
        <li className={ hasActiveMenuPath() ? "pcoded-hasmenu active pcoded-trigger" : "pcoded-hasmenu"} 
            dropdown-icon="style1" 
            subitem-icon="style1" 
            onClick={ handleOpen }>

            <NavLink to="#" onClick={ e => e.preventDefault() } className="waves-effect waves-dark">
                <span className="pcoded-micon"><i className={ props.icon }></i></span>
                <span className="pcoded-mtext">{ props.nav_name }</span>
            </NavLink>

            <ul className="pcoded-submenu" style={ hasActiveMenuPath() ? {display:''} : {display:'none'} }>
                { props.submenus.length > 0 ? props.submenus.map(val => {
                    if(val.is_nav){
                        return (
                            <Submenu
                                key={ val.id }
                                isActive={ val.url === location.pathname }
                                url= { val.url }
                                navName={ val.nav_name }
                            />
                        )
                    }else{
                        return <></>;
                    }
                }) : <></> }
            </ul>
        </li>
    );

}