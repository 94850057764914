export default function TaskTable({ title, record, isLoading }) {
  return (
    <div class="card table-card" style={{ height: "495px" }}>
      <div class="card-header">
        <h5>{title}</h5>
      </div>
      <div class="card-block">
        <div class="table-responsive">
          <table class="table table-hover m-b-0 table-borderless">
            <thead>
              <tr>
                <th>Task ID</th>
                <th>JO Number</th>
                <th>Description</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {record.length ? (
                record.map((data) => {
                  return (
                    <tr>
                      <td>
                        <div class="d-inline-block align-middle">
                          <h6>TASK-{data.id}</h6>
                        </div>
                      </td>
                      <td>{data.jo_number}</td>
                      <td>{data.description}</td>
                      <td>{data.task_type.name}</td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
              {!record.length && !isLoading ? (
                <tr style={{ textAlign: "center" }}>
                  <td colspan="4">
                    <h4>No Records Found!</h4>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
