import { herbanext_business_services } from "../../../constants";

export default function ServicesDiv() {
  const countPerHalf = () => {
    const half = herbanext_business_services.length / 2;
    return Math.round(half - 1);
  };
  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5>Services</h5>
        </div>
        <div className="card-block">
          <div className="row">
            <div className="col-md-6">
              {herbanext_business_services
                .slice(0, countPerHalf())
                .map((data, key) => {
                  return <ServicesDivItems data={data} num={key} />;
                })}
            </div>
            <div className="col-md-6">
              {herbanext_business_services
                .slice(countPerHalf(), herbanext_business_services.length)
                .map((data, key) => {
                  return (
                    <ServicesDivItems data={data} num={countPerHalf() + key} />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ServicesDivItems = ({ data, num }) => {
  return (
    <div class="mb-0">
      <div id="accordion" role="tablist" aria-multiselectable="true">
        <div class="accordion-panel">
          <div class="accordion-heading" role="tab" id="headingOne">
            <h3 class="card-title accordion-title">
              <a
                class="accordion-msg waves-effect waves-dark"
                data-toggle="collapse"
                data-parent="#accordion"
                href={`#${data.title?.replace(/\s/g, "")}`}
                aria-expanded="true"
                aria-controls={data.title?.replace(/\s/g, "")}
              >
                {num + 1}. {` ${data.title}`}
              </a>
            </h3>
          </div>
          <div
            id={data.title?.replace(/\s/g, "")}
            class="panel-collapse collapse in"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <ul class="ml-5 mb-4 mt-3">
              {data.services &&
                data.services.map((subservices) => {
                  return (
                    <li>
                      <i class="icofont icofont-bubble-right"></i>
                      {"  "}
                      {subservices}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
