import { HashRouter, Switch, Route, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import DashboardLogin from "./DashboardLogin.js";
import DashboardMain from "./dashboard/Index.js";
import Cookies from 'js-cookie';

export default function EmployeeMain() {
  let { path } = useRouteMatch();

  useEffect(() => {
    Cookies.set('herbanext_pms', btoa('herbanext_pms'));
  }, []);

  return (
    <HashRouter>
      <Switch>
        {/* LOGIN */}
        <Route path={`${path}/login`}>
          <DashboardLogin />
        </Route>

        {/* Dashboard */}
        <Route path={`${path}/dashboard`}>
          <DashboardMain />
        </Route>
      </Switch>
    </HashRouter>
  );
}
