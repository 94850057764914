import { useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HashRouter, Switch, Route, Link } from "react-router-dom";
import { setToastNotification } from "../../../store/employee/dashboardReducer";
import { checkAuthToken, logoutUser } from "../../../store/authActions";
import { routes } from "../../../constants.js";
import ToastNotification from "../../utils/ToastNotification";
import UserAvatar from "../../images/user_avatar.jpeg";
import Sidenav from "./SideNav";
import Profile from "./profile/Index";
import Home from "./home/Index";
import UserMain from "./users/Index";
import MenuMain from "./menus/Index";
import LabServicesMain from "./lab_services/Index";
import ArilMain from "./aril/Index";
import TasksMain from "./tasks/Index";
import "./Index.css";

const auth_user = JSON.parse(window.localStorage.getItem("auth_user"));
let isInitialLoad = true;

export default function DashboardMain() {
  const user = useSelector((state) => state.auth.user);
  const pageHeader = useSelector((state) => state.dashboard.pageHeader);
  const toastNotification = useSelector(
    (state) => state.dashboard.toastNotification
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const redirectToEmployeeLogin = useCallback(
    () => history.push(routes.EMPLOYEE_LOGIN_PAGE),
    [history]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isInitialLoad && auth_user) {
      dispatch(checkAuthToken());
      isInitialLoad = false;
    }
  }, [dispatch]);

  useEffect(() => {
    if (!auth_user) {
      redirectToEmployeeLogin();
    }
  }, [redirectToEmployeeLogin]);

  const handleCloseSnackbar = () => {
    dispatch(
      setToastNotification({
        toastNotification: {
          isOpen: false,
          type: "success",
          message: "",
        },
      })
    );
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <HashRouter>
      <ToastNotification
        vertical="top"
        horizontal="center"
        open={toastNotification.isOpen}
        duration={6000}
        closeHandler={handleCloseSnackbar}
        type={toastNotification.type}
        message={toastNotification.message}
      />
      <div id="pcoded" className="pcoded">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">
          <nav className="navbar header-navbar pcoded-header">
            <div className="navbar-wrapper">
              <div className="navbar-logo">
                <Link
                  to={routes.EMPLOYEE_DASHBOARD_HOME}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  HERBANEXT
                </Link>
              </div>
              <div className="navbar-container container-fluid">
                <ul className="nav-right">
                  <li className="user-profile header-notification">
                    <div className="dropdown-primary dropdown">
                      <div className="dropdown-toggle" data-toggle="dropdown">
                        <img
                          src={UserAvatar}
                          className="img-radius"
                          alt="User-Profile"
                        />
                        <span>
                          {user?.first_name ? user?.first_name : user?.username}
                        </span>
                        <i className="feather icon-chevron-down"></i>
                      </div>
                      <ul
                        className="show-notification profile-notification dropdown-menu"
                        data-dropdown-in="fadeIn"
                        data-dropdown-out="fadeOut"
                      >
                        <li>
                          <Link to={routes.EMPLOYEE_DASHBOARD_PROFILE}>
                            <i className="feather icon-user"></i> Profile
                          </Link>
                        </li>
                        <li>
                          <a href="/#" onClick={handleLogout}>
                            <i className="feather icon-log-out"></i> Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <Sidenav />

              <div className="pcoded-content">
                <div className="page-header card">
                  <div className="row align-items-end">
                    <div className="col-lg-8">
                      <div className="page-header-title">
                        <i
                          className={`feather ${pageHeader.icon} bg-c-blue`}
                        ></i>
                        <div className="d-inline">
                          <h5>{pageHeader.title}</h5>
                          <span>{pageHeader.subtitle}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="page-header-breadcrumb">
                        <ul className=" breadcrumb breadcrumb-title">
                          <li className="breadcrumb-item">
                            <Link to={routes.EMPLOYEE_DASHBOARD_HOME}>
                              <i className="feather icon-home"></i>
                            </Link>
                          </li>

                          {pageHeader.pages.map((data) => {
                            return (
                              <li className="breadcrumb-item" key={data.url}>
                                <Link to={data.url}>{data.name}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="page-body">
                        <div className="row">
                          <Switch>
                            <Route path={routes.EMPLOYEE_DASHBOARD_PROFILE}>
                              <Profile />
                            </Route>

                            <Route path={routes.EMPLOYEE_DASHBOARD_HOME}>
                              <Home />
                            </Route>

                            <Route path={routes.EMPLOYEE_DASHBOARD_USERS}>
                              <UserMain />
                            </Route>

                            <Route path={routes.EMPLOYEE_DASHBOARD_MENUS}>
                              <MenuMain />
                            </Route>

                            <Route path={routes.EMPLOYEE_DASHBOARD_LABSERVICES}>
                              <LabServicesMain />
                            </Route>

                            <Route path={routes.EMPLOYEE_DASHBOARD_ARIL}>
                              <ArilMain />
                            </Route>

                            <Route path={routes.EMPLOYEE_DASHBOARD_TASKS}>
                              <TasksMain />
                            </Route>
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HashRouter>
  );
}
