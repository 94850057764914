import axios from "axios";
import { api_urls, user_constants } from "../../constants.js";
import { login, logout } from "./authReducer";

export const checkClientAuthToken = () => {
  return (dispatch) => {
    axios
      .get(api_urls.USER_CHECK_TOKEN)
      .then((res) => {
        const userType = res.data.userDetails_user[0].category;
        if (userType !== user_constants.CATEGORY_DROPDOWN[3].value) {
          async function clearUser() {
            dispatch(logout());
            window.localStorage.clear();
          }
          clearUser().then((res) => window.location.reload());
        } else {
          dispatch(login({ data: res.data }));
        }
      })
      .catch((err) => {
        async function clearUser() {
          dispatch(logout());
          window.localStorage.clear();
        }
        clearUser().then((res) => window.location.reload());
      });
  };
};

export const logoutClient = () => {
  return (dispatch) => {
    async function clearUser() {
      dispatch(logout());
      window.localStorage.clear();
    }
    clearUser().then((res) => window.location.reload());
  };
};
