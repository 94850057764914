import { HashRouter, Switch, Route, Link } from "react-router-dom";
import { routes } from "../../../constants.js";
import { useSelector, useDispatch } from "react-redux";
import { setToastNotification } from "../../../store/client/dashboardReducer.js";
import ToastNotification from "../../utils/ToastNotification.js";
import HomeClientmain from "./home/Index";
import LabServicesClientMain from "./lab_services/Index";
import ArilClientMain from "./aril/Index";
import ProfileClientMain from "./profile/Index";
import HelpMain from "./help/Index";

export default function TabBody() {
  const dispatch = useDispatch();
  const pageHeader = useSelector((state) => state.clientDashboard.pageHeader);
  const toastNotification = useSelector(
    (state) => state.clientDashboard.toastNotification
  );

  const handleCloseSnackbar = () => {
    dispatch(
      setToastNotification({
        toastNotification: {
          isOpen: false,
          type: "success",
          message: "",
        },
      })
    );
  };
  return (
    <HashRouter>
      <ToastNotification
        vertical="top"
        horizontal="center"
        open={toastNotification.isOpen}
        duration={6000}
        closeHandler={handleCloseSnackbar}
        type={toastNotification.type}
        message={toastNotification.message}
      />
      <div className="tab-content card-block" style={{ height: "200em" }}>
        <div className="page-header card m-0 mt-4">
          <div className="row align-items-end">
            <div className="col-lg-8">
              <div className="page-header-title">
                <i className={`feather ${pageHeader.icon} bg-c-blue mt-1`}></i>
                <div className="d-inline">
                  <h4>{pageHeader.title}</h4>
                  <span>{pageHeader.subtitle}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="page-header-breadcrumb">
                <ul className=" breadcrumb breadcrumb-title">
                  <li className="breadcrumb-item">
                    <Link to={routes.CLIENT_DASHBOARD_HOME}>
                      <i className="feather icon-home"></i>
                    </Link>
                  </li>

                  {pageHeader.pages.map((data) => {
                    return (
                      <li className="breadcrumb-item" key={data.url}>
                        <Link to={data.url}>{data.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route path={routes.CLIENT_DASHBOARD_HOME}>
            <div className="tab-pane active" role="tabpanel">
              <HomeClientmain />
            </div>
          </Route>
          <Route path={routes.CLIENT_DASHBOARD_LABSERVICES_INQUIRY}>
            <div className="tab-pane active" role="tabpanel">
              <LabServicesClientMain />
            </div>
          </Route>
          <Route path={routes.CLIENT_DASHBOARD_ARIL_INQUIRY}>
            <div className="tab-pane active" role="tabpanel">
              <ArilClientMain />
            </div>
          </Route>
          <Route path={routes.CLIENT_DASHBOARD_HELP}>
            <div className="tab-pane active" role="tabpanel">
              <HelpMain/>
            </div>
          </Route>
          <Route path={routes.CLIENT_DASHBOARD_PROFILE}>
            <div className="tab-pane active" role="tabpanel">
              <ProfileClientMain />
            </div>
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
}
