import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HashRouter, Switch, Route } from "react-router-dom";
import { routes } from "../../../../constants";

import TaskList from "./TaskList";
import TaskCreate from "./TaskCreate";
import TaskDetails from "./TaskDetails";
import TaskEdit from "./TaskEdit.js";

import PersonnelList from "./PersonnelList";
import PersonnelCreate from "./PersonnelCreate";
import PersonnelDetails from "./PersonnelDetails.js";
import PersonnelEdit from "./PersonnelEdit.js";

import MachineList from "./MachineList";
import MachineCreate from "./MachineCreate";
import MachineDetails from "./MachineDetails";
import MachineEdit from "./MachineEdit";

import TaskTypeList from "./TaskTypeList";
import TaskTypeCreate from "./TaskTypeCreate";
import TaskTypeDetails from "./TaskTypeDetails";
import TaskTypeEdit from "./TaskTypeEdit";

import TaskBoard from "./TaskBoard";
import TaskBoardDetails from "./TaskBoardDetails";
import TaskDetailsSubTaskCreate from "./TaskDetailsSubTaskCreate.js";
import TaskDetailsSubTaskDetails from "./TaskDetailsSubTaskDetails.js";
import TaskDetailsSubTaskEdit from "./TaskDetailsSubTaskEdit.js";
import TaskBoardEdit from "./TaskBoardEdit";
import TaskBoardCompleted from "./TaskBoardCompleted";
import TaskBoardCompletedDetails from "./TaskBoardCompletedDetails";
import TaskBoardCancelled from "./TaskBoardCancelled";
import TaskBoardCancelledDetails from "./TaskBoardCancelledDetails";

import TaskCalendar from "./TaskCalendar";
import TaskCalendarDetails from "./TaskCalendarDetails";

import { populateMachineOptions } from "../../../../store/employee/tasks/machineActions";
import { populatePersonnelOptions } from "../../../../store/employee/tasks/personnelActions";
import { populateTaskTypeOptions } from "../../../../store/employee/tasks/taskTypeActions";
import { getValidForTask } from "../../../../store/employee/lab_services/inquiryActions";

export default function TasksMain() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(populateMachineOptions());
    dispatch(populatePersonnelOptions());
    dispatch(populateTaskTypeOptions());
    dispatch(getValidForTask());
  }, [dispatch]);
  return (
    <HashRouter>
      <Switch>
        <Route exact path={routes.EMPLOYEE_DASHBOARD_TASKS_TASK}>
          <TaskList />
        </Route>
        <Route exact path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/create`}>
          <TaskCreate />
        </Route>
        <Route exact path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/:task_id`}>
          <TaskDetails />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/:task_id/subtask/create`}
        >
          <TaskDetailsSubTaskCreate />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/:task_id/subtask/:subtask_id`}
        >
          <TaskDetailsSubTaskDetails />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/:task_id/subtask/:subtask_id/edit`}
        >
          <TaskDetailsSubTaskEdit />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/:task_id/edit`}
        >
          <TaskEdit />
        </Route>

        <Route exact path={routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}>
          <PersonnelList />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/create`}
        >
          <PersonnelCreate />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/:personnel_id`}
        >
          <PersonnelDetails />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/:personnel_id/edit`}
        >
          <PersonnelEdit />
        </Route>

        <Route exact path={routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}>
          <MachineList />
        </Route>
        <Route exact path={`${routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}/create`}>
          <MachineCreate />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}/:machine_id`}
        >
          <MachineDetails />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_MACHINE}/:machine_id/edit`}
        >
          <MachineEdit />
        </Route>

        <Route exact path={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}>
          <TaskTypeList />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/create`}
        >
          <TaskTypeCreate />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/:task_type_id`}
        >
          <TaskTypeDetails />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE}/:task_type_id/edit`}
        >
          <TaskTypeEdit />
        </Route>

        <Route exact path={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}>
          <TaskBoard />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}/:task_id`}
        >
          <TaskBoardDetails />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}/:task_id/edit`}
        >
          <TaskBoardEdit />
        </Route>
        <Route
          exact
          path={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_COMPLETED}
        >
          <TaskBoardCompleted />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_COMPLETED}/:task_id`}
        >
          <TaskBoardCompletedDetails />
        </Route>
        <Route
          exact
          path={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_CANCELLED}
        >
          <TaskBoardCancelled />
        </Route>
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_CANCELLED}/:task_id`}
        >
          <TaskBoardCancelledDetails />
        </Route>

        <Route exact path={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR}>
          <TaskCalendar />
        </Route>

        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR}/:task_id`}
        >
          <TaskCalendarDetails />
        </Route>
      </Switch>
    </HashRouter>
  );
}
