import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import { setHighlightedRecord } from "../../../../store/employee/userReducer";
import { api_urls, routes } from "../../../../constants";
import { InputTextInline } from "../../../utils/Forms/InlineInputs";
import DivLoader from "../../../utils/DivLoaderComp";

export default function UserResetPassword() {
  const [loader, SetLoader] = useState(false);
  const [newPassword, SetNewPassword] = useState("");
  const [newPasswordConfirm, SetNewPasswordConfirm] = useState("");
  const [formErrors, SetFormErrors] = useState({
    newPassword: "",
    newPasswordConfirm: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { user_id } = useParams();
  const redirectBackToUserList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_USERS),
    [history]
  );

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "User",
          subtitle: "User Reset Password",
          icon: "icon-user",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_USERS, name: "Manage Users" },
            {
              url: routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + user_id,
              name: "User Details",
            },
            {
              url:
                routes.EMPLOYEE_DASHBOARD_USERS_DETAILS +
                user_id +
                "/reset_password",
              name: "User Reset Password",
            },
          ],
        },
      })
    );
  }, [dispatch, user_id]);

  const handleReset = (e, btl) => {
    e.preventDefault();
    SetLoader(true);
    axios
      .post(api_urls.USER_RESET_PASSWORD, {
        id: user_id,
        new_password: newPassword,
        new_password_confirm: newPasswordConfirm,
      })
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Password Successfully Reset!",
            },
          })
        );
        dispatch(setHighlightedRecord(user_id));
        SetNewPassword("");
        SetNewPasswordConfirm("");
        if (btl === 1) {
          redirectBackToUserList();
        }
        SetLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          SetFormErrors({
            newPassword: field_errors.new_password?.toString(),
            newPasswordConfirm: field_errors.new_password_confirm?.toString(),
          });
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={loader} />
        <div className="card-header">
          <h5>Reset Password</h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_USERS_DETAILS}${user_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_USERS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="col-md-12">
            <InputTextInline
              secureTextEntry
              type="password"
              label="New Password:"
              placeholder="New Password"
              errorField={formErrors.newPassword}
              value={newPassword}
              setter={(e) => SetNewPassword(e.target.value)}
            />

            <InputTextInline
              type="password"
              label="Confirm New Password:"
              placeholder="Confirm New Password"
              errorField={formErrors.newPasswordConfirm}
              value={newPasswordConfirm}
              setter={(e) => SetNewPasswordConfirm(e.target.value)}
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleReset(e, 0)}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleReset(e, 1)}
              >
                Reset and Back to List
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
