import axios from "axios";
import { api_urls } from "../../../constants.js";
import { setEvents } from "./taskCalendarReducer";

export const fetchEvents = ({ datetime_from, datetime_to }) => {
  return (dispatch) => {
    axios
      .get(`${api_urls.TASKS_TASK}/get_by_datetime`, {
        params: {
          datetime_from,
          datetime_to,
        },
      })
      .then((res) => {
        const records = res.data.reduce((acc, data) => {
          const start = data.datetime_from.slice(0, -1);
          const end = data.datetime_to.slice(0, -1);
          acc.push({
            id: data.id,
            title: data.description,
            allDay: false,
            start,
            end,
            backgroundColor: data.color,
            borderColor: data.color,
          });
          return acc;
        }, []);
        dispatch(
          setEvents({
            records,
            isFetched: true,
          })
        );
      });
  };
};
