import { useEffect, useCallback, useState } from "react";
import axios from "axios";
import moment from "moment";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { user_constants } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import { search } from "../../../../store/employee/userReducer";
import {
  checkAllRecords,
  checkSpecificRecord,
  fetchUser,
  paginationClick,
  pageSizeClick,
  refreshList,
  filterRecords,
  sortRecords,
} from "../../../../store/employee/userActions";
import { api_urls, routes } from "../../../../constants";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TableHeaderDefault } from "../../../utils/Table/TableHeaders";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function UserList() {
  const [isOpenDeleteBulk, SetIsOpenDeleteBulk] = useState(false);
  const [isOpenListFilter, SetIsOpenListFilter] = useState(false);
  const [isOpenListSort, SetIsOpenListSort] = useState(false);
  const [pageLoader, SetPageLoader] = useState(false);
  const [searchQuery, SetSearchQuery] = useState("");
  const [filterCategory, SetFilterCategory] = useState("");
  const [sortField, SetSortField] = useState("");
  const [sortOrder, SetSortOrder] = useState("");

  const list = useSelector((state) => state.user.list);
  const pagination = useSelector((state) => state.user.listPagination);
  const filters = useSelector((state) => state.user.listFilters);
  const highlightedRecord = useSelector(
    (state) => state.user.highlightedRecord
  );
  const isCheckedAllRecords = useSelector(
    (state) => state.user.isCheckedAllRecords
  );
  const checkedRecords = useSelector((state) => state.user.checkedRecords);

  const styleClasses = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectToUserCreate = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_USERS_CREATE),
    [history]
  );
  const redirectToUserDetails = useCallback(
    (id) => history.push(routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + id),
    [history]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search({ query: value })), 500),
    []
  );

  useEffect(() => {
    dispatch(fetchUser({ pagination: pagination, filters: filters }));
  }, [dispatch, pagination, filters]);

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "User",
          subtitle: "Manage Users",
          icon: "icon-user",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_USERS, name: "Manage Users" },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    SetSearchQuery(value);
    delaySearch(value);
  };

  const handleCreateButtonClick = (e) => {
    e.preventDefault();
    redirectToUserCreate();
  };

  const handleOpenUserDetails = (e, id) => {
    e.preventDefault();
    redirectToUserDetails(id);
  };

  const handleCheckAllRecords = (e) => {
    dispatch(
      checkAllRecords({
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handleCheckSpecificRecord = (e, id) => {
    dispatch(
      checkSpecificRecord({
        id: id,
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    dispatch(
      paginationClick({
        willCurrent: willCurrent,
        limit: list.limit,
        size: pagination.pageSize,
      })
    );
  };

  const handlePageSizeClick = (e) => {
    e.preventDefault();
    dispatch(pageSizeClick(e.target.value));
  };

  const handleBulkDeleteSubmit = () => {
    SetIsOpenDeleteBulk(false);
    SetPageLoader(true);
    let ids = [];
    checkedRecords.forEach((data) => {
      if (data.status === true) {
        ids.push(data.id);
      }
    });
    if (ids.length > 0) {
      axios
        .delete(api_urls.USER_BULK_DELETE, {
          data: {
            ids: ids,
          },
        })
        .then((response) => {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Users Successfully Deleted!",
              },
            })
          );
          SetPageLoader(false);
          dispatch(fetchUser({ pagination: pagination, filters: filters }));
        })
        .catch((error) => {
          if (error.response.status === 404 || error.response.status === 500) {
            dispatch(
              setToastNotification({
                toastNotification: {
                  isOpen: true,
                  type: "error",
                  message:
                    "There's an error trying to submit data to the server!",
                },
              })
            );
          }
          SetPageLoader(false);
        });
    } else {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "Please select records to delete!",
          },
        })
      );
      SetPageLoader(false);
    }
  };

  const handleRefreshClick = () => {
    dispatch(refreshList());
  };

  const handleSubmitFilter = (e) => {
    SetIsOpenListFilter(false);
    SetPageLoader(true);
    dispatch(
      filterRecords({
        category: filterCategory,
        filters: filters,
      })
    );
    SetPageLoader(false);
  };

  const handleSubmitSort = (e) => {
    SetIsOpenListSort(false);
    SetPageLoader(true);
    dispatch(
      sortRecords({
        sortField: sortField,
        sortOrder: sortOrder,
        filters: filters,
      })
    );
    SetPageLoader(false);
  };

  const recordIsChecked = (id) => {
    return checkedRecords.some(
      (data) => data.id === id && data.status === true
    );
  };

  return (
    <div className="col-sm-12">
      <div className="card table-card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        {/* Table Header */}
        <div className="card-header">
          <TableHeaderDefault
            createButtonClickHandler={handleCreateButtonClick}
            searchInputValue={searchQuery}
            searchInputHandler={handleSearch}
            filterButton={true}
            filterButtonClickHandler={() => SetIsOpenListFilter(true)}
            sortButton={true}
            sortButtonClickHandler={() => SetIsOpenListSort(true)}
            refreshButtonClickHandler={handleRefreshClick}
            deleteButton={true}
            deleteButtonDisable={true}
            deleteButtonClickHandler={() => SetIsOpenDeleteBulk(true)}
            createButton={true}
            entriesSelect={true}
            entriesSelectPageSize={pagination.pageSize}
            entriesSelectChangeHandler={handlePageSizeClick}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>

        {/* TABLE BODY */}
        <div className="card-block table-border-style pb-0 pt-0">
          <div className="table-responsive">
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th className="p-0">
                    <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                      <label>
                        <input
                          type="checkbox"
                          checked={isCheckedAllRecords}
                          onChange={handleCheckAllRecords}
                        />
                        <span className="cr">
                          <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                        </span>
                      </label>
                    </div>
                  </th>
                  <th className="align-middle">Username</th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Type</th>
                  <th className="align-middle">Date Joined</th>
                </tr>
              </thead>
              <tbody>
                {list.records.map((val, key) => {
                  let date_joined = val.date_joined
                    ? moment(val.date_joined).format("MM/DD/YYYY hh:mm A")
                    : "";
                  return (
                    <tr
                      key={key}
                      className={
                        val.id == highlightedRecord || recordIsChecked(val.id)
                          ? "table-info"
                          : ""
                      }
                    >
                      <td className="p-0">
                        <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                          <label>
                            <input
                              key={key}
                              type="checkbox"
                              checked={recordIsChecked(val.id)}
                              onChange={(e) =>
                                handleCheckSpecificRecord(e, val.id)
                              }
                            />
                            <span className="cr">
                              <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td className="align-middle">
                        <a
                          href="/#"
                          onClick={(e) => handleOpenUserDetails(e, val.id)}
                        >
                          <ins className="text-info">{val.username}</ins>
                        </a>
                      </td>
                      <td className="align-middle">{val.fullname}</td>
                      <td className="align-middle">
                        <label className="label label-primary">
                          {val.userDetails_user.length === 1
                            ? user_constants.CATEGORY_DROPDOWN.find(
                                (data) =>
                                  data.value ===
                                  val.userDetails_user[0]?.category
                              )?.label
                            : "None"}
                        </label>
                      </td>
                      <td className="align-middle">{date_joined}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Table Footer */}
        <div className="card-footer">
          <TableFooterDefault
            counterPageSize={pagination.pageSize}
            counterPageCurrent={pagination.pageCurrent}
            counterPageLimit={list.limit}
            counterTotalRecords={list.totalCount}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>
      </div>

      {/* Filter Modal */}
      <Dialog open={isOpenListFilter} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Select fields to filter
          </DialogContentText>
          <form className={styleClasses.form} noValidate>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="user-type">User Type</InputLabel>
              <Select
                labelId="user-type"
                id="demo-simple-select-outlined"
                value={filterCategory}
                onChange={(e) => {
                  SetFilterCategory(e.target.value);
                }}
                label="User Type"
              >
                {user_constants.CATEGORY_DROPDOWN.map((data) => (
                  <MenuItem value={data.value} key={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetIsOpenListFilter(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitFilter}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sort Modal */}
      <Dialog open={isOpenListSort} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">Sort</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Select fields to sort
          </DialogContentText>
          <form className={styleClasses.form} noValidate>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="sort-field">Sort Field</InputLabel>
              <Select
                labelId="sort-field"
                id="demo-simple-select-outlined"
                value={sortField}
                onChange={(e) => {
                  SetSortField(e.target.value);
                }}
                label="Sort Field"
              >
                {user_constants.SU_SORT_FIELD_DROPDOWN.map((data) => (
                  <MenuItem value={data.value} key={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="sort-order">Sort Order</InputLabel>
              <Select
                labelId="sort-order"
                id="demo-simple-select-outlined"
                value={sortOrder}
                onChange={(e) => {
                  SetSortOrder(e.target.value);
                }}
                label="Sort Order"
              >
                {user_constants.SU_SORT_ORDER_DROPDOWN.map((data) => (
                  <MenuItem value={data.value} key={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetIsOpenListSort(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitSort}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Modal */}
      <DeleteDialog
        title="Delete Selected Records"
        message="Are you sure you to permanently delete the selected records?"
        open={isOpenDeleteBulk}
        handleClose={() => SetIsOpenDeleteBulk(false)}
        handleDelete={handleBulkDeleteSubmit}
      />
    </div>
  );
}
