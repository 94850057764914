import { useEffect, useCallback, useState } from "react";
import axios from "axios";
import moment from "moment";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  search,
  resetList,
} from "../../../../store/employee/lab_services/inquiryReducer";
import {
  checkAllRecords,
  checkSpecificRecord,
  fetchInquiry,
  filterRecords,
  sortRecords,
  paginationClick,
  pageSizeClick,
  downloadQuoteOrLSR,
  DOWNLOAD_ACTIONS,
} from "../../../../store/employee/lab_services/inquiryActions";
import { api_urls, labservices_constants, routes } from "../../../../constants";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TableHeaderDefault } from "../../../utils/Table/TableHeaders";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";
import ViewFileDialog from "../common/ViewFileDialog";
import {
  fileStorageUrlToMediaUrl,
  getFileTypeByFileStorageUrl,
} from "../../../utils/StringUtils";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function InquiryList() {
  const [isOpenDeleteBulk, SetIsOpenDeleteBulk] = useState(false);
  const [isOpenFilterModal, SetIsOpenFilterModal] = useState(false);
  const [isOpenSortModal, SetIsOpenSortModal] = useState(false);
  const [pageLoader, SetPageLoader] = useState(false);
  const [searchQuery, SetSearchQuery] = useState("");
  const [filterQuery, SetFilterQuery] = useState({
    serviceType: "",
    processStatus: "",
    sortField: "",
    sortOrder: "",
  });
  const [fileViewerDetails, SetFileViewerDetails] = useState({
    isOpen: false,
    fileStorageUrl: "",
    fileData: null,
    downloadAction: "",
  });

  const list = useSelector((state) => state.labServicesInquiry.list);
  const pagination = useSelector(
    (state) => state.labServicesInquiry.listPagination
  );
  const filters = useSelector((state) => state.labServicesInquiry.listFilters);
  const highlightedRecord = useSelector(
    (state) => state.labServicesInquiry.highlightedRecord
  );
  const isCheckedAllRecords = useSelector(
    (state) => state.labServicesInquiry.isCheckedAllRecords
  );
  const checkedRecords = useSelector(
    (state) => state.labServicesInquiry.checkedRecords
  );

  const styleClasses = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectToInquiryCreate = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_CREATE),
    [history]
  );
  const redirectToInquiryDetails = useCallback(
    (id) =>
      history.push(routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_DETAILS + id),
    [history]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search({ query: value })), 500),
    []
  );

  useEffect(() => {
    dispatch(fetchInquiry({ pagination: pagination, filters: filters }));
  }, [dispatch, pagination, filters]);

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Lab Services Inquiries",
          subtitle: "Manage Inquiries",
          icon: "icon-info",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY,
              name: "Manage Inquiries",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    SetSearchQuery(value);
    delaySearch(value);
  };

  const handleCreateButtonClick = (e) => {
    e.preventDefault();
    redirectToInquiryCreate();
  };

  const handleOpenInquiryDetails = (e, id) => {
    e.preventDefault();
    redirectToInquiryDetails(id);
  };

  const handleCheckAllRecords = (e) => {
    dispatch(
      checkAllRecords({
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handleCheckSpecificRecord = (e, id) => {
    dispatch(
      checkSpecificRecord({
        id: id,
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    dispatch(
      paginationClick({
        willCurrent: willCurrent,
        limit: list.limit,
        size: pagination.pageSize,
      })
    );
  };

  const handlePageSizeClick = (e) => {
    e.preventDefault();
    dispatch(pageSizeClick(e.target.value));
  };

  const handleRefreshClick = () => {
    dispatch(resetList());
  };

  const recordIsChecked = (id) => {
    return checkedRecords.some(
      (data) => data.id === id && data.status === true
    );
  };

  const findServiceType = (value) => {
    const serviceType = labservices_constants.SERVICE_TYPE_DROPDOWN.find(
      (data) => data.value === value
    );
    if (!serviceType || serviceType["label"] === "Select") {
      return "NA";
    }
    return serviceType["label"];
  };

  const handleBulkDeleteSubmit = () => {
    SetIsOpenDeleteBulk(false);
    SetPageLoader(true);
    let ids = [];
    checkedRecords.forEach((data) => {
      if (data.status === true) {
        ids.push(data.id);
      }
    });
    if (ids.length > 0) {
      axios
        .delete(api_urls.LABSERVICES_INQUIRY_BULK_DELETE, {
          data: {
            ids: ids,
          },
        })
        .then((response) => {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Inquiries Successfully Deleted!",
              },
            })
          );
          SetPageLoader(false);
          dispatch(fetchInquiry({ pagination: pagination, filters: filters }));
        })
        .catch((error) => {
          if (error.response.status === 404 || error.response.status === 500) {
            dispatch(
              setToastNotification({
                toastNotification: {
                  isOpen: true,
                  type: "error",
                  message:
                    "There's an error trying to submit data to the server!",
                },
              })
            );
          }
          SetPageLoader(false);
        });
    } else {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "Please select records to delete!",
          },
        })
      );
      SetPageLoader(false);
    }
  };

  const handleFilterSubmit = () => {
    SetIsOpenFilterModal(false);
    SetPageLoader(true);
    dispatch(
      filterRecords({
        serviceType: filterQuery.serviceType,
        processStatus: filterQuery.processStatus,
        filters,
      })
    );
    SetPageLoader(false);
  };

  const handleSortSubmit = () => {
    SetIsOpenSortModal(false);
    SetPageLoader(true);
    dispatch(
      sortRecords({
        sortField: filterQuery.sortField,
        sortOrder: filterQuery.sortOrder,
        filters,
      })
    );
    SetPageLoader(false);
  };

  const handleDownloadQuoteOrLSR = (e, action, val) => {
    e.preventDefault();
    dispatch(
      downloadQuoteOrLSR({
        inquiryId: val.id,
        quote: val.quote,
        quoteSigned: val.quote_signed,
        lsr: val.lsr,
        lsrSigned: val.lsr_signed,
        action,
      })
    );
  };

  const getProcessStatusConstantLabel = (value) => {
    return labservices_constants.PROCESS_STATUS_DROPDOWN.find(
      (data) => data.value === value
    ).label;
  };

  const renderProcessStatusLabel = (processStatus, isDeclined) => {
    if (isDeclined) {
      return <label className="label label-md label-warning">Declined</label>;
    }
    if (
      processStatus === labservices_constants.PROCESS_STATUS_DROPDOWN[1].value
    ) {
      return (
        <label className="label label-md label-danger">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else if (
      processStatus === labservices_constants.PROCESS_STATUS_DROPDOWN[2].value
    ) {
      return (
        <label className="label label-md label-warning">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else {
      return (
        <label className="label label-md label-primary">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    }
  };

  const renderDownloadableQuote = (val) => {
    return (
      <ul>
        <li>
          Initial:{" "}
          {val.quote ? (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                SetFileViewerDetails({
                  ...fileViewerDetails,
                  isOpen: true,
                  fileStorageUrl: val.quote,
                  fileData: val,
                  downloadAction: DOWNLOAD_ACTIONS.QUOTE_INITIAL,
                });
              }}
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
        <li>
          Signed:{" "}
          {val.quote_signed ? (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                SetFileViewerDetails({
                  ...fileViewerDetails,
                  isOpen: true,
                  fileStorageUrl: val.quote_signed,
                  fileData: val,
                  downloadAction: DOWNLOAD_ACTIONS.QUOTE_SIGNED,
                });
              }}
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
      </ul>
    );
  };

  const renderDownloadableLSR = (val) => {
    return (
      <ul>
        <li>
          Initial:{" "}
          {val.lsr ? (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                SetFileViewerDetails({
                  ...fileViewerDetails,
                  isOpen: true,
                  fileStorageUrl: val.lsr,
                  fileData: val,
                  downloadAction: DOWNLOAD_ACTIONS.LSR_INITIAL,
                });
              }}
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
        <li>
          Signed:{" "}
          {val.lsr_signed ? (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                SetFileViewerDetails({
                  ...fileViewerDetails,
                  isOpen: true,
                  fileStorageUrl: val.lsr_signed,
                  fileData: val,
                  downloadAction: DOWNLOAD_ACTIONS.LSR_SIGNED,
                });
              }}
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
      </ul>
    );
  };

  return (
    <div className="col-sm-12">
      <div className="card table-card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        {/* Table Header */}
        <div className="card-header">
          <TableHeaderDefault
            createButtonClickHandler={handleCreateButtonClick}
            searchInputValue={searchQuery}
            searchInputHandler={handleSearch}
            filterButton={true}
            filterButtonClickHandler={() => SetIsOpenFilterModal(true)}
            sortButton={true}
            sortButtonClickHandler={() => SetIsOpenSortModal(true)}
            refreshButtonClickHandler={handleRefreshClick}
            deleteButton={true}
            deleteButtonDisable={true}
            deleteButtonClickHandler={() => SetIsOpenDeleteBulk(true)}
            createButton={true}
            entriesSelect={true}
            entriesSelectPageSize={pagination.pageSize}
            entriesSelectChangeHandler={handlePageSizeClick}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>

        {/* TABLE BODY */}
        <div className="card-block table-border-style pb-0 pt-0">
          <div className="table-responsive">
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th className="p-0">
                    <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                      <label>
                        <input
                          type="checkbox"
                          checked={isCheckedAllRecords}
                          onChange={handleCheckAllRecords}
                        />
                        <span className="cr">
                          <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                        </span>
                      </label>
                    </div>
                  </th>
                  <th className="align-middle">JO Number</th>
                  <th className="align-middle">Client Name</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle">Service Type</th>
                  <th className="align-middle">Date Encoded</th>
                  <th className="align-middle">Quote</th>
                  <th className="align-middle">LSR</th>
                </tr>
              </thead>
              <tbody>
                {list.records.map((val, key) => {
                  const date_encoded = val.created_at
                    ? moment(val.created_at).format("MM/DD/YYYY hh:mm A")
                    : "";
                  return (
                    <tr
                      key={key}
                      className={
                        val.id == highlightedRecord || recordIsChecked(val.id)
                          ? "table-info"
                          : val.is_updated_client === true
                          ? "table-success"
                          : ""
                      }
                    >
                      <td className="p-0">
                        <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                          <label>
                            <input
                              key={key}
                              type="checkbox"
                              checked={recordIsChecked(val.id)}
                              onChange={(e) =>
                                handleCheckSpecificRecord(e, val.id)
                              }
                            />
                            <span className="cr">
                              <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td
                        onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                        className="align-middle"
                      >
                        {val.jo_number ? (
                          <a
                            href="/#"
                            onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                          >
                            <ins className="text-info">{val.jo_number}</ins>
                          </a>
                        ) : (
                          <label className="label label-md label-warning">
                            Not set
                          </label>
                        )}
                      </td>
                      <td
                        onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                        className="align-middle"
                      >
                        {val.requestor}
                      </td>
                      <td
                        onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                        className="align-middle"
                      >
                        {renderProcessStatusLabel(
                          val.process_status,
                          val.is_declined
                        )}
                      </td>
                      <td
                        onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                        className="align-middle"
                      >
                        {findServiceType(val.service_type)}
                      </td>
                      <td
                        onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                        className="align-middle"
                      >
                        {date_encoded}
                      </td>
                      <td className="align-middle">
                        {renderDownloadableQuote(val)}
                      </td>
                      <td className="align-middle">
                        {renderDownloadableLSR(val)}
                      </td>
                    </tr>
                  );
                })}
                {!list.records.length ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="8">
                      <h4>No Records Found!</h4>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Table Footer */}
        <div className="card-footer">
          <TableFooterDefault
            counterPageSize={pagination.pageSize}
            counterPageCurrent={pagination.pageCurrent}
            counterPageLimit={list.limit}
            counterTotalRecords={list.totalCount}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>
      </div>

      {/* Bulk Delete Modal */}
      <DeleteDialog
        title="Delete Selected Records"
        message="Are you sure you to permanently delete the selected records?"
        open={isOpenDeleteBulk}
        handleClose={() => SetIsOpenDeleteBulk(false)}
        handleDelete={handleBulkDeleteSubmit}
      />

      {/* Filter Modal */}
      <Dialog open={isOpenFilterModal} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Select fields to filter
          </DialogContentText>
          <form className={styleClasses.form} noValidate>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="service-type">Service Type</InputLabel>
              <Select
                labelId="service-type"
                id="demo-simple-select-outlined"
                value={filterQuery.serviceType}
                onChange={(e) => {
                  SetFilterQuery({
                    ...filterQuery,
                    serviceType: e.target.value,
                  });
                }}
                label="Service Type"
              >
                {labservices_constants.SERVICE_TYPE_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="process-status">Process Status</InputLabel>
              <Select
                labelId="process-status"
                id="demo-simple-select-outlined"
                value={filterQuery.processStatus}
                onChange={(e) => {
                  SetFilterQuery({
                    ...filterQuery,
                    processStatus: e.target.value,
                  });
                }}
                label="Process Status"
              >
                {labservices_constants.PROCESS_STATUS_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetIsOpenFilterModal(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleFilterSubmit}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sort Modal */}
      <Dialog open={isOpenSortModal} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">Sort</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Select fields to sort
          </DialogContentText>
          <form className={styleClasses.form} noValidate>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="sort-field">Sort Field</InputLabel>
              <Select
                labelId="sort-field"
                id="demo-simple-select-outlined"
                value={filterQuery.sortField}
                onChange={(e) => {
                  SetFilterQuery({ ...filterQuery, sortField: e.target.value });
                }}
                label="Sort Field"
              >
                {labservices_constants.SORT_FIELD_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="sort-order">Sort Order</InputLabel>
              <Select
                labelId="sort-order"
                id="demo-simple-select-outlined"
                value={filterQuery.sortOrder}
                onChange={(e) => {
                  SetFilterQuery({ ...filterQuery, sortOrder: e.target.value });
                }}
                label="Sort Order"
              >
                {labservices_constants.SORT_ORDER_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetIsOpenSortModal(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSortSubmit}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* View File Dialog */}
      <ViewFileDialog
        isOpen={fileViewerDetails.isOpen}
        fileType={getFileTypeByFileStorageUrl(fileViewerDetails.fileStorageUrl)}
        filePath={fileStorageUrlToMediaUrl(fileViewerDetails.fileStorageUrl)}
        errorComponent={<p>Error Encountered Trying to load file ...</p>}
        handleClose={() =>
          SetFileViewerDetails({
            isOpen: false,
            fileStorageUrl: "",
            fileData: null,
            downloadAction: "",
          })
        }
        handleDownload={(e) =>
          handleDownloadQuoteOrLSR(
            e,
            fileViewerDetails.downloadAction,
            fileViewerDetails.fileData
          )
        }
      />
    </div>
  );
}
