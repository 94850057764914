import "./Index.css";

import TopNav from "../common/TopNav.js";
import PictureDiv from "./PictureDiv.js";
import ContactUsDiv from "./ContactUsDiv.js";
import MapDiv from "./MapDiv.js";
import ServicesDiv from "./ServicesDiv.js";

export default function LandingPage() {
  return (
    <div className="lab-services-container">
      <TopNav />
      <div className="pcoded-inner-content m-4">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <PictureDiv />
              <div className="col-md-12 col-xl-4">
                <ContactUsDiv />
                <MapDiv />
              </div>
              <ServicesDiv />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
