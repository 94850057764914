import { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  search,
  resetList,
} from "../../../../store/employee/tasks/taskReducer";
import {
  fetchTask,
  filterRecords,
  sortRecords,
  checkAllRecords,
  checkSpecificRecord,
  paginationClick,
  pageSizeClick,
} from "../../../../store/employee/tasks/taskActions";
import { api_urls, routes, tasks_constants } from "../../../../constants";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TableHeaderDefault } from "../../../utils/Table/TableHeaders";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function TaskList() {
  const [isOpenDeleteBulk, SetIsOpenDeleteBulk] = useState(false);
  const [isOpenFilterModal, SetIsOpenFilterModal] = useState(false);
  const [isOpenSortModal, SetIsOpenSortModal] = useState(false);
  const [pageLoader, SetPageLoader] = useState(false);
  const [searchQuery, SetSearchQuery] = useState("");
  const [filterQuery, SetFilterQuery] = useState({
    status: "",
    taskType: "",
    sortField: "",
    sortOrder: "",
  });

  const [taskTypeFilterOptions, SetTaskTypeFilterOptions] = useState([]);

  const list = useSelector((state) => state.task.list);
  const pagination = useSelector((state) => state.task.listPagination);
  const filters = useSelector((state) => state.task.listFilters);
  const highlightedRecord = useSelector(
    (state) => state.task.highlightedRecord
  );
  const isCheckedAllRecords = useSelector(
    (state) => state.task.isCheckedAllRecords
  );
  const checkedRecords = useSelector((state) => state.task.checkedRecords);
  const taskTypeOptions = useSelector((state) => state.taskType.options);

  const styleClasses = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectToTaskCreate = useCallback(
    () => history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/create`),
    [history]
  );
  const redirectToTaskDetails = useCallback(
    (id) => history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${id}`),
    [history]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search({ query: value })), 500),
    []
  );

  useEffect(() => {
    const newtaskTypeOptions = Object.assign([], taskTypeOptions);
    newtaskTypeOptions.unshift({ value: "", label: "Select" });
    SetTaskTypeFilterOptions(newtaskTypeOptions);
  }, [taskTypeOptions]);

  useEffect(() => {
    dispatch(fetchTask({ pagination: pagination, filters: filters }));
  }, [dispatch, pagination, filters]);

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Tasks",
          subtitle: "Manage Tasks",
          icon: "icon-bookmark",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK,
              name: "Manage Tasks",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    SetSearchQuery(value);
    delaySearch(value);
  };

  const handleCreateButtonClick = (e) => {
    e.preventDefault();
    redirectToTaskCreate();
  };

  const handleOpenTaskDetails = (e, id) => {
    e.preventDefault();
    redirectToTaskDetails(id);
  };

  const handleCheckAllRecords = (e) => {
    dispatch(
      checkAllRecords({
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handleCheckSpecificRecord = (e, id) => {
    dispatch(
      checkSpecificRecord({
        id: id,
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    dispatch(
      paginationClick({
        willCurrent: willCurrent,
        limit: list.limit,
        size: pagination.pageSize,
      })
    );
  };

  const handlePageSizeClick = (e) => {
    e.preventDefault();
    dispatch(pageSizeClick(e.target.value));
  };

  const handleBulkDeleteSubmit = () => {
    SetIsOpenDeleteBulk(false);
    SetPageLoader(true);
    let ids = [];
    checkedRecords.forEach((data) => {
      if (data.status === true) {
        ids.push(data.id);
      }
    });
    if (ids.length > 0) {
      axios
        .delete(`${api_urls.TASKS_TASK}/bulk_destroy/`, {
          data: {
            ids: ids,
          },
        })
        .then((response) => {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Selected Tasks are successfully Deleted!",
              },
            })
          );
          SetPageLoader(false);
          dispatch(fetchTask({ pagination: pagination, filters: filters }));
        })
        .catch((error) => {
          if (error.response.status === 404 || error.response.status === 500) {
            dispatch(
              setToastNotification({
                toastNotification: {
                  isOpen: true,
                  type: "error",
                  message:
                    "There's an error trying to submit data to the server!",
                },
              })
            );
          }
          SetPageLoader(false);
        });
    } else {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "Please select records to delete!",
          },
        })
      );
      SetPageLoader(false);
    }
  };

  const handleRefreshClick = () => {
    SetSearchQuery("");
    SetFilterQuery({
      status: "",
      taskType: "",
      sortField: "",
      sortOrder: "",
    });
    dispatch(resetList());
  };

  const handleFilterSubmit = () => {
    SetIsOpenFilterModal(false);
    SetPageLoader(true);
    dispatch(
      filterRecords({
        filters,
        status: filterQuery.status,
        taskType: filterQuery.taskType,
      })
    );
    SetPageLoader(false);
  };

  const handleSortSubmit = () => {
    SetIsOpenSortModal(false);
    SetPageLoader(true);
    dispatch(
      sortRecords({
        sortField: filterQuery.sortField,
        sortOrder: filterQuery.sortOrder,
        filters,
      })
    );
    SetPageLoader(false);
  };

  const recordIsChecked = (id) => {
    return checkedRecords.some(
      (data) => data.id === id && data.status === true
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case tasks_constants.TASK_STATUS_DROPDOWN[1].value:
        return (
          <label className="label label-md label-warning">
            {tasks_constants.TASK_STATUS_DROPDOWN[1].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[2].value:
        return (
          <label className="label label-md label-primary">
            {tasks_constants.TASK_STATUS_DROPDOWN[2].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[3].value:
        return (
          <label className="label label-md label-success">
            {tasks_constants.TASK_STATUS_DROPDOWN[3].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[4].value:
        return (
          <label className="label label-md label-danger">
            {tasks_constants.TASK_STATUS_DROPDOWN[4].label}
          </label>
        );
      default:
        return "";
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card table-card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        {/* Table Header */}
        <div className="card-header">
          <TableHeaderDefault
            createButtonClickHandler={handleCreateButtonClick}
            searchInputValue={searchQuery}
            searchInputHandler={handleSearch}
            filterButton={true}
            filterButtonClickHandler={() => SetIsOpenFilterModal(true)}
            sortButton={true}
            sortButtonClickHandler={() => SetIsOpenSortModal(true)}
            refreshButtonClickHandler={handleRefreshClick}
            deleteButton={true}
            deleteButtonDisable={true}
            deleteButtonClickHandler={() => SetIsOpenDeleteBulk(true)}
            createButton={true}
            entriesSelect={true}
            entriesSelectPageSize={pagination.pageSize}
            entriesSelectChangeHandler={handlePageSizeClick}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>

        {/* TABLE BODY */}
        <div className="card-block table-border-style pb-0 pt-0">
          <div className="table-responsive">
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th className="p-0">
                    <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                      <label>
                        <input
                          type="checkbox"
                          checked={isCheckedAllRecords}
                          onChange={handleCheckAllRecords}
                        />
                        <span className="cr">
                          <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                        </span>
                      </label>
                    </div>
                  </th>
                  <th className="align-middle">Task ID</th>
                  <th className="align-middle">JO Number</th>
                  <th className="align-middle">Description</th>
                  <th className="align-middle">Type</th>
                  <th className="align-middle">Status</th>
                </tr>
              </thead>
              <tbody>
                {list.records.map((val, key) => {
                  return (
                    <tr
                      key={key}
                      className={
                        val.id == highlightedRecord || recordIsChecked(val.id)
                          ? "table-info"
                          : ""
                      }
                    >
                      <td className="p-0">
                        <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                          <label>
                            <input
                              key={key}
                              type="checkbox"
                              checked={recordIsChecked(val.id)}
                              onChange={(e) =>
                                handleCheckSpecificRecord(e, val.id)
                              }
                            />
                            <span className="cr">
                              <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                            </span>
                          </label>
                        </div>
                      </td>
                      <th scope="row" className="align-middle">
                        <a
                          href="/#"
                          onClick={(e) => handleOpenTaskDetails(e, val.id)}
                        >
                          <ins className="text-info">TASK-{val.id}</ins>
                        </a>
                      </th>
                      <td
                        onClick={(e) => handleOpenTaskDetails(e, val.id)}
                        className="align-middle"
                      >
                        {val.jo_number}
                      </td>
                      <td
                        onClick={(e) => handleOpenTaskDetails(e, val.id)}
                        className="align-middle"
                      >
                        {val.description}
                      </td>
                      <td
                        onClick={(e) => handleOpenTaskDetails(e, val.id)}
                        className="align-middle"
                      >
                        {val.task_type.name}
                      </td>
                      <td
                        onClick={(e) => handleOpenTaskDetails(e, val.id)}
                        className="align-middle"
                      >
                        {renderStatus(val.status)}
                      </td>
                    </tr>
                  );
                })}

                {!list.isFetched && (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="6">
                      <h4>Loading ...</h4>
                    </td>
                  </tr>
                )}

                {list.isFetched && !list.records.length ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="6">
                      <h4>No Records Found!</h4>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Table Footer */}
        <div className="card-footer">
          <TableFooterDefault
            counterPageSize={pagination.pageSize}
            counterPageCurrent={pagination.pageCurrent}
            counterPageLimit={list.limit}
            counterTotalRecords={list.totalCount}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>
      </div>

      {/* Bulk Delete Modal */}
      <DeleteDialog
        title="Delete Selected Records"
        message="Are you sure you to permanently delete the selected records?"
        open={isOpenDeleteBulk}
        handleClose={() => SetIsOpenDeleteBulk(false)}
        handleDelete={handleBulkDeleteSubmit}
      />

      {/* Filter Modal */}
      <Dialog open={isOpenFilterModal} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">Filters</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Select fields to filter
          </DialogContentText>
          <form className={styleClasses.form} noValidate>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="demo-simple-select-outlined"
                value={filterQuery.status}
                onChange={(e) => {
                  SetFilterQuery({
                    ...filterQuery,
                    status: e.target.value,
                  });
                }}
                label="Status"
              >
                {tasks_constants.TASK_STATUS_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="task-type">Task Type</InputLabel>
              <Select
                labelId="task-type"
                id="demo-simple-select-outlined"
                value={filterQuery.taskType}
                onChange={(e) => {
                  SetFilterQuery({
                    ...filterQuery,
                    taskType: e.target.value,
                  });
                }}
                label="Task Type"
              >
                {taskTypeFilterOptions.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetIsOpenFilterModal(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleFilterSubmit}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sort Modal */}
      <Dialog open={isOpenSortModal} fullWidth={true} maxWidth="md">
        <DialogTitle id="alert-dialog-title">Sort</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Select fields to sort
          </DialogContentText>
          <form className={styleClasses.form} noValidate>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="sort-field">Sort Field</InputLabel>
              <Select
                labelId="sort-field"
                id="demo-simple-select-outlined"
                value={filterQuery.sortField}
                onChange={(e) => {
                  SetFilterQuery({ ...filterQuery, sortField: e.target.value });
                }}
                label="Sort Field"
              >
                {tasks_constants.SORT_FIELD_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              className={styleClasses.formControl}
              style={{ minWidth: 350 }}
            >
              <InputLabel id="sort-order">Sort Order</InputLabel>
              <Select
                labelId="sort-order"
                id="demo-simple-select-outlined"
                value={filterQuery.sortOrder}
                onChange={(e) => {
                  SetFilterQuery({ ...filterQuery, sortOrder: e.target.value });
                }}
                label="Sort Order"
              >
                {tasks_constants.SORT_ORDER_DROPDOWN.map((data) => {
                  return (
                    <MenuItem value={data.value} key={data.value}>
                      {data.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => SetIsOpenSortModal(false)} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSortSubmit}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
