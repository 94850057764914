import axios from "axios";
import { api_urls, tasks_constants } from "../../../constants.js";
import { setListsOnBoard } from "./taskBoardReducer";

export const fetchOnboardLists = ({ onBoardFilters }) => {
  return (dispatch) => {
    axios
      .get(`${api_urls.TASKS_TASK}/get_all_except_completed`, {
        params: {
          q: onBoardFilters.search,
          pid: onBoardFilters.personnel,
          mid: onBoardFilters.machine,
          ttid: onBoardFilters.task_type,
        },
      })
      .then((res) => {
        const queued = res.data.filter(
          (data) =>
            data.status === tasks_constants.TASK_STATUS_DROPDOWN[1].value
        );
        const ongoing = res.data.filter(
          (data) =>
            data.status === tasks_constants.TASK_STATUS_DROPDOWN[2].value
        );
        dispatch(
          setListsOnBoard({
            queued: {
              records: queued,
              totalCount: queued.length,
              isFetched: true,
            },
            ongoing: {
              records: ongoing,
              totalCount: ongoing.length,
              isFetched: true,
            },
          })
        );
      });
  };
};
