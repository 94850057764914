import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { labservices_constants, routes, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/client/dashboardReducer";
import {
  openInquiryByClient,
  retrieveInquiry,
} from "../../../../store/client/lab_services/inquiryActions";
import { setPageHeader } from "../../../../store/client/dashboardReducer";
import InquiryDetailsTransactionLogs from "./InquiryDetailsTransactionLogs";
import InquiryDetailsRequirements from "./InquiryDetailsRequirements";
import InquiryDetailsQuoteAndLSR from "./InquiryDetailsQuoteAndLSR";
import InquiryDetailsPayment from "./InquiryDetailsPayment";
import InquiryDetailsReports from "./InquiryDetailsReports";

export default function ClientInquiryDetails() {
  const { inquiry_id } = useParams();
  const dispatch = useDispatch();

  const [isOpenFeedbackDialog, SetIsOpenFeedbackDialog] = useState(false);
  const [feedbacks, SetFeedbacks] = useState([]);

  const inquiryDetails = useSelector(
    (state) => state.clientLabServicesInquiry.details
  );

  useEffect(() => {
    const isDoneFeedback = inquiryDetails.feedbacks.some((data) => data.answer);
    if (
      !isDoneFeedback &&
      inquiryDetails.processStatus ===
        labservices_constants.PROCESS_STATUS_DROPDOWN[12].value
    ) {
      SetIsOpenFeedbackDialog(true);
    } else {
      SetIsOpenFeedbackDialog(false);
    }
  }, [inquiryDetails, SetIsOpenFeedbackDialog]);

  useEffect(() => {
    if (inquiryDetails.feedbacks.length > 0) {
      SetFeedbacks(inquiryDetails.feedbacks);
    }
  }, [inquiryDetails.feedbacks, SetIsOpenFeedbackDialog]);

  useEffect(() => {
    dispatch(openInquiryByClient({ inquiry_id: inquiry_id }));
    dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Lab Services Inquiries",
          subtitle: "Inquiry Details",
          icon: "icon-info",
          pages: [
            {
              url: routes.CLIENT_DASHBOARD_LABSERVICES_INQUIRY,
              name: "Manage Inquiries",
            },
            {
              url:
                routes.CLIENT_DASHBOARD_LABSERVICES_INQUIRY + "/" + inquiry_id,
              name: "Inquiry Details",
            },
          ],
        },
      })
    );
  }, [dispatch, inquiry_id]);

  const handleUpdateProcessStatus = (e, status) => {
    e.preventDefault();
    axios
      .patch(api_urls.LABSERVICES_INQUIRY_PATCH + inquiry_id + "/", {
        status: status,
      })
      .then((res) => {
        let message = "";
        if (status === labservices_constants.PROCESS_STATUS_DROPDOWN[1].value) {
          message = "Successfully cancelled the Inquiry!";
        } else {
          message = "Successfully restored the Inquiry!";
        }
        dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: message,
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const getProcessStatusConstantLabel = (value) => {
    return labservices_constants.PROCESS_STATUS_DROPDOWN.find(
      (data) => data.value === value
    ).label;
  };

  const renderProcessStatusLabel = (processStatus) => {
    if (
      processStatus === labservices_constants.PROCESS_STATUS_DROPDOWN[1].value
    ) {
      return (
        <label className="label label-lg label-danger">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else if (
      processStatus === labservices_constants.PROCESS_STATUS_DROPDOWN[2].value
    ) {
      return (
        <label className="label label-md label-warning">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else {
      return (
        <label className="label label-md label-primary">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    }
  };

  const handleSubmitFeedback = (e) => {
    e.preventDefault();
    axios
      .put(`${api_urls.LABSERVICES_INQUIRY_FEEDBACK}/bulk_update/`, {
        feedbacks: feedbacks,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully submitted the feedback!",
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleCloseFeedbackDialog = () => {
    SetIsOpenFeedbackDialog(false);
  };

  const handleChangeFeedbackRecord = (id, answer) => {
    const copyOfFeedback = feedbacks.map((data) =>
      data.id === id ? { ...data, answer } : data
    );
    SetFeedbacks(copyOfFeedback);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card z-depth-0 mt-4">
          <div className="card-header">
            <h5>Details</h5>
            <Link
              to={`${routes.CLIENT_DASHBOARD_LABSERVICES_INQUIRY}`}
              className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
            >
              <i className="fa fa-arrow-left"></i> Back to List
            </Link>
            <Link
              to={`${routes.CLIENT_DASHBOARD_LABSERVICES_INQUIRY}/${inquiry_id}/edit`}
              className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
            >
              <i className="fa fa-pencil-square-o"></i> Edit
            </Link>
            {inquiryDetails.processStatus ===
              labservices_constants.PROCESS_STATUS_DROPDOWN[1].value && (
              <a
                href="/#"
                className="btn btn-success btn-outline-success float-right pt-2 pb-2 ml-2"
                onClick={(e) =>
                  handleUpdateProcessStatus(
                    e,
                    labservices_constants.PROCESS_STATUS_DROPDOWN[3].value
                  )
                }
              >
                <i className="fa fa-refresh"></i> Restore
              </a>
            )}
            {!inquiryDetails.isOnceCancelled && (
              <a
                href="/#"
                className="btn btn-danger btn-outline-danger float-right pt-2 pb-2 ml-2"
                onClick={(e) =>
                  handleUpdateProcessStatus(
                    e,
                    labservices_constants.PROCESS_STATUS_DROPDOWN[1].value
                  )
                }
              >
                <i className="fa fa-times-circle"></i> Cancel Inquiry
              </a>
            )}
          </div>
          <div className="card-block">
            {inquiryDetails.isDeclined && (
              <div className="col-md-12">
                <div class="alert alert-warning border-warning">
                  <p>
                    <strong>
                      This inquiry has beed declined! <br />
                      Reason: {inquiryDetails.declineComments}{" "}
                    </strong>
                  </p>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-md-4">
                <p>JO Number:</p>
                <p>Client Name:</p>
                <p>Affiliation (Company / School):</p>
                <p>Course / Degree (if student):</p>
                <p>Position:</p>
                <p>Email:</p>
                <p>Contact No:</p>
                <p>Service Type:</p>
                <p>Status</p>
                <p>Inquiry:</p>
                <p>Date Encoded:</p>
                <p>Last Updated:</p>
              </div>
              <div className="col-md-8">
                <p>
                  {inquiryDetails.JONumber}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.requestor}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.company}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.course}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.position}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.email}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.contactNo}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.serviceType}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.isDeclined ? (
                    <label className="label label-md label-warning">
                      Declined
                    </label>
                  ) : (
                    renderProcessStatusLabel(inquiryDetails.processStatus)
                  )}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.description}
                  {"\u00A0"}
                </p>
                <p>
                  {moment(inquiryDetails.createdAt).format(
                    "MM/DD/YYYY hh:mm A"
                  )}
                  {"\u00A0"}
                </p>
                <p>
                  {moment(inquiryDetails.updatedAt).format(
                    "MM/DD/YYYY hh:mm A"
                  )}
                  {"\u00A0"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InquiryDetailsRequirements
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsQuoteAndLSR
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsPayment
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsReports
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsTransactionLogs
        transactionLogs={inquiryDetails.transactionLogs}
      />

      <FeedbackDialog
        isOpen={isOpenFeedbackDialog}
        feedbacks={feedbacks}
        feedbackRecordHandler={(id, answer) =>
          handleChangeFeedbackRecord(id, answer)
        }
        handleSubmit={handleSubmitFeedback}
        handleClose={handleCloseFeedbackDialog}
      />
    </div>
  );
}

const FeedbackDialog = ({
  isOpen,
  feedbacks,
  feedbackRecordHandler,
  handleSubmit,
  handleClose,
}) => {
  const useStyles = makeStyles((theme) => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      maxWidth: 700,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }));
  const styleClasses = useStyles();
  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="md">
      <DialogTitle id="alert-dialog-title">Feedback</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText id="alert-dialog-description">
          Please provide your feedback in the whole inquiry process.
        </DialogContentText>
        <form className={styleClasses.form} noValidate>
          {feedbacks &&
            feedbacks.map((data, key) => {
              return (
                <FormControl
                  key={data.id}
                  variant="outlined"
                  className={styleClasses.formControl}
                >
                  <label>
                    {key + 1}. {data.question}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.answer}
                    onChange={(e) =>
                      feedbackRecordHandler(data.id, e.target.value)
                    }
                  />
                </FormControl>
              );
            })}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
