import InquiryInstructions from "../common/InquiryInstructions";

export default function ProcessDetailsDiv() {
  return (
    <div className="col-md-12 col-xl-5">
      <div className="card">
        <div className="card-header">
          <h5>Instructions</h5>
        </div>
        <div className="card-block">
          <InquiryInstructions />
        </div>
      </div>
    </div>
  );
}
