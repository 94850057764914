import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentRoute: "",
  pageHeader: {
    title: "",
    subtitle: "",
    icon: "",
    pages: [],
  },
  toastNotification: {
    isOpen: false,
    type: "success",
    message: "",
  },
};

const clientDashboardSlice = createSlice({
  name: "clientDashboard",
  initialState,
  reducers: {
    setCurrentRoute(state, action) {
      state.currentRoute = action.payload;
    },
    setPageHeader(state, action) {
      state.pageHeader = action.payload.pageHeader;
    },
    setToastNotification(state, action) {
      state.toastNotification = action.payload.toastNotification;
    },
  },
});

export const { setCurrentRoute, setPageHeader, setToastNotification } =
  clientDashboardSlice.actions;
export default clientDashboardSlice.reducer;
