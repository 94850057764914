import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { api_urls } from "../../../../constants";
import {
  labservices_constants,
  herbanext_contact_info,
} from "../../../../constants";
import { setToastNotification } from "../../../../store/client/dashboardReducer";
import {
  retrieveInquiry,
  downloadQuoteOrLSR,
  createTransactionLog,
  updateProcessStatus,
  DOWNLOAD_ACTIONS,
  updateInquiryRecordModifiedByClient,
} from "../../../../store/client/lab_services/inquiryActions";
import ViewFileDialog from "../common/ViewFileDialog";
import {
  fileStorageUrlToMediaUrl,
  getFileTypeByFileStorageUrl,
} from "../../../utils/StringUtils";

const UPLOAD_ACTIONS = {
  QUOTE_SIGNED_CREATE: "QUOTE_SIGNED_CREATE",
  QUOTE_SIGNED_EDIT: "QUOTE_SIGNED_EDIT",
  LSR_SIGNED_CREATE: "LSR_SIGNED_CREATE",
  LSR_SIGNED_EDIT: "LSR_SIGNED_EDIT",
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function InquiryDetailsQuoteAndLSR({
  inquiryDetails,
  inquiryId,
}) {
  const [isOpenImportFileFormDialog, SetIsOpenImportFileFormDialog] =
    useState(false);
  const [importFileFormDialogTitle, SetImportFileFormDialogTitle] =
    useState("");
  const [importFileFormDialogSubtitle, SetImportFileFormDialogSubtitle] =
    useState("");
  const [formDataFile, SetFormDataFile] = useState(null);
  const [submitAction, SetSubmitAction] = useState("");
  const [fileViewerDetails, SetFileViewerDetails] = useState({
    isOpen: false,
    fileStorageUrl: "",
    fileData: null,
    downloadAction: "",
  });

  const dispatch = useDispatch();

  const handleImportFileFormOpen = (e, action) => {
    e.preventDefault();
    SetIsOpenImportFileFormDialog(true);
    SetSubmitAction(action);
    switch (action) {
      case UPLOAD_ACTIONS.QUOTE_SIGNED_CREATE:
        SetImportFileFormDialogTitle("Upload Signed Quote");
        SetImportFileFormDialogSubtitle("Upload Document");
        break;
      case UPLOAD_ACTIONS.QUOTE_SIGNED_EDIT:
        SetImportFileFormDialogTitle("Update Signed Quote");
        SetImportFileFormDialogSubtitle("Update Document");
        break;
      case UPLOAD_ACTIONS.LSR_SIGNED_CREATE:
        SetImportFileFormDialogTitle(
          "Upload Signed Laboratory Service Request"
        );
        SetImportFileFormDialogSubtitle("Upload Document");
        break;
      case UPLOAD_ACTIONS.LSR_SIGNED_EDIT:
        SetImportFileFormDialogTitle(
          "Update Signed Laboratory Service Request"
        );
        SetImportFileFormDialogSubtitle("Update Document");
        break;
      default:
        SetImportFileFormDialogTitle("");
        SetImportFileFormDialogSubtitle("");
        break;
    }
  };

  const handleImportFileFormClose = (e) => {
    SetIsOpenImportFileFormDialog(false);
    SetImportFileFormDialogTitle("");
    SetImportFileFormDialogSubtitle("");
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      SetFormDataFile(e.target.files[0]);
    }
  };

  const handleUploadQuoteOrLSR = () => {
    let url = "";
    let toastMessage = "";
    if (
      submitAction === UPLOAD_ACTIONS.QUOTE_SIGNED_CREATE ||
      submitAction === UPLOAD_ACTIONS.QUOTE_SIGNED_EDIT
    ) {
      url =
        api_urls.LABSERVICES_INQUIRY_UPLOAD_QUOTE_SIGNED +
        inquiryId +
        "/upload_quote_signed/";
      toastMessage = "Signed Quote successfully uploaded!";
      updateProcessStatus({
        inquiry_id: inquiryId,
        status: labservices_constants.PROCESS_STATUS_DROPDOWN[8].value,
      });
      createTransactionLog({
        inquiry_id: inquiryId,
        action: "Uploaded Inquiry Signed Quoted",
      });
    } else if (
      submitAction === UPLOAD_ACTIONS.LSR_SIGNED_CREATE ||
      submitAction === UPLOAD_ACTIONS.LSR_SIGNED_EDIT
    ) {
      url =
        api_urls.LABSERVICES_INQUIRY_UPLOAD_LSR_SIGNED +
        inquiryId +
        "/upload_lsr_signed/";
      toastMessage = "Signed Laboratory Service Request successfully uploaded!";
      updateProcessStatus({
        inquiry_id: inquiryId,
        status: labservices_constants.PROCESS_STATUS_DROPDOWN[5].value,
      });
      createTransactionLog({
        inquiry_id: inquiryId,
        action: "Uploaded Inquiry Signed Laboratory Service Request",
      });
    } else {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "error",
            message: "Invalid Submit Action!",
          },
        })
      );
    }
    const form = new FormData();
    form.append("file", formDataFile);
    form.append("inquiry_id", inquiryId);
    axios
      .put(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        SetIsOpenImportFileFormDialog(false);
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          updateInquiryRecordModifiedByClient({ inquiry_id: inquiryId })
        );
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: toastMessage,
            },
          })
        );
      })
      .catch((err) => {
        SetIsOpenImportFileFormDialog(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleDownloadQuoteOrLSR = (e, action) => {
    e.preventDefault();
    dispatch(
      downloadQuoteOrLSR({
        inquiryId,
        quote: inquiryDetails.quote,
        quoteSigned: inquiryDetails.quoteSigned,
        lsr: inquiryDetails.lsr,
        lsrSigned: inquiryDetails.lsrSigned,
        action,
      })
    );
  };

  const getQuoteSignedStatusLabel = (value) => {
    return value
      ? labservices_constants.INQUIRY_QUOTE_SIGNED_STATUS.find(
          (data) => data.value === value
        ).label
      : "";
  };

  const renderQuoteInitialFileActions = () => {
    return inquiryDetails.quote ? (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) => {
            e.preventDefault();
            SetFileViewerDetails({
              ...fileViewerDetails,
              isOpen: true,
              fileStorageUrl: inquiryDetails.quote,
              fileData: inquiryDetails,
              downloadAction: DOWNLOAD_ACTIONS.QUOTE_INITIAL,
            });
          }}
        >
          <ins className="text-info">Download</ins>
        </a>
      </>
    ) : (
      "Not Yet Uploaded"
    );
  };

  const renderQuoteSignedFileActions = () => {
    return !inquiryDetails.quote ? (
      "Initial Quote Not yet uploaded"
    ) : !inquiryDetails.quoteSigned ? (
      <a
        href="/#"
        onClick={(e) =>
          handleImportFileFormOpen(e, UPLOAD_ACTIONS.QUOTE_SIGNED_CREATE)
        }
      >
        <ins className="text-info">Upload</ins>
      </a>
    ) : (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) => {
            e.preventDefault();
            SetFileViewerDetails({
              ...fileViewerDetails,
              isOpen: true,
              fileStorageUrl: inquiryDetails.quoteSigned,
              fileData: inquiryDetails,
              downloadAction: DOWNLOAD_ACTIONS.QUOTE_SIGNED,
            });
          }}
        >
          <ins className="text-info">Download</ins>
        </a>
        <a
          href="/#"
          onClick={(e) =>
            handleImportFileFormOpen(e, UPLOAD_ACTIONS.QUOTE_SIGNED_EDIT)
          }
        >
          <ins className="text-info">Edit</ins>
        </a>
      </>
    );
  };

  const renderQuoteSignedStatus = () => {
    let labelClass = "";
    switch (inquiryDetails.quoteSignedStatus) {
      case labservices_constants.INQUIRY_QUOTE_SIGNED_STATUS[0].value:
        labelClass = "label-warning";
        break;
      case labservices_constants.INQUIRY_QUOTE_SIGNED_STATUS[3].value:
        labelClass = "label-danger";
        break;
      case labservices_constants.INQUIRY_QUOTE_SIGNED_STATUS[4].value:
        labelClass = "label-success";
        break;
      default:
        labelClass = "label-primary";
        break;
    }
    return (
      <label className={`label label-md ${labelClass}`}>
        {getQuoteSignedStatusLabel(inquiryDetails.quoteSignedStatus)}
      </label>
    );
  };

  const renderLSRInitialFileActions = () => {
    return inquiryDetails.lsr ? (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) => {
            e.preventDefault();
            SetFileViewerDetails({
              ...fileViewerDetails,
              isOpen: true,
              fileStorageUrl: inquiryDetails.lsr,
              fileData: inquiryDetails,
              downloadAction: DOWNLOAD_ACTIONS.LSR_INITIAL,
            });
          }}
        >
          <ins className="text-info">Download</ins>
        </a>
      </>
    ) : (
      "Not Yet Uploaded"
    );
  };

  const renderLSRSignedFileActions = () => {
    return !inquiryDetails.lsr ? (
      "Initial Laboratory Service Request Not yet uploaded"
    ) : !inquiryDetails.lsrSigned ? (
      <a
        href="/#"
        onClick={(e) =>
          handleImportFileFormOpen(e, UPLOAD_ACTIONS.LSR_SIGNED_CREATE)
        }
      >
        <ins className="text-info">Upload</ins>
      </a>
    ) : (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) => {
            e.preventDefault();
            SetFileViewerDetails({
              ...fileViewerDetails,
              isOpen: true,
              fileStorageUrl: inquiryDetails.lsrSigned,
              fileData: inquiryDetails,
              downloadAction: DOWNLOAD_ACTIONS.LSR_SIGNED,
            });
          }}
        >
          <ins className="text-info">Download</ins>
        </a>
        <a
          href="/#"
          onClick={(e) =>
            handleImportFileFormOpen(e, UPLOAD_ACTIONS.LSR_SIGNED_EDIT)
          }
        >
          <ins className="text-info">Edit</ins>
        </a>
      </>
    );
  };

  const getLSRSignedStatusLabel = (value) => {
    return value
      ? labservices_constants.INQUIRY_LSR_SIGNED_STATUS.find(
          (data) => data.value === value
        ).label
      : "";
  };

  const renderLSRSignedStatus = () => {
    let labelClass = "";
    switch (inquiryDetails.lsrSignedStatus) {
      case labservices_constants.INQUIRY_LSR_SIGNED_STATUS[0].value:
        labelClass = "label-warning";
        break;
      case labservices_constants.INQUIRY_LSR_SIGNED_STATUS[3].value:
        labelClass = "label-danger";
        break;
      case labservices_constants.INQUIRY_LSR_SIGNED_STATUS[4].value:
        labelClass = "label-success";
        break;
      default:
        labelClass = "label-primary";
        break;
    }
    return (
      <label className={`label label-md ${labelClass}`}>
        {getLSRSignedStatusLabel(inquiryDetails.lsrSignedStatus)}
      </label>
    );
  };

  return (
    <div
      className="col-md-12"
      style={
        inquiryDetails.isDeclined ||
        inquiryDetails.processStatus ===
          labservices_constants.PROCESS_STATUS_DROPDOWN[1].label
          ? { pointerEvents: "none", opacity: "0.5" }
          : {}
      }
    >
      {/* Import File Dialog */}
      <ImportFileFormDialog
        isOpen={isOpenImportFileFormDialog}
        title={importFileFormDialogTitle}
        subtitle={importFileFormDialogSubtitle}
        fileInputhandler={handleFileInput}
        handleSubmit={handleUploadQuoteOrLSR}
        handleClose={handleImportFileFormClose}
      />
      {/* View File Dialog */}
      <ViewFileDialog
        isOpen={fileViewerDetails.isOpen}
        fileType={getFileTypeByFileStorageUrl(fileViewerDetails.fileStorageUrl)}
        filePath={fileStorageUrlToMediaUrl(fileViewerDetails.fileStorageUrl)}
        errorComponent={<p>Error Encountered Trying to load file ...</p>}
        handleClose={() =>
          SetFileViewerDetails({
            isOpen: false,
            fileStorageUrl: "",
            fileData: null,
            downloadAction: "",
          })
        }
        handleDownload={(e) =>
          handleDownloadQuoteOrLSR(
            e,
            fileViewerDetails.downloadAction,
            fileViewerDetails.fileData
          )
        }
      />
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Quote and Laboratory Service Request</h5>
        </div>
        <div className="card-block">
          <table className="table">
            <thead>
              <tr>
                <th>Document</th>
                <th>Initial Document</th>
                <th>Signed Document</th>
                <th>Status</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle">Laboratory Service Request</td>
                <td className="align-middle">
                  {renderLSRInitialFileActions()}
                </td>
                <td className="align-middle">{renderLSRSignedFileActions()}</td>
                <td className="align-middle">{renderLSRSignedStatus()}</td>
                <td className="align-middle">
                  {inquiryDetails.lsrSignedComments}
                </td>
              </tr>
              <tr>
                <td className="align-middle">Quote</td>
                <td className="align-middle">
                  {renderQuoteInitialFileActions()}
                </td>
                <td className="align-middle">
                  {renderQuoteSignedFileActions()}
                </td>
                <td className="align-middle">{renderQuoteSignedStatus()}</td>
                <td className="align-middle">
                  {inquiryDetails.quoteSignedComments}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5">
                  If you have any concerns, please contact us using this link:{" "}
                  <a
                    href={herbanext_contact_info.MESSANGER_LINK}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <u>{herbanext_contact_info.MESSANGER_LINK}</u>
                  </a>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

const ImportFileFormDialog = ({
  isOpen,
  title,
  subtitle,
  fileInputhandler,
  handleSubmit,
  handleClose,
}) => {
  const styleClasses = useStyles();
  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="md">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText id="alert-dialog-description">
          {subtitle}
        </DialogContentText>
        <form className={styleClasses.form} noValidate>
          <FormControl
            variant="outlined"
            className={styleClasses.formControl}
            style={{ minWidth: 350 }}
          >
            <input type="file" onChange={fileInputhandler} />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
