import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { api_urls } from "../../../../constants";
import { InputTextInline } from "../../../utils/Forms/InlineInputs";
import { logoutClient } from "../../../../store/client/authActions";

export default function UsernameForm(props) {
  const [newUsername, setNewUsername] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [errorFields, setErrorFields] = useState({
    newUsername: "",
    currentPassword: "",
    nonFieldErrors: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(api_urls.PROFILE_USER_SET_USERNAME, {
        new_username: newUsername,
        current_password: currentPassword,
      })
      .then((response) => {
        setNewUsername("");
        setCurrentPassword("");
        setErrorFields({
          newUsername: "",
          currentPassword: "",
          nonFieldErrors: "",
        });
        dispatch(logoutClient());
      })
      .catch((error) => {
        setErrorFields({
          newUsername: error.response.data.new_username?.toString(),
          currentPassword: error.response.data.current_password?.toString(),
          nonFieldErrors: error.response.data.non_field_errors?.toString(),
        });
      });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>Change Username</h5>
      </div>
      <div className="card-block">
        <div className="alert alert-warning background-warning">
          <strong>
            <i className="fa fa-warning"></i>
          </strong>
          {"  "}
          You will be ask to login again after you change your username.
        </div>

        <form onSubmit={handleSubmit}>
          <InputTextInline
            type="text"
            label="New Username:"
            placeholder="New Username"
            errorField={errorFields.newUsername}
            value={newUsername}
            setter={(e) => setNewUsername(e.target.value)}
          />

          <InputTextInline
            type="password"
            label="Current Password:"
            placeholder="Current Password"
            errorField={errorFields.currentPassword}
            value={currentPassword}
            setter={(e) => setCurrentPassword(e.target.value)}
          />

          <div className="form-group row">
            <label className="col-sm-2"></label>
            <div className="col-sm-10">
              <button type="submit" className="btn btn-primary m-b-0">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
