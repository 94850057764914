import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  labServicesTotalRecords: 0,
  labServicesUpdatedRecords: 0,
  arilTotalRecords: 0,
  arilUpdatedRecords: 0,
};

const clientHomeSlice = createSlice({
  name: "clientHome",
  initialState,
  reducers: {
    setLabServicesTotalRecords(state, action) {
      state.labServicesTotalRecords = action.payload;
    },
    setLabServicesUpdatedRecords(state, action) {
      state.labServicesUpdatedRecords = action.payload;
    },
    setArilTotalRecords(state, action) {
      state.arilTotalRecords = action.payload;
    },
    setArilUpdatedRecords(state, action) {
      state.arilUpdatedRecords = action.payload;
    },
  },
});

export const {
  setLabServicesTotalRecords,
  setLabServicesUpdatedRecords,
  setArilTotalRecords,
  setArilUpdatedRecords,
} = clientHomeSlice.actions;
export default clientHomeSlice.reducer;
