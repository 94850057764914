import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: {
    records: [],
    totalCount: 0,
    limit: 0,
    errorMessage: "",
    isFetched: false,
  },
  listPagination: {
    pagePrev: 0,
    pageCurrent: 1,
    pageNext: 2,
    pageSize: 10,
  },
  listFilters: {
    query: "",
    sortField: "",
    sortOrder: "",
    status: "",
    taskType: "",
  },
  // Task
  formData: {
    taskType: { value: "", label: "Select" },
    description: "",
    JONumber: { value: "", label: "Select" },
    status: { value: "NA", label: "Select" },
    datetimeFrom: "",
    datetimeTo: "",
  },
  formDataMachines: [],
  formDataPersonnel: [],
  formDataInquiryValidForTask: [],
  formErrors: {
    taskType: "",
    description: "",
    JONumber: "",
    status: "",
    machines: "",
    personnel: "",
    datetimeFrom: "",
    datetimeTo: "",
  },
  details: {
    taskID: "",
    user: {},
    taskType: {},
    description: "",
    machines: [],
    personnel: [],
    subtasks: [],
    status: { value: "NA", label: "NA" },
    JONumber: "",
    datetimeFrom: "",
    datetimeTo: "",
    createdAt: "",
    updatedAt: "",
    createdBy: null,
    updatedBy: null,
  },
  checkedRecords: [],
  isCheckedAllRecords: false,
  highlightedRecord: 0,
  // Sub Task
  formDataSubTask: {
    description: "",
    status: { value: "NA", label: "NA" },
    datetimeFrom: "",
    datetimeTo: "",
  },
  formDataSubTaskMachines: [],
  formDataSubTaskPersonnel: [],
  formErrorsSubTask: {
    description: "",
    status: "",
    datetimeFrom: "",
    datetimeTo: "",
  },
  detailsSubTask: {
    subTaskId: "",
    description: "",
    machines: [],
    personnel: [],
    status: { value: "NA", label: "NA" },
    datetimeFrom: "",
    datetimeTo: "",
    createdAt: "",
    updatedAt: "",
    createdBy: null,
    updatedBy: null,
  },
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    fetch(state, action) {
      state.list = action.payload.list;
    },
    paginate(state, action) {
      state.listPagination = action.payload.listPagination;
    },
    filter(state, action) {
      state.listFilters = action.payload;
    },
    search(state, action) {
      state.listFilters = { ...state.listFilters, query: action.payload.query };
    },
    resetList(state) {
      state.list = {
        records: [],
        totalCount: 0,
        limit: 0,
        errorMessage: "",
      };
      state.listPagination = {
        pagePrev: 0,
        pageCurrent: 1,
        pageNext: 2,
        pageSize: 10,
      };
      state.listFilters = {
        query: "",
      };
      state.checkedRecords = [];
      state.isCheckedAllRecords = false;
      state.highlightedRecord = 0;
    },
    // Task
    setFormData(state, action) {
      state.formData = action.payload;
    },
    setFormDataMachines(state, action) {
      state.formDataMachines = action.payload;
    },
    setFormDataPersonnel(state, action) {
      state.formDataPersonnel = action.payload;
    },
    setFormInquiryValidForTask(state, action) {
      state.formDataInquiryValidForTask = action.payload;
    },
    setFormErrors(state, action) {
      state.formErrors = action.payload;
    },
    setDetails(state, action) {
      state.details = action.payload;
    },
    setCheckedRecords(state, action) {
      state.checkedRecords = action.payload.checkedRecords;
    },
    setIsCheckedAllRecords(state, action) {
      state.isCheckedAllRecords = action.payload;
    },
    setHighlightedRecord(state, action) {
      state.highlightedRecord = action.payload;
    },
    // SubTask
    setFormDataSubTask(state, action) {
      state.formDataSubTask = action.payload;
    },
    setFormDataSubTaskMachines(state, action) {
      state.formDataSubTaskMachines = action.payload;
    },
    setFormDataSubTaskPersonnel(state, action) {
      state.formDataSubTaskPersonnel = action.payload;
    },
    setFormErrorsSubTask(state, action) {
      state.formErrorsSubTask = action.payload;
    },
    setDetailsSubTask(state, action) {
      state.detailsSubTask = action.payload;
    },
  },
});

export const {
  fetch,
  search,
  paginate,
  filter,
  resetList,
  setFormData,
  setFormDataMachines,
  setFormDataPersonnel,
  setFormInquiryValidForTask,
  setFormErrors,
  setDetails,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setHighlightedRecord,
  setFormDataSubTask,
  setFormDataSubTaskMachines,
  setFormDataSubTaskPersonnel,
  setFormErrorsSubTask,
  setDetailsSubTask,
} = taskSlice.actions;
export default taskSlice.reducer;
