import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import { retrieveUser } from "../../../../store/employee/userActions";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

export default function UserDetails() {
  const [page_loader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const { user_id } = useParams();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.details);
  const userList = useSelector((state) => state.user.list.records);
  const history = useHistory();
  const redirectBackToUserList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_USERS),
    [history]
  );

  useEffect(() => {
    dispatch(retrieveUser({ user_id: user_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "User",
          subtitle: "User Details",
          icon: "icon-user",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_USERS, name: "Manage Users" },
            {
              url: routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + user_id,
              name: "User Details",
            },
          ],
        },
      })
    );
  }, [dispatch, user_id]);

  const handleOpenDeleteModal = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
  };

  const handleDeleteUserSubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(api_urls.USER_DELETE + user_id + "/")
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "User Successfully Deleted!",
            },
          })
        );
        redirectBackToUserList();
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DeleteDialog
          title="Delete Permanent"
          message="Are you sure you to permanently delete this record?"
          open={isOpenDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDeleteUserSubmit}
        />
        <DivLoader type="Circles" loading={page_loader} />
        <div className="card-header">
          <h5>User Details</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_USERS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_USERS_DETAILS}${user_id}/edit`}
            className="btn btn-md btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-pencil-square-o"></i> Edit
          </Link>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_USERS_DETAILS}${user_id}/reset_password`}
            className="btn btn-md btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-key"></i> Reset Password
          </Link>
          <button
            className="btn btn-md btn-danger btn-outline-danger float-right pt-2 pb-2"
            onClick={handleOpenDeleteModal}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>
        <div className="card-block">
          <div className="row">
            <div className="col-md-2">
              <p>Username:</p>
              <p>Firstname:</p>
              <p>Lastname:</p>
              <p>Email Address:</p>
              <p>Active Status:</p>
              <p>Date Joined:</p>
              <p>Last Login:</p>
              <p>Modules:</p>
              <p>Module Permissions:</p>
            </div>
            <div className="col-md-4">
              <p>
                {userDetails.username}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.firstname}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.lastname}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.email}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.is_active === true ? (
                  <label className="label label-success">Active</label>
                ) : (
                  <label className="label label-danger">Inactive</label>
                )}
                {"\u00A0"}
              </p>
              <p>
                {moment(userDetails.dateJoined).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(userDetails.lastLogin).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.userRoutes.map((data) => (
                  <label key={data.value} className="label label-primary">
                    {data.label}
                  </label>
                ))}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.userSubroutes.map((data) => (
                  <label key={data.value} className="label label-warning">
                    {data.label}
                  </label>
                ))}
                {"\u00A0"}
              </p>
            </div>
            <div className="col-md-2">
              <p>Category:</p>
              <p>Date Created:</p>
              <p>Date Updated:</p>
              <p>Created By:</p>
              <p>Updated By:</p>
              <p>Ip Created:</p>
              <p>Ip Updated:</p>
            </div>
            <div className="col-md-4">
              <p>
                {userDetails.category}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.createdAt
                  ? moment(userDetails.createdAt).format("MM/DD/YYYY hh:mm A")
                  : ""}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.updatedAt
                  ? moment(userDetails.updatedAt).format("MM/DD/YYYY hh:mm A")
                  : ""}
                {"\u00A0"}
              </p>
              <p>
                {userList.length > 0
                  ? userList.filter(
                      (data) => data.id === userDetails.createdBy
                    )[0]?.username
                  : ""}
                {"\u00A0"}
              </p>
              <p>
                {userList.length > 0
                  ? userList.filter(
                      (data) => data.id === userDetails.updatedBy
                    )[0]?.username
                  : ""}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.ipCreated}
                {"\u00A0"}
              </p>
              <p>
                {userDetails.ipUpdated}
                {"\u00A0"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
