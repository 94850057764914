import { HashRouter, Switch, Route } from "react-router-dom";
import { routes } from "../../../../constants";
import InquiryList from "./InquiryList";
import InquiryCreate from "./InquiryCreate";
import InquiryDetails from "./InquiryDetails";
import InquiryEdit from "./InquiryEdit";

export default function ArilMain() {
  return (
    <HashRouter>
      <Switch>
        {/* INQUIRY LIST */}
        <Route exact path={routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}>
          <InquiryList />
        </Route>
        {/* CREATE */}
        <Route exact path={`${routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}/create`}>
          <InquiryCreate />
        </Route>
        {/* Details  */}
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}/:inquiry_id`}
        >
          <InquiryDetails />
        </Route>

        {/* edit */}
        <Route
          exact
          path={`${routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}/:inquiry_id/edit`}
        >
          <InquiryEdit />
        </Route>
      </Switch>
    </HashRouter>
  );
}
