import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import { api_urls, routes, tasks_constants } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormDataSubTask,
  setFormDataSubTaskMachines,
  setFormDataSubTaskPersonnel,
  setFormErrorsSubTask,
} from "../../../../store/employee/tasks/taskReducer";
import {
  resetForm,
  retrieveTask,
} from "../../../../store/employee/tasks/taskActions";
import DivLoader from "../../../utils/DivLoaderComp";
import {
  InputTextInline,
  SelectInputInline,
  SelectMultiInline,
} from "../../../utils/Forms/InlineInputs";

export default function TaskDetailsSubTaskCreate() {
  const history = useHistory();
  const { task_id } = useParams();
  const [isPageLoading, SetIsPageLoading] = useState(false);
  const [taskMachinesTransformed, SetTaskMachinesTransformed] = useState([]);
  const [taskPersonnelTransformed, SetTaskPersonnelTransformed] = useState([]);

  const dispatch = useDispatch();
  const taskDetails = useSelector((state) => state.task.details);
  const formDataSubTask = useSelector((state) => state.task.formDataSubTask);
  const formDataSubTaskMachines = useSelector(
    (state) => state.task.formDataSubTaskMachines
  );
  const formDataSubTaskPersonnel = useSelector(
    (state) => state.task.formDataSubTaskPersonnel
  );
  const formErrorsSubTask = useSelector(
    (state) => state.task.formErrorsSubTask
  );

  useEffect(() => {
    dispatch(retrieveTask({ task_id: task_id }));
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Tasks",
          subtitle: "Create Sub Task",
          icon: "icon-bookmark",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK,
              name: "Manage Task",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}`,
              name: "Task Details",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}/subtask/edit`,
              name: "Edit Sub Task",
            },
          ],
        },
      })
    );
  }, [dispatch, task_id]);

  useEffect(() => {
    const newMachines = taskDetails.machines.map((data) => ({
      value: data.machine.id,
      label: data.machine.name,
    }));
    const newPersonnels = taskDetails.personnel.map((data) => ({
      value: data.personnel.id,
      label: data.personnel.fullname,
    }));
    SetTaskMachinesTransformed(newMachines);
    SetTaskPersonnelTransformed(newPersonnels);
  }, [
    dispatch,
    taskDetails,
    SetTaskMachinesTransformed,
    SetTaskPersonnelTransformed,
  ]);

  const redirectBackToTaskDetails = useCallback(
    () => history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}`),
    [history, task_id]
  );

  const handleCreate = (e, isa) => {
    e.preventDefault();
    SetIsPageLoading(true);
    const taskDatetimeFrom = moment(taskDetails.datetimeFrom);
    const taskDatetimeTo = moment(taskDetails.datetimeTo);
    if (
      taskDatetimeFrom.isAfter(formDataSubTask.datetimeFrom) ||
      taskDatetimeTo.isBefore(formDataSubTask.datetimeTo)
    ) {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message:
              "Invalid date! Date must be beetween the main task datetime.",
          },
        })
      );
      SetIsPageLoading(false);
      return;
    }
    const machines = formDataSubTaskMachines.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
    const personnel = formDataSubTaskPersonnel.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
    axios
      .post(`${api_urls.TASKS_TASK_SUBTASK}/`, {
        task_id: task_id,
        description: formDataSubTask.description,
        machine_ids: machines,
        personnel_ids: personnel,
        status: formDataSubTask.status.value,
        datetime_from: formDataSubTask.datetimeFrom,
        datetime_to: formDataSubTask.datetimeTo,
      })
      .then((response) => {
        dispatch(resetForm());
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Sub Task Successfully Created!",
            },
          })
        );
        if (isa === 0) {
          redirectBackToTaskDetails();
        }
        SetIsPageLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrorsSubTask({
              description: field_errors.description?.toString(),
              machine: field_errors.machine_ids?.toString(),
              personnel: field_errors.personnel_ids?.toString(),
              status: field_errors.status?.toString(),
              datetimeFrom: field_errors.datetime_from?.toString(),
              datetimeTo: field_errors.datetime_to?.toString(),
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetIsPageLoading(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={isPageLoading} />
        <div className="card-header">
          <h5>Create Subtask</h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
        </div>

        <div className="card-block">
          <div className="col-md-12">
            <InputTextInline
              type="text"
              label="Description:"
              placeholder="Description"
              errorField={formErrorsSubTask.description}
              value={formDataSubTask.description}
              setter={(e) =>
                dispatch(
                  setFormDataSubTask({
                    ...formDataSubTask,
                    description: e.target.value,
                  })
                )
              }
            />

            <SelectMultiInline
              label="Equipments:"
              name="machines"
              value={formDataSubTaskMachines}
              errorField={formErrorsSubTask.machines}
              options={taskMachinesTransformed}
              onChange={(values) =>
                dispatch(setFormDataSubTaskMachines(values))
              }
              closeMenuOnSelect={false}
              defaultMenuIsOpen={false}
            />

            <SelectMultiInline
              label="Personnel:"
              name="personnel"
              value={formDataSubTaskPersonnel}
              errorField={formErrorsSubTask.personnel}
              options={taskPersonnelTransformed}
              onChange={(values) =>
                dispatch(setFormDataSubTaskPersonnel(values))
              }
              closeMenuOnSelect={false}
              defaultMenuIsOpen={false}
            />

            <SelectInputInline
              type="text"
              label="Status:"
              isDisabled={false}
              options={tasks_constants.TASK_STATUS_DROPDOWN}
              errorField={formErrorsSubTask.status}
              value={formDataSubTask.status}
              onChange={(value) =>
                dispatch(
                  setFormDataSubTask({ ...formDataSubTask, status: value })
                )
              }
            />

            <InputTextInline
              type="datetime-local"
              label="Start Date:"
              placeholder="Start Date"
              errorField={formErrorsSubTask.datetimeFrom}
              value={formDataSubTask.datetimeFrom}
              setter={(e) =>
                dispatch(
                  setFormDataSubTask({
                    ...formDataSubTask,
                    datetimeFrom: e.target.value,
                  })
                )
              }
            />

            <InputTextInline
              type="datetime-local"
              label="End Date:"
              placeholder="End Date"
              errorField={formErrorsSubTask.datetimeTo}
              value={formDataSubTask.datetimeTo}
              setter={(e) =>
                dispatch(
                  setFormDataSubTask({
                    ...formDataSubTask,
                    datetimeTo: e.target.value,
                  })
                )
              }
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 1)}
              >
                Save and add another
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={() => dispatch(resetForm())}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
