import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import { fetchEvents } from "../../../../store/employee/tasks/taskCalendarActions";
import { routes } from "../../../../constants";

export default function TaskCalendar() {
  const events = useSelector((state) => state.taskCalendar.events);

  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToCompletedTaskDetails = useCallback(
    (id) =>
      history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR}/${id}`),
    [history]
  );

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Task Calendar",
          subtitle: "Manage Tasks in Calendar View",
          icon: "icon-calendar",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR,
              name: "Task Calendar",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleOpenTaskCalendarDetails = (id) => {
    redirectToCompletedTaskDetails(id);
  };

  return (
    <div className="col-sm-12">
      <div className="card table-card z-depth-0">
        <div className="card-header">
          <h5>Calendar</h5>
        </div>

        <div className="card-block p-5">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              center: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView="dayGridMonth"
            displayEventTime={false}
            events={events.records}
            datesSet={(arg) => {
              dispatch(
                fetchEvents({
                  datetime_from: moment(arg.view.activeStart).format("Y-MM-DD"),
                  datetime_to: moment(arg.view.activeEnd).format("Y-MM-DD"),
                })
              );
            }}
            eventClick={(arg) => {
              handleOpenTaskCalendarDetails(arg.event.id);
            }}
          />
        </div>

        {/* Table Footer */}
        <div className="card-footer"></div>
      </div>
    </div>
  );
}
