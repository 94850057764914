import "./config.js";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { store } from "./store/index.js";
import { Provider } from "react-redux";

import ClientMain from "./components/client/Index.js";
import EmployeeMain from "./components/employee/Index.js";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Switch>
          {/* Redirect to landing page */}
          <Route exact path="/">
            <Redirect to="/client" />
          </Route>
          {/* EMPLOYEE DASHBOARD*/}
          <Route path="/employee">
            <EmployeeMain />
          </Route>

          {/* CLIENT PAGE */}
          <Route path="/client">
            <ClientMain />
          </Route>
        </Switch>
      </HashRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
