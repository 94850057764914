import moment from "moment";

export default function InquiryDetailsTransactionLogs({ transactionLogs }) {
  return (
    <div className="col-md-12">
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>History</h5>
        </div>
        <div className="card-block">
          <ul class="basic-list">
            {transactionLogs.map((logs) => {
              return (
                <li key={logs.id}>
                  <h5>{logs.action}</h5>
                  <p>Updated By: {logs.created_by_name}</p>
                  <p>
                    Date: {moment(logs.created_at).format("MM/DD/YYYY hh:mm A")}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
