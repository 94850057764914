import axios from "axios";
import { api_urls } from "../../../constants.js";
import {
  fetch,
  paginate,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setFormData,
  setFormErrors,
  setDetails,
  setDetailsTasks,
  setOptions,
} from "./personnelReducer";

export const fetchPersonnel = ({ pagination, filters }) => {
  return (dispatch) => {
    dispatch(setCheckedRecords({ checkedRecords: [] }));
    axios
      .get(api_urls.TASKS_PERSONNEL, {
        params: {
          q: filters.query,
          page_size: pagination.pageSize,
          page: pagination.pageCurrent,
          sort_field: filters.sortField,
          sort_order: filters.sortOrder,
        },
      })
      .then((res) => {
        const result = res.data.results;
        const newCheckedRecords = [];
        result.forEach((data) =>
          newCheckedRecords.push({ id: data.id, status: false })
        );
        dispatch(
          fetch({
            list: {
              records: result,
              totalCount: res.data.count,
              limit: Math.ceil(res.data.count / pagination.pageSize),
              isFetched: true,
            },
          })
        );
        dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
      });
  };
};

export const fetchTasksByPersonnel = ({ personnel_id, pagination }) => {
  return (dispatch) => {
    axios
      .get(`${api_urls.TASKS_TASK}/fetch_by_personnel`, {
        params: {
          pid: personnel_id,
          page_size: pagination.pageSize,
          page: pagination.pageCurrent,
        },
      })
      .then((res) => {
        dispatch(
          setDetailsTasks({
            records: res.data.results,
            totalCount: res.data.count,
            limit: Math.ceil(res.data.count / pagination.pageSize),
          })
        );
      });
  };
};

export const retrievePersonnel = ({ personnel_id }) => {
  return (dispatch) => {
    if (personnel_id !== "") {
      axios.get(`${api_urls.TASKS_PERSONNEL}/${personnel_id}`).then((res) => {
        dispatch(
          setFormData({
            fullname: res.data.fullname,
            position: res.data.position,
            email: res.data.email,
            contactNo: res.data.contact_no,
          })
        );
        dispatch(
          setDetails({
            fullname: res.data.fullname,
            position: res.data.position,
            email: res.data.email,
            contactNo: res.data.contact_no,
            createdAt: res.data.created_at,
            updatedAt: res.data.updated_at,
            createdBy: res.data.created_by,
            updatedBy: res.data.updated_by,
          })
        );
      });
    }
  };
};

export const checkAllRecords = ({ checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = [];
    checkedRecords.map((data) =>
      newCheckedRecords.push({ id: data.id, status: checked })
    );
    dispatch(setIsCheckedAllRecords(checked));
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const checkSpecificRecord = ({ id, checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = JSON.parse(JSON.stringify(checkedRecords));
    let objIndex = newCheckedRecords.findIndex((data) => data.id === id);
    newCheckedRecords[objIndex].status = checked;
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const paginationClick = ({ willCurrent, limit, size }) => {
  return (dispatch) => {
    if (willCurrent > 0 && willCurrent <= limit) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: willCurrent - 1,
            pageCurrent: willCurrent,
            pageNext: willCurrent + 1,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const pageSizeClick = (size) => {
  return (dispatch) => {
    if (size > 0) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: 0,
            pageCurrent: 1,
            pageNext: 2,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const resetForm = () => {
  return (dispatch) => {
    dispatch(
      setFormData({
        fullname: "",
        position: "",
        email: "",
        contactNo: "",
      })
    );
    dispatch(
      setFormErrors({
        fullname: "",
        position: "",
        email: "",
        contactNo: "",
      })
    );
  };
};

export const populatePersonnelOptions = () => {
  return (dispatch) => {
    axios.get(`${api_urls.TASKS_PERSONNEL}/get_all`).then((response) => {
      let routes = response.data;
      let options = [];
      if (routes.length > 0) {
        routes.forEach((data) => {
          options.push({
            value: data.id,
            label: `${data.fullname} - ${data.position}`,
          });
        });
      }
      dispatch(setOptions(options));
    });
  };
};
