import axios from "axios";
import { api_urls } from "../../constants.js";
import {
  setLabServicesStatistics,
  setLabServicesStatisticsLoading,
} from "./dashboardReducer";

export const getLabServicesStatistics = () => {
  return (dispatch) => {
    dispatch(
      setLabServicesStatisticsLoading({ labServicesStatisticsLoading: true })
    );
    axios
      .get(`${api_urls.LABSERVICES_INQUIRY_DASHBOARD}/get_statistics`)
      .then((res) => {
        dispatch(
          setLabServicesStatistics({
            labServicesStatistics: {
              inquiryTodayCount: res.data.inquiry_today_count,
              inquiryMonthCount: res.data.inquiry_month_count,
              inquiryIsUpdatedByClientCount:
                res.data.inquiry_isUpdatedByClient_count,
              inquiryIsUpdatedByClientRecord:
                res.data.inquiry_isUpdatedByClient_record,
              taskInQueueCount: res.data.task_InQueue_count,
              taskInQueueRecord: res.data.task_InQueue_record,
              taskOngoingCount: res.data.task_Ongoing_count,
              taskOngoingRecord: res.data.task_Ongoing_record,
              taskCompletedTodayCount: res.data.task_completedToday_count,
              taskCompletedThisMonthCount:
                res.data.task_completedThisMonth_count,
            },
          })
        );
        dispatch(
          setLabServicesStatisticsLoading({
            labServicesStatisticsLoading: false,
          })
        );
      });
  };
};
