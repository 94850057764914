import "./TopNav.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes, USE_ARIL_RELEASE } from "../../../constants";
import { logoutClient } from "../../../store/client/authActions";

export default function TopNav() {
  const isAuth = useSelector((state) => state.clientAuth.isAuth);
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutClient());
  };

  return (
    <nav className="top-nav">
      <div className="top-nav-left">
        <h3>HERBANEXT</h3>
      </div>
      <div className="top-nav-right">
        <div className="top-nav-link-wrapper">
          {isAuth ? (
            <a href="/#" className="text-white" onClick={handleLogout}>
              LOGOUT
            </a>
          ) : (
            <>
              <Link to={routes.EMPLOYEE_LOGIN_PAGE} className="text-white mr-4">
                STAFF PORTAL
              </Link>

              <Link to={routes.CLIENT_LANDING_PAGE} className="text-white mr-4">
                LANDING PAGE
              </Link>

              <Link
                to={routes.CLIENT_INQ_LAB_SERVICES}
                className="text-white mr-4"
              >
                LAB SERVICES INQUIRY
              </Link>

              {USE_ARIL_RELEASE && (
                <Link to={routes.CLIENT_INQ_ARIL} className="text-white mr-4">
                  ARIL INQUIRY
                </Link>
              )}

              <Link to={routes.CLIENT_DASHBOARD_LOGIN} className="text-white">
                LOGIN
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}
