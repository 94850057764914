import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { routes, api_urls } from "../../../../constants";
import {
  retrieveInquiry,
  createTransactionLog,
} from "../../../../store/employee/lab_services/inquiryActions";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import { labservices_constants } from "../../../../constants";

export default function InquiryDetailsBasicDetails({
  inquiryDetails,
  inquiryId,
}) {
  const [isOpenJONumberFormDialog, SetIsOpenJONumberFormDialog] =
    useState(false);
  const [isOpenDeclineFormDialog, SetIsOpenDeclineFormDialog] = useState(false);
  const [JONumber, SetJONumber] = useState("");
  const [declineReason, SetDeclineReason] = useState("");

  useEffect(() => {
    SetJONumber(inquiryDetails.JONumber);
  }, [inquiryDetails]);

  const dispatch = useDispatch();

  const handleSetJONumber = (e) => {
    e.preventDefault();
    if (!JONumber) {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "Empty JO Number!",
          },
        })
      );
    }
    axios
      .put(`${api_urls.LABSERVICES_INQUIRY}/${inquiryId}/update_jo_number/`, {
        jo_number: JONumber,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the JO Number!",
            },
          })
        );
        SetIsOpenJONumberFormDialog(false);
        createTransactionLog({
          inquiry_id: inquiryId,
          action: !inquiryDetails.JONumber
            ? "Created Inquiry Job Order Number"
            : "Updated Inquiry Job Order Number",
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        if (err.response.status === 400) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message: err.response.data.jo_number,
              },
            })
          );
        }
      });
  };

  const handleDecline = (e) => {
    e.preventDefault();
    axios
      .put(`${api_urls.LABSERVICES_INQUIRY}/${inquiryId}/toggle_is_declined/`, {
        is_declined: true,
        declined_comments: declineReason,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully Declined the inquiry!",
            },
          })
        );
        SetIsOpenDeclineFormDialog(false);
        createTransactionLog({
          inquiry_id: inquiryId,
          action: "Declined the Inquiry.",
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        if (err.response.status === 400) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message: err.response.data.jo_number,
              },
            })
          );
        }
      });
  };

  return (
    <div className="col-md-12">
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Details</h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_DETAILS}${inquiryId}/edit`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-pencil-square-o"></i> Edit
          </Link>
          <button
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
            onClick={() => SetIsOpenJONumberFormDialog(true)}
          >
            Set JO Number
          </button>
          <button
            className={`btn btn-warning btn-outline-warning float-right pt-2 pb-2 ml-2 ${
              inquiryDetails.isDeclined && "disabled"
            }`}
            onClick={() => SetIsOpenDeclineFormDialog(true)}
            disabled={inquiryDetails.isDeclined}
          >
            Decline
          </button>
        </div>
        <div className="card-block">
          <div className="row mt-4">
            <div className="col-md-4">
              <p>JO Number:</p>
              <p>Client Name:</p>
              <p>Affiliation (Company / School):</p>
              <p>Course / Degree (if student):</p>
              <p>Position:</p>
              <p>Email:</p>
              <p>Contact No:</p>
              <p>Service Type:</p>
              <p>Status</p>
              <p>Inquiry:</p>
              <p>Date Encoded:</p>
              <p>Last Updated:</p>
            </div>
            <div className="col-md-8">
              <p>
                {inquiryDetails.JONumber ? (
                  inquiryDetails.JONumber
                ) : (
                  <label className="label label-md label-warning">
                    Not set
                  </label>
                )}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.requestor}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.company}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.course}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.position}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.email}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.contactNo}
                {"\u00A0"}
              </p>
              <p>{inquiryDetails.serviceType}</p>
              <p style={{marginTop: "-2px"}}>
                {inquiryDetails.isDeclined ? (
                  <label className="label label-md label-warning">
                    Declined
                  </label>
                ) : (
                  <label className="label label-md label-primary">
                    {inquiryDetails.processStatus}
                  </label>
                )}
                {"\u00A0"}
              </p>
              <p>
                {inquiryDetails.description}
                {"\u00A0"}
              </p>
              <p>
                {moment(inquiryDetails.createdAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
              <p>
                {moment(inquiryDetails.updatedAt).format("MM/DD/YYYY hh:mm A")}
                {"\u00A0"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <JONumberFormDialog
        isOpen={isOpenJONumberFormDialog}
        JONumber={JONumber}
        JONumberFormHandler={(e) => SetJONumber(e.target.value)}
        handleSubmit={handleSetJONumber}
        handleClose={() => SetIsOpenJONumberFormDialog(false)}
      />
      <DeclineFormDialog
        isOpen={isOpenDeclineFormDialog}
        declineReason={declineReason}
        declineReasonFormHandler={(e) => SetDeclineReason(e.target.value)}
        handleSubmit={handleDecline}
        handleClose={() => SetIsOpenDeclineFormDialog(false)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: "60vh",
    maxHeight: "60vh",
  },
  dialogContent: {
    minHeight: "20vh",
    maxHeight: "20vh",
    overflow: "hidden",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: 20,
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "80vh",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function JONumberFormDialog({
  isOpen,
  JONumber,
  JONumberFormHandler,
  handleSubmit,
  handleClose,
}) {
  const styleClasses = useStyles();
  return (
    <Dialog
      className={styleClasses.dialog}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Set JO NUmber</DialogTitle>
      <DialogContent className={styleClasses.dialogContent}>
        <form className={styleClasses.form} noValidate>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>JO Number:</label>
            <input
              type="text"
              className="form-control"
              value={JONumber}
              onChange={JONumberFormHandler}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeclineFormDialog({
  isOpen,
  declineReason,
  declineReasonFormHandler,
  handleSubmit,
  handleClose,
}) {
  const styleClasses = useStyles();
  return (
    <Dialog
      className={styleClasses.dialog}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Decline Inquiry</DialogTitle>
      <DialogContent className={styleClasses.dialogContent}>
        <form className={styleClasses.form} noValidate>
          <FormControl className={styleClasses.formControl}>
            <label>Reason:</label>
            <select
              className="form-control"
              onChange={declineReasonFormHandler}
            >
              <option value="">Select</option>
              {labservices_constants.DECLINE_REASONS.map((data) => {
                return <option value={data.value}>{data.label}</option>;
              })}
            </select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
