export default function PageHeader(props) {
  return (
    <div className="page-header card">
      <div className="row align-items-end">
        <div className="col-lg-12">
          <div className="page-header-title">
            <i className="feather icon-clipboard bg-c-blue"></i>
            <div className="d-inline">
              <h5>{props.heading}</h5>
              <span>{props.subHeading}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
