import axios from "axios";
import { api_urls, aril_constants } from "../../../constants.js";
import {
  fetch,
  paginate,
  setFormData,
  setFormDataFiles,
  setFormErrors,
  setDetails,
} from "./inquiryReducer";

import { setToastNotification } from "../dashboardReducer.js";

export const DOWNLOAD_ACTIONS = {
  QUOTE_INITIAL: "QUOTE_INITIAL",
  QUOTE_SIGNED: "QUOTE_SIGNED",
  LSR_INITIAL: "LSR_INITIAL",
  LSR_SIGNED: "LSR_SIGNED",
};

export const fetchInquiryByUserId = ({ pagination, filters, userId }) => {
  return (dispatch) => {
    if (userId) {
      axios
        .get(api_urls.ARIL_INQUIRY, {
          params: {
            uid: userId,
            q: filters.query,
            page_size: pagination.pageSize,
            page: pagination.pageCurrent,
            st: filters.serviceType,
            ps: filters.processStatus,
            sort_field: filters.sortField,
            sort_order: filters.sortOrder,
          },
        })
        .then((res) => {
          const result = res.data.results;
          const newCheckedRecords = [];
          result.forEach((data) =>
            newCheckedRecords.push({ id: data.id, status: false })
          );
          dispatch(
            fetch({
              list: {
                records: result,
                totalCount: res.data.count,
                limit: Math.ceil(res.data.count / pagination.pageSize),
              },
            })
          );
        });
    }
  };
};

export const retrieveInquiry = ({ inquiry_id }) => {
  return (dispatch) => {
    if (inquiry_id !== "") {
      axios.get(`${api_urls.ARIL_INQUIRY}/${inquiry_id}`).then((res) => {
        const serviceType = aril_constants.SERVICE_TYPE_DROPDOWN.find(
          (data) => data.value === res.data.service_type
        );
        dispatch(
          setFormData({
            requestor: res.data.requestor,
            company: res.data.company,
            course: res.data.course,
            position: res.data.position,
            email: res.data.email,
            contactNo: res.data.contact_no,
            serviceType: serviceType,
            processStatus: res.data.process_status,
            description: res.data.description,
          })
        );
        dispatch(
          setDetails({
            JONumber: res.data.jo_number,
            requestor: res.data.requestor,
            company: res.data.company,
            course: res.data.course,
            position: res.data.position,
            email: res.data.email,
            contactNo: res.data.contact_no,
            serviceType: serviceType.label,
            processStatus: res.data.process_status,
            description: res.data.description,
            createdAt: res.data.created_at,
            updatedAt: res.data.updated_at,
            files: res.data.arilInquiryFiles_arilInquiry,
            reports: res.data.arilInquiryReports_arilInquiry,
            proofOfPayments: res.data.arilInquiryProofOfPayments_arilInquiry,
            transactionLogs: res.data.arilInquiryTransactionLogs_arilInquiry,
            user: res.data.user,
            updatedBy: res.data.updated_by,
            quote: res.data.quote,
            quoteSigned: res.data.quote_signed,
            quoteSignedStatus: res.data.quote_signed_status,
            quoteSignedComments: res.data.quote_signed_comments,
            lsr: res.data.lsr,
            lsrSigned: res.data.lsr_signed,
            lsrSignedStatus: res.data.lsr_signed_status,
            lsrSignedComments: res.data.lsr_signed_comments,
            payment: res.data.payment,
            paymentStatus: res.data.payment_status,
            paymentComments: res.data.payment_comments,
          })
        );
      });
    }
  };
};

export const downloadQuoteOrLSR = ({
  inquiryId,
  quote,
  quoteSigned,
  lsr,
  lsrSigned,
  action,
}) => {
  return (dispatch) => {
    let filename = "";
    let url = "";
    switch (action) {
      case DOWNLOAD_ACTIONS.QUOTE_INITIAL:
        filename =
          quote && quote.split(".")[1]
            ? "initial_quote." + quote.split(".").pop()
            : "initial_quote";
        url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/download_quote_initial`;
        break;
      case DOWNLOAD_ACTIONS.QUOTE_SIGNED:
        filename =
          quoteSigned && quoteSigned.split(".")[1]
            ? "signed_quote." + quoteSigned.split(".").pop()
            : "signed_quote";
        url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/download_quote_signed`;
        break;
      case DOWNLOAD_ACTIONS.LSR_INITIAL:
        filename =
          lsr && lsr.split(".")[1]
            ? "lsr_initial." + lsr.split(".").pop()
            : "lsr_initial";
        url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/download_lsr_initial`;
        break;
      case DOWNLOAD_ACTIONS.LSR_SIGNED:
        filename =
          lsrSigned && lsrSigned.split(".")[1]
            ? "signed_lsr." + lsrSigned.split(".").pop()
            : "signed_lsr";
        url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/download_lsr_signed`;
        break;
      default:
        break;
    }
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };
};

export const downloadProofOfPayment = ({ inquiryId, proofOfPayment }) => {
  return (dispatch) => {
    let filename = "proof_of_payment." + proofOfPayment.split(".").pop();
    axios
      .get(`${api_urls.ARIL_INQUIRY}/${inquiryId}/download_payment`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };
};

export const openInquiryByClient = ({ inquiry_id }) => {
  return (dispatch) => {
    if (inquiry_id !== "") {
      axios.put(
        `${api_urls.ARIL_INQUIRY}/${inquiry_id}/open_record_by_client/`
      );
    }
  };
};

export const paginationClick = ({ willCurrent, limit, size }) => {
  return (dispatch) => {
    if (willCurrent > 0 && willCurrent <= limit) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: willCurrent - 1,
            pageCurrent: willCurrent,
            pageNext: willCurrent + 1,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const pageSizeClick = (size) => {
  return (dispatch) => {
    if (size > 0) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: 0,
            pageCurrent: 1,
            pageNext: 2,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const resetForm = () => {
  return (dispatch) => {
    dispatch(
      setFormData({
        requestor: "",
        company: "",
        course: "",
        position: "",
        description: "",
        email: "",
        contactNo: "",
        serviceType: "",
        processStatus: "",
      })
    );
    dispatch(setFormDataFiles([]));
    dispatch(
      setFormErrors({
        requestor: "",
        company: "",
        course: "",
        position: "",
        description: "",
        email: "",
        contactNo: "",
        serviceType: "",
        processStatus: "",
        files: "",
      })
    );
  };
};

export const createTransactionLog = ({ arilInquiry_id, action }) => {
  axios.post(`${api_urls.ARIL_INQUIRY_TRANSACTION_LOG}/`, {
    arilInquiry_id,
    action,
  });
};
