
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ToastNotification(props){
  return(
    <Snackbar
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }} 
      open={props.open}
      autoHideDuration={props.duration} 
      onClose={props.closeHandler}>
      <Alert onClose={props.closeHandler} severity={props.type}>
          { props.message }
      </Alert>
    </Snackbar>
  )
}