import { useState } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { api_urls, labservices_constants } from "../../../constants.js";
import {
  InputText,
  FileInput,
  SelectInput,
} from "../../utils/Forms/DefaultInputs";
import DivLoader from "../../utils/DivLoaderComp";

export default function InquiryFormDiv() {
  const [formData, SetFormData] = useState({
    requestor: "",
    company: "",
    course: "",
    position: "",
    service_type: { value: "", label: "Select" },
    description: "",
    email: "",
    contact_no: "",
    files: [],
  });
  const [formErrors, SetFormErrors] = useState({
    requestor: "",
    company: "",
    course: "",
    position: "",
    service_type: "",
    description: "",
    email: "",
    contact_no: "",
    files: "",
  });
  const [pageLoader, SetPageLoader] = useState(false);
  const [openDialog, SetOpenDialog] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    SetPageLoader(true);
    const form = new FormData();
    form.append("requestor", formData.requestor);
    form.append("company", formData.company);
    form.append("course", formData.course);
    form.append("position", formData.position);
    form.append("service_type", formData.service_type.value);
    form.append("description", formData.description);
    form.append("email", formData.email);
    form.append("contact_no", formData.contact_no);
    form.append(
      "feedback_questions",
      JSON.stringify(labservices_constants.FEEDBACK_QUESTIONS)
    );
    for (let i = 0; i < formData.files.length; i++) {
      form.append("files", formData.files[i], formData.files[i].name);
    }
    axios
      .post(api_urls.LAB_SERVICES_CLIENT_INQUIRE_CREATE, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        SetFormErrors({});
        SetFormData({
          requestor: "",
          company: "",
          course: "",
          position: "",
          service_type: { value: "", label: "Select" },
          description: "",
          email: "",
          contact_no: "",
          files: [],
        });
        SetPageLoader(false);
        SetOpenDialog(true);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          let field_errors = err.response.data;
          SetFormErrors({
            requestor: field_errors.requestor,
            company: field_errors.company,
            course: field_errors.course,
            position: field_errors.position,
            service_type: field_errors.service_type,
            description: field_errors.description,
            email: field_errors.email,
            contact_no: field_errors.contact_no,
            files: field_errors.files,
          });
        }
        SetPageLoader(false);
      });
  };

  const handleDialogClose = () => {
    SetOpenDialog(false);
    window.location.reload();
  };

  return (
    <div className="col-md-12 col-xl-7">
      <div className="card">
        <div className="card-header">
          <h5>Inquiry Form</h5>
        </div>
        <div className="card-block">
          <div className="row">
            <DivLoader type="Circles" loading={pageLoader} />

            <InputText
              col="col-sm-6"
              type="text"
              label="Name:"
              placeholder="Name"
              errorField={formErrors.requestor}
              value={formData.requestor}
              setter={(e) =>
                SetFormData({ ...formData, requestor: e.target.value })
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Affiliation (Company / School):"
              placeholder="Affiliation (Company / School)"
              errorField={formErrors.company}
              value={formData.company}
              setter={(e) =>
                SetFormData({ ...formData, company: e.target.value })
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Course / Degree (if student):"
              placeholder="Course / Degree (if student)"
              errorField={formErrors.course}
              value={formData.course}
              setter={(e) =>
                SetFormData({ ...formData, course: e.target.value })
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Position:"
              placeholder="Position"
              errorField={formErrors.position}
              value={formData.position}
              setter={(e) =>
                SetFormData({ ...formData, position: e.target.value })
              }
            />

            <SelectInput
              col="col-sm-6"
              type="text"
              label="Service Type:"
              isDisabled={false}
              options={labservices_constants.SERVICE_TYPE_DROPDOWN}
              errorField={formErrors.service_type}
              value={formData.service_type}
              onChange={(value) =>
                SetFormData({ ...formData, service_type: value })
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Inquiry:"
              placeholder="Inquiry"
              errorField={formErrors.description}
              value={formData.description}
              setter={(e) =>
                SetFormData({ ...formData, description: e.target.value })
              }
            />

            <InputText
              col="col-sm-6"
              type="email"
              label="Email:"
              placeholder="Email"
              errorField={formErrors.email}
              value={formData.email}
              setter={(e) =>
                SetFormData({ ...formData, email: e.target.value })
              }
            />

            <InputText
              col="col-sm-6"
              type="contact_no"
              label="Contact No.:"
              placeholder="Contact No."
              errorField={formErrors.contact_no}
              value={formData.contact_no}
              setter={(e) =>
                SetFormData({ ...formData, contact_no: e.target.value })
              }
            />

            <FileInput
              col="col-sm-12"
              label="Upload Documents:"
              errorField={formErrors.files}
              acceptedFiles={
                labservices_constants.INQUIRY_FILE_UPLOAD_CONFIG.file_types
              }
              filesLimit={
                labservices_constants.INQUIRY_FILE_UPLOAD_CONFIG.file_limit
              }
              maxFileSize={
                labservices_constants.INQUIRY_FILE_UPLOAD_CONFIG.file_size
              }
              onChange={(files) => SetFormData({ ...formData, files: files })}
            />
          </div>

          <div className="form-group row mt-3">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary float-left mr-2"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => SetOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Success!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your Requirements has been successfully submitted. Our Laboratory
            Services will review the submitted requirements and send a notice in
            your email regarding the status of your Inquiry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
