import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPageHeader } from "../../../../store/client/dashboardReducer";
import {
  search,
  resetList,
} from "../../../../store/client/aril/inquiryReducer";
import {
  fetchInquiryByUserId,
  paginationClick,
  pageSizeClick,
  resetForm,
  downloadQuoteOrLSR,
  DOWNLOAD_ACTIONS,
} from "../../../../store/client/aril/inquiryActions";
import { aril_constants, routes } from "../../../../constants";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TableHeaderDefault } from "../../../utils/Table/TableHeaders";

export default function ClientInquiryList() {
  const [searchQuery, SetSearchQuery] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.clientAuth.user);
  const list = useSelector((state) => state.clientArilInquiry.list);
  const pagination = useSelector(
    (state) => state.clientArilInquiry.listPagination
  );
  const filters = useSelector((state) => state.clientArilInquiry.listFilters);
  const highlightedRecord = useSelector(
    (state) => state.clientArilInquiry.highlightedRecord
  );
  const checkedRecords = useSelector(
    (state) => state.clientArilInquiry.checkedRecords
  );

  const redirectToInquiryCreate = useCallback(
    () => history.push(`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/create`),
    [history]
  );
  const redirectToInquiryDetails = useCallback(
    (id) => history.push(`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/${id}`),
    [history]
  );
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search({ query: value })), 500),
    []
  ); 

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "ARIL Inquiries",
          subtitle: "Manage Inquiries",
          icon: "icon-info",
          pages: [
            {
              url: routes.CLIENT_DASHBOARD_ARIL_INQUIRY,
              name: "Manage Inquiries",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchInquiryByUserId({
        pagination: pagination,
        filters: filters,
        userId: user?.id,
      })
    );
  }, [dispatch, pagination, filters, user]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    SetSearchQuery(value);
    delaySearch(value);
  };

  const handleCreateButtonClick = (e) => {
    e.preventDefault();
    redirectToInquiryCreate();
  };

  const handleOpenInquiryDetails = (e, id) => {
    e.preventDefault();
    redirectToInquiryDetails(id);
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    dispatch(
      paginationClick({
        willCurrent: willCurrent,
        limit: list.limit,
        size: pagination.pageSize,
      })
    );
  };

  const handlePageSizeClick = (e) => {
    e.preventDefault();
    dispatch(pageSizeClick(e.target.value));
  };

  const handleRefreshClick = () => {
    dispatch(resetList());
  };

  const handleDownloadQuoteOrLSR = (e, action, val) => {
    e.preventDefault();
    dispatch(
      downloadQuoteOrLSR({
        inquiryId: val.id,
        quote: val.quote,
        quoteSigned: val.quote_signed,
        lsr: val.lsr,
        lsrSigned: val.lsr_signed,
        action,
      })
    );
  };

  const recordIsChecked = (id) => {
    return checkedRecords.some(
      (data) => data.id === id && data.status === true
    );
  };

  const findServiceType = (value) => {
    if (value === "") {
    }
    const serviceType = aril_constants.SERVICE_TYPE_DROPDOWN.find(
      (data) => data.value === value
    );
    if (!serviceType || serviceType["label"] === "Select") {
      return "NA";
    }
    return serviceType["label"];
  };

  const getProcessStatusConstantLabel = (value) => {
    return aril_constants.PROCESS_STATUS_DROPDOWN.find(
      (data) => data.value === value
    ).label;
  };

  const renderProcessStatusLabel = (processStatus) => {
    if (processStatus === aril_constants.PROCESS_STATUS_DROPDOWN[1].value) {
      return (
        <label className="label label-md label-danger">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else if (
      processStatus === aril_constants.PROCESS_STATUS_DROPDOWN[2].value
    ) {
      return (
        <label className="label label-md label-warning">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else {
      return (
        <label className="label label-md label-primary">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    }
  };

  const renderDownloadableQuote = (val) => {
    return (
      <ul>
        <li>
          Initial:{" "}
          {val.quote ? (
            <a
              href="/#"
              onClick={(e) =>
                handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.QUOTE_INITIAL, val)
              }
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
        <li>
          Signed:{" "}
          {val.quote_signed ? (
            <a
              href="/#"
              onClick={(e) =>
                handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.QUOTE_SIGNED, val)
              }
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
      </ul>
    );
  };

  const renderDownloadableLSR = (val) => {
    return (
      <ul>
        <li>
          Initial:{" "}
          {val.lsr ? (
            <a
              href="/#"
              onClick={(e) =>
                handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.LSR_INITIAL, val)
              }
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
        <li>
          Signed:{" "}
          {val.lsr_signed ? (
            <a
              href="/#"
              onClick={(e) =>
                handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.LSR_SIGNED, val)
              }
            >
              <ins className="text-info">Download</ins>
            </a>
          ) : (
            "Not yet uploaded"
          )}
        </li>
      </ul>
    );
  };

  return (
    <div className="card table-card z-depth-0 mt-4">
      {/* Table Header */}
      <div className="card-header">
        <TableHeaderDefault
          createButtonClickHandler={handleCreateButtonClick}
          searchInputValue={searchQuery}
          searchInputHandler={handleSearch}
          filterButton={false}
          sortButton={false}
          refreshButtonClickHandler={handleRefreshClick}
          deleteButton={false}
          createButton={true}
          entriesSelect={true}
          entriesSelectPageSize={pagination.pageSize}
          entriesSelectChangeHandler={handlePageSizeClick}
          paginationPagePrev={pagination.pagePrev}
          paginationPageNext={pagination.pageNext}
          paginationPageLimit={list.limit}
          paginationPrevClickHandler={(e) =>
            handlePaginationClick(e, pagination.pagePrev)
          }
          paginationNextClickHandler={(e) =>
            handlePaginationClick(e, pagination.pageNext)
          }
        />
      </div>

      {/* TABLE BODY */}
      <div className="card-block table-border-style pb-0 pt-0">
        <div className="table-responsive">
          <table className="table table-lg table-hover">
            <thead>
              <tr>
                <th className="align-middle">JO Number</th>
                <th className="align-middle">Client Name</th>
                <th className="align-middle">Status</th>
                <th className="align-middle">Service Type</th>
                <th className="align-middle">Date Encoded</th>
                <th className="align-middle">Quote</th>
                <th className="align-middle">LSR</th>
              </tr>
            </thead>
            <tbody>
              {list.records.map((val, key) => {
                const date_encoded = val.created_at
                  ? moment(val.created_at).format("MM/DD/YYYY hh:mm A")
                  : "";
                return (
                  <tr
                    key={key}
                    className={
                      val.id.toString() === (highlightedRecord || recordIsChecked(val.id)).toString()
                        ? "table-info"
                        : val.is_updated_admin === true
                        ? "table-success"
                        : ""
                    }
                  >
                    <td
                      onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                      className="align-middle"
                    >
                      {val.jo_number ? (
                        <a
                          href="/#"
                          onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                        >
                          <ins className="text-info">{val.jo_number}</ins>
                        </a>
                      ) : (
                        <label className="label label-md label-warning">
                          Not set
                        </label>
                      )}
                    </td>
                    <td
                      onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                      className="align-middle"
                    >
                      {val.requestor}
                    </td>
                    <td
                      onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                      className="align-middle"
                    >
                      {renderProcessStatusLabel(val.process_status)}
                    </td>
                    <td
                      onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                      className="align-middle"
                    >
                      {findServiceType(val.service_type)}
                    </td>
                    <td
                      onClick={(e) => handleOpenInquiryDetails(e, val.id)}
                      className="align-middle"
                    >
                      {date_encoded}
                    </td>
                    <td className="align-middle">
                      {renderDownloadableQuote(val)}
                    </td>
                    <td className="align-middle">
                      {renderDownloadableLSR(val)}
                    </td>
                  </tr>
                );
              })}
              {!list.records.length ? (
                <tr style={{ textAlign: "center" }}>
                  <td colspan="7">
                    <h4>No Records Found!</h4>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Table Footer */}
      <div className="card-footer">
        <TableFooterDefault
          counterPageSize={pagination.pageSize}
          counterPageCurrent={pagination.pageCurrent}
          counterPageLimit={list.limit}
          counterTotalRecords={list.totalCount}
          paginationPagePrev={pagination.pagePrev}
          paginationPageNext={pagination.pageNext}
          paginationPageLimit={list.limit}
          paginationPrevClickHandler={(e) =>
            handlePaginationClick(e, pagination.pagePrev)
          }
          paginationNextClickHandler={(e) =>
            handlePaginationClick(e, pagination.pageNext)
          }
        />
      </div>
    </div>
  );
}
