import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: {
    records: [],
    totalCount: 0,
    limit: 0,
    errorMessage: "",
  },
  listPagination: {
    pagePrev: 0,
    pageCurrent: 1,
    pageNext: 2,
    pageSize: 10,
  },
  listFilters: {
    query: "",
    category: "",
    sortField: "",
    sortOrder: "",
  },
  formData: {
    firstname: "",
    lastname: "",
    email: "",
    category: { value: "", label: "Select" },
    username: "",
    password: "",
    passwordConfirm: "",
  },
  formDataUserRoutes: [],
  formDataUserSubroutes: [],
  formErrors: {
    firstname: "",
    lastname: "",
    email: "",
    category: "",
    username: "",
    password: "",
    userRoutes: "",
    userSubroutes: "",
  },
  details: {
    username: "",
    firstname: "",
    lastname: "",
    category: "",
    email: "",
    isActive: "",
    dateJoined: "",
    lastLogin: "",
    userRoutes: [],
    userSubroutes: [],
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    updatedBy: "",
    ipCreated: "",
    ipUpdated: "",
  },
  routeOptions: [],
  subrouteOptions: [],
  checkedRecords: [],
  isCheckedAllRecords: false,
  highlightedRecord: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetch(state, action) {
      state.list = action.payload.list;
    },
    paginate(state, action) {
      state.listPagination = action.payload.listPagination;
    },
    filter(state, action) {
      state.listFilters = action.payload.listFilters;
    },
    search(state, action) {
      state.listFilters = { ...state.listFilters, query: action.payload.query };
    },
    resetList(state, action) {
      state.list = {
        records: [],
        totalCount: 0,
        limit: 0,
        errorMessage: "",
      };
      state.listPagination = {
        pagePrev: 0,
        pageCurrent: 1,
        pageNext: 2,
        pageSize: 10,
      };
      state.listFilters = {
        query: "",
        category: "",
        sortField: "",
        sortOrder: "",
      };
      state.checkedRecords = [];
      state.isCheckedAllRecords = false;
      state.highlightedRecord = 0;
    },

    setFormData(state, action) {
      state.formData = action.payload;
    },
    setFormDataUserRoutes(state, action) {
      state.formDataUserRoutes = action.payload;
    },
    setFormDataUserSubroutes(state, action) {
      state.formDataUserSubroutes = action.payload;
    },
    setFormErrors(state, action) {
      state.formErrors = action.payload;
    },
    setDetails(state, action) {
      state.details = action.payload;
    },

    setRouteOptions(state, action) {
      state.routeOptions = action.payload.routes;
    },
    setSubrouteOptions(state, action) {
      state.subrouteOptions = action.payload.subroutes;
    },
    setCheckedRecords(state, action) {
      state.checkedRecords = action.payload.checkedRecords;
    },
    setIsCheckedAllRecords(state, action) {
      state.isCheckedAllRecords = action.payload;
    },
    setHighlightedRecord(state, action) {
      state.highlightedRecord = action.payload.id;
    },
  },
});

export const {
  fetch,
  search,
  filter,
  paginate,
  resetList,
  setFormData,
  setFormDataUserRoutes,
  setFormDataUserSubroutes,
  setFormErrors,
  setDetails,
  setRouteOptions,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setSubrouteOptions,
  setHighlightedRecord,
} = userSlice.actions;
export default userSlice.reducer;
