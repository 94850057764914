import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { api_urls, routes } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setHighlightedRecord,
  setFormErrors,
} from "../../../../store/employee/tasks/personnelReducer";
import { resetForm } from "../../../../store/employee/tasks/personnelActions";
import DivLoader from "../../../utils/DivLoaderComp";
import { InputTextInline } from "../../../utils/Forms/InlineInputs";

export default function PersonnelCreate() {
  const history = useHistory();
  const [isPageLoading, SetIsPageLoading] = useState(false);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.personnel.formData);
  const formErrors = useSelector((state) => state.personnel.formErrors);

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Personnel",
          subtitle: "Create Personnel",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL,
              name: "Manage Personnel",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/create`,
              name: "Create Personnel",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const redirectBackToPersonnelList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL),
    [history]
  );

  const handleCreate = (e, isa) => {
    e.preventDefault();
    SetIsPageLoading(true);
    axios
      .post(`${api_urls.TASKS_PERSONNEL}/`, {
        fullname: formData.fullname,
        position: formData.position,
        email: formData.email,
        contact_no: formData.contactNo,
      })
      .then((response) => {
        dispatch(resetForm());
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Personnel Successfully Created!",
            },
          })
        );
        dispatch(setHighlightedRecord(response.data.id));
        if (isa === 0) {
          redirectBackToPersonnelList();
        }
        SetIsPageLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              fullname: field_errors.fullname?.toString(),
              position: field_errors.position?.toString(),
              email: field_errors.email?.toString(),
              contactNo: field_errors.contact_no?.toString(),
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetIsPageLoading(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={isPageLoading} />
        <div className="card-header">
          <h5>Create Personnel</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="col-md-12">
            <InputTextInline
              type="text"
              label="Fullname:"
              placeholder="Fullname"
              errorField={formErrors.fullname}
              value={formData.fullname}
              setter={(e) =>
                dispatch(setFormData({ ...formData, fullname: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Position:"
              placeholder="Position"
              errorField={formErrors.position}
              value={formData.position}
              setter={(e) =>
                dispatch(setFormData({ ...formData, position: e.target.value }))
              }
            />

            <InputTextInline
              type="email"
              label="Email:"
              placeholder="Email"
              errorField={formErrors.email}
              value={formData.email}
              setter={(e) =>
                dispatch(setFormData({ ...formData, email: e.target.value }))
              }
            />

            <InputTextInline
              type="text"
              label="Contact No:"
              placeholder="Contact No"
              errorField={formErrors.contactNo}
              value={formData.contactNo}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, contactNo: e.target.value })
                )
              }
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 1)}
              >
                Save and add another
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={() => dispatch(resetForm())}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
