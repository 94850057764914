import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: {
    records: [],
    totalCount: 0,
    limit: 0,
    errorMessage: "",
  },
  listPagination: {
    pagePrev: 0,
    pageCurrent: 1,
    pageNext: 2,
    pageSize: 10,
  },
  listFilters: {
    query: "",
    sortField: "",
    sortorder: "",
  },
  formData: {
    name: "",
    description: "",
    status: { value: "NA", label: "Select" },
  },
  formErrors: {
    name: "",
    description: "",
    status: "",
  },
  details: {
    name: "",
    description: "",
    status: "",
    createdAt: "",
    updatedAt: "",
    createdBy: null,
    updatedBy: null,
  },
  detailsTasks: {
    records: [],
    totalCount: 0,
    limit: 0,
  },
  checkedRecords: [],
  isCheckedAllRecords: false,
  highlightedRecord: 0,
  options: [],
};

const machineSlice = createSlice({
  name: "machine",
  initialState,
  reducers: {
    fetch(state, action) {
      state.list = action.payload.list;
    },
    paginate(state, action) {
      state.listPagination = action.payload.listPagination;
    },
    filter(state, action) {
      state.listFilters = action.payload.listFilters;
    },
    search(state, action) {
      state.listFilters = { ...state.listFilters, query: action.payload.query };
    },
    resetList(state) {
      state.list = {
        records: [],
        totalCount: 0,
        limit: 0,
        errorMessage: "",
      };
      state.listPagination = {
        pagePrev: 0,
        pageCurrent: 1,
        pageNext: 2,
        pageSize: 10,
      };
      state.listFilters = {
        query: "",
      };
      state.checkedRecords = [];
      state.isCheckedAllRecords = false;
      state.highlightedRecord = 0;
    },

    setFormData(state, action) {
      state.formData = action.payload;
    },
    setFormErrors(state, action) {
      state.formErrors = action.payload;
    },
    setDetails(state, action) {
      state.details = action.payload;
    },
    setDetailsTasks(state, action) {
      state.detailsTasks = action.payload;
    },
    setCheckedRecords(state, action) {
      state.checkedRecords = action.payload.checkedRecords;
    },
    setIsCheckedAllRecords(state, action) {
      state.isCheckedAllRecords = action.payload;
    },
    setHighlightedRecord(state, action) {
      state.highlightedRecord = action.payload;
    },
    setOptions(state, action) {
      state.options = action.payload;
    },
  },
});

export const {
  fetch,
  search,
  paginate,
  filter,
  resetList,
  setFormData,
  setFormDataFiles,
  setFormErrors,
  setDetails,
  setDetailsTasks,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setHighlightedRecord,
  setOptions,
} = machineSlice.actions;
export default machineSlice.reducer;
