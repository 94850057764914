import { NavLink } from "react-router-dom";
import { routes, USE_ARIL_RELEASE } from "../../../constants";

export default function TabHeader() {
  return (
    <ul className="nav nav-tabs md-tabs " role="tablist">
      <li className="nav-item">
        <NavLink
          to={routes.CLIENT_DASHBOARD_HOME}
          className="nav-link"
          data-toggle="tab"
          href="#"
          role="tab"
        >
          Home
        </NavLink>
        <div className="slide"></div>
      </li>
      <li className="nav-item">
        <NavLink
          to={routes.CLIENT_DASHBOARD_LABSERVICES_INQUIRY}
          className="nav-link"
          data-toggle="tab"
          href="#"
          role="tab"
        >
          Lab Services
        </NavLink>
        <div className="slide"></div>
      </li>
      {USE_ARIL_RELEASE && (
        <li className="nav-item">
          <NavLink
            to={routes.CLIENT_DASHBOARD_ARIL_INQUIRY}
            className="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            ARIL
          </NavLink>
          <div className="slide"></div>
        </li>
      )}
      <li className="nav-item">
        <NavLink
          to={routes.CLIENT_DASHBOARD_HELP}
          className="nav-link"
          data-toggle="tab"
          href="#"
          role="tab"
        >
          Help
        </NavLink>
        <div className="slide"></div>
      </li>
      <li className="nav-item">
        <NavLink
          to={routes.CLIENT_DASHBOARD_PROFILE}
          className="nav-link"
          data-toggle="tab"
          href="#"
          role="tab"
        >
          My Profile
        </NavLink>
        <div className="slide"></div>
      </li>
    </ul>
  );
}
