export function fileStorageUrlToMediaUrl(url) {
  if (!url) return "";
  const parsedUrl = new URL(url);
  let stringArray = parsedUrl.pathname.split("/");
  stringArray[1] = "media";
  return [
    ...[parsedUrl.origin],
    ...stringArray.filter((item) => Boolean(item)),
  ].join("/");
}

export function getFileTypeByFileStorageUrl(url) {
  if (!url) return "";
  const parsedUrl = new URL(url);
  return parsedUrl.pathname.split(".")[1];
}

export function getEllipsisText(text, maxLimit) {
  if (!text) return text;
  return text.length > maxLimit
    ? text.substring(0, maxLimit - 3) + "..."
    : text;
}
