import TaskBoardItem from "./TaskBoardItem";
export function TaskBoardBlock({ title, tasks }) {
  return (
    <div class="col-md-6">
      <div class="card ticket-card">
        <div className="card-header">
          <h5>{title}</h5>
        </div>
        <div class="card-body">
          <div class="row">
            {tasks.records.map((data) => (
              <TaskBoardItem key={data.id} task={data} />
            ))}
            {!tasks.isFetched ? (
              <div class="col-md-12">
                <p>Loading ...</p>
              </div>
            ) : tasks.isFetched && tasks.records.length === 0 ? (
              <div class="col-md-12">
                <p>No Records </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
