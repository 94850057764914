import { useState } from "react";
import axios from "axios";
import { api_urls, routes } from "../../constants";
import TopNav from "./common/TopNav.js";
import DivLoader from "../utils/DivLoaderComp";
import ToastNotification from "../utils/ToastNotification";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ClientDashboardForgotPassword() {
  const [formData, SetFormData] = useState({ email: "" });
  const [formErrors, SetFormErrors] = useState({});
  const [isSuccessDialogOpen, SetIsSuccessDialogOpen] = useState(false);
  const [pageLoader, SetPageLoader] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    SetPageLoader(true);
    window.localStorage.clear();
    axios
      .post(api_urls.USER_SEND_FORGOT_PASSWORD_EMAIL, formData)
      .then((res) => {
        SetIsSuccessDialogOpen(true);
        SetPageLoader(false);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          SetFormErrors({
            email: err.response.data.email?.toString(),
            not_found: err.response.data.not_found?.toString(),
          });
        }
        SetPageLoader(false);
      });
  };

  const handleDialogClose = () => {
    SetIsSuccessDialogOpen(false);
    window.location.reload();
  };

  return (
    <div className="lab-services-container">
      <DivLoader type="Circles" loading={pageLoader} />
      <TopNav />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div
            className="page-wrapper"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "5em",
              marginBottom: "40em",
            }}
          >
            <div className="page-body">
              <div style={{ width: "32em", height: "32em" }}>
                <form onSubmit={handleSubmit}>
                  <div className="auth-box card">
                    <div className="card-block">
                      <div className="row m-b-20">
                        <div className="col-md-12">
                          <h3 className="text-center txt-primary">
                            Client Forgot Password
                          </h3>
                        </div>
                      </div>

                      <p className="text-muted text-center p-b-5">
                        Please provide your email
                      </p>

                      <div
                        className={
                          formErrors.email || formErrors.not_found
                            ? "form-group form-danger has-danger mb-0"
                            : "form-group form-primary mb-0"
                        }
                      >
                        <label className="col-form-label">Email:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.username}
                          onChange={(e) =>
                            SetFormData({
                              ...formData,
                              email: e.target.value,
                            })
                          }
                        />
                        <div className="col-form-label">
                          {formErrors.email ? formErrors.email : ""}
                          {formErrors.not_found ? formErrors.not_found : ""}
                        </div>
                      </div>

                      <div className="row m-t-10">
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-round btn-primary btn-md btn-block waves-effect text-center m-b-10"
                          >
                            SEND RESET PASSWORD
                          </button>
                        </div>
                      </div>

                      <div className="row m-t-10">
                        <div className="col-md-12 text-center">
                          <Link to={routes.CLIENT_DASHBOARD_LOGIN}>
                            <u>Back to Login</u>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isSuccessDialogOpen}
        onClose={() => SetIsSuccessDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Password Successfully Generated!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The new password was sent to you via email. Please check your email
            address for your updated password and sign in again. <br />
            Note: If you want to set your own password, please visit the My
            Profile page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
