import "./Index.css";
import { routes } from "../../../constants.js";

import TopNav from "../common/TopNav.js";
import PageHeader from "../common/PageHeader.js";
import InquiryARILProcessDetailsDiv from "./InquiryARILProcessDetailsDiv.js";
import InquiryARILFormDiv from "./InquiryARILFormDiv.js";

export default function InquireARILPage() {
  return (
    <div className="aril-container">
      <TopNav />
      <PageHeader
        heading="Herbanext ARIL Inquiry"
        subHeading="Requirements Submission for ARIL"
        pages={[
          {
            url: routes.CLIENT_INQ_LAB_SERVICES,
            name: "ARIL Requirement Submission",
          },
        ]}
      />

      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <InquiryARILFormDiv />
                <InquiryARILProcessDetailsDiv />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
