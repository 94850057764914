import axios from "axios";
import { api_urls } from "../../constants.js";
import {
  fetch,
  paginate,
  setCheckedRecords,
  setIsCheckedAllRecords,
  resetList,
  setFormData,
  setFormDataSubroutes,
  setFormErrors,
  setDetails,
} from "./menuReducer";

export const fetchMenu = ({ pagination, filters }) => {
  return (dispatch) => {
    dispatch(setCheckedRecords({ checkedRecords: [] }));
    axios
      .get(api_urls.ROUTE_FETCH, {
        params: {
          q: filters.query,
          page_size: pagination.pageSize,
          page: pagination.pageCurrent,
          sort_field: filters.sortField,
          sort_order: filters.sortOrder,
        },
      })
      .then((res) => {
        const menus = res.data.results;
        const newCheckedRecords = [];
        menus.forEach((data) =>
          newCheckedRecords.push({ id: data.id, status: false })
        );
        dispatch(
          fetch({
            list: {
              records: menus,
              totalCount: res.data.count,
              limit: Math.ceil(res.data.count / pagination.pageSize),
            },
          })
        );
        dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
      });
  };
};

export const retrieveMenu = ({ menu_id }) => {
  return (dispatch) => {
    if (menu_id !== "") {
      axios.get(api_urls.ROUTE_FIND_BY_ID + menu_id).then((response) => {
        const responseSubroutes = response.data.subroute_route;
        let newSubroutes = [];
        // Set Subroutes
        responseSubroutes.forEach((data) => {
          newSubroutes.push({
            id: data.id,
            is_nav: data.is_nav,
            name: data.name,
            nav_name: data.nav_name,
            url: data.url,
            url_name: data.url_name,
            is_from_query: true,
          });
        });
        dispatch(
          setFormData({
            category: response.data.category,
            name: response.data.name,
            isMenu: response.data.is_menu,
            isDropdown: response.data.is_dropdown,
            navName: response.data.nav_name,
            icon: response.data.icon,
            url: response.data.url,
            urlName: response.data.url_name,
          })
        );
        dispatch(setFormDataSubroutes(newSubroutes));
        dispatch(
          setDetails({
            category: response.data.category,
            name: response.data.name,
            isMenu: response.data.is_menu,
            isDropdown: response.data.is_dropdown,
            navName: response.data.nav_name,
            icon: response.data.icon,
            url: response.data.url,
            urlName: response.data.url_name,
            subroutes: newSubroutes,
          })
        );
      });
    }
  };
};

export const checkAllRecords = ({ checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = [];
    checkedRecords.map((data) =>
      newCheckedRecords.push({ id: data.id, status: checked })
    );
    dispatch(setIsCheckedAllRecords(checked));
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const checkSpecificRecord = ({ id, checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = JSON.parse(JSON.stringify(checkedRecords));
    let objIndex = newCheckedRecords.findIndex((data) => data.id === id);
    newCheckedRecords[objIndex].status = checked;
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const paginationClick = ({ willCurrent, limit, size }) => {
  return (dispatch) => {
    if (willCurrent > 0 && willCurrent <= limit) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: willCurrent - 1,
            pageCurrent: willCurrent,
            pageNext: willCurrent + 1,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const pageSizeClick = (size) => {
  return (dispatch) => {
    if (size > 0) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: 0,
            pageCurrent: 1,
            pageNext: 2,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const refreshList = () => {
  return (dispatch) => {
    dispatch(resetList());
  };
};

export const resetForm = () => {
  return (dispatch) => {
    dispatch(
      setFormData({
        category: "",
        name: "",
        isMenu: null,
        isDropdown: null,
        navName: "",
        icon: "",
        url: "",
        urlName: "",
      })
    );
    dispatch(setFormDataSubroutes([]));
    dispatch(
      setFormErrors({
        category: "",
        name: "",
        isMenu: "",
        isDropdown: "",
        navName: "",
        icon: "",
        url: "",
        urlName: "",
        subroutes: [],
      })
    );
  };
};

export const addFormDataSubroutesRow = (existingFormDataSubroute) => {
  return (dispatch) => {
    dispatch(
      setFormDataSubroutes([
        ...existingFormDataSubroute,
        { name: "", is_nav: "", nav_name: "", url: "", url_name: "" },
      ])
    );
  };
};

export const modifyFormDataSubroutesRow = ({
  existingFormDataSubroute,
  key,
  name,
  value,
}) => {
  return (dispatch) => {
    const newFormDataSubroute = JSON.parse(
      JSON.stringify(existingFormDataSubroute)
    );
    newFormDataSubroute[key][name] = value;
    dispatch(setFormDataSubroutes(newFormDataSubroute));
  };
};

export const deleteFormDataSubroutesRow = ({
  existingFormDataSubroute,
  key,
}) => {
  return (dispatch) => {
    const newFormDataSubroute = JSON.parse(
      JSON.stringify(existingFormDataSubroute)
    );
    newFormDataSubroute.splice(key, 1);
    dispatch(setFormDataSubroutes(newFormDataSubroute));
  };
};
