import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

import { labservices_constants, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import {
  retrieveInquiry,
  createTransactionLog,
  updateProcessStatus,
} from "../../../../store/employee/lab_services/inquiryActions";
import ViewFileDialog from "../common/ViewFileDialog";
import {
  fileStorageUrlToMediaUrl,
  getFileTypeByFileStorageUrl,
  getEllipsisText,
} from "../../../utils/StringUtils";

const ACTION = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export default function InquiryDetailsReports({ inquiryDetails, inquiryId }) {
  const dispatch = useDispatch();

  const [isOpenImportFileFormDialog, SetIsOpenImportFileFormDialog] =
    useState(false);
  const [selectedId, SetSelectedId] = useState(null);
  const [formDataFile, SetFormDataFile] = useState(null);
  const [formDataDescription, SetFormDataDescription] = useState("");
  const [formAction, SetFormAction] = useState("");
  const [formTitle, SetFormTitle] = useState("");
  const [formSubtitle, SetFormSubtitle] = useState("");
  const [fileViewerDetails, SetFileViewerDetails] = useState({
    isOpen: false,
    fileData: null,
  });

  const handleOpenCreateDialog = (e) => {
    e.preventDefault();
    SetIsOpenImportFileFormDialog(true);
    SetFormAction(ACTION.CREATE);
    SetFormTitle("Add Report");
    SetFormSubtitle("Upload File / Document");
  };

  const handleOpenUpdateDialog = (e, report) => {
    e.preventDefault();
    SetSelectedId(report.id);
    SetFormDataDescription(report.description);
    SetIsOpenImportFileFormDialog(true);
    SetFormAction(ACTION.UPDATE);
    SetFormTitle("Update Report");
    SetFormSubtitle("Replace File / Document");
  };

  const handleCloseForm = () => {
    SetIsOpenImportFileFormDialog(false);
    SetFormAction("");
    SetFormTitle("");
    SetFormSubtitle("");
    SetSelectedId(null);
    SetFormDataFile(null);
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      SetFormDataFile(e.target.files[0]);
    }
  };

  const handleSubmitForm = () => {
    if (formDataFile === null) {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "No File selected!",
          },
        })
      );
      return;
    }
    const form = new FormData();
    form.append("file", formDataFile);
    form.append("description", formDataDescription);
    form.append("inquiry_id", inquiryId);
    if (formAction === ACTION.CREATE) {
      axios
        .post(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          SetIsOpenImportFileFormDialog(false);
          SetFormDataDescription("");
          dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Report successfully added!",
              },
            })
          );
          createTransactionLog({
            inquiry_id: inquiryId,
            action: `Created Inquiry Report`,
          });
        })
        .catch((err) => {
          SetIsOpenImportFileFormDialog(false);
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        });
    } else {
      axios
        .put(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/${selectedId}/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          SetIsOpenImportFileFormDialog(false);
          SetFormDataDescription("");
          dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Report successfully updated!",
              },
            })
          );
          createTransactionLog({
            inquiry_id: inquiryId,
            action: `Updated Inquiry Report Details`,
          });
        })
        .catch((err) => {
          SetIsOpenImportFileFormDialog(false);
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        });
    }
  };

  const handleUpdateReportStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/${id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        if (status === labservices_constants.REPORT_STATUS[2].value) {
          updateProcessStatus({
            inquiry_id: inquiryId,
            status: labservices_constants.PROCESS_STATUS_DROPDOWN[12].value,
          });
        }
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the report status!",
            },
          })
        );
        createTransactionLog({
          inquiry_id: inquiryId,
          action: `Updated Inquiry Report Status`,
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleDownloadFile = (e, id, filename) => {
    e.preventDefault();
    axios
      .get(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/${id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  return (
    <div
      className="col-md-12"
      style={
        inquiryDetails.isDeclined ||
        inquiryDetails.processStatus ===
          labservices_constants.PROCESS_STATUS_DROPDOWN[1].label
          ? { pointerEvents: "none", opacity: "0.5" }
          : {}
      }
    >
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Reports</h5>
          <button
            className="btn btn-success btn-outline-success float-right pt-2 pb-2 ml-2"
            onClick={handleOpenCreateDialog}
          >
            <i className="fa fa-plus"></i> Add
          </button>
        </div>
        <div className="card-block">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Filename</th>
                <th>Description</th>
                <th>Status</th>
                <th>Comment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {inquiryDetails.reports.map((data) => {
                let statusLabel = "";
                let statusClass = "";
                switch (data.status) {
                  case labservices_constants.REPORT_STATUS[0].value:
                    statusClass = "label-warning";
                    statusLabel = labservices_constants.REPORT_STATUS[0].label;
                    break;
                  case labservices_constants.REPORT_STATUS[1].value:
                    statusClass = "label-warning";
                    statusLabel = labservices_constants.REPORT_STATUS[1].label;
                    break;
                  case labservices_constants.REPORT_STATUS[2].value:
                    statusClass = "label-primary";
                    statusLabel = labservices_constants.REPORT_STATUS[2].label;
                    break;
                  case labservices_constants.REPORT_STATUS[3].value:
                    statusClass = "label-success";
                    statusLabel = labservices_constants.REPORT_STATUS[3].label;
                    break;
                  default:
                    statusClass = "";
                }
                return (
                  <tr>
                    <td>
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          SetFileViewerDetails({
                            ...fileViewerDetails,
                            isOpen: true,
                            fileData: data,
                          });
                        }}
                      >
                        <ins className="text-info">
                          {getEllipsisText(data.orig_filename, 30)}
                        </ins>
                      </a>
                    </td>
                    <td>{data.description}</td>
                    <td>
                      <label className={`label ${statusClass}`}>
                        {statusLabel}
                      </label>
                    </td>
                    <td>{data.feedback}</td>
                    <td className="align-middle">
                      <button
                        onClick={(e) => handleOpenUpdateDialog(e, data)}
                        className="btn btn-sm btn-primary pb-2 pt-2 mr-2"
                        type="button"
                      >
                        Edit
                      </button>
                      <button
                        onClick={(e) =>
                          handleUpdateReportStatus(
                            e,
                            data.id,
                            labservices_constants.REPORT_STATUS[2].value
                          )
                        }
                        className="btn btn-sm btn-primary pb-2 pt-2 mr-2"
                        type="button"
                      >
                        Release
                      </button>
                      <button
                        onClick={(e) =>
                          handleUpdateReportStatus(
                            e,
                            data.id,
                            labservices_constants.REPORT_STATUS[3].value
                          )
                        }
                        className="btn btn-sm btn-primary pb-2 pt-2"
                        type="button"
                      >
                        Acknowledge
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Import File Dialog */}
      <ImportFileFormDialog
        isOpen={isOpenImportFileFormDialog}
        title={formTitle}
        subtitle={formSubtitle}
        fileInputHandler={handleFileInput}
        descriptionInputValue={formDataDescription}
        descriptionInputHandler={(e) => SetFormDataDescription(e.target.value)}
        handleSubmit={handleSubmitForm}
        handleClose={handleCloseForm}
      />

      {/* View File Dialog */}
      <ViewFileDialog
        isOpen={fileViewerDetails.isOpen}
        fileType={getFileTypeByFileStorageUrl(fileViewerDetails.fileData?.file)}
        filePath={fileStorageUrlToMediaUrl(fileViewerDetails.fileData?.file)}
        errorComponent={<p>Error Encountered Trying to load file ...</p>}
        handleClose={() =>
          SetFileViewerDetails({
            isOpen: false,
            fileData: null,
          })
        }
        handleDownload={(e) =>
          handleDownloadFile(
            e,
            fileViewerDetails.fileData?.id,
            fileViewerDetails.fileData?.orig_filename
          )
        }
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  dialogContent: {
    minHeight: "55vh",
    maxHeight: "20vh",
    overflow: "hidden",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: 20,
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "80vh",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function ImportFileFormDialog({
  isOpen,
  title,
  subtitle,
  fileInputHandler,
  descriptionInputValue,
  descriptionInputHandler,
  handleSubmit,
  handleClose,
}) {
  const styleClasses = useStyles();
  return (
    <Dialog
      className={styleClasses.dialog}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={styleClasses.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          {subtitle}
        </DialogContentText>
        <form className={styleClasses.form} noValidate>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>File / Document:</label>
            <input
              type="file"
              className="form-control"
              onChange={fileInputHandler}
            />
          </FormControl>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>Description:</label>
            <input
              type="text"
              className="form-control"
              value={descriptionInputValue}
              onChange={descriptionInputHandler}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
