import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

import { aril_constants, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import {
  retrieveInquiry,
  createTransactionLog,
} from "../../../../store/employee/aril/inquiryActions";
import { InquiryFilesComments } from "./molecules/InquiryFilesComments";

const ACTION = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export default function InquiryDetailsRequirements({ files, inquiryId }) {
  const dispatch = useDispatch();

  const [isOpenImportFileFormDialog, SetIsOpenImportFileFormDialog] =
    useState(false);
  const [formDataFile, SetFormDataFile] = useState(null);
  const [formDataComments, SetFormDataComments] = useState("");
  const [formAction, SetFormAction] = useState("");
  const [formTitle, SetFormTitle] = useState("");
  const [formSubtitle, SetFormSubtitle] = useState("");

  const handleOpenCreateDialog = (e) => {
    e.preventDefault();
    SetIsOpenImportFileFormDialog(true);
    SetFormAction(ACTION.CREATE);
    SetFormTitle("Add Requirement");
    SetFormSubtitle("Upload File");
  };

  const handleCloseForm = () => {
    SetIsOpenImportFileFormDialog(false);
    SetFormAction("");
    SetFormTitle("");
    SetFormSubtitle("");
    SetFormDataFile(null);
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      SetFormDataFile(e.target.files[0]);
    }
  };

  const handleUpdateRequirementStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.ARIL_INQUIRY_FILES}/${id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the requirement status!",
            },
          })
        );
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Updated Inquiry Requirement Status",
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleOpenFile = (e, id, filename) => {
    e.preventDefault();
    axios
      .get(`${api_urls.ARIL_INQUIRY_FILES}/${id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleSubmitForm = () => {
    if (formDataFile === null) {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "No File selected!",
          },
        })
      );
      return;
    }
    const form = new FormData();
    form.append("file", formDataFile);
    form.append("comments", formDataComments);
    form.append("arilInquiry_id", inquiryId);
    if (formAction === ACTION.CREATE) {
      axios
        .post(`${api_urls.ARIL_INQUIRY_FILES}/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          SetIsOpenImportFileFormDialog(false);
          SetFormDataComments("");
          dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Requirement successfully added!",
              },
            })
          );
          createTransactionLog({
            arilInquiry_id: inquiryId,
            action: "Created Inquiry Requirement",
          });
        })
        .catch((err) => {
          SetIsOpenImportFileFormDialog(false);
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        });
    } else {
      SetIsOpenImportFileFormDialog(false);
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "error",
            message: "Invalid Action!",
          },
        })
      );
    }
  };

  return (
    <div
      className="col-md-12"
      // style={{ pointerEvents: "none", opacity: "0.5" }}
    >
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Submitted Requirements</h5>
          <button
            className="btn btn-success btn-outline-success float-right pt-2 pb-2 ml-2"
            onClick={handleOpenCreateDialog}
          >
            <i className="fa fa-plus"></i> Add
          </button>
        </div>
        <div className="card-block">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Filename</th>
                <th>Status</th>
                <th>Comments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {files.map((data) => {
                let statusLabel = "";
                let statusClass = "";
                let approveButtonClass = "";
                let rejectButtonClass = "";
                switch (data.status) {
                  case aril_constants.FILE_STATUS_DROPDOWN[1].value:
                    statusClass = "label-danger";
                    statusLabel = aril_constants.FILE_STATUS_DROPDOWN[1].label;
                    approveButtonClass = "btn-success btn-outline-success";
                    rejectButtonClass = "btn-disabled disabled";
                    break;
                  case aril_constants.FILE_STATUS_DROPDOWN[2].value:
                    statusClass = "label-primary";
                    statusLabel = aril_constants.FILE_STATUS_DROPDOWN[2].label;
                    approveButtonClass = "btn-success btn-outline-success";
                    rejectButtonClass = "btn-danger btn-outline-danger";
                    break;
                  case aril_constants.FILE_STATUS_DROPDOWN[3].value:
                    statusClass = "label-success";
                    statusLabel = aril_constants.FILE_STATUS_DROPDOWN[3].label;
                    approveButtonClass = "btn-disabled disabled";
                    rejectButtonClass = "btn-danger btn-outline-danger";
                    break;
                  default:
                    statusClass = "";
                }
                return (
                  <tr>
                    <td>
                      <a
                        href="/#"
                        onClick={(e) =>
                          handleOpenFile(e, data.id, data.orig_filename)
                        }
                      >
                        <ins className="text-info">{data.orig_filename}</ins>
                      </a>
                    </td>
                    <td>
                      <label className={`label ${statusClass}`}>
                        {statusLabel}
                      </label>
                    </td>
                    <td>
                      <InquiryFilesComments
                        inquiryId={inquiryId}
                        fileDetails={data}
                      />
                    </td>
                    <td className="align-middle">
                      <button
                        onClick={(e) =>
                          handleUpdateRequirementStatus(
                            e,
                            data.id,
                            aril_constants.FILE_STATUS_DROPDOWN[3].value
                          )
                        }
                        className={`btn btn-sm pb-2 pt-2 mr-2 ${approveButtonClass}`}
                        type="button"
                      >
                        Approve
                      </button>
                      <button
                        onClick={(e) =>
                          handleUpdateRequirementStatus(
                            e,
                            data.id,
                            aril_constants.FILE_STATUS_DROPDOWN[1].value
                          )
                        }
                        className={`btn btn-sm pb-2 pt-2 ${rejectButtonClass}`}
                        type="button"
                      >
                        Dissaprove
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ImportFileFormDialog
        isOpen={isOpenImportFileFormDialog}
        title={formTitle}
        subtitle={formSubtitle}
        fileInputHandler={handleFileInput}
        commentsInputValue={formDataComments}
        commentsInputHandler={(e) => SetFormDataComments(e.target.value)}
        handleSubmit={handleSubmitForm}
        handleClose={handleCloseForm}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  dialogContent: {
    minHeight: "55vh",
    maxHeight: "20vh",
    overflow: "hidden",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: 20,
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "80vh",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function ImportFileFormDialog({
  isOpen,
  title,
  subtitle,
  fileInputHandler,
  commentsInputValue,
  commentsInputHandler,
  handleSubmit,
  handleClose,
}) {
  const styleClasses = useStyles();
  return (
    <Dialog
      className={styleClasses.dialog}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={styleClasses.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          {subtitle}
        </DialogContentText>
        <form className={styleClasses.form} noValidate>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>File / Document:</label>
            <input
              type="file"
              className="form-control"
              onChange={fileInputHandler}
            />
          </FormControl>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>Comments:</label>
            <textarea
              rows="9"
              className="form-control"
              value={commentsInputValue}
              onChange={commentsInputHandler}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
