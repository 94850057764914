import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import dashboardReducer from "./employee/dashboardReducer";
import userReducer from "./employee/userReducer";
import menuReducer from "./employee/menuReducer";
import labServicesInquiryReducer from "./employee/lab_services/inquiryReducer";
import arilInquiryReducer from "./employee/aril/inquiryReducer";
import clientDashboardReducer from "./client/dashboardReducer";
import clientAuthReducer from "./client/authReducer";
import clientHomeReducer from "./client/home/homeReducer";
import clientLabServicesInquiryReducer from "./client/lab_services/inquiryReducer";
import clientArilInquiryReducer from "./client/aril/inquiryReducer";
import taskReducer from "./employee/tasks/taskReducer";
import personnelReducer from "./employee/tasks/personnelReducer";
import machineReducer from "./employee/tasks/machineReducer";
import taskTypeReducer from "./employee/tasks/taskTypeReducer";
import taskBoardReducer from "./employee/tasks/taskBoardReducer";
import taskCalendarReducer from "./employee/tasks/taskCalendarReducer";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    auth: authReducer,
    user: userReducer,
    menu: menuReducer,
    labServicesInquiry: labServicesInquiryReducer,
    arilInquiry: arilInquiryReducer,
    clientDashboard: clientDashboardReducer,
    clientAuth: clientAuthReducer,
    clientHome: clientHomeReducer,
    clientLabServicesInquiry: clientLabServicesInquiryReducer,
    clientArilInquiry: clientArilInquiryReducer,
    task: taskReducer,
    personnel: personnelReducer,
    machine: machineReducer,
    taskType: taskTypeReducer,
    taskBoard: taskBoardReducer,
    taskCalendar: taskCalendarReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
