import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    list: {
      records: [],
      totalCount: 0,
      limit: 0,
      errorMessage:"",
    },
    listPagination: {
      pagePrev: 0,
      pageCurrent: 1,
      pageNext: 2,
      pageSize: 10,
    },
    listFilters:{
        query: "",
    },
    formData:{
      category: "",
      name: "",
      isMenu: null,
      isDropdown: null,
      navName: "",
      icon: "",
      url: "",
      urlName: "",
    },
    formDataSubroutes:[],
    formErrors:{
      category: "",
      name: "",
      isMenu: "",
      isDropdown: "",
      navName: "",
      icon: "",
      url: "",
      urlName: "",
      subroutes: []
    },
    details:{
      category: "",
      name: "",
      isMenu: null,
      isDropdown: null,
      navName: "",
      icon: "",
      url: "",
      urlName: "",
      subroutes: []
    },
    checkedRecords:[],
    isCheckedAllRecords:false,
    highlightedRecord:0,
}


const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {

    fetch(state, action){
        state.list = action.payload.list;
    },
    paginate(state, action){
        state.listPagination = action.payload.listPagination;
    },
    search(state, action){
      state.listFilters = { ...state.listFilters, query: action.payload.query};
    },
    resetList(state){
      state.list = {
        records: [],
        totalCount: 0,
        limit: 0,
        errorMessage:"",
      };
      state.listPagination =  {
        pagePrev: 0,
        pageCurrent: 1,
        pageNext: 2,
        pageSize: 10,
      };
      state.listFilters = {
          query: "",
      };
      state.checkedRecords = [];
      state.isCheckedAllRecords = false;
      state.highlightedRecord = 0;
    },

    setFormData(state, action){
        state.formData = action.payload;
    },
    setFormDataSubroutes(state, action){
        state.formDataSubroutes = action.payload;
    },
    setFormErrors(state, action){
        state.formErrors = action.payload;
    },
    setDetails(state, action){
        state.details = action.payload;
    },
    setCheckedRecords(state, action){
        state.checkedRecords = action.payload.checkedRecords;
    },
    setIsCheckedAllRecords(state, action){
        state.isCheckedAllRecords = action.payload;
    },
    setHighlightedRecord(state, action){
        state.highlightedRecord = action.payload.id;
    },

  },
});


export const { 
  fetch, 
  search, 
  paginate,
  resetList,
  setFormData,
  setFormDataSubroutes,
  setFormErrors,
  setDetails,
  setCheckedRecords,
  setIsCheckedAllRecords,
  setHighlightedRecord 
} = menuSlice.actions
export default menuSlice.reducer;