import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes, api_urls, tasks_constants } from "../../../../constants";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import { fetchOnboardLists } from "../../../../store/employee/tasks/taskBoardActions";

export default function TaskBoardItem({ task }) {
  const onBoardFilters = useSelector((state) => state.taskBoard.onBoardFilters);
  const dispatch = useDispatch();

  const handleUpdateStatus = (e, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.TASKS_TASK}/${task.id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(fetchOnboardLists({ onBoardFilters }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the status!",
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  return (
    <div class="col-md-12">
      <div class="card prod-p-card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-md-12">
              <p class="card-text">TASK-{task.id}</p>
              <Link
                to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}/${task.id}`}
              >
                <h6 class="m-b-10">
                  <ins className="text-info">{task.jo_number}</ins>
                </h6>
              </Link>

              <p class="card-text">{task.description}</p>

              <label
                className="label label-md label-primary"
                style={{ backgroundColor: task.task_type.color }}
              >
                {task.task_type.name}
              </label>
            </div>
            <div className="col-md-12 mt-2">
              <div class="dropdown-primary dropdown open float-right">
                <button
                  class="btn btn-sm btn-primary dropdown-toggle pt-2 pb-2"
                  type="button"
                  id="dropdown-2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Set as
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdown-2"
                  data-dropdown-in="fadeIn"
                  data-dropdown-out="fadeOut"
                >
                  {tasks_constants.TASK_STATUS_DROPDOWN.slice(1).map((data) => (
                    <a
                      class="dropdown-item waves-light waves-effect"
                      href="/#"
                      onClick={(e) => handleUpdateStatus(e, data.value)}
                    >
                      {data.label}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
