import { HashRouter, Switch, Route } from "react-router-dom";
import { routes } from "../../../../constants";
import ClientInquiryList from "./InquiryList";
import ClientInquiryCreate from "./InquiryCreate";
import ClientInquiryDetails from "./InquiryDetails";
import ClientInquiryEdit from "./InquiryEdit";

export default function ArilClientMain() {
  return (
    <HashRouter>
      <Switch>
        {/* LIST */}
        <Route exact path={routes.CLIENT_DASHBOARD_ARIL_INQUIRY}>
          <ClientInquiryList />
        </Route>
        {/* CREATE */}
        <Route exact path={`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/create`}>
          <ClientInquiryCreate />
        </Route>
        {/* Details  */}
        <Route
          exact
          path={`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/:inquiry_id`}
        >
          <ClientInquiryDetails />
        </Route>

        {/* EDIT */}
        <Route
          exact
          path={`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/:inquiry_id/edit`}
        >
          <ClientInquiryEdit />
        </Route>
      </Switch>
    </HashRouter>
  );
}
