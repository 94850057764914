import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPageHeader } from "../../../../store/client/dashboardReducer";
import { routes } from "../../../../constants";
import UsernameForm from "./UsernameForm";
import PasswordForm from "./PasswordForm";

export default function ProfilePage() {
  const user = useSelector((state) => state.clientAuth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "User Profile",
          subtitle: "User info and credentials",
          icon: "icon-user",
          pages: [
            {
              url: routes.CLIENT_DASHBOARD_PROFILE,
              name: "Profile",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="card z-depth-0 mt-4">
        <div className="card-header">
          <h5>User Information</h5>
        </div>
        <div className="card-block">
          <p className="lead m-t-0">
            Name : {user?.fullname ? user?.fullname : "N/A"}
          </p>
          <p className="lead m-t-0">Username : {user?.username}</p>
          <p className="lead m-t-0">
            Email : {user?.email ? user?.email : "N/A"}
          </p>
        </div>
      </div>
      <UsernameForm />
      <PasswordForm />
    </>
  );
}
