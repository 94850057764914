import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormErrors,
  setHighlightedRecord,
} from "../../../../store/employee/lab_services/inquiryReducer";
import {
  retrieveInquiry,
  createTransactionLog,
} from "../../../../store/employee/lab_services/inquiryActions";
import { routes, api_urls, labservices_constants } from "../../../../constants";
import { InputText, SelectInput } from "../../../utils/Forms/DefaultInputs";
import DivLoader from "../../../utils/DivLoaderComp";

export default function InquiryEdit() {
  const [pageLoader, SetPageLoader] = useState(false);
  const { inquiry_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.labServicesInquiry.formData);
  const formErrors = useSelector(
    (state) => state.labServicesInquiry.formErrors
  );
  const redirectBackToInquiryList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY),
    [history]
  );
  const detailsRoute =
    routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_DETAILS + inquiry_id;

  useEffect(() => {
    dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Lab Services Inquiries",
          subtitle: "Edit Inquiry",
          icon: "icon-info",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY,
              name: "Manage Inquiries",
            },
            {
              url: detailsRoute,
              name: "Inquiry Details",
            },
            {
              url: detailsRoute + "/edit",
              name: "Inquiry Edit",
            },
          ],
        },
      })
    );
  }, [dispatch, detailsRoute, inquiry_id]);

  const handleUpdate = (e, isGoBackToList) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(api_urls.LABSERVICES_INQUIRY_UPDATE + inquiry_id + "/", {
        requestor: formData.requestor,
        company: formData.company,
        course: formData.course,
        position: formData.position,
        service_type: formData.serviceType.value,
        description: formData.description,
        email: formData.email,
        contact_no: formData.contactNo,
      })
      .then((res) => {
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Inquiry Successfully Updated!",
            },
          })
        );
        if (isGoBackToList) {
          redirectBackToInquiryList();
          dispatch(setHighlightedRecord(res.data.id));
        }
        createTransactionLog({ inquiry_id, action: "Updated Inquiry Details" });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          let field_errors = err.response.data;
          dispatch(
            setFormErrors({
              requestor: field_errors.requestor,
              company: field_errors.company,
              course: field_errors.course,
              position: field_errors.position,
              serviceType: field_errors.service_type,
              description: field_errors.description,
              email: field_errors.email,
              contactNo: field_errors.contact_no,
            })
          );
        }
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        <div className="card-header">
          <h5>Edit Lab Services Inquiry</h5>
          <Link
            to={detailsRoute}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 mr-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="row">
            <InputText
              col="col-sm-6"
              type="text"
              label="Name:"
              placeholder="Name"
              errorField={formErrors.requestor}
              value={formData.requestor}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, requestor: e.target.value })
                )
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Affiliation (Company / School):"
              placeholder="Affiliation (Company / School)"
              errorField={formErrors.company}
              value={formData.company}
              setter={(e) =>
                dispatch(setFormData({ ...formData, company: e.target.value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Course / Degree (if student):"
              placeholder="Course / Degree (if student)"
              errorField={formErrors.course}
              value={formData.course}
              setter={(e) =>
                dispatch(setFormData({ ...formData, course: e.target.value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Position:"
              placeholder="Position"
              errorField={formErrors.position}
              value={formData.position}
              setter={(e) =>
                dispatch(setFormData({ ...formData, position: e.target.value }))
              }
            />

            <SelectInput
              col="col-sm-6"
              type="text"
              label="Service Type:"
              isDisabled={false}
              options={labservices_constants.SERVICE_TYPE_DROPDOWN}
              errorField={formErrors.serviceType}
              value={formData.serviceType}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, serviceType: value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Inquiry:"
              placeholder="Inquiry"
              errorField={formErrors.description}
              value={formData.description}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, description: e.target.value })
                )
              }
            />

            <InputText
              col="col-sm-6"
              type="email"
              label="Email:"
              placeholder="Email"
              errorField={formErrors.email}
              value={formData.email}
              setter={(e) =>
                dispatch(setFormData({ ...formData, email: e.target.value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="contact_no"
              label="Contact No.:"
              placeholder="Contact No."
              errorField={formErrors.contactNo}
              value={formData.contactNo}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, contactNo: e.target.value })
                )
              }
            />
          </div>

          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, true)}
              >
                Update and go back to list
              </button>
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, false)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
