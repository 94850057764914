import { HashRouter, Switch, Route } from "react-router-dom";
import { routes } from "../../../../constants";
import MenuList from "./List";
import MenuCreate from "./Create";
import MenuDetails from "./Details";
import MenuEditPermissions from "./EditPermissions";
import MenuEdit from "./Edit";

export default function MenuMain() {
  return (
    <HashRouter>
      <Switch>
        {/* LIST */}
        <Route exact path={routes.EMPLOYEE_DASHBOARD_MENUS}>
          <MenuList />
        </Route>

        {/* CREATE */}
        <Route exact path={routes.EMPLOYEE_DASHBOARD_MENUS_CREATE}>
          <MenuCreate />
        </Route>

        {/* details */}
        <Route
          exact
          path={routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS + ":menu_id"}
        >
          <MenuDetails />
        </Route>

        {/* edit */}
        <Route
          exact
          path={routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS + ":menu_id/edit"}
        >
          <MenuEdit />
        </Route>

        {/* edit permissions */}
        <Route
          exact
          path={
            routes.EMPLOYEE_DASHBOARD_MENUS_DETAILS +
            ":menu_id/edit_permissions"
          }
        >
          <MenuEditPermissions />
        </Route>
      </Switch>
    </HashRouter>
  );
}
