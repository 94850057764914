import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onBoardFilters: {
    search: "",
    personnel: "",
    machine: "",
    task_type: "",
  },
  queuedListAll: {
    records: [],
    totalCount: 0,
    isFetched: false,
  },
  ongoingListAll: {
    records: [],
    totalCount: 0,
    isFetched: false,
  },
};

const taskBoardSlice = createSlice({
  name: "taskBoard",
  initialState,
  reducers: {
    setListsOnBoard(state, action) {
      state.queuedListAll = action.payload.queued;
      state.ongoingListAll = action.payload.ongoing;
    },
    search(state, action) {
      state.onBoardFilters = {
        ...state.onBoardFilters,
        search: action.payload,
      };
    },
    filter(state, action) {
      state.onBoardFilters = {
        ...state.onBoardFilters,
        personnel: action.payload.personnel,
        machine: action.payload.machine,
        task_type: action.payload.task_type,
      };
    },
    fetchCompletedList(state, action) {
      state.completedlist = action.payload;
    },
  },
});

export const { setListsOnBoard, search, filter, fetchCompletedList } =
  taskBoardSlice.actions;
export default taskBoardSlice.reducer;
