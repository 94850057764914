import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageHeader } from "../../../../store/client/dashboardReducer";
import { routes } from "../../../../constants";
import InquiryInstructions from "../../common/InquiryInstructions";

export default function HelpPage() {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        setPageHeader({
          pageHeader: {
            title: "Help",
            subtitle: "Inquiry Instructions",
            icon: "icon-info",
            pages: [
              {
                url: routes.CLIENT_DASHBOARD_HELP,
                name: "Help",
              },
            ],
          },
        })
      );
    }, [dispatch]);

  return (
    <>
      <div className="card z-depth-0 mt-4">
        <div className="card-header">
          <h5>Instructions</h5>
        </div>
        <div className="card-block">
            <InquiryInstructions/>
        </div>
      </div>
    </>
  );
}
