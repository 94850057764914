import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { aril_constants, routes, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/client/dashboardReducer";
import {
  openInquiryByClient,
  retrieveInquiry,
} from "../../../../store/client/aril/inquiryActions";
import { setPageHeader } from "../../../../store/client/dashboardReducer";
import InquiryDetailsTransactionLogs from "./InquiryDetailsTransactionLogs";
import InquiryDetailsRequirements from "./InquiryDetailsRequirements";
import InquiryDetailsQuoteAndLSR from "./InquiryDetailsQuoteAndLSR";
import InquiryDetailsPayment from "./InquiryDetailsPayment";
import InquiryDetailsReports from "./InquiryDetailsReports";

export default function ClientInquiryDetails() {
  const { inquiry_id } = useParams();
  const dispatch = useDispatch();
  const inquiryDetails = useSelector(
    (state) => state.clientArilInquiry.details
  );

  useEffect(() => {
    dispatch(openInquiryByClient({ inquiry_id: inquiry_id }));
    dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "ARIL Inquiries",
          subtitle: "Inquiry Details",
          icon: "icon-info",
          pages: [
            {
              url: routes.CLIENT_DASHBOARD_ARIL_INQUIRY,
              name: "Manage Inquiries",
            },
            {
              url: `${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/${inquiry_id}`,
              name: "Inquiry Details",
            },
          ],
        },
      })
    );
  }, [dispatch, inquiry_id]);

  const handleUpdateProcessStatus = (e, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.ARIL_INQUIRY}/${inquiry_id}/`, {
        status: status,
      })
      .then((res) => {
        let message = "";
        if (status === aril_constants.PROCESS_STATUS_DROPDOWN[1].value) {
          message = "Successfully cancelled the Inquiry!";
        } else {
          message = "Successfully restored the Inquiry!";
        }
        dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: message,
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const getProcessStatusConstantLabel = (value) => {
    return aril_constants.PROCESS_STATUS_DROPDOWN.find(
      (data) => data.value === value
    ).label;
  };

  const renderProcessStatusLabel = (processStatus) => {
    if (processStatus === aril_constants.PROCESS_STATUS_DROPDOWN[1].value) {
      return (
        <label className="label label-lg label-danger">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else if (
      processStatus === aril_constants.PROCESS_STATUS_DROPDOWN[2].value
    ) {
      return (
        <label className="label label-md label-warning">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    } else {
      return (
        <label className="label label-md label-primary">
          {getProcessStatusConstantLabel(processStatus)}
        </label>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card z-depth-0 mt-4">
          <div className="card-header">
            <h5>Details</h5>
            <Link
              to={`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}`}
              className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
            >
              <i className="fa fa-arrow-left"></i> Back to List
            </Link>
            <Link
              to={`${routes.CLIENT_DASHBOARD_ARIL_INQUIRY}/${inquiry_id}/edit`}
              className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
            >
              <i className="fa fa-pencil-square-o"></i> Edit
            </Link>
            {inquiryDetails.processStatus ===
            aril_constants.PROCESS_STATUS_DROPDOWN[1].value ? (
              <a
                href="/#"
                className="btn btn-success btn-outline-success float-right pt-2 pb-2 ml-2"
                onClick={(e) =>
                  handleUpdateProcessStatus(
                    e,
                    aril_constants.PROCESS_STATUS_DROPDOWN[3].value
                  )
                }
              >
                <i className="fa fa-refresh"></i> Restore
              </a>
            ) : (
              <a
                href="/#"
                className="btn btn-danger btn-outline-danger float-right pt-2 pb-2 ml-2"
                onClick={(e) =>
                  handleUpdateProcessStatus(
                    e,
                    aril_constants.PROCESS_STATUS_DROPDOWN[1].value
                  )
                }
              >
                <i className="fa fa-times-circle"></i> Cancel Inquiry
              </a>
            )}
          </div>
          <div className="card-block">
            <div className="row mt-4">
              <div className="col-md-4">
                <p>JO Number:</p>
                <p>Client Name:</p>
                <p>Affiliation (Company / School):</p>
                <p>Course / Degree (if student):</p>
                <p>Position:</p>
                <p>Email:</p>
                <p>Contact No:</p>
                <p>Service Type:</p>
                <p>Status</p>
                <p>Inquiry:</p>
                <p>Date Encoded:</p>
                <p>Last Updated:</p>
              </div>
              <div className="col-md-8">
                <p>
                  {inquiryDetails.JONumber}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.requestor}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.company}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.course}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.position}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.email}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.contactNo}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.serviceType}
                  {"\u00A0"}
                </p>
                <p>
                  {renderProcessStatusLabel(inquiryDetails.processStatus)}
                  {"\u00A0"}
                </p>
                <p>
                  {inquiryDetails.description}
                  {"\u00A0"}
                </p>
                <p>
                  {moment(inquiryDetails.createdAt).format(
                    "MM/DD/YYYY hh:mm A"
                  )}
                  {"\u00A0"}
                </p>
                <p>
                  {moment(inquiryDetails.updatedAt).format(
                    "MM/DD/YYYY hh:mm A"
                  )}
                  {"\u00A0"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InquiryDetailsRequirements
        files={inquiryDetails.files}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsQuoteAndLSR
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsPayment
        proofOfPayments={inquiryDetails.proofOfPayments}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsReports
        reports={inquiryDetails.reports}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsTransactionLogs
        transactionLogs={inquiryDetails.transactionLogs}
      />
    </div>
  );
}
