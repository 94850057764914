export default function TaskCountCard({
  taskInQueueCount,
  taskOngoingCount,
  taskCompletedTodayCount,
  taskCompletedThisMonthCount,
}) {
  const cardItems = [
    {
      color: "red",
      description: "In Queue Tasks",
      count: taskInQueueCount,
    },
    {
      color: "yellow",
      description: "Ongoing Tasks",
      count: taskOngoingCount,
    },
    {
      color: "blue",
      description: "Completed Tasks Today",
      count: taskCompletedTodayCount,
    },
    {
      color: "green",
      description: "Completed Tasks This Month",
      count: taskCompletedThisMonthCount,
    },
  ];
  return (
    <div class="card product-progress-card">
      <div class="card-block">
        <div class="row pp-main">
          {cardItems.map(({ color, description, count }) => {
            return (
              <TaskCountCardItem
                color={color}
                count={count}
                description={description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function TaskCountCardItem({ color, count, description }) {
  return (
    <div class="col-xl-3 col-md-6">
      <div class="pp-cont">
        <div class="row align-items-center m-b-20">
          <div class="col-auto">
            <i class="fas fa-tag f-24 text-mute"></i>
          </div>
          <div class="col text-right">
            <h2 class={`m-b-0 text-c-${color}`}>{count}</h2>
          </div>
        </div>
        <div class="row align-items-center m-b-15">
          <div class="col-auto">
            <p class="m-b-0">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
