import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { api_urls, routes, labservices_constants } from "../../../../constants";
import {
  openInquiryByAdmin,
  retrieveInquiry,
  createTransactionLog,
  updateProcessStatus,
} from "../../../../store/employee/lab_services/inquiryActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";
import InquiryDetailsRequirements from "./InquiryDetailsRequirements";
import InquiryDetailsTransactionLogs from "./InquiryDetailsTransactionLogs";
import InquiryDetailsGenerateClientAccount from "./InquiryDetailsGenerateClientAccount";
import InquiryDetailsQuoteAndLSR from "./InquiryDetailsQuoteAndLSR";
import InquiryDetailsPayment from "./InquiryDetailsPayment";
import InquiryDetailsReports from "./InquiryDetailsReports";
import InquiryDetailsBasicDetails from "./InquiryDetailsBasicDetails";
import InquiryDetailsFeedbacks from "./InquiryDetailsFeedbacks";

export default function InquiryDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const history = useHistory();
  const { inquiry_id } = useParams();
  const dispatch = useDispatch();
  const inquiryDetails = useSelector(
    (state) => state.labServicesInquiry.details
  );

  const redirectBackToInquiryList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY),
    [history]
  );

  useEffect(() => {
    dispatch(openInquiryByAdmin({ inquiry_id: inquiry_id }));
    dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Lab Services Inquiries",
          subtitle: "Inquiry Details",
          icon: "icon-info",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY,
              name: "Manage Inquiries",
            },
            {
              url:
                routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_DETAILS +
                inquiry_id,
              name: "Inquiry Details",
            },
          ],
        },
      })
    );
  }, [dispatch, inquiry_id]);

  const handleUpdateProcessStatus = (e, status) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .patch(api_urls.LABSERVICES_INQUIRY_PATCH + inquiry_id + "/", {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the process status!",
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleDeleteInquirySubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(api_urls.LABSERVICES_INQUIRY_DELETE + inquiry_id + "/")
      .then((res) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Inquiry Successfully Deleted!",
            },
          })
        );
        redirectBackToInquiryList();
        SetPageLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handleGenerateUser = (e) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(
        api_urls.LABSERVICES_INQUIRY_GENERATE_CLIENT_ACCOUNT +
          inquiry_id +
          "/generate_client_account/",
        {
          first_name: inquiryDetails.requestor,
          last_name: inquiryDetails.requestor,
          email: inquiryDetails.email,
        }
      )
      .then((res) => {
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Client Account Successfully Generated!",
            },
          })
        );
        updateProcessStatus({
          inquiry_id,
          status: labservices_constants.PROCESS_STATUS_DROPDOWN[4].value,
        });
        createTransactionLog({ inquiry_id, action: "Generated inquiry user" });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message: "Email address already exist in database!",
              },
            })
          );
        }
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <>
      <DivLoader type="Circles" loading={pageLoader} />
      <div className="col-sm-12">
        <div className="row mb-3 pl-2 pr-2">
          <div className="col-md-6 pt-1"></div>
          <div className="col-md-6">
            <Link
              to={routes.EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY}
              className="btn btn-primary float-right ml-2"
            >
              <i className="fa fa-arrow-left"></i> Back to List
            </Link>

            <div class="dropdown-primary dropdown open float-right ml-2">
              <button
                class="btn btn-primary dropdown-toggle waves-effect waves-light "
                type="button"
                id="dropdown-2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                Set as
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdown-2"
                data-dropdown-in="fadeIn"
                data-dropdown-out="fadeOut"
              >
                {labservices_constants.PROCESS_STATUS_DROPDOWN.slice(1).map(
                  (data) => (
                    <a
                      class="dropdown-item waves-light waves-effect"
                      href="/#"
                      onClick={(e) => handleUpdateProcessStatus(e, data.value)}
                    >
                      {data.label}
                    </a>
                  )
                )}
              </div>
            </div>
            <button
              className="btn btn-md btn-danger btn-danger float-right ml-2"
              onClick={() => SetIsOpenDeleteModal(true)}
            >
              <i className="fa fa-trash"></i> Delete
            </button>
          </div>
        </div>
      </div>

      {inquiryDetails.isDeclined && (
        <div className="col-md-12">
          <div class="alert alert-warning border-warning">
            <p>
              <strong>
                This inquiry has beed declined! <br />
                Reason: {inquiryDetails.declineComments}{" "}
              </strong>
            </p>
          </div>
        </div>
      )}

      <InquiryDetailsBasicDetails
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsGenerateClientAccount
        inquiryDetails={inquiryDetails}
        handleGenerateUser={handleGenerateUser}
      />

      <InquiryDetailsRequirements
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsQuoteAndLSR
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsPayment
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsReports
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsFeedbacks feedbacks={inquiryDetails.feedbacks} />

      <InquiryDetailsTransactionLogs
        transactionLogs={inquiryDetails.transactionLogs}
      />

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteInquirySubmit}
      />
    </>
  );
}
