import { useDispatch } from "react-redux";
import axios from "axios";
import { aril_constants, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/client/dashboardReducer";
import {
  retrieveInquiry,
  createTransactionLog,
} from "../../../../store/client/aril/inquiryActions";

export default function InquiryDetailsReports({ reports, inquiryId }) {
  const dispatch = useDispatch();

  const handleUpdateReportStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.ARIL_INQUIRY_REPORTS}/${id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the report status!",
            },
          })
        );
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Updated Inquiry Report Status",
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleOpenFile = (e, id, filename) => {
    e.preventDefault();
    axios
      .get(`${api_urls.ARIL_INQUIRY_REPORTS}/${id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  return (
    <div
      className="col-md-12"
      // style={{ pointerEvents: "none", opacity: "0.5" }}
    >
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Reports</h5>
        </div>
        <div className="card-block">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Filename</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((data) => {
                let statusLabel = "";
                let statusClass = "";
                let acknowledgeButtonLabel = "btn-disabled disabled";
                switch (data.status) {
                  case aril_constants.REPORT_STATUS[0].value:
                    statusClass = "label-warning";
                    statusLabel = aril_constants.REPORT_STATUS[0].label;
                    break;
                  case aril_constants.REPORT_STATUS[1].value:
                    statusClass = "label-warning";
                    statusLabel = aril_constants.REPORT_STATUS[1].label;
                    break;
                  case aril_constants.REPORT_STATUS[2].value:
                    statusClass = "label-primary";
                    statusLabel = aril_constants.REPORT_STATUS[2].label;
                    acknowledgeButtonLabel = "btn-primary";
                    break;
                  case aril_constants.REPORT_STATUS[3].value:
                    statusClass = "label-success";
                    statusLabel = aril_constants.REPORT_STATUS[3].label;
                    acknowledgeButtonLabel = "btn-primary";
                    break;
                  default:
                    statusClass = "";
                }
                return (
                  <tr>
                    <td>
                      {data.status === aril_constants.REPORT_STATUS[2].value ||
                      data.status === aril_constants.REPORT_STATUS[3].value ? (
                        <a
                          href="/#"
                          onClick={(e) =>
                            handleOpenFile(e, data.id, data.orig_filename)
                          }
                        >
                          <ins className="text-info">{data.orig_filename}</ins>
                        </a>
                      ) : (
                        <>{data.orig_filename}</>
                      )}
                    </td>
                    <td>{data.description}</td>
                    <td>
                      <label className={`label ${statusClass}`}>
                        {statusLabel}
                      </label>
                    </td>
                    <td className="align-middle">
                      <button
                        onClick={(e) =>
                          handleUpdateReportStatus(
                            e,
                            data.id,
                            aril_constants.REPORT_STATUS[3].value
                          )
                        }
                        className={`btn btn-sm pb-2 pt-2 ${acknowledgeButtonLabel}`}
                        type="button"
                      >
                        Acknowledge
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
