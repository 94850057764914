import "./MapDiv.css";
export default function MapDiv() {
  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5>Location</h5>
        </div>
        <div className="card-block">
          <div class="gmap_canvas">
            <iframe
              title="herbanext_location"
              width="550"
              height="335"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62747.63724146654!2d122.84412362931309!3d10.600479019698104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33aecf6926a1486b%3A0x5c43b6e1055dfe00!2sHerbanext%20Laboratories%2C%20Inc.!5e0!3m2!1sen!2sph!4v1658543357244!5m2!1sen!2sph"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
