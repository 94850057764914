import axios from "axios";
import { api_urls, user_constants } from "../../constants.js";
import {
  fetch,
  paginate,
  filter,
  resetList,
  setDetails,
  setFormData,
  setFormErrors,
  setFormDataUserRoutes,
  setFormDataUserSubroutes,
  setRouteOptions,
  setSubrouteOptions,
  setCheckedRecords,
  setIsCheckedAllRecords,
} from "./userReducer";

export const fetchUser = ({ pagination, filters }) => {
  return (dispatch) => {
    dispatch(setCheckedRecords({ checkedRecords: [] }));
    axios
      .get(api_urls.USER_FETCH, {
        params: {
          q: filters.query,
          page_size: pagination.pageSize,
          page: pagination.pageCurrent,
          cat: filters.category,
          sort_field: filters.sortField,
          sort_order: filters.sortOrder,
        },
      })
      .then((res) => {
        const users = res.data.results;
        const newCheckedRecords = [];
        users.forEach((data) =>
          newCheckedRecords.push({ id: data.id, status: false })
        );
        dispatch(
          fetch({
            list: {
              records: users,
              totalCount: res.data.count,
              limit: Math.ceil(res.data.count / pagination.pageSize),
            },
          })
        );
        dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
      });
  };
};

export const retrieveUser = ({ user_id }) => {
  return (dispatch) => {
    if (user_id !== "") {
      axios.get(api_urls.USER_FIND_BY_ID + user_id).then((response) => {
        const userDetails = response.data.userDetails_user;
        const userRoute = response.data.userRoute_user;
        let userRouteNew = [];
        let userSubrouteNew = [];
        userRoute.forEach((dataUserRoute) => {
          const userSubroute = dataUserRoute.userSubroute_userRoute;
          // set subroute options
          axios.get("api/route/" + dataUserRoute.route.id).then((response) => {
            const subroutes = response.data.subroute_route;
            const subroutesOptionsNew = [];
            if (subroutes.length > 0) {
              subroutes.forEach((data_subroute) => {
                subroutesOptionsNew.push({
                  value: data_subroute.id,
                  label: data_subroute.name,
                });
              });
              dispatch(setSubrouteOptions({ subroutes: subroutesOptionsNew }));
            }
          });
          // push user route
          userRouteNew.push({
            value: dataUserRoute.route.id,
            label: dataUserRoute.route.name,
          });
          // push user subroute
          userSubroute.forEach((dataUserSubroute) => {
            userSubrouteNew.push({
              value: dataUserSubroute.subroute.id,
              label: dataUserSubroute.subroute.name,
            });
          });
        });
        dispatch(
          setDetails({
            username: response.data.username,
            firstname: response.data.first_name,
            lastname: response.data.last_name,
            email: response.data.email,
            category: user_constants.CATEGORY_DROPDOWN.find(
              (data) => data.value === userDetails[0]?.category
            )?.label,
            isActive: response.data.is_active,
            dateJoined: response.data.date_joined,
            lastLogin: response.data.last_login,
            userRoutes: userRouteNew,
            userSubroutes: userSubrouteNew,
            createdAt: userDetails[0]?.created_at,
            updatedAt: userDetails[0]?.updated_at,
            createdBy: userDetails[0]?.created_by_id,
            updatedBy: userDetails[0]?.updated_by_id,
            ipCreated: userDetails[0]?.ip_created,
            ipUpdated: userDetails[0]?.ip_updated,
          })
        );
        dispatch(
          setFormData({
            username: response.data.username,
            firstname: response.data.first_name,
            lastname: response.data.last_name,
            email: response.data.email,
            category: user_constants.CATEGORY_DROPDOWN.find(
              (data) => data.value === userDetails[0]?.category
            ),
            password: "",
            passwordConfirm: "",
          })
        );
        dispatch(setFormDataUserRoutes(userRouteNew));
        dispatch(setFormDataUserSubroutes(userSubrouteNew));
      });
    }
  };
};

export const filterRecords = ({ category, filters }) => {
  return (dispatch) => {
    dispatch(
      filter({
        listFilters: {
          query: filters.query,
          category: category,
          sortField: filters.sortField,
          sortOrder: filters.sortOrder,
        },
      })
    );
  };
};

export const sortRecords = ({ sortField, sortOrder, filters }) => {
  return (dispatch) => {
    dispatch(
      filter({
        listFilters: {
          query: filters.query,
          filterOnlineStatus: filters.filterOnlineStatus,
          filterSUStatus: filters.filterSUStatus,
          sortField: sortField,
          sortOrder: sortOrder,
        },
      })
    );
  };
};

export const checkAllRecords = ({ checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = [];
    checkedRecords.map((data) =>
      newCheckedRecords.push({ id: data.id, status: checked })
    );
    dispatch(setIsCheckedAllRecords(checked));
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const checkSpecificRecord = ({ id, checked, checkedRecords }) => {
  return (dispatch) => {
    const newCheckedRecords = JSON.parse(JSON.stringify(checkedRecords));
    let objIndex = newCheckedRecords.findIndex((data) => data.id === id);
    newCheckedRecords[objIndex].status = checked;
    dispatch(setCheckedRecords({ checkedRecords: newCheckedRecords }));
  };
};

export const paginationClick = ({ willCurrent, limit, size }) => {
  return (dispatch) => {
    if (willCurrent > 0 && willCurrent <= limit) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: willCurrent - 1,
            pageCurrent: willCurrent,
            pageNext: willCurrent + 1,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const pageSizeClick = (size) => {
  return (dispatch) => {
    if (size > 0) {
      dispatch(
        paginate({
          listPagination: {
            pagePrev: 0,
            pageCurrent: 1,
            pageNext: 2,
            pageSize: size,
          },
        })
      );
    }
  };
};

export const refreshList = () => {
  return (dispatch) => {
    dispatch(resetList());
  };
};

export const populateRouteOptions = () => {
  return (dispatch) => {
    axios.get(api_urls.ROUTE_GET_ALL).then((response) => {
      let routes = response.data;
      let routesOptions = [];
      if (routes.length > 0) {
        routes.forEach((data) => {
          routesOptions.push({ value: data.id, label: data.name });
        });
      }
      dispatch(setRouteOptions({ routes: routesOptions }));
    });
  };
};

export const populateSubrouteOptions = ({
  subrouteOptionsCopy,
  differentiate,
}) => {
  return (dispatch) => {
    axios
      .get(api_urls.ROUTE_FIND_BY_ID + differentiate.toString())
      .then((response) => {
        const subroutes = response.data.subroute_route;
        const subrouteOptionsNew = [...subrouteOptionsCopy];
        if (subroutes.length > 0) {
          subroutes.forEach((data) => {
            subrouteOptionsNew.push({ value: data.id, label: data.name });
          });
        }
        dispatch(setSubrouteOptions({ subroutes: subrouteOptionsNew }));
      });
  };
};

export const resetForm = () => {
  return (dispatch) => {
    dispatch(
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        category: { value: "", label: "Select" },
        username: "",
        password: "",
        passwordConfirm: "",
      })
    );
    dispatch(setFormDataUserRoutes([]));
    dispatch(setFormDataUserSubroutes([]));
    dispatch(
      setFormErrors({
        firstname: "",
        lastname: "",
        email: "",
        category: "",
        username: "",
        password: "",
        userRoutes: "",
        userSubroutes: "",
        nonFieldErrors: "",
      })
    );
  };
};

export const userRouteMultiSelectChange = ({
  values,
  formDataUserRoutes,
  formDataUserSubroutes,
  subrouteOptions,
}) => {
  return (dispatch) => {
    const userRoutesCopy = [...formDataUserRoutes];
    const existingUserRoutes = [];
    const passedUserRoutes = [];
    let differentiate = [];
    userRoutesCopy.forEach((data) => existingUserRoutes.push(data.value));
    values.forEach((data) => passedUserRoutes.push(data.value));
    // Add to subrouteOptions if added
    if (existingUserRoutes.length < passedUserRoutes.length) {
      differentiate = passedUserRoutes.filter(
        (x) => !existingUserRoutes.includes(x)
      );
      if (differentiate.length === 1) {
        const subrouteOptionsCopy = [...subrouteOptions];
        dispatch(
          populateSubrouteOptions({
            subrouteOptionsCopy: subrouteOptionsCopy,
            differentiate: differentiate,
          })
        );
      }
    }
    // Remove item from subrouteOptions and formdata.subroutes if removed
    if (existingUserRoutes.length > passedUserRoutes.length) {
      differentiate = existingUserRoutes.filter(
        (x) => !passedUserRoutes.includes(x)
      );
      if (differentiate.length === 1) {
        axios
          .get(api_urls.ROUTE_FIND_BY_ID + differentiate.toString())
          .then((response) => {
            const subroutes = response.data.subroute_route;
            const subrouteOptionsCopy = [...subrouteOptions];
            const userSubroutesCopy = [...formDataUserSubroutes];
            if (subroutes.length > 0) {
              subroutes.forEach((data) => {
                // remove from subrouteOptions
                for (let i = 0; i < subrouteOptionsCopy.length; i++) {
                  if (subrouteOptionsCopy[i].value === data.id) {
                    subrouteOptionsCopy.splice(i, 1);
                  }
                }
                for (var i = 0; i < userSubroutesCopy.length; i++) {
                  if (userSubroutesCopy[i].value === data.id) {
                    userSubroutesCopy.splice(i, 1);
                  }
                }
              });
            }

            dispatch(setSubrouteOptions({ subroutes: subrouteOptionsCopy }));
            dispatch(setFormDataUserSubroutes(userSubroutesCopy));
          });
      }
    }
    dispatch(setFormDataUserRoutes(values));
  };
};

export const userSubrouteMultiSelectChange = (values) => {
  return (dispatch) => {
    dispatch(setFormDataUserSubroutes([...values]));
  };
};
