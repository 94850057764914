import { herbanext_contact_info } from "../../../constants.js";
export default function ContactUsDiv() {
  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5>Contact us</h5>
        </div>
        <div className="card-block">
          <ul>
            <li>
              <dl class="dl-horizontal row">
                <dt class="col-sm-3">Address: </dt>
                <dd class="col-sm-9">{herbanext_contact_info.ADDRESS}</dd>
                <dt class="col-sm-3">Tel No.:</dt>
                <dd class="col-sm-9">{herbanext_contact_info.TEL_NO}</dd>
                <dt class="col-sm-3">Email:</dt>
                <dd class="col-sm-9">{herbanext_contact_info.EMAIL}</dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
