import axios from "axios";
import { api_urls } from "../../../constants.js";
import {
  setLabServicesTotalRecords,
  setLabServicesUpdatedRecords,
  // setArilTotalRecords,
  // setArilUpdatedRecords,
} from "./homeReducer";

export const countAllLabServicesByUserInquiry = () => {
  return (dispatch) => {
    axios.get(api_urls.LABSERVICES_INQUIRY_COUNT_ALL_BY_USER).then((res) => {
      dispatch(setLabServicesTotalRecords(res.data.count));
    });
  };
};

export const countUpdatedLabServicesByUserInquiry = () => {
  return (dispatch) => {
    axios
      .get(api_urls.LABSERVICES_INQUIRY_COUNT_UPDATED_BY_USER)
      .then((res) => {
        dispatch(setLabServicesUpdatedRecords(res.data.count));
      });
  };
};

// export const countAllARILInquiry = () => {
//   return (dispatch) => {
//     axios.get(`${api_urls.ARIL_INQUIRY}/count_all`).then((res) => {
//       dispatch(setArilTotalRecords(res.data.count));
//     });
//   };
// };

// export const countUpdatedARILInquiry = () => {
//   return (dispatch) => {
//     axios.get(`${api_urls.ARIL_INQUIRY}/count_updated`).then((res) => {
//       dispatch(setArilUpdatedRecords(res.data.count));
//     });
//   };
// };
