import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

export default function SideNavMenu(props) {
  const location = useLocation();

  return (
    <li
      className={location.pathname === props.url ? "active" : ""}
      key={props.id}
    >
      <NavLink to={props.url} className="waves-effect waves-dark">
        <span className="pcoded-micon">
          <i className={props.icon}></i>
        </span>
        <span className="pcoded-mtext">{props.nav_name}</span>
      </NavLink>
    </li>
  );
}
