export default function InquiryDetailsFeedbacks({ feedbacks }) {
  return (
    <div className="col-md-12">
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Client Feedback</h5>
        </div>
        <div className="card-block">
          <ul class="basic-list">
            {feedbacks.map((feedback, key) => {
              return (
                <li class="">
                  <h5>
                    {key + 1}. {feedback.question}
                  </h5>
                  <p> - {feedback.answer}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
