import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: {
    records: [],
    isFetched: false,
  },
};

const taskCalendarSlice = createSlice({
  name: "taskCalendar",
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload;
    },
  },
});

export const { setEvents } = taskCalendarSlice.actions;
export default taskCalendarSlice.reducer;
