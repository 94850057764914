import { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { routes, api_urls, tasks_constants } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormErrors,
  setHighlightedRecord,
  setFormDataMachines,
  setFormDataPersonnel,
} from "../../../../store/employee/tasks/taskReducer";
import { retrieveTask } from "../../../../store/employee/tasks/taskActions";
import DivLoader from "../../../utils/DivLoaderComp";
import {
  InputTextInline,
  SelectInputInline,
  SelectMultiInline,
} from "../../../utils/Forms/InlineInputs";

export default function TaskEdit() {
  const [pageLoader, SetPageLoader] = useState(false);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.task.formData);
  const formDataMachines = useSelector((state) => state.task.formDataMachines);
  const formDataPersonnel = useSelector(
    (state) => state.task.formDataPersonnel
  );
  const formErrors = useSelector((state) => state.task.formErrors);
  const machineOptions = useSelector((state) => state.machine.options);
  const personnelOptions = useSelector((state) => state.personnel.options);
  const taskTypeOptions = useSelector((state) => state.taskType.options);
  const history = useHistory();
  const { task_id } = useParams();

  useEffect(() => {
    dispatch(retrieveTask({ task_id: task_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Tasks",
          subtitle: "Task Edit",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD,
              name: "Tasks Board",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}/${task_id}`,
              name: "Task Details",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}/${task_id}/edit`,
              name: "Task Edit",
            },
          ],
        },
      })
    );
  }, [dispatch, task_id]);

  const redirectBackToTaskBoard = useCallback(() => {
    history.push(routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD);
  }, [history]);

  const handleUpdate = (e, btl) => {
    e.preventDefault();
    SetPageLoader(true);
    const machines = formDataMachines.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
    const personnel = formDataPersonnel.reduce((acc, val) => {
      acc.push(val.value);
      return acc;
    }, []);
    axios
      .put(`${api_urls.TASKS_TASK}/${task_id}/`, {
        task_type: formData.taskType?.value,
        description: formData.description,
        machine_ids: machines,
        personnel_ids: personnel,
        status: formData.status.value,
      })
      .then((response) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Task Successfully Updated!",
            },
          })
        );
        dispatch(setHighlightedRecord(response.data.id));
        if (btl === 1) {
          redirectBackToTaskBoard();
        }
        SetPageLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              taskType: field_errors.task_type?.toString(),
              description: field_errors.description?.toString(),
              machine: field_errors.machine_ids?.toString(),
              personnel_ids: field_errors.personnel_ids?.toString(),
              status: field_errors.status?.toString(),
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />
        <div className="card-header">
          <h5>Edit Task </h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}/${task_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to Board
          </Link>
        </div>

        <div className="card-block">
          <div className="col-md-12">
            <SelectInputInline
              type="text"
              label="Task Type:"
              isDisabled={false}
              options={taskTypeOptions}
              errorField={formErrors.taskType}
              value={formData.taskType}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, taskType: value }))
              }
            />

            <InputTextInline
              type="text"
              label="Description:"
              placeholder="Description"
              errorField={formErrors.description}
              value={formData.description}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, description: e.target.value })
                )
              }
            />

            <SelectMultiInline
              label="Equipments:"
              name="machines"
              value={formDataMachines}
              errorField={formErrors.machines}
              options={machineOptions}
              onChange={(values) => dispatch(setFormDataMachines(values))}
              closeMenuOnSelect={false}
              defaultMenuIsOpen={false}
            />

            <SelectMultiInline
              label="Personnel:"
              name="personnel"
              value={formDataPersonnel}
              errorField={formErrors.personnel}
              options={personnelOptions}
              onChange={(values) => dispatch(setFormDataPersonnel(values))}
              closeMenuOnSelect={false}
              defaultMenuIsOpen={false}
            />

            <SelectInputInline
              type="text"
              label="Status:"
              isDisabled={false}
              options={tasks_constants.TASK_STATUS_DROPDOWN}
              errorField={formErrors.status}
              value={formData.status}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, status: value }))
              }
            />
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, 1)}
              >
                Save and back to board
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
