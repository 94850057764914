import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { api_urls, routes, CLIENT } from "../../constants";
import { checkClientAuthToken } from "../../store/client/authActions";
import TopNav from "./common/TopNav.js";
import DivLoader from "../utils/DivLoaderComp";
import ToastNotification from "../utils/ToastNotification";
import { Link } from "react-router-dom";

const auth_user = JSON.parse(window.localStorage.getItem("auth_user"));
let isInitialLoad = true;

export default function ClientDashboardLogin() {
  const [formData, SetFormData] = useState({ username: "", password: "" });
  const [formErrors, SetFormErrors] = useState({});
  const [toastNotification, SetToastNotification] = useState(false);
  const [pageLoader, SetPageLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInitialLoad && auth_user) {
      dispatch(checkClientAuthToken());
      isInitialLoad = false;
    }
  }, [dispatch]);

  useEffect(() => {
    if (auth_user) {
      window.location.replace(CLIENT + "#/client/dashboard/home");
      window.location.reload();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    SetPageLoader(true);
    window.localStorage.clear();
    axios
      .post(api_urls.USER_LOGIN_POST, formData)
      .then((res) => {
        async function storeUser() {
          const auth_user = {};
          auth_user.token = res.data.auth_token;
          window.localStorage.setItem("auth_user", JSON.stringify(auth_user));
        }
        storeUser().then((res) => {
          SetPageLoader(false);
          window.location.reload();
        });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          SetFormErrors({
            username: err.response.data.username?.toString(),
            password: err.response.data.password?.toString(),
            non_field_errors: err.response.data.non_field_errors?.toString(),
          });
        }
        SetPageLoader(false);
      });
  };
  return (
    <div className="lab-services-container">
      <DivLoader type="Circles" loading={pageLoader} />
      <ToastNotification
        vertical="top"
        horizontal="center"
        open={toastNotification}
        duration={6000}
        closeHandler={() => SetToastNotification(false)}
        type="error"
        message="There's an error trying to send data to the server!"
      />
      <TopNav />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div
            className="page-wrapper"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "5em",
              marginBottom: "40em",
            }}
          >
            <div className="page-body">
              <div style={{ width: "32em", height: "32em" }}>
                <form onSubmit={handleSubmit}>
                  <div className="auth-box card">
                    <div className="card-block">
                      <div className="row m-b-20">
                        <div className="col-md-12">
                          <h3 className="text-center txt-primary">
                            Client Sign in
                          </h3>
                        </div>
                      </div>

                      <p className="text-muted text-center p-b-5">
                        Sign in with your regular account
                      </p>

                      <div
                        className={
                          formErrors.username || formErrors.non_field_errors
                            ? "form-group form-danger has-danger mb-0"
                            : "form-group form-primary mb-0"
                        }
                      >
                        <label className="col-form-label">
                          Username / Email:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.username}
                          onChange={(e) =>
                            SetFormData({
                              ...formData,
                              username: e.target.value,
                            })
                          }
                        />
                        <div className="col-form-label">
                          {formErrors.username ? formErrors.username : ""}
                          {formErrors.non_field_errors
                            ? formErrors.non_field_errors
                            : ""}
                        </div>
                      </div>

                      <div
                        className={
                          formErrors.password
                            ? "form-group form-danger has-danger"
                            : "form-group form-primary"
                        }
                      >
                        <label className="col-form-label">Password:</label>
                        <input
                          type="password"
                          className="form-control"
                          value={formData.password}
                          onChange={(e) =>
                            SetFormData({
                              ...formData,
                              password: e.target.value,
                            })
                          }
                        />
                        <div className="col-form-label">
                          {formErrors.password ? formErrors.password : ""}
                        </div>
                      </div>

                      <div className="row m-t-10">
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-round btn-primary btn-md btn-block waves-effect text-center m-b-10"
                          >
                            LOGIN
                          </button>
                        </div>
                      </div>

                      <div className="row m-t-10">
                        <div className="col-md-12 text-center">
                          <Link to={routes.CLIENT_DASHBOARD_FORGOT_PASSWORD}>
                            <u>FORGOT PASSWORD</u>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
