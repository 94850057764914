export default function InquiryCountCard({ color, title, body, description }) {
  return (
    <div class={`card prod-p-card card-${color}`}>
      <div class="card-body">
        <div class="row align-items-center m-b-30">
          <div class="col">
            <h6 class="m-b-5 text-white">{title}</h6>
            <h3 class="m-b-0 f-w-700 text-white">{body}</h3>
          </div>
          <div class="col-auto">
            <i class={`fas fa-tag text-c-${color} f-18`}></i>
          </div>
        </div>
        <p class="m-b-0 text-white">{description}</p>
      </div>
    </div>
  );
}
