import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { routes, api_urls, user_constants } from "../../../../constants";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormErrors,
  setHighlightedRecord,
} from "../../../../store/employee/userReducer";
import {
  populateRouteOptions,
  resetForm,
  userRouteMultiSelectChange,
  userSubrouteMultiSelectChange,
} from "../../../../store/employee/userActions";
import {
  InputTextInline,
  SelectMultiInline,
  SelectInputInline,
} from "../../../utils/Forms/InlineInputs";
import DivLoader from "../../../utils/DivLoaderComp";

let isInitialLoad = true;

export default function UserCreate() {
  const [pageLoader, SetPageLoader] = useState(false);

  const formData = useSelector((state) => state.user.formData);
  const formDataUserRoutes = useSelector(
    (state) => state.user.formDataUserRoutes
  );
  const formDataUserSubroutes = useSelector(
    (state) => state.user.formDataUserSubroutes
  );
  const formErrors = useSelector((state) => state.user.formErrors);
  const routeOptions = useSelector((state) => state.user.routeOptions);
  const subrouteOptions = useSelector((state) => state.user.subrouteOptions);
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectBackToUserList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_USERS),
    [history]
  );

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "User",
          subtitle: "Create User",
          icon: "icon-user",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_USERS, name: "Manage Users" },
            {
              url: routes.EMPLOYEE_DASHBOARD_USERS_CREATE,
              name: "Create User",
            },
          ],
        },
      })
    );
    if (isInitialLoad) {
      dispatch(populateRouteOptions());
      isInitialLoad = false;
    }
  }, [dispatch]);

  const handleUserRouteMultiSelectChange = (values) => {
    dispatch(
      userRouteMultiSelectChange({
        values: values,
        formDataUserRoutes: formDataUserRoutes,
        formDataUserSubroutes: formDataUserSubroutes,
        subrouteOptions: subrouteOptions,
      })
    );
  };

  const handleUserSubrouteMultiSelectChange = (values) => {
    dispatch(userSubrouteMultiSelectChange(values));
  };

  const handleResetForm = (e) => {
    dispatch(resetForm());
  };

  const handleCreate = (e, isSaveAnother) => {
    e.preventDefault();
    SetPageLoader(true);
    if (formData.password !== formData.passwordConfirm) {
      dispatch(
        setFormErrors({ ...formErrors, password: "Password doesn't match!" })
      );
      SetPageLoader(false);
    } else {
      axios
        .post(api_urls.USER_STORE, {
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.email,
          category: formData.category.value,
          username: formData.username,
          password: formData.password,
          user_routes: formDataUserRoutes,
          user_subroutes: formDataUserSubroutes,
        })
        .then((response) => {
          dispatch(setHighlightedRecord({ id: response.data.id }));
          handleResetForm();
          if (isSaveAnother === 0) {
            redirectBackToUserList();
          }
          SetPageLoader(false);
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "User Successfully Created!",
              },
            })
          );
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let field_errors = error.response.data;
            dispatch(
              setFormErrors({
                firstname: field_errors.first_name?.toString(),
                lastname: field_errors.last_name?.toString(),
                category: field_errors.category?.toString(),
                email: field_errors.email?.toString(),
                username: field_errors.username?.toString(),
                password: field_errors.password?.toString(),
                userRoutes: field_errors.user_routes?.toString(),
                userSubroutes: field_errors.user_subroutes?.toString(),
                nonFieldErrors: field_errors.non_field_errors?.toString(),
              })
            );
          }
          if (error.response.status === 404 || error.response.status === 500) {
            dispatch(
              setToastNotification({
                toastNotification: {
                  isOpen: true,
                  type: "error",
                  message:
                    "There's an error trying to submit data to the server!",
                },
              })
            );
          }
          SetPageLoader(false);
        });
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        <div className="card-header">
          <h5>Create User</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_USERS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-sm-6">
              <h4 className="sub-title">User Details</h4>

              <InputTextInline
                type="text"
                label="Firstname:"
                placeholder="Firstname"
                errorField={formErrors.firstname}
                value={formData.firstname}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, firstname: e.target.value })
                  )
                }
              />

              <InputTextInline
                type="text"
                label="Lastname:"
                placeholder="Lastname"
                errorField={formErrors.lastname}
                value={formData.lastname}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, lastname: e.target.value })
                  )
                }
              />

              <InputTextInline
                type="text"
                label="Email:"
                placeholder="Email"
                errorField={formErrors.email}
                value={formData.email}
                setter={(e) =>
                  dispatch(setFormData({ ...formData, email: e.target.value }))
                }
              />

              <SelectInputInline
                label="Type:"
                name="type"
                value={formData.category}
                errorField={formErrors.category}
                options={user_constants.CATEGORY_DROPDOWN}
                onChange={(value) =>
                  dispatch(setFormData({ ...formData, category: value }))
                }
                closeMenuOnSelect={false}
                defaultMenuIsOpen={false}
              />

              <InputTextInline
                type="text"
                label="Username:"
                placeholder="Username"
                errorField={formErrors.username}
                value={formData.username}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, username: e.target.value })
                  )
                }
              />

              <InputTextInline
                secureTextEntry
                type="password"
                label="Password:"
                placeholder="Password"
                errorField={formErrors.password}
                value={formData.password}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, password: e.target.value })
                  )
                }
              />

              <InputTextInline
                type="password"
                label="Confirm Password:"
                placeholder="Confirm Password"
                errorField={formErrors.passwordConfirm}
                value={formData.passwordConfirm}
                setter={(e) =>
                  dispatch(
                    setFormData({
                      ...formData,
                      passwordConfirm: e.target.value,
                    })
                  )
                }
              />
            </div>

            <div className="col-sm-6">
              <h4 className="sub-title">User Modules and Permissions</h4>

              <SelectMultiInline
                label="Modules:"
                name="user_routes"
                value={formDataUserRoutes}
                errorField={formErrors.userRoutes}
                options={routeOptions}
                onChange={handleUserRouteMultiSelectChange}
                closeMenuOnSelect={false}
                defaultMenuIsOpen={false}
              />

              <SelectMultiInline
                label="Permissions:"
                name="user_subroutes"
                value={formDataUserSubroutes}
                errorField={formErrors.userSubroutes}
                options={subrouteOptions}
                onChange={handleUserSubrouteMultiSelectChange}
                closeMenuOnSelect={false}
                defaultMenuIsOpen={false}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 0)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, 1)}
              >
                Save and add another
              </button>
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleResetForm(e)}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
