export const InquiryFilesButtons = ({
  isEditQuoteComments,
  comments,
  SetIsEditQuoteComments,
  SetComments,
}) => {
  if (!isEditQuoteComments) {
    return !comments ? (
      <button
        className="btn btn-sm btn-primary btn-outline-primary"
        onClick={(e) => SetIsEditQuoteComments(true)}
      >
        Add Comments
      </button>
    ) : (
      <a
        href="/#"
        className="mt-2 ml-3"
        onClick={(e) => {
          e.preventDefault();
          SetComments(comments);
          SetIsEditQuoteComments(true);
        }}
      >
        <i className="fa fa-edit"></i>
      </a>
    );
  }
  return "";
};
