import { useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { routes } from "../../../constants.js";
import { checkClientAuthToken } from "../../../store/client/authActions.js";
import TopNav from "../common/TopNav.js";
import PageHeader from "../common/PageHeader.js";
import TabHeader from "./TabHeader.js";
import TabBody from "./TabBody.js";

const auth_user = JSON.parse(window.localStorage.getItem("auth_user"));
let isInitialLoad = true;

export default function ClientDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const redirectToClientDashboardLogin = useCallback(
    () => history.push(routes.CLIENT_DASHBOARD_LOGIN),
    [history]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isInitialLoad && auth_user) {
      dispatch(checkClientAuthToken());
      isInitialLoad = false;
    }
  }, [dispatch]);

  useEffect(() => {
    if (!auth_user) {
      redirectToClientDashboardLogin();
    }
  }, [redirectToClientDashboardLogin]);

  return (
    <div className="lab-services-container">
      <TopNav />
      <PageHeader
        heading="Client Dashboard"
        subHeading="Manage Requests and Inquiries"
      />
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-md-12 col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <div class="col-lg-12 col-xl-12">
                        <TabHeader />
                        <TabBody />
                      </div>
                    </div>
                    <div className="card-block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
