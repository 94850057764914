import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { setPageHeader } from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormErrors,
  setHighlightedRecord,
} from "../../../../store/employee/userReducer";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import {
  retrieveUser,
  populateRouteOptions,
  resetForm,
  userRouteMultiSelectChange,
  userSubrouteMultiSelectChange,
} from "../../../../store/employee/userActions";
import { api_urls, routes, user_constants } from "../../../../constants";
import DivLoader from "../../../utils/DivLoaderComp";
import {
  InputTextInline,
  SelectInputInline,
  SelectMultiInline,
} from "../../../utils/Forms/InlineInputs";

let isInitialLoad = true;

export default function UserEdit() {
  const [page_loader, SetPageLoader] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.user.formData);
  const formDataUserRoutes = useSelector(
    (state) => state.user.formDataUserRoutes
  );
  const formDataUserSubroutes = useSelector(
    (state) => state.user.formDataUserSubroutes
  );
  const formErrors = useSelector((state) => state.user.formErrors);
  const routeOptions = useSelector((state) => state.user.routeOptions);
  const subrouteOptions = useSelector((state) => state.user.subrouteOptions);
  const { user_id } = useParams();
  const redirectBackToUserList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_USERS),
    [history]
  );

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "User",
          subtitle: "User Details",
          icon: "icon-user",
          pages: [
            { url: routes.EMPLOYEE_DASHBOARD_USERS, name: "Manage Users" },
            {
              url: routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + user_id,
              name: "User Details",
            },
            {
              url: routes.EMPLOYEE_DASHBOARD_USERS_DETAILS + user_id + "/edit",
              name: "User Edit",
            },
          ],
        },
      })
    );
    dispatch(retrieveUser({ user_id: user_id }));
    if (isInitialLoad) {
      dispatch(populateRouteOptions());
      isInitialLoad = false;
    }
  }, [dispatch, user_id]);

  const handleUserRouteMultiSelectChange = (values) => {
    dispatch(
      userRouteMultiSelectChange({
        values: values,
        formDataUserRoutes: formDataUserRoutes,
        formDataUserSubroutes: formDataUserSubroutes,
        subrouteOptions: subrouteOptions,
      })
    );
  };

  const handleUserSubrouteMultiSelectChange = (values) => {
    dispatch(userSubrouteMultiSelectChange(values));
  };

  const handleUpdate = (e, btl) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(api_urls.USER_UPDATE + user_id + "/", {
        first_name: formData.firstname,
        last_name: formData.lastname,
        email: formData.email,
        category: formData.category.value,
        username: formData.username,
        user_routes: formDataUserRoutes,
        user_subroutes: formDataUserSubroutes,
      })
      .then((response) => {
        dispatch(setHighlightedRecord({ id: response.data.id }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "User Successfully Updated!",
            },
          })
        );
        SetPageLoader(false);
        if (btl === 1) {
          redirectBackToUserList();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let field_errors = error.response.data;
          dispatch(
            setFormErrors({
              firstname: field_errors.first_name?.toString(),
              lastname: field_errors.last_name?.toString(),
              email: field_errors.email?.toString(),
              category: field_errors.category?.toString(),
              username: field_errors.username?.toString(),
              userRoutes: field_errors.user_routes?.toString(),
              userSubroutes: field_errors.user_subroutes?.toString(),
            })
          );
        }
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={page_loader} />
        <div className="card-header">
          <h5>Edit User </h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_USERS_DETAILS}${user_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_USERS}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-md-6">
              <h4 className="sub-title">User Details</h4>

              <InputTextInline
                type="text"
                label="Firstname:"
                placeholder="Firstname"
                errorField={formErrors.firstname}
                value={formData.firstname}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, firstname: e.target.value })
                  )
                }
              />

              <InputTextInline
                type="text"
                label="Lastname:"
                placeholder="Lastname"
                errorField={formErrors.lastname}
                value={formData.lastname}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, lastname: e.target.value })
                  )
                }
              />

              <InputTextInline
                type="text"
                label="Email:"
                placeholder="Email"
                errorField={formErrors.email}
                value={formData.email}
                setter={(e) =>
                  dispatch(setFormData({ ...formData, email: e.target.value }))
                }
              />

              <SelectInputInline
                label="Type:"
                name="type"
                value={formData.category}
                errorField={formErrors.category}
                options={user_constants.CATEGORY_DROPDOWN}
                onChange={(value) =>
                  dispatch(setFormData({ ...formData, category: value }))
                }
                closeMenuOnSelect={false}
                defaultMenuIsOpen={false}
              />

              <InputTextInline
                type="text"
                label="Username:"
                placeholder="Username"
                errorField={formErrors.username}
                value={formData.username}
                setter={(e) =>
                  dispatch(
                    setFormData({ ...formData, username: e.target.value })
                  )
                }
              />
            </div>

            <div className="col-sm-6">
              <h4 className="sub-title">User Modules and Module Permissions</h4>

              <SelectMultiInline
                label="Modules:"
                name="user_routes"
                value={formDataUserRoutes}
                errorField={formErrors.user_routes}
                options={routeOptions}
                onChange={handleUserRouteMultiSelectChange}
                closeMenuOnSelect={false}
                defaultMenuIsOpen={false}
              />

              <SelectMultiInline
                label="Permissions:"
                name="user_subroutes"
                value={formDataUserSubroutes}
                errorField={formErrors.user_subroutes}
                options={subrouteOptions}
                onChange={handleUserSubrouteMultiSelectChange}
                closeMenuOnSelect={false}
                defaultMenuIsOpen={false}
              />
            </div>
          </div>

          {/* BUTTON / FOOTERS */}
          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, 0)}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleUpdate(e, 1)}
              >
                Save and back to list
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
