import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { api_urls, aril_constants, routes } from "../../../../constants";
import {
  openInquiryByAdmin,
  retrieveInquiry,
  createTransactionLog,
} from "../../../../store/employee/aril/inquiryActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";
import InquiryDetailsRequirements from "./InquiryDetailsRequirements";
import InquiryDetailsTransactionLogs from "./InquiryDetailsTransactionLogs";
import InquiryDetailsGenerateClientAccount from "./InquiryDetailsGenerateClientAccount";
import InquiryDetailsQuoteAndLSR from "./InquiryDetailsQuoteAndLSR";
import InquiryDetailsPayment from "./InquiryDetailsPayment";
import InquiryDetailsReports from "./InquiryDetailsReports";
import InquiryDetailsBasicDetails from "./InquiryDetailsBasicDetails";

export default function InquiryDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const history = useHistory();
  const { inquiry_id } = useParams();
  const dispatch = useDispatch();
  const inquiryDetails = useSelector((state) => state.arilInquiry.details);

  const redirectBackToInquiryList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY),
    [history]
  );

  useEffect(() => {
    dispatch(openInquiryByAdmin({ inquiry_id: inquiry_id }));
    dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "ARIL Inquiries",
          subtitle: "Inquiry Details",
          icon: "icon-info",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY,
              name: "Manage Inquiries",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}/${inquiry_id}`,
              name: "Inquiry Details",
            },
          ],
        },
      })
    );
  }, [dispatch, inquiry_id]);

  const handleUpdateProcessStatus = (e, status) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .patch(`${api_urls.ARIL_INQUIRY}/${inquiry_id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiry_id }));
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the process status!",
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleDeleteInquirySubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(`${api_urls.ARIL_INQUIRY}/${inquiry_id}/`)
      .then((res) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Inquiry Successfully Deleted!",
            },
          })
        );
        redirectBackToInquiryList();
        SetPageLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handleGenerateUser = (e) => {
    e.preventDefault();
    SetPageLoader(true);
    axios
      .put(`${api_urls.ARIL_INQUIRY}/${inquiry_id}/generate_client_account/`, {
        first_name: inquiryDetails.requestor,
        last_name: inquiryDetails.requestor,
        email: inquiryDetails.email,
      })
      .then((res) => {
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Client Account Successfully Generated!",
            },
          })
        );
        createTransactionLog({
          arilInquiry_id: inquiry_id,
          action: "Generated Inquiry User",
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message: "Email address already exist in database!",
              },
            })
          );
        }
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  return (
    <>
      <DivLoader type="Circles" loading={pageLoader} />
      <div className="col-sm-12">
        <div className="row mb-3 pl-2 pr-2">
          <div className="col-md-6 pt-1"></div>
          <div className="col-md-6">
            <Link
              to={routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}
              className="btn btn-primary float-right ml-2"
            >
              <i className="fa fa-arrow-left"></i> Back to List
            </Link>

            <div class="dropdown-primary dropdown open float-right ml-2">
              <button
                class="btn btn-primary dropdown-toggle waves-effect waves-light "
                type="button"
                id="dropdown-2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                Set as
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdown-2"
                data-dropdown-in="fadeIn"
                data-dropdown-out="fadeOut"
              >
                {aril_constants.PROCESS_STATUS_DROPDOWN.slice(1).map((data) => (
                  <a
                    class="dropdown-item waves-light waves-effect"
                    href="/#"
                    onClick={(e) => handleUpdateProcessStatus(e, data.value)}
                  >
                    {data.label}
                  </a>
                ))}
              </div>
            </div>
            <button
              className="btn btn-md btn-danger btn-danger float-right ml-2"
              onClick={() => SetIsOpenDeleteModal(true)}
            >
              <i className="fa fa-trash"></i> Delete
            </button>
          </div>
        </div>
      </div>

      <InquiryDetailsBasicDetails
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsGenerateClientAccount
        inquiryDetails={inquiryDetails}
        handleGenerateUser={handleGenerateUser}
      />

      <InquiryDetailsRequirements
        files={inquiryDetails.files}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsQuoteAndLSR
        inquiryDetails={inquiryDetails}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsPayment
        proofOfPayments={inquiryDetails.proofOfPayments}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsReports
        reports={inquiryDetails.reports}
        inquiryId={inquiry_id}
      />

      <InquiryDetailsTransactionLogs
        transactionLogs={inquiryDetails.transactionLogs}
      />

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteInquirySubmit}
      />
    </>
  );
}
