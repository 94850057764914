import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import { api_urls, routes, tasks_constants } from "../../../../constants";
import { retrieveSubTask } from "../../../../store/employee/tasks/taskActions";
import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

export default function TaskDetailsSubTaskDetails() {
  const [pageLoader, SetPageLoader] = useState(false);
  const [isOpenDeleteModal, SetIsOpenDeleteModal] = useState(false);

  const history = useHistory();
  const { task_id, subtask_id } = useParams();
  const dispatch = useDispatch();
  const taskSubTaskDetails = useSelector((state) => state.task.detailsSubTask);

  useEffect(() => {
    dispatch(retrieveSubTask({ subtask_id: subtask_id }));
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Tasks",
          subtitle: "Sub Task Details",
          icon: "icon-bookmark",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_TASK,
              name: "Manage Task",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}`,
              name: "Task Details",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}/subtask/${subtask_id}`,
              name: "Sub Task Details",
            },
          ],
        },
      })
    );
  }, [dispatch, task_id, subtask_id]);

  const redirectBackToTaskDetails = useCallback(
    () => history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}`),
    [history, task_id]
  );

  const handleDeleteTaskSubmit = (e) => {
    e.preventDefault();
    SetIsOpenDeleteModal(false);
    SetPageLoader(true);
    axios
      .delete(`${api_urls.TASKS_TASK_SUBTASK}/${subtask_id}/`)
      .then((response) => {
        redirectBackToTaskDetails();
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Sub Task Successfully Deleted!",
            },
          })
        );
      })
      .catch((error) => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handleUpdateStatus = (e, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.TASKS_TASK_SUBTASK}/${subtask_id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveSubTask({ subtask_id: subtask_id }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Sub Task status successfully updated!",
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const renderStatus = (status) => {
    switch (status) {
      case tasks_constants.TASK_STATUS_DROPDOWN[1].value:
        return (
          <label className="label label-md label-warning">
            {tasks_constants.TASK_STATUS_DROPDOWN[1].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[2].value:
        return (
          <label className="label label-md label-primary">
            {tasks_constants.TASK_STATUS_DROPDOWN[2].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[3].value:
        return (
          <label className="label label-md label-success">
            {tasks_constants.TASK_STATUS_DROPDOWN[3].label}
          </label>
        );
      case tasks_constants.TASK_STATUS_DROPDOWN[4].value:
        return (
          <label className="label label-md label-danger">
            {tasks_constants.TASK_STATUS_DROPDOWN[4].label}
          </label>
        );
      default:
        return "";
    }
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader
          type="Circles"
          loading={!taskSubTaskDetails.subTaskId ? true : pageLoader}
        />
        <div className="card-header">
          <h5>Sub Task Details</h5>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}`}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-arrow-left"></i> Back
          </Link>
          <Link
            to={`${routes.EMPLOYEE_DASHBOARD_TASKS_TASK}/${task_id}/subtask/${subtask_id}/edit`}
            className="btn btn-md btn-primary btn-outline-primary float-right pt-2 pb-2 ml-2"
          >
            <i className="fa fa-pencil-square-o"></i> Edit
          </Link>
          <button
            className="btn btn-md btn-danger btn-outline-danger float-right pt-2 pb-2 ml-2"
            onClick={() => SetIsOpenDeleteModal(true)}
          >
            <i className="fa fa-trash"></i> Delete
          </button>
          <div class="dropdown-primary dropdown open float-right">
            <button
              class="btn btn-md btn-primary dropdown-toggle pt-2 pb-2"
              type="button"
              id="dropdown-2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Set as
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdown-2"
              data-dropdown-in="fadeIn"
              data-dropdown-out="fadeOut"
            >
              {tasks_constants.TASK_STATUS_DROPDOWN.slice(1).map((data) => (
                <a
                  class="dropdown-item waves-light waves-effect"
                  href="/#"
                  onClick={(e) => handleUpdateStatus(e, data.value)}
                >
                  {data.label}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="card-block">
          <div className="row">
            <div className="col-md-3">
              <p>Description:</p>
              <p>Equipments:</p>
              <p>Personnel:</p>
              <p>Status:</p>
              <p>Start Date:</p>
              <p>End Date:</p>
              <p>Created at:</p>
              <p>Updated at:</p>
              <p>Created by:</p>
              <p>Updated by:</p>
            </div>
            <div className="col-md-9">
              <p>
                {taskSubTaskDetails.description}
                {"\u00A0"}
              </p>
              <p>
                {taskSubTaskDetails.machines.map((data) => (
                  <label
                    key={data.value}
                    className="label label-md label-primary"
                  >
                    {data.machine.name}
                  </label>
                ))}
                {"\u00A0"}
              </p>
              <p>
                {taskSubTaskDetails.personnel.map((data) => (
                  <label
                    key={data.value}
                    className="label label-md label-primary"
                  >
                    {data.personnel.fullname}
                  </label>
                ))}
                {"\u00A0"}
              </p>
              <p>
                {renderStatus(taskSubTaskDetails.status.value)}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskSubTaskDetails.datetimeFrom).format(
                  "MM/DD/YYYY hh:mm A"
                )}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskSubTaskDetails.datetimeTo).format(
                  "MM/DD/YYYY hh:mm A"
                )}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskSubTaskDetails.createdAt).format(
                  "MM/DD/YYYY hh:mm A"
                )}
                {"\u00A0"}
              </p>
              <p>
                {moment(taskSubTaskDetails.updatedAt).format(
                  "MM/DD/YYYY hh:mm A"
                )}
                {"\u00A0"}
              </p>
              <p>
                {taskSubTaskDetails.createdBy?.fullname}
                {"\u00A0"}
              </p>
              <p>
                {taskSubTaskDetails.updatedBy?.fullname}
                {"\u00A0"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE MODAL */}
      <DeleteDialog
        title="Delete Permanent"
        message="Are you sure you to permanently delete this record?"
        open={isOpenDeleteModal}
        handleClose={() => SetIsOpenDeleteModal(false)}
        handleDelete={handleDeleteTaskSubmit}
      />
    </div>
  );
}
