export const CLIENT = process.env.REACT_APP_CLIENT_HOST;
export const SERVER = process.env.REACT_APP_SERVER_HOST;
export const USE_ARIL_RELEASE =
  process.env.REACT_APP_USE_ARIL_RELEASE === "true" ? true : false;

export const herbanext_contact_info = {
  EMAIL: "labservices@herbanext.com",
  TEL_NO: "732-8106",
  ADDRESS: "KM 11, Negros South Road, Brgy. Taloc, Bago City",
  MESSANGER_LINK: "https://m.me/herbanextlabservices/",
};

export const user_constants = {
  CATEGORY_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "SUPER_ADMIN", label: "Super Admin" },
    { value: "ADMIN", label: "Staff Admin" },
    { value: "CLIENT", label: "Client" },
  ],
  ONLINE_STATUS_DROPDOWN: [
    { value: "", label: "Select" },
    { value: 1, label: "Online" },
    { value: 0, label: "Offline" },
  ],
  SU_STATUS_DROPDOWN: [
    { value: "", label: "Select" },
    { value: 1, label: "Super User" },
    { value: 0, label: "Normal User" },
  ],
  SU_SORT_FIELD_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "username", label: "Username" },
    { value: "first_name", label: "Firstname" },
    { value: "last_name", label: "Lastname" },
    { value: "is_active", label: "Status" },
    { value: "last_login", label: "Last Login" },
    { value: "date_joined", label: "Date Joined" },
  ],
  SU_SORT_ORDER_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ],
};

export const labservices_constants = {
  INQUIRY_FILE_UPLOAD_CONFIG: {
    file_types: [
      ".jpg",
      ".jpeg",
      ".png",
      ".pdf",
      ".doc",
      ".docx",
      ".xls",
      ".xlsx",
    ],
    file_limit: 10,
    file_size: 40000000,
  },
  INQUIRY_QUOTE_SIGNED_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "TO_BE_SIGNED", label: "To be signed by Client" },
    { value: "FOR_REVIEW", label: "Under Review By R&D" },
    { value: "REJECTED", label: "Rejected" },
    { value: "APPROVED", label: "Approved" },
  ],
  INQUIRY_LSR_SIGNED_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "TO_BE_SIGNED", label: "To be signed by Client" },
    { value: "FOR_REVIEW", label: "Under Review By R&D" },
    { value: "REJECTED", label: "Rejected" },
    { value: "APPROVED", label: "Approved" },
  ],
  SERVICE_TYPE_DROPDOWN: [
    { value: "", label: "Select" },
    { value: 1, label: "Material Processing" },
    { value: 2, label: "Laboratory Analysis" },
    { value: 3, label: "Purchase of Laboratory Supplies" },
  ],
  PROCESS_STATUS_DROPDOWN: [
    { value: "", label: "Select" }, // 0
    { value: "CANCELLED", label: "Cancelled" }, // 1
    { value: "RETURNED", label: "Returned" }, // 2
    { value: "FOR_REVIEW", label: "For Review" }, // 3
    { value: "REVIEWED", label: "Reviewed" }, // 4
    { value: "SIGNED_LSR_RECEIVED", label: "Signed LSR Received" }, // 5
    { value: "QUOTATION_PREPARED", label: "Quotation Prepared" }, // 6
    { value: "QUOTATION_SENT_TO_CLIENT", label: "Quotation Sent to Client" }, // 7
    { value: "SIGNED_QUOTATION_RECEIVED", label: "Signed Quotation Received" }, // 8
    { value: "SAMPLES_RECEIVED", label: "Samples Received" }, // 9
    { value: "FOR_ADDITION_OF_TASK", label: "For Addition of Task" }, // 10
    { value: "TASK_ADDED", label: "Task Added" }, // 11
    { value: "TASK_FINISHED", label: "Task Finished" }, // 12
  ],
  SORT_FIELD_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "requestor", label: "Requestor" },
    { value: "email", label: "Email" },
    { value: "created_at", label: "Date Encoded" },
  ],
  SORT_ORDER_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ],
  FILE_STATUS_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "RETURNED", label: "Returned" },
    { value: "FOR_REVIEW", label: "Under Review" },
    { value: "APPROVED", label: "Approved" },
  ],
  PAYMENT_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "FOR_REVIEW", label: "Under Review" },
    { value: "REJECTED", label: "Disapproved" },
    { value: "APPROVED", label: "Approved" },
  ],
  REPORT_STATUS: [
    { value: "NA", label: "Not Available" },
    { value: "PENDING", label: "Pending" },
    { value: "RELEASED", label: "Released" },
    { value: "ACKNOWLEDGE", label: "Acknowledge" },
  ],
  PROOF_OF_PAYMENT_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "FOR_REVIEW", label: "Under Review" },
    { value: "REJECTED", label: "Disapproved" },
    { value: "APPROVED", label: "Approved" },
  ],
  FEEDBACK_QUESTIONS: [
    {
      code: "Q1",
      question: "How did you hear about us?",
    },
    {
      code: "Q2",
      question: "How satisfied are you with our services?",
    },
    {
      code: "Q3",
      question: "Do you have any concern about the services provided?",
    },
    {
      code: "Q4",
      question: "How can we make your experience better?",
    },
    {
      code: "Q5",
      question: "What services would you like us to add in the future?",
    },
    {
      code: "Q6",
      question: "How likely are you to recommend our services?",
    },
  ],
  DECLINE_REASONS: [
    {
      value: `We have reached the maximum number of client requests for this month. Please try again after a month.`,
      label: `We have reached the maximum number of client requests for this month. Please try again after a month.`,
    },
    {
      value: `Service is not offered.`,
      label: `Service is not offered.`,
    },
  ],
};

export const aril_constants = {
  INQUIRY_FILE_UPLOAD_CONFIG: {
    file_types: [
      ".jpg",
      ".jpeg",
      ".png",
      ".pdf",
      ".doc",
      ".docx",
      ".xls",
      ".xlsx",
    ],
    file_limit: 10,
    file_size: 40000000,
  },
  INQUIRY_QUOTE_SIGNED_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "TO_BE_SIGNED", label: "To be signed by Client" },
    { value: "FOR_REVIEW", label: "Under Review By R&D" },
    { value: "REJECTED", label: "Rejected" },
    { value: "APPROVED", label: "Approved" },
  ],
  INQUIRY_LSR_SIGNED_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "TO_BE_SIGNED", label: "To be signed by Client" },
    { value: "FOR_REVIEW", label: "Under Review By R&D" },
    { value: "REJECTED", label: "Rejected" },
    { value: "APPROVED", label: "Approved" },
  ],
  SERVICE_TYPE_DROPDOWN: [
    { value: "", label: "Select" },
    { value: 1, label: "Material Processing" },
    { value: 2, label: "Laboratory Analysis" },
    { value: 3, label: "Purchase of Laboratory Supplies" },
  ],
  PROCESS_STATUS_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "RETURNED", label: "Returned" },
    { value: "FOR_REVIEW", label: "For Review" },
    { value: "REVIEWED", label: "Reviewed" },
    { value: "QUOTATION_PREPARED", label: "Quotation Prepared" },
    { value: "QUOTATION_SENT_TO_CLIENT", label: "Quotation Sent to Client" },
    { value: "SIGNED_QUOTATION_RECEIVED", label: "Signed Quotation Received" },
    { value: "SIGNED_LSR_RECEIVED", label: "Signed LSR Received" },
    { value: "SAMPLES_RECEIVED", label: "Samples Received" },
    { value: "FOR_ADDITION_OF_TASK", label: "For Addition of Task" },
    { value: "TASK_ADDED", label: "Task Added" },
    { value: "TASK_FINISHED", label: "Task Finished" },
  ],
  SORT_FIELD_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "requestor", label: "Requestor" },
    { value: "email", label: "Email" },
    { value: "created_at", label: "Date Encoded" },
  ],
  SORT_ORDER_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ],
  FILE_STATUS_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "RETURNED", label: "Returned" },
    { value: "FOR_REVIEW", label: "Under Review" },
    { value: "APPROVED", label: "Approved" },
  ],
  PAYMENT_STATUS: [
    { value: "NA", label: "Not yet uploaded" },
    { value: "FOR_REVIEW", label: "Under Review" },
    { value: "REJECTED", label: "Disapproved" },
    { value: "APPROVED", label: "Approved" },
  ],
  REPORT_STATUS: [
    { value: "NA", label: "Not Available" },
    { value: "PENDING", label: "Pending" },
    { value: "RELEASED", label: "Released" },
    { value: "ACKNOWLEDGE", label: "Acknowledge" },
  ],
};

export const tasks_constants = {
  TASK_STATUS_DROPDOWN: [
    { value: "NA", label: "Select" },
    { value: "QUEUED", label: "In Queue" },
    { value: "ONGOING", label: "Ongoing" },
    { value: "COMPLETED", label: "Completed" },
    { value: "CANCELLED", label: "Cancelled" },
  ],
  MACHINE_STATUS_DROPDOWN: [
    { value: "NA", label: "Select" },
    { value: "WORKING", label: "Working" },
    { value: "UNDER_MAINTENANCE", label: "Under Maintenance" },
    { value: "MALFUNCTION", label: "Malfunction" },
    { value: "FOR_REPLACEMENT", label: "For Replacement" },
  ],
  SORT_FIELD_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "task_type", label: "Task Type" },
    { value: "status", label: "Status" },
    { value: "description", label: "Description" },
    { value: "created_at", label: "Date Encoded" },
  ],
  SORT_ORDER_DROPDOWN: [
    { value: "", label: "Select" },
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ],
};

export const routes = {
  // CLIENT LANDING PAGE ROUTES
  CLIENT_LANDING_PAGE: "/client",
  CLIENT_DASHBOARD_LOGIN: "/client/login",
  CLIENT_DASHBOARD_FORGOT_PASSWORD: "/client/forgot_password",
  CLIENT_INQ_LAB_SERVICES: "/client/inquire_laboratory",
  CLIENT_INQ_ARIL: "/client/inquire_aril",

  // CLIENT DASHBOARD
  CLIENT_DASHBOARD: "/client/dashboard/",
  CLIENT_DASHBOARD_REGISTER: "/client/dashboard/register",
  CLIENT_DASHBOARD_HOME: "/client/dashboard/home",
  CLIENT_DASHBOARD_PROFILE: "/client/dashboard/profile",
  CLIENT_DASHBOARD_HELP: "/client/dashboard/help",

  CLIENT_DASHBOARD_LABSERVICES_INQUIRY:
    "/client/dashboard/lab_services_inquiry",
  CLIENT_DASHBOARD_LABSERVICES_INQUIRY_CREATE:
    "/client/dashboard/lab_services_inquiry/create",
  CLIENT_DASHBOARD_LABSERVICES_INQUIRY_DETAILS:
    "/client/dashboard/lab_services_inquiry/",

  CLIENT_DASHBOARD_ARIL_INQUIRY: "/client/dashboard/aril_inquiry",

  // EMPLOYEE ROUTES
  EMPLOYEE_LOGIN_PAGE: "/employee/login",
  EMPLOYEE_DASHBOARD_PROFILE: "/employee/dashboard/profile",
  EMPLOYEE_DASHBOARD_HOME: "/employee/dashboard/home",
  EMPLOYEE_DASHBOARD_USERS: "/employee/dashboard/users",
  EMPLOYEE_DASHBOARD_USERS_CREATE: "/employee/dashboard/users/create",
  EMPLOYEE_DASHBOARD_USERS_DETAILS: "/employee/dashboard/users/",

  EMPLOYEE_DASHBOARD_MENUS: "/employee/dashboard/menus",
  EMPLOYEE_DASHBOARD_MENUS_CREATE: "/employee/dashboard/menus/create",
  EMPLOYEE_DASHBOARD_MENUS_DETAILS: "/employee/dashboard/menus/",

  EMPLOYEE_DASHBOARD_LABSERVICES: "/employee/dashboard/lab_services",
  EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY:
    "/employee/dashboard/lab_services/inquiry",
  EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_CREATE:
    "/employee/dashboard/lab_services/inquiry/create",
  EMPLOYEE_DASHBOARD_LABSERVICES_INQUIRY_DETAILS:
    "/employee/dashboard/lab_services/inquiry/",
  EMPLOYEE_DASHBOARD_LABSERVICES_TASK: "/employee/dashboard/lab_services/task",
  EMPLOYEE_DASHBOARD_LABSERVICES_TASK_BOARD:
    "/employee/dashboard/lab_services/task_board",

  EMPLOYEE_DASHBOARD_ARIL: "/employee/dashboard/aril",
  EMPLOYEE_DASHBOARD_ARIL_INQUIRY: "/employee/dashboard/aril/inquiry",

  EMPLOYEE_DASHBOARD_TASKS: "/employee/dashboard/tasks",
  EMPLOYEE_DASHBOARD_TASKS_TASK: "/employee/dashboard/tasks/task",
  EMPLOYEE_DASHBOARD_TASKS_PERSONNEL: "/employee/dashboard/tasks/personnel",
  EMPLOYEE_DASHBOARD_TASKS_MACHINE: "/employee/dashboard/tasks/machine",
  EMPLOYEE_DASHBOARD_TASKS_TASK_TYPE: "/employee/dashboard/tasks/task_type",
  EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD: "/employee/dashboard/tasks/task_board",
  EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_COMPLETED:
    "/employee/dashboard/tasks/task_board_completed",
  EMPLOYEE_DASHBOARD_TASKS_TASK_BOARD_CANCELLED:
    "/employee/dashboard/tasks/task_board_cancelled",
  EMPLOYEE_DASHBOARD_TASKS_TASK_CALENDAR:
    "/employee/dashboard/tasks/task_calendar",
};

export const api_urls = {
  // Profile
  PROFILE_USER_SET_USERNAME: SERVER + "auth/users/set_username/",
  PROFILE_USER_SET_PASSWORD: SERVER + "auth/users/set_password/",

  // User
  USER_CHECK_TOKEN: SERVER + "auth/users/me/",
  USER_LOGIN_POST: SERVER + "auth/token/login/",
  USER_FETCH: SERVER + "api/user",
  USER_STORE: SERVER + "api/user/",
  USER_UPDATE: SERVER + "api/user/",
  USER_FIND_BY_ID: SERVER + "api/user/",
  USER_RESET_PASSWORD: SERVER + "api/user/reset_password/",
  USER_DELETE: SERVER + "api/user/",
  USER_BULK_DELETE: SERVER + "api/user/bulk_destroy/",
  USER_SEND_FORGOT_PASSWORD_EMAIL:
    SERVER + "api/user_client/send_forgot_password_email/",

  // Menu
  ROUTE_FETCH: SERVER + "api/route",
  ROUTE_STORE: SERVER + "api/route/",
  ROUTE_UPDATE: SERVER + "api/route/",
  ROUTE_DELETE: SERVER + "api/route/",
  ROUTE_BULK_DELETE: SERVER + "api/route/bulk_destroy/",
  ROUTE_GET_ALL: SERVER + "api/route/get_all",
  ROUTE_FIND_BY_ID: SERVER + "api/route/",
  SUBROUTE_STORE: SERVER + "api/subroute/",
  SUBROUTE_UPDATE: SERVER + "api/subroute/",
  SUBROUTE_DELETE: SERVER + "api/subroute/",

  // Lab Services
  LABSERVICES_INQUIRY: SERVER + "api/lab_services/inquiry",
  LAB_SERVICES_CLIENT_INQUIRE_CREATE:
    SERVER + "api/lab_services/client_inquiry/",
  LABSERVICES_INQUIRY_FETCH: SERVER + "api/lab_services/inquiry",
  LABSERVICES_INQUIRY_CREATE: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_BULK_DELETE:
    SERVER + "api/lab_services/inquiry/bulk_destroy",
  LABSERVICES_INQUIRY_FIND_BY_ID: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_PATCH: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPDATE: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_DELETE: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPDATE_QUOTE_SIGNED_COMMENTS:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPDATE_QUOTE_SIGNED_STATUS:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPDATE_LSR_SIGNED_COMMENTS:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPDATE_LSR_SIGNED_STATUS:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_OPEN_RECORD: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPDATE_RECORD_MODIFIED_BY_CLIENT:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_GENERATE_CLIENT_ACCOUNT:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_COUNT_ALL_BY_USER:
    SERVER + "api/lab_services/inquiry/count_all_by_user",
  LABSERVICES_INQUIRY_COUNT_UPDATED_BY_USER:
    SERVER + "api/lab_services/inquiry/count_updated_by_user",

  LABSERVICES_INQUIRY_UPLOAD_QUOTE_INITIAL:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_DOWNLOAD_QUOTE_INITIAL:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPLOAD_QUOTE_SIGNED: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_DOWNLOAD_QUOTE_SIGNED:
    SERVER + "api/lab_services/inquiry/",

  LABSERVICES_INQUIRY_SEND_LSR_INITIAL: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPLOAD_LSR_INITIAL: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_DOWNLOAD_LSR_INITIAL:
    SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_UPLOAD_LSR_SIGNED: SERVER + "api/lab_services/inquiry/",
  LABSERVICES_INQUIRY_DOWNLOAD_LSR_SIGNED: SERVER + "api/lab_services/inquiry/",

  LABSERVICES_INQUIRY_FILES_CREATE: SERVER + "api/lab_services/inquiry_files/",
  LABSERVICES_INQUIRY_FILES_UPDATE: SERVER + "api/lab_services/inquiry_files/",
  LABSERVICES_INQUIRY_FILES_DELETE: SERVER + "api/lab_services/inquiry_files/",
  LABSERVICES_INQUIRY_FILES_DOWNLOAD:
    SERVER + "api/lab_services/inquiry_files/",
  LABSERVICES_INQUIRY_FILES_PATCH: SERVER + "api/lab_services/inquiry_files/",
  LABSERVICES_INQUIRY_REPORTS: SERVER + "api/lab_services/inquiry_reports",
  LABSERVICES_INQUIRY_PROOF_OF_PAYMENT:
    SERVER + "api/lab_services/inquiry_proof_of_payment",
  LABSERVICES_INQUIRY_TRANSACTION_LOG:
    SERVER + "api/lab_services/inquiry_transaction_log",
  LABSERVICES_INQUIRY_FEEDBACK: SERVER + "api/lab_services/inquiry_feedback",
  LABSERVICES_INQUIRY_DASHBOARD: SERVER + "api/lab_services/inquiry_dashboard",

  // Task
  TASKS_PERSONNEL: SERVER + "api/tasks/personnel",
  TASKS_MACHINE: SERVER + "api/tasks/machine",
  TASKS_TASK_TYPE: SERVER + "api/tasks/task_type",
  TASKS_TASK: SERVER + "api/tasks/task",
  TASKS_TASK_SUBTASK: SERVER + "api/tasks/task_subtask",

  // Aril
  ARIL_CLIENT_INQUIRE_CREATE: SERVER + "api/aril/client_inquiry",
  ARIL_INQUIRY: SERVER + "api/aril/inquiry",
  ARIL_INQUIRY_FILES: SERVER + "api/aril/inquiry_files",
  ARIL_INQUIRY_REPORTS: SERVER + "api/aril/inquiry_reports",
  ARIL_INQUIRY_PROOF_OF_PAYMENT: SERVER + "api/aril/inquiry_proof_of_payment",
  ARIL_INQUIRY_TRANSACTION_LOG: SERVER + "api/aril/inquiry_transaction_log",
};

export const herbanext_business_services = [
  {
    title: "PHYSICO-CHEMICAL TESTS",
    services: [
      "Weight Determination (Top Loading Balance)",
      "Weight Determination (Analytical Balance)",
      "pH",
      "EC (Electrical Conductivity)",
      "TDS (Total Dissolved Solids)",
      "Brix",
      "Refractive Index",
      "Salinity",
      "Percent alcohol (ABV)",
      "Viscosity",
      "Moisture Content/ Total Solids",
      "Specific Gravity",
      "Bulk and Tapped Density",
      "Particle size distribution/Analytical Sieving",
      "Total Ash Content",
      "Color Assessment",
      "Angle of Repose",
      "Titratable Acidity",
      "Foreign matter",
    ],
  },
  {
    title: "Fats and Oils Analysis",
    services: ["Free Fatty Acid/ Acid Value", "Peroxide Value"],
  },
  {
    title: "MICROBIAL TESTS",
    services: [
      "TAC (Total Aerobic Count)",
      "TYM (Total yeast and Molds)",
      "E. coli",
      "Total Coliform",
      "Pseudomonas aeruginosa",
      "Enterobacteriaceae",
      "Staphylococcus aureus",
      "Salmonella spp.",
      "Candida albicans",
    ],
  },
  {
    title: "Antibiotic Screening by Disc/Well Diffusion Assay Package",
    services: ["Bacteria", "Fungi", "Yeast"],
  },
  {
    title:
      "ANTIMICROBIAL ASSAY AND MICROBIAL ISOLATION SERVICES/ITEMS/EQUIPMENT RENTAL",
    services: [
      "MHA Plate or Tube",
      "NSS",
      "SDA Plate or Tube",
      "Sterile Discs",
      "Antibiotic Discs",
      "Solution Preparations (Antibiotic Standard, NSS, etc.)",
      "Incubator",
      "Autoclave/Sterilization",
      "Biosafety Cabinet Use",
      "Laminar hood",
      "Microscopy",
      "Sample preparations (fixing of isolates on glass slides for microscopy)",
      "Documentation Fee",
      "Certificate of Identification for Isolates",
    ],
  },
  {
    title: "MICROBIOLOGICAL SPECIMEN ISOLATION PACKAGE",
    services: ["Bacterial/Fungal Isolation"],
  },
  {
    title: "Antibiotic Screening by MIC/MBC/MFC Determination Package",
    services: [
      "Minimum Inhibitory Concentration Assay (Bacteria) + Min. Bactericidal Concentration Assay",
      "Minimum Inhibitory Concentration Assay (Filamentous Fungi)",
      "Minimum Inhibitory Concentration Assay (Yeast) 5,650 /sample (1 microorganism only) + Min. Fungicidal Concentration Assay",
    ],
  },
  {
    title: "EXTRACTION",
    services: [
      "Oven drying",
      "Pyrolysis",
      "Grinding",
      "Sieving (Manual)",
      "Multi-layer Sieve (Bench type sieve shaker)",
      "Vibrio Siever",
      "Oscillating granulator",
      "Homogenization (Motorized, with Variable speed)",
      "Vacuum filtration",
      "Ultrasonic probe",
      "Ultrasonic bath",
      "Mechanical shaker",
      "Thermostatic Shaking Water bath",
      "Soxhlet",
      "Hot Plate Stirrer",
      "Blender",
      "Centrifuge",
      "Ultra-low temp Freezer (-25C)",
      "Ultra-low temp Freezer (-40C)",
      "Block Heater/Hot plate",
      "Heating Mantle/Isomantle",
    ],
  },
  {
    title: "EXTRACT CONCENTRATION",
    services: [
      "Rotavap (Alcohols and other organic solvents)",
      "Rotavap (Water)",
      "Speedvac (excluding vials)",
      "Freeze-drying or Lyophilization",
      "Spray-drying (Large-scale)",
      "Spray-drying (Pilot-scale)",
      "Spray-drying (Lab-scale)",
    ],
  },
  {
    title: "DISTILLATION/ESSENTIAL OIL EXTRACTION",
    services: [
      "Laboratory-scale hydrodistillation",
      "Laboratory-scale steam distillation",
      "Pilot-scale Steam Distillation/Hydrodistillation",
    ],
  },
  {
    title: "TLC (THIN LAYER CHROMATOGRAPHY) ANALYSIS",
    services: [
      "Fingerprinting (with available/existing method) - TLC Profiling + Photodocumentation",
      "Fingerprinting (with available/existing method) - TLC Detection of Marker + Photodocumentation",
      "Fingerprinting (with Development of method) - TLC Profiling + Photodocumentation",
      "Fingerprinting (with Development of method) -TLC Detection of Marker + Photodocumentation",
    ],
  },
  {
    title: "HPTLC (HIGH PERFORMANCE TLC) ANALYSIS",
    services: [
      "Fingerprinting (with available/existing methods only) - HPTLC Profiling + Photodocumentation",
      "Fingerprinting (with available/existing methods only) - HPTLC Detection of Marker + Photodocumentation",
      "Fingerprinting (with Development of method) - HPTLC Profiling + Photodocumentation",
      "Fingerprinting (with Development of method) - HPTLC Detection of Marker+Photodocumentation",
      "Quantification (by densitometry) - Reference Compound",
    ],
  },
  {
    title: "PHYTOCHEMICAL TESTS (Test-tube method)",
    services: [
      "Alkaloids",
      "Flavonoids (TLC method also available)",
      "Phenols (TLC method also available)",
      "Tannins",
      "Phytosterols",
      "Saponins (Sapogenins: TLC method also available)",
      "Terpenoids",
      "Reducing Sugars (Fehling’s Test)",
      "Proteins (Lowry Assay)",
      "Glycosides",
    ],
  },
  {
    title: "UV-VIS SPECTROPHOTOMETRY",
    services: [
      "Qualitative Tests",
      "Quantitative Assays",
      "Activity Screening Assays",
    ],
  },
  {
    title: "DOSAGE FORM QUALITY TESTS",
    services: [
      "Hardness Test (Digital Tester)",
      "Friability Test",
      "Disintegration",
      "Leak test",
      "Thickness and Diameter",
    ],
  },
  {
    title: "TABLETING/CAPSULIZING",
    services: [
      "Manual Capsulizing (Lab-scale)",
      "Manual Tableting (Lab-scale)",
      "Automated Capsulizing",
      "Automated Tableting",
    ],
  },
  {
    title: "STABILITY CHAMBER RENTAL",
    services: ["Room temp. stability", "Accelerated stability"],
  },
];
