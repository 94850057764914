import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

import { labservices_constants, api_urls } from "../../../../constants";
import { setToastNotification } from "../../../../store/client/dashboardReducer";
import {
  retrieveInquiry,
  createTransactionLog,
} from "../../../../store/client/lab_services/inquiryActions";
import ViewFileDialog from "../common/ViewFileDialog";
import {
  fileStorageUrlToMediaUrl,
  getFileTypeByFileStorageUrl,
  getEllipsisText,
} from "../../../utils/StringUtils";

const initialFeedbackValue = {
  id: "",
  feedback: "",
};

export default function InquiryDetailsReports({ inquiryDetails, inquiryId }) {
  const dispatch = useDispatch();

  const [isOpenFeedbackDialog, SetIsOpenFeedbackDialog] = useState(false);
  const [feedback, SetFeedback] = useState(initialFeedbackValue);
  const [fileViewerDetails, SetFileViewerDetails] = useState({
    isOpen: false,
    fileData: null,
  });

  const handleUpdateReportStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/${id}/`, {
        status: status,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the report status!",
            },
          })
        );
        createTransactionLog({
          inquiry_id: inquiryId,
          action: "Updated Inquiry Report Status",
        });
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  const handleDownloadFile = (e, id, filename) => {
    e.preventDefault();
    axios
      .get(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/${id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleOpenFeedbackDialog = (id, feedback) => {
    SetIsOpenFeedbackDialog(true);
    SetFeedback({ id, feedback });
  };

  const handleCloseFeedbackDialog = () => {
    SetIsOpenFeedbackDialog(false);
    SetFeedback(initialFeedbackValue);
  };

  const handleSubmitFeedback = (e) => {
    e.preventDefault();
    if (feedback.id) {
      axios
        .patch(`${api_urls.LABSERVICES_INQUIRY_REPORTS}/${feedback.id}/`, {
          feedback: feedback.feedback,
        })
        .then((res) => {
          dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Successfully created/updated the report Comment!",
              },
            })
          );
          createTransactionLog({
            inquiry_id: inquiryId,
            action: "Updated Inquiry Report Comment",
          });
          SetIsOpenFeedbackDialog(false);
          SetFeedback(initialFeedbackValue);
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.status === 500) {
            dispatch(
              setToastNotification({
                toastNotification: {
                  isOpen: true,
                  type: "error",
                  message:
                    "There's an error trying to submit data to the server!",
                },
              })
            );
          }
        });
    }
  };

  return (
    <div
      className="col-md-12"
      style={
        inquiryDetails.isDeclined ||
        inquiryDetails.processStatus ===
          labservices_constants.PROCESS_STATUS_DROPDOWN[1].label
          ? { pointerEvents: "none", opacity: "0.5" }
          : {}
      }
    >
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Reports</h5>
        </div>
        <div className="card-block">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Filename</th>
                <th>Description</th>
                <th>Status</th>
                <th>Comment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {inquiryDetails.reports.map((data) => {
                let statusLabel = "";
                let statusClass = "";
                let acknowledgeButtonLabel = "btn-disabled disabled";
                switch (data.status) {
                  case labservices_constants.REPORT_STATUS[0].value:
                    statusClass = "label-warning";
                    statusLabel = labservices_constants.REPORT_STATUS[0].label;
                    break;
                  case labservices_constants.REPORT_STATUS[1].value:
                    statusClass = "label-warning";
                    statusLabel = labservices_constants.REPORT_STATUS[1].label;
                    break;
                  case labservices_constants.REPORT_STATUS[2].value:
                    statusClass = "label-primary";
                    statusLabel = labservices_constants.REPORT_STATUS[2].label;
                    acknowledgeButtonLabel = "btn-primary";
                    break;
                  case labservices_constants.REPORT_STATUS[3].value:
                    statusClass = "label-success";
                    statusLabel = labservices_constants.REPORT_STATUS[3].label;
                    acknowledgeButtonLabel = "btn-primary";
                    break;
                  default:
                    statusClass = "";
                }
                return (
                  <tr key={data.id}>
                    <td>
                      {data.status ===
                        labservices_constants.REPORT_STATUS[2].value ||
                      data.status ===
                        labservices_constants.REPORT_STATUS[3].value ? (
                        <a
                          href="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            SetFileViewerDetails({
                              ...fileViewerDetails,
                              isOpen: true,
                              fileData: data,
                            });
                          }}
                        >
                          <ins className="text-info">
                            {getEllipsisText(data.orig_filename, 30)}
                          </ins>
                        </a>
                      ) : (
                        <>{getEllipsisText(data.orig_filename, 30)}</>
                      )}
                    </td>
                    <td>{data.description}</td>
                    <td>
                      <label className={`label ${statusClass}`}>
                        {statusLabel}
                      </label>
                    </td>
                    <td>{data.feedback}</td>
                    <td className="align-middle">
                      <button
                        onClick={(e) =>
                          handleUpdateReportStatus(
                            e,
                            data.id,
                            labservices_constants.REPORT_STATUS[3].value
                          )
                        }
                        className={`btn btn-sm pb-2 pt-2 ${acknowledgeButtonLabel}`}
                        type="button"
                      >
                        Acknowledge
                      </button>
                      {"    "}
                      <button
                        onClick={() =>
                          handleOpenFeedbackDialog(data.id, data.feedback)
                        }
                        className={`btn btn-sm pb-2 pt-2 btn-success`}
                        type="button"
                      >
                        Comment
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Feedback Dialog */}
      <FeedbackDialog
        isOpen={isOpenFeedbackDialog}
        feedback={feedback.feedback}
        feedbackFormHandler={(e) =>
          SetFeedback({ ...feedback, feedback: e.target.value })
        }
        handleSubmit={handleSubmitFeedback}
        handleClose={handleCloseFeedbackDialog}
      />

      {/* View File Dialog */}
      <ViewFileDialog
        isOpen={fileViewerDetails.isOpen}
        fileType={getFileTypeByFileStorageUrl(fileViewerDetails.fileData?.file)}
        filePath={fileStorageUrlToMediaUrl(fileViewerDetails.fileData?.file)}
        errorComponent={<p>Error Encountered Trying to load file ...</p>}
        handleClose={() =>
          SetFileViewerDetails({
            isOpen: false,
            fileData: null,
          })
        }
        handleDownload={(e) =>
          handleDownloadFile(
            e,
            fileViewerDetails.fileData?.id,
            fileViewerDetails.fileData?.orig_filename
          )
        }
      />
    </div>
  );
}

const FeedbackDialog = ({
  isOpen,
  feedback,
  feedbackFormHandler,
  handleSubmit,
  handleClose,
}) => {
  const useStyles = makeStyles((theme) => ({
    dialog: {
      minHeight: "60vh",
      maxHeight: "60vh",
    },
    dialogContent: {
      minHeight: "20vh",
      maxHeight: "20vh",
      overflow: "hidden",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: 20,
      width: "fit-content",
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: "80vh",
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }));
  const styleClasses = useStyles();
  return (
    <Dialog
      className={styleClasses.dialog}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Comment</DialogTitle>
      <DialogContent className={styleClasses.dialogContent}>
        <form className={styleClasses.form} noValidate>
          <FormControl variant="outlined" className={styleClasses.formControl}>
            <label>Comment:</label>
            <input
              type="text"
              className="form-control"
              value={feedback || ""}
              onChange={feedbackFormHandler}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
