import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FileViewer from "react-file-viewer";
import { makeStyles } from "@material-ui/core/styles";

export default function ViewFileDialog({
  isOpen,
  fileType,
  filePath,
  errorComponent,
  handleClose,
  handleDownload,
}) {
  const useStyles = makeStyles((theme) => ({
    content: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      minWidth: "1200px",
      minHeight: "700px",
    },
  }));
  const styleClasses = useStyles();
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      maxHeight="lg"
    >
      <DialogTitle id="alert-dialog-title">File Viewer</DialogTitle>
      <DialogContent className={styleClasses.content}>
        <FileViewer
          fileType={fileType}
          filePath={filePath}
          errorComponent={errorComponent}
        />
      </DialogContent>
      <DialogActions>
        {handleDownload && (
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download
          </Button>
        )}
        {handleClose && (
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
