import { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  search,
  resetList,
} from "../../../../store/employee/tasks/personnelReducer";
import {
  checkAllRecords,
  checkSpecificRecord,
  fetchPersonnel,
  paginationClick,
  pageSizeClick,
} from "../../../../store/employee/tasks/personnelActions";
import { api_urls, routes } from "../../../../constants";
import { TableFooterDefault } from "../../../utils/Table/TableFooters";
import { TableHeaderDefault } from "../../../utils/Table/TableHeaders";
import DivLoader from "../../../utils/DivLoaderComp";
import DeleteDialog from "../common/DeleteDialog";

export default function PersonnelList() {
  const [isOpenDeleteBulk, SetIsOpenDeleteBulk] = useState(false);
  const [pageLoader, SetPageLoader] = useState(false);
  const [searchQuery, SetSearchQuery] = useState("");

  const list = useSelector((state) => state.personnel.list);
  const pagination = useSelector((state) => state.personnel.listPagination);
  const filters = useSelector((state) => state.personnel.listFilters);
  const highlightedRecord = useSelector(
    (state) => state.personnel.highlightedRecord
  );
  const isCheckedAllRecords = useSelector(
    (state) => state.personnel.isCheckedAllRecords
  );
  const checkedRecords = useSelector((state) => state.personnel.checkedRecords);

  const dispatch = useDispatch();
  const history = useHistory();
  const redirectToPersonnelCreate = useCallback(
    () => history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/create`),
    [history]
  );
  const redirectToPersonnelDetails = useCallback(
    (id) => history.push(`${routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL}/${id}`),
    [history]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delaySearch = useCallback(
    debounce((value) => dispatch(search({ query: value })), 500),
    []
  );

  useEffect(() => {
    dispatch(fetchPersonnel({ pagination: pagination, filters: filters }));
  }, [dispatch, pagination, filters]);

  useEffect(() => {
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "Personnel",
          subtitle: "Manage Personnel",
          icon: "icon-users",
          pages: [
            {
              url: routes.EMPLOYEE_DASHBOARD_TASKS_PERSONNEL,
              name: "Manage Personnels",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    SetSearchQuery(value);
    delaySearch(value);
  };

  const handleCreateButtonClick = (e) => {
    e.preventDefault();
    redirectToPersonnelCreate();
  };

  const handleOpenPersonnelDetails = (e, id) => {
    e.preventDefault();
    redirectToPersonnelDetails(id);
  };

  const handleCheckAllRecords = (e) => {
    dispatch(
      checkAllRecords({
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handleCheckSpecificRecord = (e, id) => {
    dispatch(
      checkSpecificRecord({
        id: id,
        checked: e.target.checked,
        checkedRecords: [...checkedRecords],
      })
    );
  };

  const handlePaginationClick = (e, willCurrent) => {
    e.preventDefault();
    dispatch(
      paginationClick({
        willCurrent: willCurrent,
        limit: list.limit,
        size: pagination.pageSize,
      })
    );
  };

  const handlePageSizeClick = (e) => {
    e.preventDefault();
    dispatch(pageSizeClick(e.target.value));
  };

  const handleBulkDeleteSubmit = () => {
    SetIsOpenDeleteBulk(false);
    SetPageLoader(true);
    let ids = [];
    checkedRecords.forEach((data) => {
      if (data.status === true) {
        ids.push(data.id);
      }
    });
    if (ids.length > 0) {
      axios
        .delete(`${api_urls.TASKS_PERSONNEL}/bulk_destroy/`, {
          data: {
            ids: ids,
          },
        })
        .then((response) => {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "success",
                message: "Personnels Successfully Deleted!",
              },
            })
          );
          SetPageLoader(false);
          dispatch(
            fetchPersonnel({ pagination: pagination, filters: filters })
          );
        })
        .catch((error) => {
          if (error.response.status === 404 || error.response.status === 500) {
            dispatch(
              setToastNotification({
                toastNotification: {
                  isOpen: true,
                  type: "error",
                  message:
                    "There's an error trying to submit data to the server!",
                },
              })
            );
          }
          SetPageLoader(false);
        });
    } else {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "warning",
            message: "Please select records to delete!",
          },
        })
      );
      SetPageLoader(false);
    }
  };

  const handleRefreshClick = () => {
    SetSearchQuery("");
    dispatch(resetList());
  };

  const recordIsChecked = (id) => {
    return checkedRecords.some(
      (data) => data.id === id && data.status === true
    );
  };

  return (
    <div className="col-sm-12">
      <div className="card table-card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        {/* Table Header */}
        <div className="card-header">
          <TableHeaderDefault
            createButtonClickHandler={handleCreateButtonClick}
            searchInputValue={searchQuery}
            searchInputHandler={handleSearch}
            filterButton={false}
            sortButton={false}
            refreshButtonClickHandler={handleRefreshClick}
            deleteButton={true}
            deleteButtonDisable={true}
            deleteButtonClickHandler={() => SetIsOpenDeleteBulk(true)}
            createButton={true}
            entriesSelect={true}
            entriesSelectPageSize={pagination.pageSize}
            entriesSelectChangeHandler={handlePageSizeClick}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>

        {/* TABLE BODY */}
        <div className="card-block table-border-style pb-0 pt-0">
          <div className="table-responsive">
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th className="p-0">
                    <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                      <label>
                        <input
                          type="checkbox"
                          checked={isCheckedAllRecords}
                          onChange={handleCheckAllRecords}
                        />
                        <span className="cr">
                          <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                        </span>
                      </label>
                    </div>
                  </th>
                  <th className="align-middle">Fullname</th>
                  <th className="align-middle">Position</th>
                  <th className="align-middle">Email</th>
                  <th className="align-middle">Contact No.</th>
                </tr>
              </thead>
              <tbody>
                {list.records.map((val, key) => {
                  return (
                    <tr
                      key={key}
                      className={
                        val.id == highlightedRecord || recordIsChecked(val.id)
                          ? "table-info"
                          : ""
                      }
                    >
                      <td className="p-0">
                        <div className="checkbox-fade fade-in-primary ml-3 mt-3">
                          <label>
                            <input
                              key={key}
                              type="checkbox"
                              checked={recordIsChecked(val.id)}
                              onChange={(e) =>
                                handleCheckSpecificRecord(e, val.id)
                              }
                            />
                            <span className="cr">
                              <i className="cr-icon icofont icofont-ui-check txt-primary"></i>
                            </span>
                          </label>
                        </div>
                      </td>
                      <th scope="row" className="align-middle">
                        <a
                          href="/#"
                          onClick={(e) => handleOpenPersonnelDetails(e, val.id)}
                        >
                          <ins className="text-info">{val.fullname}</ins>
                        </a>
                      </th>
                      <td className="align-middle">{val.position}</td>
                      <td className="align-middle">{val.email}</td>
                      <td className="align-middle">{val.contact_no}</td>
                    </tr>
                  );
                })}

                {!list.isFetched && (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="5">
                      <h4>Loading ...</h4>
                    </td>
                  </tr>
                )}

                {list.isFetched && !list.records.length ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colspan="5">
                      <h4>No Records Found!</h4>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Table Footer */}
        <div className="card-footer">
          <TableFooterDefault
            counterPageSize={pagination.pageSize}
            counterPageCurrent={pagination.pageCurrent}
            counterPageLimit={list.limit}
            counterTotalRecords={list.totalCount}
            paginationPagePrev={pagination.pagePrev}
            paginationPageNext={pagination.pageNext}
            paginationPageLimit={list.limit}
            paginationPrevClickHandler={(e) =>
              handlePaginationClick(e, pagination.pagePrev)
            }
            paginationNextClickHandler={(e) =>
              handlePaginationClick(e, pagination.pageNext)
            }
          />
        </div>
      </div>

      {/* Bulk Delete Modal */}
      <DeleteDialog
        title="Delete Selected Records"
        message="Are you sure you to permanently delete the selected records?"
        open={isOpenDeleteBulk}
        handleClose={() => SetIsOpenDeleteBulk(false)}
        handleDelete={handleBulkDeleteSubmit}
      />
    </div>
  );
}
