import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  setPageHeader,
  setToastNotification,
} from "../../../../store/employee/dashboardReducer";
import {
  setFormData,
  setFormDataFiles,
  setFormErrors,
  setHighlightedRecord,
} from "../../../../store/employee/aril/inquiryReducer";
import { resetForm } from "../../../../store/employee/aril/inquiryActions";
import { routes, api_urls, aril_constants } from "../../../../constants";
import {
  InputText,
  SelectInput,
  FileInput,
} from "../../../utils/Forms/DefaultInputs";
import DivLoader from "../../../utils/DivLoaderComp";

export default function InquiryCreate() {
  const [pageLoader, SetPageLoader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.arilInquiry.formData);
  const formDataFiles = useSelector((state) => state.arilInquiry.formDataFiles);
  const formErrors = useSelector((state) => state.arilInquiry.formErrors);
  const redirectBackToInquiryList = useCallback(
    () => history.push(routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY),
    [history]
  );

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      setPageHeader({
        pageHeader: {
          title: "ARIL Inquiries",
          subtitle: "Create Inquiry",
          icon: "icon-info",
          pages: [
            {
              url: `${routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}`,
              name: "Manage Inquiries",
            },
            {
              url: `${routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}/create`,
              name: "Inquiry Create",
            },
          ],
        },
      })
    );
  }, [dispatch]);

  const handleCreate = (e, isSaveAnother) => {
    e.preventDefault();
    SetPageLoader(true);
    const form = new FormData();
    form.append("is_admin", true);
    form.append("requestor", formData.requestor);
    form.append("company", formData.company);
    form.append("course", formData.course);
    form.append("position", formData.position);
    form.append("service_type", formData.serviceType.value);
    form.append("description", formData.description);
    form.append("email", formData.email);
    form.append("contact_no", formData.contactNo);
    for (let i = 0; i < formDataFiles.length; i++) {
      form.append("files", formDataFiles[i], formDataFiles[i].name);
    }
    axios
      .post(`${api_urls.ARIL_INQUIRY}/`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(resetForm());
        SetPageLoader(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Inquiry Successfully Created!",
            },
          })
        );
        if (!isSaveAnother) {
          redirectBackToInquiryList();
          dispatch(setHighlightedRecord(res.data.id));
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          let field_errors = err.response.data;
          dispatch(
            setFormErrors({
              requestor: field_errors.requestor,
              company: field_errors.company,
              course: field_errors.course,
              position: field_errors.position,
              serviceType: field_errors.service_type,
              description: field_errors.description,
              email: field_errors.email,
              contactNo: field_errors.contact_no,
              files: field_errors.files,
            })
          );
        }
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
        SetPageLoader(false);
      });
  };

  const handleResetForm = (e) => {
    e.preventDefault();
    window.location.reload();
    dispatch(resetForm());
  };

  return (
    <div className="col-sm-12">
      <div className="card z-depth-0">
        <DivLoader type="Circles" loading={pageLoader} />

        <div className="card-header">
          <h5>Create ARIL Inquiry</h5>
          <Link
            to={routes.EMPLOYEE_DASHBOARD_ARIL_INQUIRY}
            className="btn btn-primary btn-outline-primary float-right pt-2 pb-2"
          >
            <i className="fa fa-arrow-left"></i> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="row">
            <InputText
              col="col-sm-6"
              type="text"
              label="Name:"
              placeholder="Name"
              errorField={formErrors.requestor}
              value={formData.requestor}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, requestor: e.target.value })
                )
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Affiliation (Company / School):"
              placeholder="Affiliation (Company / School)"
              errorField={formErrors.company}
              value={formData.company}
              setter={(e) =>
                dispatch(setFormData({ ...formData, company: e.target.value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Course / Degree (if student):"
              placeholder="Course / Degree (if student)"
              errorField={formErrors.course}
              value={formData.course}
              setter={(e) =>
                dispatch(setFormData({ ...formData, course: e.target.value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Position:"
              placeholder="Position"
              errorField={formErrors.position}
              value={formData.position}
              setter={(e) =>
                dispatch(setFormData({ ...formData, position: e.target.value }))
              }
            />

            <SelectInput
              col="col-sm-6"
              type="text"
              label="Service Type:"
              isDisabled={false}
              options={aril_constants.SERVICE_TYPE_DROPDOWN}
              errorField={formErrors.serviceType}
              value={formData.serviceType}
              onChange={(value) =>
                dispatch(setFormData({ ...formData, serviceType: value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="text"
              label="Inquiry:"
              placeholder="Inquiry"
              errorField={formErrors.description}
              value={formData.description}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, description: e.target.value })
                )
              }
            />

            <InputText
              col="col-sm-6"
              type="email"
              label="Email:"
              placeholder="Email"
              errorField={formErrors.email}
              value={formData.email}
              setter={(e) =>
                dispatch(setFormData({ ...formData, email: e.target.value }))
              }
            />

            <InputText
              col="col-sm-6"
              type="contact_no"
              label="Contact No.:"
              placeholder="Contact No."
              errorField={formErrors.contactNo}
              value={formData.contactNo}
              setter={(e) =>
                dispatch(
                  setFormData({ ...formData, contactNo: e.target.value })
                )
              }
            />

            <FileInput
              col="col-sm-12"
              label="Upload Documents:"
              errorField={formErrors.files}
              acceptedFiles={
                aril_constants.INQUIRY_FILE_UPLOAD_CONFIG.file_types
              }
              filesLimit={aril_constants.INQUIRY_FILE_UPLOAD_CONFIG.file_limit}
              maxFileSize={aril_constants.INQUIRY_FILE_UPLOAD_CONFIG.file_size}
              onChange={(files) => dispatch(setFormDataFiles(files))}
            />
          </div>

          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, false)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={(e) => handleCreate(e, true)}
              >
                Save and add another
              </button>
              <button
                type="button"
                className="btn btn-primary float-right mr-2"
                onClick={handleResetForm}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
