import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { api_urls } from "../../../../constants";
import { aril_constants } from "../../../../constants";
import { setToastNotification } from "../../../../store/employee/dashboardReducer";
import {
  retrieveInquiry,
  downloadQuoteOrLSR,
  createTransactionLog,
  DOWNLOAD_ACTIONS,
} from "../../../../store/employee/aril/inquiryActions";

const UPLOAD_ACTIONS = {
  QUOTE_INITIAL_CREATE: "QUOTE_INITIAL_CREATE",
  QUOTE_INITIAL_EDIT: "QUOTE_INITIAL_EDIT",
  QUOTE_SIGNED_CREATE: "QUOTE_SIGNED_CREATE",
  QUOTE_SIGNED_EDIT: "QUOTE_SIGNED_EDIT",
  LSR_INITIAL_EDIT: "LSR_INITIAL_EDIT",
  LSR_SIGNED_CREATE: "LSR_SIGNED_CREATE",
  LSR_SIGNED_EDIT: "LSR_SIGNED_EDIT",
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function InquiryDetailsQuoteAndLSR({
  inquiryDetails,
  inquiryId,
}) {
  const [isOpenImportFileFormDialog, SetIsOpenImportFileFormDialog] =
    useState(false);
  const [importFileFormDialogTitle, SetImportFileFormDialogTitle] =
    useState("");
  const [importFileFormDialogSubtitle, SetImportFileFormDialogSubtitle] =
    useState("");
  const [formDataFile, SetFormDataFile] = useState(null);
  const [submitAction, SetSubmitAction] = useState("");

  const [isEditQuoteComments, SetIsEditQuoteComments] = useState(false);
  const [quoteComments, SetQuoteComments] = useState("");
  const [isEditLSRComments, SetIsEditLSRComments] = useState(false);
  const [lsrComments, SetLSRComments] = useState("");

  const dispatch = useDispatch();

  const handleImportFileFormOpen = (e, action) => {
    e.preventDefault();
    SetIsOpenImportFileFormDialog(true);
    SetSubmitAction(action);
    switch (action) {
      case UPLOAD_ACTIONS.QUOTE_INITIAL_CREATE:
        SetImportFileFormDialogTitle("Upload Initial Quote");
        SetImportFileFormDialogSubtitle("Upload Document");
        break;
      case UPLOAD_ACTIONS.QUOTE_INITIAL_EDIT:
        SetImportFileFormDialogTitle("Update Initial Quote");
        SetImportFileFormDialogSubtitle("Replace Document");
        break;
      case UPLOAD_ACTIONS.QUOTE_SIGNED_CREATE:
        SetImportFileFormDialogTitle("Upload Signed Quote");
        SetImportFileFormDialogSubtitle("Upload Document");
        break;
      case UPLOAD_ACTIONS.QUOTE_SIGNED_EDIT:
        SetImportFileFormDialogTitle("Update Signed Quote");
        SetImportFileFormDialogSubtitle("Replace Document");
        break;
      case UPLOAD_ACTIONS.LSR_INITIAL_EDIT:
        SetImportFileFormDialogTitle(
          "Update Initial Laboratory Service Request"
        );
        SetImportFileFormDialogSubtitle("Replace Document");
        break;
      case UPLOAD_ACTIONS.LSR_SIGNED_EDIT:
        SetImportFileFormDialogTitle(
          "Update Signed Laboratory Service Request"
        );
        SetImportFileFormDialogSubtitle("Replace Document");
        break;
      default:
        SetImportFileFormDialogTitle("");
        SetImportFileFormDialogSubtitle("");
        break;
    }
  };

  const handleImportFileFormClose = (e) => {
    SetIsOpenImportFileFormDialog(false);
    SetImportFileFormDialogTitle("");
    SetImportFileFormDialogSubtitle("");
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      SetFormDataFile(e.target.files[0]);
    }
  };

  const handleUploadDocument = () => {
    let url = "";
    let toastMessage = "";
    if (
      submitAction === UPLOAD_ACTIONS.QUOTE_INITIAL_CREATE ||
      submitAction === UPLOAD_ACTIONS.QUOTE_INITIAL_EDIT
    ) {
      url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/upload_quote_initial/`;
      toastMessage = "Initial Quote successfully uploaded!";
      createTransactionLog({
        arilInquiry_id: inquiryId,
        action: "Uploaded Inquiry Initial Quote",
      });
    } else if (
      submitAction === UPLOAD_ACTIONS.QUOTE_SIGNED_CREATE ||
      submitAction === UPLOAD_ACTIONS.QUOTE_SIGNED_EDIT
    ) {
      url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/upload_quote_signed/`;
      toastMessage = "Signed Quote successfully uploaded!";
      createTransactionLog({
        arilInquiry_id: inquiryId,
        action: "Uploaded Inquiry Signed Quote",
      });
    } else if (submitAction === UPLOAD_ACTIONS.LSR_INITIAL_EDIT) {
      url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/upload_lsr_initial/`;
      toastMessage =
        "Initial Laboratory Service Request successfully uploaded!";
      createTransactionLog({
        arilInquiry_id: inquiryId,
        action: "Uploaded Inquiry Initial Laboratory Service Request",
      });
    } else if (
      submitAction === UPLOAD_ACTIONS.LSR_SIGNED_CREATE ||
      submitAction === UPLOAD_ACTIONS.LSR_SIGNED_EDIT
    ) {
      url = `${api_urls.ARIL_INQUIRY}/${inquiryId}/upload_lsr_signed/`;
      toastMessage = "Signed Laboratory Service Request successfully uploaded!";
      createTransactionLog({
        arilInquiry_id: inquiryId,
        action: "Uploaded Inquiry Signed Laboratory Service Request",
      });
    } else {
      dispatch(
        setToastNotification({
          toastNotification: {
            isOpen: true,
            type: "error",
            message: "Invalid Submit Action!",
          },
        })
      );
    }
    const form = new FormData();
    form.append("file", formDataFile);
    form.append("inquiry_id", inquiryId);
    axios
      .put(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        SetIsOpenImportFileFormDialog(false);
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: toastMessage,
            },
          })
        );
      })
      .catch((err) => {
        SetIsOpenImportFileFormDialog(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleDownloadQuoteOrLSR = (e, action) => {
    e.preventDefault();
    dispatch(
      downloadQuoteOrLSR({
        inquiryId,
        quote: inquiryDetails.quote,
        quoteSigned: inquiryDetails.quoteSigned,
        lsr: inquiryDetails.lsr,
        lsrSigned: inquiryDetails.lsrSigned,
        action,
      })
    );
  };

  const handleSaveQuoteComments = (e) => {
    e.preventDefault();
    axios
      .put(
        `${api_urls.ARIL_INQUIRY}/${inquiryId}/update_quote_signed_comments/`,
        {
          quote_signed_comments: quoteComments,
        }
      )
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully Added comments on quote!",
            },
          })
        );
        SetIsEditQuoteComments(false);
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Updated Inquiry Quote Comments",
        });
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleUpdateQuoteStatus = (e, status) => {
    e.preventDefault();
    axios
      .put(
        `${api_urls.ARIL_INQUIRY}/${inquiryId}/update_quote_signed_status/`,
        {
          quote_signed_status: status.value,
        }
      )
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully updated the quote status!",
            },
          })
        );
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Updated Inquiry Quote Status",
        });
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleSendLSR = (e) => {
    e.preventDefault();
    axios
      .put(`${api_urls.ARIL_INQUIRY}/${inquiryId}/update_lsr/`)
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message:
                "Laboratory Service Request successfully sent to client!",
            },
          })
        );
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Sent Inquiry Laboratory Service Request",
        });
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleUpdateLSRStatus = (e, status) => {
    e.preventDefault();
    axios
      .put(`${api_urls.ARIL_INQUIRY}/${inquiryId}/update_lsr_signed_status/`, {
        lsr_signed_status: status.value,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message:
                "Successfully updated the Laboratory Service Request status!",
            },
          })
        );
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Updated Inquiry Laboratory Service Request Status",
        });
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const handleSaveLSRComments = (e) => {
    e.preventDefault();
    axios
      .put(
        `${api_urls.ARIL_INQUIRY}/${inquiryId}/update_lsr_signed_comments/`,
        {
          lsr_signed_comments: lsrComments,
        }
      )
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message:
                "Successfully Added comments on Laboratory Service Request!",
            },
          })
        );
        SetIsEditLSRComments(false);
        createTransactionLog({
          arilInquiry_id: inquiryId,
          action: "Updated Inquiry Laboratory Service Request Comments",
        });
      })
      .catch((err) => {
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "error",
              message: "There's an error trying to submit data to the server!",
            },
          })
        );
      });
  };

  const renderQuoteInitialFileActions = () => {
    return inquiryDetails.quote ? (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) =>
            handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.QUOTE_INITIAL)
          }
        >
          <ins className="text-info">Download</ins>
        </a>
        <a
          href="/#"
          onClick={(e) =>
            handleImportFileFormOpen(e, UPLOAD_ACTIONS.QUOTE_INITIAL_EDIT)
          }
        >
          <ins className="text-info">Edit</ins>
        </a>
      </>
    ) : (
      <a
        href="/#"
        onClick={(e) =>
          handleImportFileFormOpen(e, UPLOAD_ACTIONS.QUOTE_INITIAL_CREATE)
        }
      >
        <ins className="text-info">Upload</ins>
      </a>
    );
  };

  const renderQuoteSignedFileActions = () => {
    return !inquiryDetails.quote ? (
      <>Initial Quote is not yet uploaded</>
    ) : !inquiryDetails.quoteSigned ? (
      <a
        href="/#"
        onClick={(e) =>
          handleImportFileFormOpen(e, UPLOAD_ACTIONS.QUOTE_SIGNED_CREATE)
        }
      >
        <ins className="text-info">Upload Manually</ins>
      </a>
    ) : (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) =>
            handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.QUOTE_SIGNED)
          }
        >
          <ins className="text-info">Download</ins>
        </a>
        <a
          href="/#"
          onClick={(e) =>
            handleImportFileFormOpen(e, UPLOAD_ACTIONS.QUOTE_SIGNED_EDIT)
          }
        >
          <ins className="text-info">Edit</ins>
        </a>
      </>
    );
  };

  const getQuoteSignedStatusLabel = (value) => {
    return value
      ? aril_constants.INQUIRY_QUOTE_SIGNED_STATUS.find(
          (data) => data.value === value
        ).label
      : "";
  };

  const renderQuoteSignedStatus = () => {
    let labelClass = "";
    switch (inquiryDetails.quoteSignedStatus) {
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[0].value:
        labelClass = "label-warning";
        break;
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[3].value:
        labelClass = "label-danger";
        break;
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[4].value:
        labelClass = "label-success";
        break;
      default:
        labelClass = "label-primary";
        break;
    }
    return (
      <label className={`label label-md ${labelClass}`}>
        {getQuoteSignedStatusLabel(inquiryDetails.quoteSignedStatus)}
      </label>
    );
  };

  const renderQuoteCommentsButton = () => {
    if (!isEditQuoteComments) {
      return !inquiryDetails.quoteSignedComments ? (
        <button
          className="btn btn-sm btn-primary btn-outline-primary"
          onClick={(e) => SetIsEditQuoteComments(true)}
        >
          Add Comments
        </button>
      ) : (
        <a
          href="/#"
          className="mt-2 ml-3"
          onClick={(e) => {
            e.preventDefault();
            SetQuoteComments(inquiryDetails.quoteSignedComments);
            SetIsEditQuoteComments(true);
          }}
        >
          <i className="fa fa-edit"></i>
        </a>
      );
    }
  };

  const renderQuoteComments = () => {
    if (!isEditQuoteComments) {
      return inquiryDetails.quoteSignedComments;
    }
    return (
      <div className="no-padding d-flex flex-row">
        <input
          type="text"
          value={quoteComments}
          className="form-control"
          onChange={(e) => SetQuoteComments(e.target.value)}
        />
        <button
          className="btn btn-sm btn-primary ml-3"
          onClick={handleSaveQuoteComments}
        >
          Save
        </button>
        <a
          href="/#"
          className="mt-2 ml-3"
          onClick={(e) => {
            e.preventDefault();
            SetIsEditQuoteComments(false);
          }}
        >
          <i className="fa fa-times"></i>
        </a>
      </div>
    );
  };

  const renderQuoteActionButtons = () => {
    let approveButtonClass = "";
    let rejectButtonClass = "";
    switch (inquiryDetails.quoteSignedStatus) {
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[1].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[2].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[3].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-disabled disabled";
        break;
      case aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[4].value:
        approveButtonClass = "btn-disabled disabled";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      default:
        approveButtonClass = "btn-disabled disabled";
        rejectButtonClass = "btn-disabled disabled";
        break;
    }
    return (
      <>
        <button
          onClick={(e) =>
            handleUpdateQuoteStatus(
              e,
              aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[4]
            )
          }
          className={`btn btn-sm pb-2 pt-2 mr-2 ${approveButtonClass}`}
          type="button"
        >
          Approve
        </button>
        <button
          onClick={(e) =>
            handleUpdateQuoteStatus(
              e,
              aril_constants.INQUIRY_QUOTE_SIGNED_STATUS[3]
            )
          }
          className={`btn btn-sm pb-2 pt-2 ${rejectButtonClass}`}
          type="button"
        >
          Dissaprove
        </button>
      </>
    );
  };

  const renderLSRInitialFileActions = () => {
    return inquiryDetails.lsr ? (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) =>
            handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.LSR_INITIAL)
          }
        >
          <ins className="text-info">Download</ins>
        </a>
        <a
          href="/#"
          onClick={(e) =>
            handleImportFileFormOpen(e, UPLOAD_ACTIONS.LSR_INITIAL_EDIT)
          }
        >
          <ins className="text-info">Edit</ins>
        </a>
      </>
    ) : (
      <a href="/#" onClick={handleSendLSR}>
        <ins className="text-info">
          Send Laboratory Service Request to client
        </ins>
      </a>
    );
  };

  const renderLSRSignedFileActions = () => {
    return !inquiryDetails.lsr ? (
      <>Initial Laboratory Service Request is not yet uploaded</>
    ) : !inquiryDetails.lsrSigned ? (
      <a
        href="/#"
        onClick={(e) =>
          handleImportFileFormOpen(e, UPLOAD_ACTIONS.LSR_SIGNED_CREATE)
        }
      >
        <ins className="text-info">Upload Manually</ins>
      </a>
    ) : (
      <>
        <a
          href="/#"
          className="mr-3"
          onClick={(e) =>
            handleDownloadQuoteOrLSR(e, DOWNLOAD_ACTIONS.LSR_SIGNED)
          }
        >
          <ins className="text-info">Download</ins>
        </a>
        <a
          href="/#"
          onClick={(e) =>
            handleImportFileFormOpen(e, UPLOAD_ACTIONS.LSR_SIGNED_EDIT)
          }
        >
          <ins className="text-info">Edit</ins>
        </a>
      </>
    );
  };

  const getLSRSignedStatusLabel = (value) => {
    return value
      ? aril_constants.INQUIRY_LSR_SIGNED_STATUS.find(
          (data) => data.value === value
        ).label
      : "";
  };

  const renderLSRSignedStatus = () => {
    let labelClass = "";
    switch (inquiryDetails.lsrSignedStatus) {
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[0].value:
        labelClass = "label-warning";
        break;
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[3].value:
        labelClass = "label-danger";
        break;
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[4].value:
        labelClass = "label-success";
        break;
      default:
        labelClass = "label-primary";
        break;
    }
    return (
      <label className={`label label-md ${labelClass}`}>
        {getLSRSignedStatusLabel(inquiryDetails.lsrSignedStatus)}
      </label>
    );
  };

  const renderLSRCommentsButton = () => {
    if (!isEditLSRComments) {
      return !inquiryDetails.lsrSignedComments ? (
        <button
          className="btn btn-sm btn-primary btn-outline-primary"
          onClick={(e) => SetIsEditLSRComments(true)}
        >
          Add Comments
        </button>
      ) : (
        <a
          href="/#"
          className="mt-2 ml-3"
          onClick={(e) => {
            e.preventDefault();
            SetLSRComments(inquiryDetails.lsrSignedComments);
            SetIsEditLSRComments(true);
          }}
        >
          <i className="fa fa-edit"></i>
        </a>
      );
    }
  };

  const renderLSRComments = () => {
    if (!isEditLSRComments) {
      return inquiryDetails.lsrSignedComments;
    }
    return (
      <div className="no-padding d-flex flex-row">
        <input
          type="text"
          value={lsrComments}
          className="form-control"
          onChange={(e) => SetLSRComments(e.target.value)}
        />
        <button
          className="btn btn-sm btn-primary ml-3"
          onClick={handleSaveLSRComments}
        >
          Save
        </button>
        <a
          href="/#"
          className="mt-2 ml-3"
          onClick={(e) => {
            e.preventDefault();
            SetIsEditLSRComments(false);
          }}
        >
          <i className="fa fa-times"></i>
        </a>
      </div>
    );
  };

  const renderLSRActionButtons = () => {
    let approveButtonClass = "";
    let rejectButtonClass = "";
    switch (inquiryDetails.lsrSignedStatus) {
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[1].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[2].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[3].value:
        approveButtonClass = "btn-success btn-outline-success";
        rejectButtonClass = "btn-disabled disabled";
        break;
      case aril_constants.INQUIRY_LSR_SIGNED_STATUS[4].value:
        approveButtonClass = "btn-disabled disabled";
        rejectButtonClass = "btn-danger btn-outline-danger";
        break;
      default:
        approveButtonClass = "btn-disabled disabled";
        rejectButtonClass = "btn-disabled disabled";
        break;
    }
    return (
      <>
        <button
          onClick={(e) =>
            handleUpdateLSRStatus(
              e,
              aril_constants.INQUIRY_LSR_SIGNED_STATUS[4]
            )
          }
          className={`btn btn-sm pb-2 pt-2 mr-2 ${approveButtonClass}`}
          type="button"
        >
          Approve
        </button>
        <button
          onClick={(e) =>
            handleUpdateLSRStatus(
              e,
              aril_constants.INQUIRY_LSR_SIGNED_STATUS[3]
            )
          }
          className={`btn btn-sm pb-2 pt-2 ${rejectButtonClass}`}
          type="button"
        >
          Dissaprove
        </button>
      </>
    );
  };

  return (
    <div className="col-md-12">
      <ImportFileFormDialog
        isOpen={isOpenImportFileFormDialog}
        title={importFileFormDialogTitle}
        subtitle={importFileFormDialogSubtitle}
        fileInputhandler={handleFileInput}
        handleSubmit={handleUploadDocument}
        handleClose={handleImportFileFormClose}
      />
      <div className="card z-depth-0">
        <div className="card-header">
          <h5>Quote and Laboratory Service Request</h5>
        </div>
        <div className="card-block">
          <table className="table">
            <thead>
              <tr>
                <th>Document Name</th>
                <th>Initial Document</th>
                <th>Signed Document</th>
                <th>Status</th>
                <th>Comments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle">Laboratory Service Request</td>
                <td className="align-middle">
                  {renderLSRInitialFileActions()}
                </td>
                <td className="align-middle">{renderLSRSignedFileActions()}</td>
                <td className="align-middle">{renderLSRSignedStatus()}</td>
                <td className="align-middle">
                  {renderLSRComments()}
                  {renderLSRCommentsButton()}
                </td>
                <td className="align-middle">{renderLSRActionButtons()}</td>
              </tr>
              <tr>
                <td className="align-middle">Quote</td>
                <td className="align-middle">
                  {renderQuoteInitialFileActions()}
                </td>
                <td className="align-middle">
                  {renderQuoteSignedFileActions()}
                </td>
                <td className="align-middle">{renderQuoteSignedStatus()}</td>
                <td className="align-middle">
                  {renderQuoteComments()}
                  {renderQuoteCommentsButton()}
                </td>
                <td className="align-middle">{renderQuoteActionButtons()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const ImportFileFormDialog = ({
  isOpen,
  title,
  subtitle,
  fileInputhandler,
  handleSubmit,
  handleClose,
}) => {
  const styleClasses = useStyles();
  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="md">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText id="alert-dialog-description">
          {subtitle}
        </DialogContentText>
        <form className={styleClasses.form} noValidate>
          <FormControl
            variant="outlined"
            className={styleClasses.formControl}
            style={{ minWidth: 350 }}
          >
            <input type="file" onChange={fileInputhandler} />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
