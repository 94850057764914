import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { api_urls } from "../../../../../constants";
import { setToastNotification } from "../../../../../store/employee/dashboardReducer";
import { retrieveInquiry } from "../../../../../store/employee/lab_services/inquiryActions";
import { InquiryFilesButtons } from "./InquiryFilesButtons";
import { getEllipsisText } from "../../../../utils/StringUtils";

export const InquiryFilesComments = ({ inquiryId, fileDetails }) => {
  const dispatch = useDispatch();

  const [isEditQuoteComments, SetIsEditQuoteComments] = useState(false);
  const [comments, SetComments] = useState(false);

  useEffect(() => {
    SetComments(fileDetails.comments);
  }, [fileDetails]);

  const handleSaveComments = (e) => {
    e.preventDefault();
    axios
      .patch(`${api_urls.LABSERVICES_INQUIRY_FILES_PATCH + fileDetails.id}/`, {
        comments: comments,
      })
      .then((res) => {
        dispatch(retrieveInquiry({ inquiry_id: inquiryId }));
        SetIsEditQuoteComments(false);
        dispatch(
          setToastNotification({
            toastNotification: {
              isOpen: true,
              type: "success",
              message: "Successfully added/updated the comments!",
            },
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 404 || err.response.status === 500) {
          dispatch(
            setToastNotification({
              toastNotification: {
                isOpen: true,
                type: "error",
                message:
                  "There's an error trying to submit data to the server!",
              },
            })
          );
        }
      });
  };

  return (
    <>
      {!isEditQuoteComments ? (
        <>{getEllipsisText(comments, 30)}</>
      ) : (
        <div className="no-padding d-flex flex-row">
          <input
            type="text"
            value={comments}
            className="form-control"
            onChange={(e) => SetComments(e.target.value)}
          />
          <button
            className="btn btn-sm btn-primary ml-3"
            onClick={handleSaveComments}
          >
            Save
          </button>
          <a
            href="/#"
            className="mt-2 ml-3"
            onClick={(e) => {
              e.preventDefault();
              SetIsEditQuoteComments(false);
            }}
          >
            <i className="fa fa-times"></i>
          </a>
        </div>
      )}
      <InquiryFilesButtons
        isEditQuoteComments={isEditQuoteComments}
        comments={comments}
        SetIsEditQuoteComments={SetIsEditQuoteComments}
        SetComments={SetComments}
      />
    </>
  );
};
